import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Controller, useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "react-phone-input-2/lib/style.css";
import { editprofile, teacherViewProfile } from '../../../api/apiHandler'
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service'
import { Helmet } from 'react-helmet';
import { uploadImageOnAWS } from '../../aws/Service';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard';
import OFooter_Dashboard from '../OInclude/OFooter_Dashboard';

export default function OEditProfile() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();

  const schema = yup.object().shape({
    // full_name : yup.string().required("Assessment topic is required"),

  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  console.log('errors :', errors);

  const [data, setData] = useState([]);
  var [CountryData, SetCountryData] = useState([]);
  const [profileTeacherImg, setProfileTeacherImg] = useState('')
  const [profileTeacherVideo, setProfileTeacherVideo] = useState('')
  const [profileTeacherCover, setProfileTeacherCover] = useState('')
  const allowedCountries = ['au', 'ca', 'nz', 'sg', 'gb', 'us'];
  const [phone, setPhone] = useState('');
  console.log('phone :', phone);
  const [country, setCountry] = useState('ca');
  const [countryCode, setCountryCode] = useState('');

  const teacherPtofile = () => {

    teacherViewProfile().then((response) => {
    console.log('teacher_view_profile :', response);

      if (response?.code == 1) {
        setPhone(response.data.phone);
        setCountryCode(response.data.country_code);
        setData(response.data)
        SetCountryData(response.data.country_name)
        setProfileTeacherImageState(response.data.teacher_profile_image)
        setProfileImageState(response.data.teacher_cover_photo)
        setProfileVideoState(response.data.teacher_video)
        setProfileTeacherImg(response?.data?.profile_image)
        setProfileTeacherVideo(response?.data?.video)
        setProfileTeacherCover(response?.data?.cover_photo)


        reset({
          full_name: response?.data?.full_name,
          email: response?.data?.email,
          add_qualification : response?.data?.add_qualification,
          password : response?.data?.password,
          phone : response?.data?.phone,
          about_me : response?.data?.about_me,
          phone: response?.data?.country_code + response?.data?.phone

          // country : response?.data?.country_name,

      });
      setValue('phone', response.data.phone);

      // defaultValue={data.add_qualification != null ? data.add_qualification : " "}
      } else {
         
      }
    });
  }

  useEffect(() => {
    teacherPtofile();
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const countryData = (data) => {
    // alert(data)
    SetCountryData(data)
  }

  const [profileTeacherImageState, setProfileTeacherImageState] = useState('./assets/images/Kids-profile images-big.png')
  console.log('profileTeacherImageState :', profileTeacherImageState);

  const handleTeacherImageChange = async (image) => {
    // alert('a')
    setValue("imageUploadTeacher", image);
    const bufferImage = await convertToBase64(image);
    setProfileTeacherImageState(bufferImage);
    clearErrors("imageUploadTeacher");
  }

  const [profileImageState, setProfileImageState] = useState('')
  // console.log("profileImageState", profileImageState)

  const handleImageChange = async (image) => {
    setValue("profileImage", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState(bufferImage);
    clearErrors("profileImage");
  }

  const [profileVideoState, setProfileVideoState] = useState(null)
  const handleVideoChange = async (Video) => {
    setValue("profileVideo", Video);
    const bufferVideo = await convertToBase64(Video);

    setProfileVideoState(bufferVideo);
    clearErrors("profileVideo");
  }

  const handleOnChange = (value, country) => {
    // console.log('country35453 :', country.dialCode);
    // console.log('value :', value);
    const formattedValue = value.substring(country.dialCode.length);
    // console.log('formattedValue :', formattedValue);
    setCountryCode(country.dialCode)
    setPhone(formattedValue);
    setCountry(country.dialCode);
    setValue('phone', value)

  };

  let onSubmit = async (data) => {
  console.log('onSubmit :', data);
    // console.log("teacherProfile", data.imageUploadTeacher)

    let teacherProfile = data?.imageUploadTeacher
    if (teacherProfile instanceof Blob) teacherProfile = await uploadImageOnAWS(data?.imageUploadTeacher, "profile_image");

    let teacherCover = data?.profileImage
    if (teacherCover instanceof Blob) teacherCover = await uploadImageOnAWS(data?.profileImage, "profile_teacher_cover_photo");

    let teacherVideo = data?.profileVideo
    if (teacherVideo instanceof Blob) teacherVideo = await uploadImageOnAWS(data?.profileVideo, "profile_teacher_video");

    // var teacherProfile = await uploadImageOnAWS(data?.imageUploadTeacher, "profile_image");
    // var video = await uploadImageOnAWS(data?.profileVideo, "class_image");
    // console.log('video :', video);
    // var document_lesson = await uploadImageOnAWS(data?.doc, "class_image");
    // console.log('document_lesson :', document_lesson);


    let edit_teacher_data = {
      category : "organization",
      full_name: data.full_name,
      email: data.email,
      country_code : "+" + countryCode,
      phone : phone,
      password: data.password,
      login_type: "S",
      country_name: data.country,
      add_qualification: data.add_qualification,
      about_me : data.about_me
    }

    if(teacherProfile != null && teacherProfile != undefined && teacherProfile != ""  ){
      edit_teacher_data.profile_image = teacherProfile
    }else{
      edit_teacher_data.profile_image = profileTeacherImg
    }
    if(teacherCover != null && teacherCover != undefined && teacherCover != ""  ){
      edit_teacher_data.cover_photo = teacherCover
    }else{
      edit_teacher_data.cover_photo = profileTeacherCover
    }
    if(teacherVideo != null && teacherVideo != undefined && teacherVideo != ""  ){
      edit_teacher_data.video = teacherVideo
    }else{
      edit_teacher_data.video = profileTeacherVideo
    }
    editprofile(edit_teacher_data).then((res) => {

      if (res.code == 1) {
        TOAST_SUCCESS(res.message);
        // navigate('/tech_detail',state:{}); 
        navigate('/organization_dashboard')
      } else {
        TOAST_ERROR(res.message);
      }
    });

  }



  return (
    <>
      <Helmet>
        <style>{`
      .parent_edit img {
        width: 350px;
        height: 347px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
      }

      .react-tel-input .form-control {
        width: 93% !important;
        height: 45px !important;
        margin-left: 45px !important;

      }
      .react-tel-input {
        border-radius: 0.25rem !important;
      }
      
      .flag-dropdown {
        background-color: #f2f2f2 !important;
      }
      .css-olqui2-singleValue {
        grid-area: 1/1/2/3;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: hsl(0deg 0% 6.3%);
        margin-left: 2px;
        margin-right: 2px;
        box-sizing: border-box;
    }
    .css-1vx9cxd-control {
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      cursor: default;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      min-height: 38px;
      outline: 0!important;
      position: relative;
      -webkit-transition: all 100ms;
      transition: all 100ms;
      /* background-color: hsl(0, 0%, 95%); */
      border-color: hsl(0, 0%, 90%);
      border-radius: 12px;
      border-style: solid;
      border-width: 1px;
      box-sizing: border-box;
      border: 1px solid rgba(102, 102, 102, 0.35);
      padding: 5px 10px;
      height: 46px;
      background-color: #e9ecef;
    }
      `}</style>
      </Helmet>
      <JqueryLoad />
      <OHeader_Dashboard />
  <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={()=>navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span  className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}
              <div className>
                <div className=" ">
                  {/*start*/}
                  <div className="mb-4">
                    <h2 className="mb-2 text-capitalize text-dif-black poppins fw-600">Please update your profile here.</h2>
                  </div>
                  {/*end*/}
                  {/*start*/}
                  <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                    <div className="row mb-3 align-items-center">
                      <div className="col-lg-6">
                        <div className="form-group mb-4">
                          <label className="mb-2">full name</label>
                          <input type="text" className="form-control" {...register("full_name")} name="full_name" />
 
                        </div>
                        <div className="form-group mb-4">
                          <label className="mb-2">Add Qualification</label>
                          <input type="text" className="form-control" {...register("add_qualification")} name="add_qualification"  />
                        </div>
                        <div className="form-group mb-4">
                          <label className="mb-2">Email ID(Not Shared In Public)</label>
                          <input type="email" className="form-control" {...register("email")} name="email"  />
                        </div>
                        <div className="form-group mb-4">
                          <div className="d-flex justify-content-between align-items-center">
                            <label className="mb-2">Password</label>
                            <a href="#" onClick={handleTogglePassword}>
                              <span>
                                {showPassword ? (
                                  <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" /></svg>
                                ) : (
                                  // <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">

                                  // </svg>


                                  <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z" fill="#666666" fillOpacity="0.8" />
                                    <path d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z" fill="#666666" fillOpacity="0.8" />
                                  </svg>
                                )}
                              </span>
                              {/* {showPassword ? 'Hide' : 'Show'} */}
                            </a>
                          </div>
                          <input name="password"
                            {...register("password")}

                            type={showPassword ? 'text' : 'password'}
                            placeholder=""
                            className="form-control" />
                        </div>
                        {/* <div className="form-group mb-4">
                          <label className="mb-2 d-block">Phone Number(Not Shared In Public)</label>
                          <input type="text" id="mobile_code" className="form-control w-100" {...register("phone")} name="phone" />
                        </div> */}
                         <div className="form-group mb-4"> 
                          <label className="mb-2 d-block">Phone Number(Not Shared In Public)</label>
                          <PhoneInput placeholder="Enter phone number" name="phone" {...register("phone")} value={`${countryCode}${phone}`}
                            id="phone" country="ca" onChange={(value, country) => handleOnChange(value, country)} onlyCountries={allowedCountries}  
                            />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.phone?.message}</span></p>

                        </div>
                        <div className="form-group mb-4">
                          <label className="mb-2">Country</label>
                          <select className="form-select" {...register("country")} name="country"
                            onChange={(e) => countryData(e.target.value)} aria-label="Default select example">
                            {/* <option selected={CountryData == 'Australia' ? 'selected' : ''} value="4" >Australia</option>
                            <option selected={CountryData == 'Canada' ? 'selected' : ''} value="1">Canada</option>
                            <option selected={CountryData == 'New Zealand' ? 'selected' : ''} value="5">New Zealand</option>
                            <option selected={CountryData == 'Singapore' ? 'selected' : ''} value="6">Singapore</option>
                            <option selected={CountryData == 'UK' ? 'selected' : ''} value="3">UK</option>
                            <option selected={CountryData == 'USA' ? 'selected' : ''} value="2">USA</option> */}

                            <option selected={CountryData == 'Australia' ? 'selected' : ''} value="14" >🇦🇺  Australia</option>
                            <option selected={CountryData == 'Canada' ? 'selected' : ''} value="39">🇨🇦  Canada</option>
                            <option selected={CountryData == 'New Zealand' ? 'selected' : ''} value="158">🇳🇿  New Zealand</option>
                            <option selected={CountryData == 'Singapore' ? 'selected' : ''} value="199">🇸🇬  Singapore</option>
                            <option selected={CountryData == 'UK' ? 'selected' : ''} value="232">🇬🇧  UK</option>
                            <option selected={CountryData == 'USA' ? 'selected' : ''} value="233">🇺🇸  USA</option>
                          </select>
                        </div>
                      </div>

                      <Controller
                        name="imageUploadTeacher"
                        control={control}
                        defaultValue={null}
                        rules={{ required: "Profile image is required" }}
                        render={({ field }) => (
                          <>
                                                   
                            <div className="col-lg-6 mb-lg-0 mb-5 order-lg-last order-first text-lg-end text-center">
                              <div className="parent_edit ms-lg-auto m-auto position-relative">
                                <img src={profileTeacherImageState} alt="kids-profile-demo" />
                                <div className="position-absolute edit_pren_prof">
                                  {/* Wrap SVG with a label to trigger file input */}
                                  <label htmlFor="imageUploadTeacher">
                                    <svg width={30} height={30} viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M4.24945 33.6268C4.64791 33.6268 4.7276 33.587 5.08622 33.5073L12.2585 32.0728C13.0156 31.8736 13.7727 31.515 14.3704 30.9173L31.7433 13.5444C34.413 10.8747 34.413 6.29237 31.7433 3.62267L30.269 2.06868C27.5993 -0.601017 22.9771 -0.601017 20.3074 2.06868L2.93453 19.4814C2.37668 20.0393 1.97822 20.8362 1.77899 21.5933L0.264837 28.8453C0.0656068 30.2001 0.464068 31.515 1.42037 32.4713C2.17745 33.2284 3.29314 33.6268 4.24945 33.6268ZM5.60422 22.3504L22.9771 4.9376C24.1327 3.78206 26.2445 3.78206 27.3602 4.9376L28.8744 6.45175C30.2291 7.80652 30.2291 9.71914 28.8744 11.0341L11.5413 28.4468L4.16976 29.6821L5.60422 22.3504Z" fill="#1D1E25" />
                                      <path d="M31.5847 36.0977H2.17824C1.0227 36.0977 0.265625 36.8547 0.265625 38.0103C0.265625 39.1658 1.22193 39.9229 2.17824 39.9229H31.4253C32.5809 39.9229 33.5372 39.1658 33.5372 38.0103C33.4973 36.8547 32.541 36.0977 31.5847 36.0977Z" fill="#1D1E25" />
                                    </svg>
                                  </label>
                                </div>
                                <input
                                  type="file"
                                  className="file-input"
                                  id="imageUploadTeacher"
                                  accept="image/*"
                                  onInput={(e) => handleTeacherImageChange(e.target.files[0])}
                                  style={{ display: 'none' }}  // Hide the file input
                                />
                              </div>
                              <div className='pro_img'><p>Add your profile picture. </p>
                              <p>
                              Please take a clear picture of your face. Don’t forget to smile 🙂
                              </p>
                              </div>

                            </div>
                          </>
                        )}
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group mb-4 class_image edit_img_profile">
                          <label className="form-label">Add Cover Photo. The image should not have any text.</label>
                          <Controller name="profileImage" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                            <>
                              <div className="imageWrapper position-relative">
                                <div className="d-flex align-items-center justify-content-center">
                                  <img className="image" src={profileImageState ? profileImageState : "./assets/images/image_upload.png"} alt="image_upload" style={{ width: profileImageState ? "100%" : "", marginTop: "22px" }} />
                                </div>
                                <label htmlFor='profileImage'>

                                  <button className="file-upload">
                                    <input type="file" className="file-input" id="profileImage" accept="image/*" onInput={(e) => handleImageChange(e.target.files[0])} />Choose File
                                  </button>
                                </label>

                              </div>
                            </>
                          )} />
                        </div>

                        <div className="form-group mb-4 class_image edit_img_profile">
                          <label className="form-label"> About Me Video. Please add a 30 second video marketing yourself and your classes. </label>
                          <p className="fs-6 mb-2 text-capitalize" style={{color: "#666666"}} >Please remember: You should NOT include any personal information such as your email address, contact number, address, or any website or social media details as it is against our policies. </p>

                          <Controller name="profileVideo" control={control} defaultValue={null} rules={{ required: "Please upload video" }} render={({ field }) => (
                            <>
                              <div className="imageWrapper position-relative">
                                <div className="d-flex align-items-center justify-content-center">
                                  {profileVideoState ?
                                    <video src={profileVideoState} width="100%" height="100%" style={{ marginTop: "22px" }} className="video" loop muted autoPlay playsInline controls></video>
                                    :
                                    <img className="video" src="./assets/images/video_upload.png" style={{ "width": profileVideoState ? "100%" : "", marginTop: "15px" }} alt="video_upload" />}
                                </div>
                                <label htmlFor='profileVideo'>
                                  <button className="file-upload">
                                    <input type="file" className="file-input" id="profileVideo" accept="video/*"  onChange={(e) => handleVideoChange(e.target.files[0])} />Choose File
                                  </button>
                                </label>
                              </div>
                            </>
                          )} />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.profileVideo?.message}</span></p>

                        </div>
                        <div className="form-group mb-4">
                          <label className="form-label">About Me Description. </label>
                          <p className="fs-6 mb-2 text-capitalize" style={{color: "#666666"}} >Add short description about your</p>
                          <p className="fs-6 mb-2 text-capitalize" style={{color: "#666666"}} >-Education qualifications</p>
                          <p className="fs-6 mb-2 text-capitalize" style={{color: "#666666"}} >-Teaching history and qualifications</p>
                          <p className="fs-6 mb-2 text-capitalize" style={{color: "#666666"}} >-Please remember: You should NOT enter any personal information such as your email address, contact number, address, or any website or social media details as it is against our policies.</p>
                          <div className="position-relative class_summary">

                          <textarea className="form-control" {...register("about_me")} name="about_me" maxLength={300} rows={7}  />
                          <span className="position-absolute word_count">{300 - (watch("about_me")?.split('')?.length ?? 0)} Words</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="learner_profile_list row mt-5">
                      <div className="col-xl-2 col-lg-3 col-md-4">
                        <button className="btn-theme bg-yellow mb-xl-0 mb-3 px-3 text-center d-block w-100" >Save</button>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-4">
                        <Link to="/organization-profile-details" state={data.id} className="btn-theme px-3 text-center d-block">View My Public Profile</Link>
                      </div>
                    </div>
                  </form>
                  {/*end*/}
                  {/*start*/}

                  {/*end*/}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <OFooter_Dashboard />
    </>
  )
}
