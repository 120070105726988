import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { search_teacher_profile, teacher_follow, teacher_other_class_data, teacher_reviews_list } from '../../../api/apiHandler';
import { Video_URL } from '../../../Config';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import OFooter_Dashboard from '../OInclude/OFooter_Dashboard';
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard';

const dayjs = require('dayjs');

export default function ManageTeacherDetails() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();
  const location = useLocation();

  var teacher_id = location?.state;
  console.log('iddgfgfd :', teacher_id);

  const [TeacherData, setTeacherData] = useState([])
  console.log('TeacherData :', TeacherData);
  const [otherClassData, setOtherClassData] = useState([]);
  const [showAllReviews, setShowAllReviews] = useState(false);

  const toggleShowAllReviews = () => {
    setShowAllReviews(prevState => !prevState);
  };

  const TeacherProfile = () => {

    search_teacher_profile({ teacher_id: teacher_id }).then((response) => {
      console.log("search_teacher_profile1111", response)

      if (response?.code == 1) {
        if (response?.data[0]?.teacher_ratings == null) {
          response.data[0].teacher_ratings = 0
        }
        setTeacherData(response?.data[0])
      } else {
        setTeacherData([])

      }
    });
  }

  useEffect(() => {

    TeacherProfile();
  }, []);

  const teacher_other_class = () => {
    teacher_other_class_data({ teacher_id: teacher_id }).then((response) => {
      console.log('teacher_other_class_data :', response);
      if (response?.code == 1) {
        setOtherClassData(response.data)
      } else {

      }
    });
  }
  const [reviewData, setReviewData] = useState([]);
  console.log('reviewData :', reviewData);
  const ReviewList = () => {
    teacher_reviews_list({ teacher_id: teacher_id }).then((response) => {
      console.log('teacher_reviews_list :', response);
      if (response?.code == 1) {
        setReviewData(response.data)
      } else {

      }
    })
  }


  useEffect(() => {

    teacher_other_class();
    ReviewList();

  }, []);


  const followTeacher = (IdofTeacher) => {
    // alert("1")
    console.log('IdofTeacher :', IdofTeacher);
    teacher_follow({ teacher_id: IdofTeacher }).then((response) => {
      console.log('teacher_follow :', response);
      if (response?.code == 1) {
        TeacherProfile();
      } else {
        console.log("error")
      }
    });

  }

  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />

      <main className="dashboard-app d-lg-flex">
        <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
          <div className="align-items-center justify-content-between d-flex">
            <div className="d-flex align-items-center">
              <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                <i className="fa fa-bars" />
              </a>
              <a href="#" className="d-lg-none d-block brand-logo">
                <img src="./assets/images/logo.png" alt="logo" />
              </a>
            </div>
          </div>
        </header>
        {/*start*/}
        <div className="class-content pb-0" style={{ backgroundImage: 'url("./assets/images/teacher_bg.png")' }}>
          {/*start*/}
          <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
            <span>
              <svg width={10} height={18} viewBox="0 0 10 18" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#fff" />
              </svg>
              <span className="ms-2 text-white">Back</span>
            </span></a>
          {/*end*/}
          {/*start*/}
          <div className="d-flex align-items-center tch_head_det">
            <div className="tech_img">
              <img src={TeacherData.profile_image} alt="meet_techer" />
            </div>
            <div className="tech_ctn ms-4">
              <h2 className="text-white mb-1">{TeacherData.full_name}</h2>
              <div className="mb-3 d-flex align-items-center">
                <span className="text-white d-block">Lives in {TeacherData.country_name}</span>
                <span className="text-white d-block ms-2">{TeacherData.country_image}</span>
                {/* <img src="./assets/images/country_flag.png" alt="country_flag" className="ms-3 flag-img" /> */}
              </div>
              <p className="text-white">Experienced Multi-Subject Teacher: Creative Arts &amp; World Travels</p>
            </div>
          </div>
          {/*end*/}
        </div>
        {/*end*/}
        {/*start*/}
        <div className="class-content custom_btm_brdr">
          {/*start*/}
          <div className="row tech_about ">
            <div className="col-lg-6">
              <div className="d-xl-flex mb-3 align-items-center">
                <div className="deg_name mb-xl-0 mb-3">
                  <h2 className="poppins fw-600 text-purple">About Me</h2>
                </div>
                <div className="d-flex align-items-center ms-3">
                  {/* <a href="#" className="btn-theme bg-purple d-inline-block py-2" tabIndex={0}>Follow me</a> */}
                  {localStorage.getItem('Tid') != TeacherData?.teacher_id ?
                    <div>
                      <button className="btn-theme bg-purple d-inline-block py-2" onClick={() => followTeacher(TeacherData?.id)}>{TeacherData.is_follow == "1" ? "Following" : "Follow Me"}</button>
                    </div> : <div></div>
                  }
                  <span className="ms-3 text-dif-black">({TeacherData.follow_count} Followers)</span>
                </div>
              </div>
              <p className="text-capitalize">{TeacherData.about_me != null ? TeacherData.about_me : "N/A"}</p>
              <ul className="my-3">
                <li className="mb-3">
                  <span>
                    <svg width={30} height={30} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M31.2969 3.875H28.25V2.25C28.25 1.81902 28.0788 1.4057 27.774 1.10095C27.4693 0.796205 27.056 0.625 26.625 0.625C26.194 0.625 25.7807 0.796205 25.476 1.10095C25.1712 1.4057 25 1.81902 25 2.25V3.875H20.125V2.25C20.125 1.81902 19.9538 1.4057 19.649 1.10095C19.3443 0.796205 18.931 0.625 18.5 0.625C18.069 0.625 17.6557 0.796205 17.351 1.10095C17.0462 1.4057 16.875 1.81902 16.875 2.25V3.875H12V2.25C12 1.81902 11.8288 1.4057 11.524 1.10095C11.2193 0.796205 10.806 0.625 10.375 0.625C9.94402 0.625 9.5307 0.796205 9.22595 1.10095C8.92121 1.4057 8.75 1.81902 8.75 2.25V3.875H5.70312C4.35685 3.87672 3.06621 4.41229 2.11425 5.36425C1.16229 6.31621 0.62672 7.60685 0.625 8.95312V13.625H36.375V8.95312C36.3733 7.60685 35.8377 6.31621 34.8858 5.36425C33.9338 4.41229 32.6432 3.87672 31.2969 3.875ZM0.625 31.2969C0.62672 32.6432 1.16229 33.9338 2.11425 34.8858C3.06621 35.8377 4.35685 36.3733 5.70312 36.375H31.2969C32.6432 36.3733 33.9338 35.8377 34.8858 34.8858C35.8377 33.9338 36.3733 32.6432 36.375 31.2969V16.875H0.625V31.2969ZM12.4761 24.7676C12.7809 24.463 13.1941 24.2918 13.625 24.2918C14.0559 24.2918 14.4691 24.463 14.7739 24.7676L16.875 26.8671L22.2261 21.5176C22.5326 21.2216 22.9431 21.0578 23.3692 21.0615C23.7952 21.0652 24.2028 21.2361 24.5041 21.5374C24.8054 21.8387 24.9763 22.2463 24.98 22.6723C24.9837 23.0984 24.8199 23.5089 24.5239 23.8154L18.0239 30.3154C17.7191 30.62 17.3059 30.7912 16.875 30.7912C16.4441 30.7912 16.0309 30.62 15.7261 30.3154L12.4761 27.0654C12.1715 26.7606 12.0003 26.3474 12.0003 25.9165C12.0003 25.4856 12.1715 25.0724 12.4761 24.7676Z" fill="#570861" />
                    </svg>
                  </span>
                  <span className="ms-3 fw-600">{TeacherData?.completed_classes} Completed Class</span>
                </li>
                <li className>
                  <span>
                    <svg width={30} height={30} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M27.875 5.125H26.25V1.875C26.25 1.44402 26.0788 1.0307 25.774 0.725951C25.4693 0.421205 25.056 0.25 24.625 0.25C24.194 0.25 23.7807 0.421205 23.476 0.725951C23.1712 1.0307 23 1.44402 23 1.875V5.125H10V1.875C10 1.44402 9.82879 1.0307 9.52405 0.725951C9.2193 0.421205 8.80598 0.25 8.375 0.25C7.94402 0.25 7.5307 0.421205 7.22595 0.725951C6.92121 1.0307 6.75 1.44402 6.75 1.875V5.125H5.125C3.83207 5.125 2.59209 5.63861 1.67785 6.55285C0.763615 7.46709 0.25 8.70707 0.25 10V11.625H32.75V10C32.75 8.70707 32.2364 7.46709 31.3221 6.55285C30.4079 5.63861 29.1679 5.125 27.875 5.125ZM0.25 27.875C0.25 29.1679 0.763615 30.4079 1.67785 31.3221C2.59209 32.2364 3.83207 32.75 5.125 32.75H27.875C29.1679 32.75 30.4079 32.2364 31.3221 31.3221C32.2364 30.4079 32.75 29.1679 32.75 27.875V14.875H0.25V27.875Z" fill="#570861" />
                    </svg>
                  </span>

                  {console.log(TeacherData.joined_date)}
                  <span className="ms-3 fw-600">Joined {TeacherData.joined_date}</span>
                </li>
              </ul>
              <div className="mb-3 d-lg-flex align-items-center">

                <i data-star={TeacherData.teacher_ratings}></i>

                <span className="fs-6 fw-600 ms-lg-3">{TeacherData.teacher_ratings} Ratings</span>
                <span className="fw-bold text-gray d-sm-inline-block d-flex fs-6 ms-sm-2">({TeacherData.teacher_review} Total Reviews)</span>
              </div>
            </div>
            <div className="col-lg-6 right">
              <div className="tech_video_box position-relative">
                <video id="video" className="w-100" loop controls="controls" poster="./assets/images/video_bg_dash.png">
                  <source src={`${Video_URL}/Classroom -teachers (2).mp4`} type="video/mp4" />

                </video>
                <div className="play-button-wrapper">
                  <div title="Play video" className="play-gif" id="circle-play-b">
                    {/* SVG Play Button */}
                    <svg width={98} height={98} viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_b_1855_32422)">
                        <circle cx={49} cy={49} r={49} fill="#555050" fillOpacity="0.4" />
                      </g>
                      <path d="M74 49L36.5 70.6506L36.5 27.3494L74 49Z" fill="white" />
                      <defs>
                        <filter id="filter0_b_1855_32422" x={-10} y={-10} width={118} height={118} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                          <feFlood floodOpacity={0} result="BackgroundImageFix" />
                          <feGaussianBlur in="BackgroundImageFix" stdDeviation={5} />
                          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1855_32422" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1855_32422" result="shape" />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end*/}
        </div>
        {/*end*/}
        {/*start*/}
        <div className="class-content custom_btm_brdr">
          <h2 className="mb-4">Reviews</h2>
          <div className="mb-4 d-sm-flex align-items-center">
            <ul className="d-flex mb-sm-0 mb-2 align-items-center">
              <li className="me-2">
                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.4701 1.125C17.1612 0.4375 16.5084 0 15.7915 0C15.0745 0 14.4276 0.4375 14.1128 1.125L10.365 9.39375L1.99495 10.7188C1.2955 10.8313 0.712632 11.3563 0.496971 12.075C0.281309 12.7938 0.456169 13.5875 0.957438 14.1188L7.03094 20.5625L5.59708 29.6688C5.48051 30.4188 5.77194 31.1813 6.34898 31.625C6.92602 32.0688 7.68958 32.125 8.31908 31.7688L15.7973 27.4875L23.2755 31.7688C23.905 32.125 24.6686 32.075 25.2456 31.625C25.8227 31.175 26.1141 30.4188 25.9975 29.6688L24.5578 20.5625L30.6313 14.1188C31.1326 13.5875 31.3133 12.7938 31.0918 12.075C30.8703 11.3563 30.2933 10.8313 29.5938 10.7188L21.218 9.39375L17.4701 1.125Z" fill="#FDC840" />
                </svg>
              </li>
            </ul>
            <span className="fs-5 fw-600 ms-lg-3">{TeacherData.teacher_ratings} Reviews</span>
            <span className="fw-bold text-gray d-sm-inline-block d-flex fs-5 ms-sm-2">({TeacherData.teacher_review} Total Reviews)</span>
          </div>
          {/*start-review-back*/}
          <div className="review_dack">
            {reviewData.slice(0, showAllReviews ? reviewData.length : 2)?.map((review_data, index) => {
              return (

                <div className="card review_box mb-4">
                  <div className="review_top">
                    <div className="d-lg-flex justify-content-between mb-3 align-items-satrt">
                      <div className="review_user d-flex align-items-center">
                        <img src={review_data.profile_image} alt="user1" />
                        <div className="ms-3">
                          <span className="d-block fs-5">{review_data.user_name}</span>
                          <ul className="d-flex d-lg-none align-items-center">
                            <li className="me-2">
                              <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.8942 25.5637C20.5767 25.5637 20.2608 25.4662 19.9888 25.2721L13.5953 20.687L7.20182 25.2721C6.93692 25.4627 6.61859 25.5647 6.29227 25.5636C5.96595 25.5624 5.64832 25.4582 5.38476 25.2658C5.12094 25.0745 4.92417 24.8049 4.82243 24.4953C4.7207 24.1858 4.71918 23.852 4.8181 23.5415L7.2026 15.8457L0.866469 11.3809C0.603876 11.1871 0.40886 10.9156 0.309004 10.6049C0.209148 10.2942 0.209508 9.95997 0.310033 9.64947C0.411351 9.33973 0.607448 9.06967 0.87061 8.87744C1.13377 8.68522 1.45069 8.58056 1.77657 8.57826L9.62404 8.56647L12.1225 1.06166C12.2256 0.752496 12.4234 0.483606 12.6878 0.293068C12.9522 0.102529 13.2698 0 13.5957 0C13.9216 0 14.2393 0.102529 14.5037 0.293068C14.7681 0.483606 14.9658 0.752496 15.0689 1.06166L17.525 8.56647L25.4125 8.57826C25.7388 8.5801 26.0562 8.6847 26.3196 8.87722C26.583 9.06973 26.7791 9.34035 26.8799 9.65065C26.9808 9.96095 26.9813 10.2951 26.8814 10.6057C26.7815 10.9163 26.5862 11.1876 26.3234 11.3809L19.9873 15.8457L22.3718 23.5415C22.4709 23.8519 22.4696 24.1857 22.368 24.4953C22.2663 24.8049 22.0697 25.0745 21.8059 25.2658C21.5415 25.4597 21.2221 25.5641 20.8942 25.5637Z" fill="#CEC9C1" />
                              </svg>
                            </li>
                            <li className="me-2">
                              <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33" />
                              </svg>
                            </li>
                            <li className="me-2">
                              <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33" />
                              </svg>
                            </li>
                            <li className="me-2">
                              <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33" />
                              </svg>
                            </li>
                            <li className>
                              <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33" />
                              </svg>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <i data-star={review_data.ratings}></i>

                    </div>
                    <div className="review_text row mb-4">
                      <div className="col-lg-6">
                        <p className="fw-500">{review_data.review}</p>
                      </div>
                    </div>
                    <span className="text-gray fs-6">{dayjs(review_data.created_at).format('MMMM D, YYYY')}</span>
                  </div>
                </div>

              )
            })}
            {reviewData.length > 2 && (
              <div className="button mt-5 text-center">
                <button className="btn-theme d-inline-block text-center fw-600 text-dif-black" onClick={toggleShowAllReviews}>
                  {showAllReviews ? 'See Less Reviews' : 'See More Reviews'}
                </button>
              </div>
            )}
          </div>
          {/*end*/}
        </div>
        {/*end*/}
        {/*start-slider*/}
        <section className="class-content explore_new_class pt-5">
          <div className="container-fluid">
            <div className="title mb-4">
              <h2>Other classes by Celina Mark</h2>
            </div>
            <div className="row">
              {otherClassData?.length > 0 ?
                otherClassData?.map((other_class, index) => {
                  return (
                    <div className="col-xl-3 col-lg-4 col-md-6 mb-4">
                      <div className="single-box mx-0">
                        <div className="class_image_box">
                          <div className="class_img position-relative">
                            <div className="video-container">
                              <video id="video" className loop width="100%"  >
                                <source src={other_class.video_file} type="video/mp4" />
                              </video>
                            </div>
                            <div className="toggle like">
                              <input type="checkbox" tabIndex={0} />
                              <label />
                            </div>
                            <div className="d-flex bottom-info align-items-center justify-content-between">
                              <div className="d-flex align-items-center">
                                <img src={other_class.profile_image} alt="user1" />
                                <span className="tech_nm ms-2">{other_class.full_name}</span>
                              </div>
                              <div className="star_rat">
                                <span>
                                  <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z" fill="#FDC840" />
                                  </svg>
                                </span>
                                <span className="fw-600">{other_class.teacher_ratings}<span className="text-gray fw-500">({other_class.teacher_review})</span></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="class_room_info">
                          <div className="tag mb-3">
                            <a href="#" className="px-4 py-1 bg-yellow d-inline-block">{other_class.main_category}</a>
                          </div>
                          <div className="class_title mb-4">
                            <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                          </div>
                          <div className="row mb-4">
                            <div className="col-8">
                              <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Time</span>
                              <span className="fw-600">{other_class.start_time != null ? dayjs(dayjs().format('YYYY-MM-DD') + other_class.start_time).format('hh:mma') : "08:00am"} - {other_class.end_time != null ? dayjs(dayjs().format('YYYY-MM-DD') + other_class.end_time).format('hh:mma') : "10:00am"}</span>
                            </div>
                            <div className="col-4">
                              <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Year</span>
                              <span className="fw-600">{other_class.min_age}-{other_class.max_age} Years</span>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-8">
                              <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                              <span className="fw-600">{other_class.day != null ? other_class.day : "Monday"}</span>
                            </div>
                            <div className="col-4">
                              <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                              <span className="fw-600">{other_class.price}$</span>
                            </div>
                          </div>
                          <div className="button">
                            <a href="#" className="btn-theme d-block text-center fw-500">Subscribe</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }) :
                <h4 className="d-flex justify-content-center" style={{ "color": "#570861" }}>Data Not Found</h4>
              }

            </div>
          </div>
        </section>
        {/*end-slider*/}
      </main>
      <OFooter_Dashboard />
    </>
  )
}
