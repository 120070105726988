import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { discussion_upload_marks, learner_name_list, student_discussion_list } from '../../../api/apiHandler';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import TFooter_Dashboard from '../Include/TFooter_Dashboard';
import THeader_Dashboard from '../Include/THeader_Dashboard';
import { htmlToText } from 'html-to-text';
import DisscussionChat from './DisscussionChat';

const TruncateText = ({ text, length }) => {
    const truncateString = (str, num) => {
      if (str.length <= num) {
        return str;
      }
      return str.slice(0, num) + '...';
    };
  
    // Convert HTML to plain text
    const plainText = htmlToText(text || '', {
      wordwrap: false,
      ignoreImage: true,
      ignoreHref: true,
    });
  
    const truncatedText = truncateString(plainText, length);
    return (
      <p
        dangerouslySetInnerHTML={{ __html: truncatedText }}
        className="fs-6"
        style={{ fontWeight: 'normal', fontSize: '1rem', textDecoration: 'none' }} // Adjust the font size and weight
      />
    );
  };
  
export default function DisscussionResponse() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const navigate = useNavigate();
    const location = useLocation();
    const disscussion_id = location?.state?.disscussion_id
    console.log('disscussion_id :', disscussion_id);
    const activity_id = location?.state?.id?.id
    const activity_type = location?.state?.id?.class

    const [data, setData] = useState([])
    const [learnerData, setLearnerData] = useState([]);
    const [discussionId, setDiscussionId] = useState(null);
    const [userData, setUserData] = useState(null);

    console.log('discussionId :', discussionId);

    const getTeacherData = (userData) => {
        console.log('userData :', userData);
        setDiscussionId(disscussion_id)
        if (userData.user_id != null) {
            setUserData({ full_name: userData?.full_name, profile_image: userData?.user_profile, id: userData?.user_id, status: userData?.status, user_type: 'user' , chat_id  : userData?.id ,activity_type : activity_type, activity_id : activity_id,})
        } else {
            setUserData({ full_name: userData?.learner_name, profile_image: userData?.learner_profile, id: userData?.learner_id, status: userData?.status, user_type: 'learner',chat_id  : userData?.id,activity_type : activity_type, activity_id : activity_id, })
        }
    };

    const learner_listing = () => {

        learner_name_list({ activity_id: activity_id, activity_type: activity_type }).then((response) => {
            console.log("learner_name_list", response)

            if (response?.code == 1) {
                setLearnerData(response.data)
            } else {
                 
            }
        });
    }
    useEffect(() => {
        learner_listing();
        AllDiscussionList(0);
    }, []);


    const handleSelectedLearner = (data) => {
        console.log('data123 :', data);
        var main = JSON.parse(data);
        console.log('main :', main);
        if (main != 0) {
            AllDiscussionList(main)


        } else {
            AllDiscussionList(0)

        }
    }

    const AllDiscussionList = async (main) => {
        try {
            let Discussion_data = {
                discussion_id: disscussion_id,
                activity_id: activity_id,
                activity_type: activity_type
            }
            if (main != 0) {
                if (main.type == 'user') {
                    Discussion_data.userId = main.id
                } else {
                    Discussion_data.learnerId = main.id
                }
            }
            console.log('Discussion_data :', Discussion_data);

            const response = await student_discussion_list(Discussion_data)
            console.log('response123 :', response);
            if (response?.code == 1) {
                setData(response.data)
            } else {
                setData([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        AllDiscussionList();
    }, [])

    const schemaMobile = yup.object().shape({
    });

    const editTempFormOption = { resolver: yupResolver(schemaMobile) };
    const { register: register1, handleSubmit: handleSubmit1, setValue: setValue1, reset: reset1, formState: { errors: errors1 } } = useForm(editTempFormOption);

    const onSubmitMobile = (data, index) => {
        console.log('dataonSubmitMobile :', data);

        console.log(data)
        let reUpload = {
            discussion_id: disscussion_id,
            score: data[`score_${index}`],
        }
        if (data[`learner_id_${index}`] != null && data[`learner_id_${index}`] != '') {
            reUpload.learnerId = data[`learner_id_${index}`]
        }
        if (data[`user_id_${index}`] != null && data[`user_id_${index}`] != '') {
            reUpload.userId = data[`user_id_${index}`]
        }
        console.log('reUpload1 :', reUpload);

        discussion_upload_marks(reUpload).then((response) => {
            if (response?.code == 1) {
                TOAST_SUCCESS(response.message);
                setValue1(`score_${index}`, '')
                // AllAssesmment();
                AllDiscussionList(0);

            } else {
                TOAST_ERROR(response.message);
            }
        })
    }

    return (

        <>
            <JqueryLoad />
            <THeader_Dashboard />
            <Helmet>
                <style>
                    {`
                    .class-content {
                        padding: 18px 30px;
                    }
                    .btn-theme {
                        padding: 8px 50px;
                    }
                    `}
                </style>
            </Helmet>
            <main className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="#" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>
                {!discussionId ? (
                    <>
                        <div className="class-content ">
                            {/*start*/}
                            <a className="mb-4 arrow_left mb-3 d-block" onclick="window.history.back()">
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span onClick={() => navigate(-1)} className="ms-2">Back</span>
                                </span></a>
                            {/*end*/}
                            {/*start*/}

                            {/*end*/}
                        </div>

                        <div className="class-content ">

                            {/* <Header onStepChange={handleStepChange} /> */}
                            <div className="row">
                                <div className="col-xl-12 mb-xl-0 mb-4">
                                    {/*start-content*/}
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="col-xl-9 mb-xl-0 mb-4">
                                            <div className="d-flex mb-4 justify-content-between align-items-center">
                                                <select onChange={(e) => handleSelectedLearner(e.target.value)} className="bg-purple text-white border-purple fs-6 rounded-cricle rounded-pill ms-sm-3 px-2 py-2">

                                                    <option value='0' selected >Learner’s Name</option>

                                                    {learnerData?.map((student, index) => {
                                                        const type = student?.learner_id ? 'learner' : 'user';
                                                        const id = student?.learner_id || student?.user_id;
                                                        const valueObj = JSON.stringify({ type, id });
                                                        return (
                                                            <option key={index} value={valueObj}>{student?.learner_name || student?.user_name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>

                                            <div className="review_dack">
                                                {data?.map((Discussion, index) => {
                                                    const handleSubmitForm = handleSubmit1((data) => {
                                                        // Handle form submission for this specific form
                                                        onSubmitMobile(data, index);
                                                    });
                                                    return (

                                                        <div class="card review_box mb-4">
                                                            <div class="review_top">
                                                                <div class="d-flex flex-wrap justify-content-between align-items-satrt">
                                                                    <div class="list_btw_dots mb-3">
                                                                        <ul class="d-xl-flex align-items-center">
                                                                            <span class="pe-sm-4 fw-600 position-relative">{Discussion.topic} <span class="green_dots position-absolute"></span></span>
                                                                            <li>
                                                                                <span class="text-purple fw-500"> Score : {Discussion.score}/{Discussion?.total_score}</span>
                                                                            </li>
                                                                            <li>
                                                                                <span class="text-purple fw-500">Total Duration: {Discussion.Total_duration}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                                                        <span>Learner's Name</span>:&nbsp;<span>{Discussion.learner_name}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-wrap justify-content-between align-items-satrt" >
                                                                    <div class="col-lg-8">
                                                                    <TruncateText text={Discussion?.description} length={25} className="fs-6" />

                                                                    </div>
                                                                    <form onSubmit={handleSubmitForm} className="custom_form">
                                                                        <div className="form-group mb-4">

                                                                            <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center" style={{ marginRight: "-225px" }} >
                                                                                {/* <Link to="/assesment-response" className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">Student Assesment</Link> */}
                                                                                <input type="hidden" value={Discussion.user_id} name={`user_id_${index}`} {...register1(`user_id_${index}`)}></input>
                                                                                <input type="hidden" value={Discussion.learner_id} name={`learner_id_${index}`} {...register1(`learner_id_${index}`)}></input>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control ms-lg-3 mr-2 mx-2"
                                                                                    style={{ width: "25%" }}
                                                                                    maxLength="2"
                                                                                    pattern="[0-9]*"
                                                                                    name={`score_${index}`}
                                                                                    {...register1(`score_${index}`)} />
                                                                                <button className="btn-theme mb-sm-0 mb-3 px-3 d-inline-block text-center">Add Score</button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                                <div class="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                                                                    <button
                                                                        onClick={() => getTeacherData(Discussion)}
                                                                        className="discussion_btn btn-theme px-3 d-inline-block text-center"
                                                                    >
                                                                        Start Discussion
                                                                    </button>
                                                                    <div class="list_btw_dots mb-xl-0 mb-4">
                                                                        <ul class="d-sm-flex align-items-center flex-wrap justify-content-end">
                                                                            {Discussion.status == "opened" ?
                                                                                <li>
                                                                                    <span class="text-success fw-500">{Discussion.status}</span>
                                                                                </li> : <li>
                                                                                    <span class="text-danger fw-500">{Discussion.status}</span>
                                                                                </li>
                                                                            }
                                                                            <li>
                                                                                <span class="text-purple fw-500"> <span class="text-dif-black">Posted On:</span>{Discussion.Posted_On}</span>
                                                                            </li>
                                                                            <li>
                                                                                <span class="text-purple fw-500"><span class="text-dif-black">Deadline:</span> {Discussion.Deadline}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) :
                    <>
                        <div className="class-content">
                            {/*start*/}
                            <a className="mb-4 arrow_left mb-3 d-block" onClick={() => setDiscussionId(null)}>
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span className="ms-2">Back</span>
                                </span>
                            </a>
                      
                        </div>
                        <div className="class-content ">

                            {/* <Header onStepChange={handleStepChange} /> */}
                            <div className="row">
                                <div className="col-xl-12 mb-xl-0 mb-4">
                                    {/*start-content*/}
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="col-xl-9 mb-xl-0 mb-4">
                                            <div className="d-flex mb-4 justify-content-between align-items-center">
                                             
                                            </div>
                                            <DisscussionChat discussionId={discussionId} userData={userData} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </main>
            <TFooter_Dashboard />
        </>
    )
}
