import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { add_learner } from "../../api/apiHandler";
import { TOAST_ERROR, TOAST_INFO, TOAST_SUCCESS } from "../../utils/common.service";
import * as yup from "yup";
import Modals from "react-modal";
import Model from "../Models/Model";
import Select from "react-select";
import countryList from "react-select-country-list";
import { yupResolver } from "@hookform/resolvers/yup";
import LearnerAvatar from "../Models/LearnerAvatar";
import BackToPrevious from "../../components/backToPrevious";

var model_css = {
  content: {
    backgroundColor: "transparent",
    background: "none",
    border: "0px",
  },
};

var avtar_css = {
  content: {
    backgroundColor: "transparent",
    background: "none",
    border: "0px",
  },
};

export default function Signup3() {
  const navigate = useNavigate();

  const schema = yup.object().shape({
    learner_name: yup
      .string()
      .required("Please enter first name")
      .matches(/^[A-Za-z]+$/, "Only alphabet and characters are allowed")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .min(2, "Field must contain atleast 2 or 3 characters long"),

    learner_age: yup.string().required("Please select age"),
    email: yup
      .string()
      .email()
      .required("Please enter email")
      .matches(/^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/, "Email Id is invalid"),
    password: yup.string()
      .required("Please enter password")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .min(8, "Password must contain 8 or more characters, at least one uppercase, one lowercase, one digit, and one special character")
      .matches(/^\S+$/, "Password cannot contain leading or trailing spaces")
      .matches(/(?=.*[a-z])(?=.*[A-Z])/, "Password must contain at least one uppercase and one lowercase letter")
      .matches(/\d/, "Password must contain at least one digit")
      .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Password must contain at least one special character"),
    sub_cat: yup.string().required("Please select subject"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // console.log(errors)
  var step1_data = localStorage.getItem("step_1") != undefined ? JSON.parse(localStorage.getItem("step_1")) : "";
  console.log("step1_data :", step1_data);

  const [continuebutton, setContinuebutton] = useState(null);

  let onSubmit = (data) => {
    let filename = "avatar1.png";

    console.log("image avtart", imageAvatar);
    if (imageAvatar != undefined) {
      const urlSegments = imageAvatar.split("/");
      filename = urlSegments[urlSegments.length - 1];

      // Log the filename to check if it's correct
      console.log(filename, "filename");
    }

    setContinuebutton(data);

    // alert('gfy');
    let submitData = {
      user_id: localStorage.getItem("PAid"),
      learner_name: data.learner_name,
      learner_password: data.password,
      learner_age: data.learner_age,
      learner_email: data.email,
      learner_profile_img: filename,
      sub_cat: data.sub_cat,
    };

    // localStorage.setItem("MAid", data.id);

    console.log("submitData", submitData);
    add_learner(submitData).then((res) => {
      // console.log('add_learner_res :', res);
      // alert('hello')
      if (res.code == 1) {
        TOAST_SUCCESS(res.message);
      } else {
        TOAST_ERROR(res.message);
      }
    });
    setSelectSubject([]);
    reset();

    //  localStorage.setItem("step_1",JSON.stringify(data))
    // navigate("/dashboard");
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const [value, setCountry] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setCountry(value);
    // console.log(value);
  };

  const [modalIsOpenForgot, setIsOpenForgot] = useState(false);
  // console.log('model',modalIsOpenForgot)

  const [selectSubject, setSelectSubject] = useState([]);
  console.log("selectSubject :", selectSubject);

  const [selectDone, setSelectDone] = useState(false);
  const [imageAvatar, setImageAvatar] = useState();
  const [avtarModel, setavtarModel] = useState(false);
  const [selectAvtar, setselectAvtar] = useState([]);
  console.log("selectAvtar :", selectAvtar);
  const [avtarDone, setavtarDone] = useState(false);

  const handleSelectSubject = (subjectName) => {
    // selectSubject.push(subjectName)
    // setSelectSubject([...selectSubject,subjectName])
    // console.log('selectSubject',selectSubject)
    console.log(selectSubject);
    if (selectSubject.includes(subjectName)) {
      // console.log(selectSubject.indexOf(subjectName))
      // selectSubject.includes(subjectName)
      // var index = selectSubject.indexOf(subjectName)
      // delete selectSubject[index]
      let filteredArray = selectSubject.filter((item) => item !== subjectName);
      setSelectSubject(filteredArray);
    } else {
      setSelectSubject((prevSelectSubject) => [...new Set([...prevSelectSubject, subjectName])]);
    }
    clearErrors('sub_cat')
    // console.log('subjectName :', subjectName)
  };
  // console.log('cgdg',selectSubject)

  const [addLearner, setAddLerner] = useState([
    {
      learner_name: "",
      learner_age: "",
      learner_email: "",
      password: "",
      sub_cat: "",
    },
  ]);
  const [inputValue, setInputValue] = useState("");

  const handleAddTodo = () => {
    // console.log('addLearner :', addLearner);

    // alert('fgdfg')

    if (addLearner != undefined) {
      setAddLerner([...addLearner, inputValue]);
      setInputValue("");
    } else {
      setAddLerner(inputValue);
    }
  };

  const handleContinue = () => {
    navigate('/login')
    TOAST_INFO('You must verify your email first before you can login.')
    localStorage.clear()
  }

  return (
    <>
      <div>
        <main>
          <div className="login_sec">
            <div className="container-fluid">
              <div className="row">
                <div
                  className="align-items-center col-lg-6 d-flex justify-content-center left1"
                  style={{ backgroundImage: "url(./assets/images/auth/sign_in2.png)" }}
                >
                  <h1 className="text-white poppins fw_600 position-relative">Create Accounts for Learners Under 18 Years Old.</h1>
                </div>
                <div className="col-lg-6 right">
                  <div className="top">
                    <div className="d-md-flex justify-content-between">
                      <BackToPrevious />
                      <div>
                        <p className="fs-6 text-gray">
                          Already Have Account?
                          <Link to="/login" className="text-purple ps-2">
                            Sign In
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="btm_div form-section">
                    <div className="d-xl-flex justify-content-between align-items-center mb-5">
                      <h2 className="fw-600 text-purple poppins text-capitalize mb-xl-0 mb-4">
                        Tell us about your kids! We’ll recommend classes they’ll love.
                      </h2>
                      <span className="text-end d-block text-purple">Step 2/2</span>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                      {addLearner &&
                        addLearner.map((item, index) => {
                          return (
                            <>
                              <div className="avatar-upload">
                                <div className="avatar-edit">
                                  <input id="imageUpload" accept=".png, .jpg, .jpeg" />
                                  <label htmlFor="imageUpload" onClick={() => setavtarModel(true)} />
                                </div>
                                <div className="avatar-preview">
                                  <div
                                    id="imagePreview"
                                    style={{
                                      backgroundImage: imageAvatar ? `url("${imageAvatar}")` : 'url("./assets/images/kids_avtar/avatar1.png")',
                                    }}
                                  >
                                    {" "}
                                  </div>
                                </div>
                              </div>
                              <div className="form-group mb-4">
                                <label className="mb-2">Learner's First Name</label>
                                <input type="text" {...register("learner_name")} className="form-control" name="learner_name" placeholder />
                                <p className="mt-2 font-bold col-red">
                                  <span style={{ color: "red" }}>{errors.learner_name?.message}</span>
                                </p>
                              </div>
                              {/* <div className="form-group mb-4">
                            <label className="mb-2">Age</label>
    
                            <input type="number" {...register("age")} className="form-control" name='age' placeholder defaultValue />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.age?.message}</span></p>
    
                          </div> */}
                              <div className="form-group mb-4">
                                <label className="mb-2">Age</label>
                                <select className="form-select" {...register("learner_age")} name="learner_age">
                                  <option>3 Years</option>
                                  <option>4 Years</option>
                                  <option>5 Years</option>
                                  <option>6 Years</option>
                                  <option>7 Years</option>
                                  <option>8 Years</option>
                                  <option>9 Years</option>
                                  <option>10 Years</option>
                                  <option>11 Years</option>
                                  <option>12 Years</option>
                                  <option>13 Years</option>
                                  <option>14 Years</option>
                                  <option>15 Years</option>
                                  <option>16 Years</option>
                                  <option>17 Years</option>
                                  <option>18 Years</option>
                                </select>
                                <p className="mt-2 font-bold col-red">
                                  <span style={{ color: "red" }}>{errors.learner_age?.message}</span>
                                </p>
                              </div>

                              {/* <div className="form-group mb-4">
                            <label className="mb-2">Country</label>
                            <Select    {...register("country")}
                              name='country'
                              options={options}
                              value={value}
                              // onChange={changeHandler}
                              onChange={(changeHandler) => {
                                setValue("country", changeHandler.value);
                                setCountry(changeHandler)
                                clearErrors("country");
                              }}
                              placeholder=""
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  border: '1px solid rgba(102, 102, 102, 0.35)',
                                  borderRadius: '12px',
                                  padding: '5px 10px', // Adjust padding to make the component smaller
                                  height: '46px', // Set the height of the component
                                }),
    
                              }}
                            />
    
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.country?.message}</span></p>
    
                          </div> */}
                              <div className="form-group mb-4">
                                <label className="mb-2">Email ID</label>
                                <input type="text" className="form-control" name="email" {...register("email")} placeholder />
                                <p className="mt-2 font-bold col-red">
                                  <span style={{ color: "red" }}>{errors.email?.message}</span>
                                </p>
                              </div>
                              <div className="form-group mb-4">
                                <div className="d-flex justify-content-between position-relative">
                                  <label className="mb-2">Password</label>
                                  <Link onClick={handleTogglePassword}
                                    style={{ position: "absolute", right: "20px", zIndex: "9999", bottom: "-36px" }}
                                  >
                                    <span>
                                      {showPassword ? (
                                        <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                          <path d="M0 0h24v24H0z" fill="none" />
                                          <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                        </svg>
                                      ) : (
                                        <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                            fill="#666666"
                                            fillOpacity="0.8"
                                          />
                                          <path
                                            d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                            fill="#666666"
                                            fillOpacity="0.8"
                                          />
                                        </svg>
                                      )}
                                    </span>
                                  </Link>
                                </div>
                                <input name="password"
                                  {...register("password")}
                                  type={showPassword ? 'text' : 'password'}
                                  placeholder=""
                                  className="form-control" />
                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.password?.message}</span></p>
                              </div>
                              <div className="form-group mb-4">
                                <label className="mb-2">Select Subjects Your Learner Likes </label>
                                <input
                                  name="sub_cat"
                                  onClick={() => setIsOpenForgot(true)}
                                  {...register("sub_cat")}
                                  type="text"
                                  placeholder=""
                                  value={selectDone ? selectSubject.join(",") : ""}
                                  className="form-select"
                                />
                                {/* <select onClick={()=>setIsOpenForgot(true)} className="form-select" aria-label="Default select example" readOnly>
                              <option value={1} selected>Arts</option>
                              <option value={2}>Life Skills</option>
                              <option value={3}>Academic</option>
                              <option value={4}>Special ED</option>
                              <option value={5}>Languages</option>
                              <option value={6}>Homeschool</option>
                              <option value={7}>Coding &amp; Tech</option>
                              <option value={8}>Health and Wellness</option>
                              <option value={9}>Professional Training</option>
                              <option value={9}>Music</option>
                            </select> */}
                                <p className="mt-2 font-bold col-red">
                                  <span style={{ color: "red" }}>{errors.sub_cat?.message}</span>
                                </p>
                              </div>
                            </>
                          );
                        })}

                      {/* <div className="text-end mt-2">
                              <Link  onClick={handleAddTodo} className="text-decoration-underline text-purple fs-6">+ Add another learner</Link>
                            </div> */}
                      <div className="mt-4">
                        {/* <Link to="/kidsDashboard" className="btn-theme bg-yellow text-center d-block fw-600">Sign Up</Link> */}
                        <button type="submit" className="btn-theme bg-yellow text-center fw-600 d-block">
                          + Add another learner
                        </button>
                      </div>
                      {continuebutton != null ? (
                        <div className="mt-4">
                          <button onClick={() => handleContinue()} className="btn-theme bg-yellow text-center fw-600 d-block">
                            Continue
                          </button>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </form>
                    {/* <div className="position-relative or_sec">
                      <div className="position-absolute">OR</div>
                    </div>
                    <div>
                      <a href="#" className="btn-theme btn-prple-border text-dark d-block text-center my-4">
                        <span>
                          <svg width={33} height={33} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16.5" cy="16.5" r={14} fill="#0C82EE" />
                            <path
                              d="M21.7137 20.7816L22.3356 16.8301H18.4452V14.267C18.4452 13.1857 18.9877 12.1311 20.7302 12.1311H22.5V8.76699C22.5 8.76699 20.8945 8.5 19.3603 8.5C16.1548 8.5 14.0617 10.3929 14.0617 13.8184V16.8301H10.5V20.7816H14.0617V30.3345C14.7767 30.444 15.5082 30.5 16.2534 30.5C16.9986 30.5 17.7302 30.444 18.4452 30.3345V20.7816H21.7137Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        Continue with Facebook
                      </a>
                      <a href="#" className="btn-theme btn-prple-border text-dark d-block text-center ">
                        <span>
                          <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M22.501 12.7331C22.501 11.8698 22.4296 11.2398 22.2748 10.5864H12.2153V14.483H18.12C18.001 15.4514 17.3582 16.9097 15.9296 17.8897L15.9096 18.0202L19.0902 20.4349L19.3106 20.4564C21.3343 18.6247 22.501 15.9297 22.501 12.7331Z"
                              fill="#4285F4"
                            />
                            <path
                              d="M12.214 23.0001C15.1068 23.0001 17.5353 22.0667 19.3092 20.4567L15.9282 17.89C15.0235 18.5083 13.8092 18.94 12.214 18.94C9.38069 18.94 6.97596 17.1083 6.11874 14.5767L5.99309 14.5871L2.68583 17.0955L2.64258 17.2133C4.40446 20.6433 8.0235 23.0001 12.214 23.0001Z"
                              fill="#34A853"
                            />
                            <path
                              d="M6.12046 14.5767C5.89428 13.9234 5.76337 13.2233 5.76337 12.5C5.76337 11.7767 5.89428 11.0767 6.10856 10.4234L6.10257 10.2842L2.75386 7.7356L2.64429 7.78667C1.91814 9.21002 1.50146 10.8084 1.50146 12.5C1.50146 14.1917 1.91814 15.79 2.64429 17.2133L6.12046 14.5767Z"
                              fill="#FBBC05"
                            />
                            <path
                              d="M12.2141 6.05997C14.2259 6.05997 15.583 6.91163 16.3569 7.62335L19.3807 4.73C17.5236 3.03834 15.1069 2 12.2141 2C8.02353 2 4.40447 4.35665 2.64258 7.78662L6.10686 10.4233C6.97598 7.89166 9.38073 6.05997 12.2141 6.05997Z"
                              fill="#EB4335"
                            />
                          </svg>
                        </span>
                        Continue with Google
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/*start*/}
        {/* Modal */}
        <div className="modal fade" id="avtar_kids" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Select Your Avatar
                </h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <ul className="list-unstyled your_avatar">
                  <li>
                    <a href="#" className="kid_img active">
                      <img src="./assets/images/kids_avtar/avatar1.png" alt="avatar1" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="kid_img">
                      <img src="./assets/images/kids_avtar/avatar2.png" alt="avatar2" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="kid_img">
                      <img src="./assets/images/kids_avtar/avatar3.png" alt="avatar3" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="kid_img">
                      <img src="./assets/images/kids_avtar/avatar4.png" alt="avatar4" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="kid_img">
                      <img src="./assets/images/kids_avtar/avatar5.png" alt="avatar5" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="kid_img">
                      <img src="./assets/images/kids_avtar/avatar6.png" alt="avatar6" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="kid_img">
                      <img src="./assets/images/kids_avtar/avatar7.png" alt="avatar7" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="kid_img">
                      <img src="./assets/images/kids_avtar/avatar8.png" alt="avatar8" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="modal-footer justify-content-center">
                <div className="col-lg-4 col-12">
                  <button type="button" className="btn-theme w-100 bg-yellow fw-600" data-bs-dismiss="modal">
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end*/}
        <Modals isOpen={avtarModel} style={avtar_css} contentLabel="Example Modals">
          <LearnerAvatar
            setavtarModel={setavtarModel}
            handleSelectAvtar={setImageAvatar}
            setavtarDone={setavtarDone}
            setselectAvtar={setselectAvtar}

          />
        </Modals>
        {/* Modal */}

        <Modals isOpen={modalIsOpenForgot} style={model_css} contentLabel="Example Modal">
          <Model
            setIsOpenForgot={setIsOpenForgot}
            handleSelectSubject={handleSelectSubject}
            handleSelectAvtar={setImageAvatar}
            setSelectDone={setSelectDone}
            setSelectSubject={setSelectSubject}
            selectSubject={selectSubject}
            selectAvtar={selectAvtar}
          />
        </Modals>
      </div>
    </>
  );
}
