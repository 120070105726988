import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import PFooter_Dashboard from "../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../Pinc/PHeader_Dashboard";
import { TOAST_ERROR } from "../../../utils/common.service";
import { getQuizInfo } from "../../../api/apiHandler";
import BackToPrevious from "../../../components/backToPrevious";

export default function PQuizInfo() {
  const [quizInfo, setQuizInfo] = useState(null);
  const location = useLocation();
  const { id: quiz_id ,class_id} = location?.state;
  console.log("🚀 ~ PQuizInfo ~ quizInfo:", quizInfo);
console.log(class_id,'class_id');
  const fetchQuizInfo = async () => {
    try {
      const { code, data, message } = await getQuizInfo({ quiz_id });
      console.log("🚀 ~ fetchQuizInfo ~ data:", data);
      if (code == 1) {
        const updatedData = { ...data[0], class_id };
         setQuizInfo(updatedData);
        }
      else{
        TOAST_ERROR(message);
      } 
    } catch (err) {
      TOAST_ERROR(err.message);
    }
  };

  useEffect(() => {
    quiz_id && fetchQuizInfo();
  }, [quiz_id]);

  return (
    <>
      <JqueryLoad />
      <PHeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div>
              <BackToPrevious />
              <div>
                <div className="class_lsit_section">
                  <div className="mb-4">
                    <h2 className="mb-2 poppins fw-600">Start Quiz</h2>
                    <p className="fs-6 text-gray">Start Your Quiz </p>
                  </div>
                  <div className="quiz_card">
                    <div className="card_title mb-4">
                      <h3 className="mb-2 poppins fw-bold">{quizInfo?.subject_category} Quiz</h3>
                      <p className="fs-6 text-dif-black">Read the following instructions</p>
                    </div>
                    <div className="row mb-5 align-items-center">
                      <div className="col-xl-6 mb-xl-0 mb-4 left_side">
                        <video id="video" loop width="100%" src={quizInfo?.quiz_video} controls />
                        {/* <video id="video" loop width="100%" controls poster="">
                          <source src={quizInfo?.quiz_video} />
                        </video> */}
                        {/* <div className="quiz_image">
                          <img src="./assets/images/classroom/class4.png" alt="class4" />
                        </div> */}
                      </div>
                      <div className="col-xl-6 right_side">
                        <div className="quiz_info ps-xl-5">
                          <table className="table table-borderless">
                            <tbody>
                              <tr>
                                <td className="text-purple fw-600">Date: </td>
                                <td className="text-dif-black">{quizInfo?.created_date}</td>
                              </tr>
                              {quizInfo?.duration && (
                                <tr>
                                  <td className="text-purple fw-600">Time Limit:</td>
                                  <td className="text-dif-black">{quizInfo?.duration}</td>
                                </tr>
                              )}
                              {/* <tr>
                                <td className="text-purple fw-600">Attempts:</td>
                                <td className="text-dif-black">Once</td>
                              </tr> */}
                              <tr>
                                <td className="text-purple fw-600">Points:</td>
                                <td className="text-dif-black">{quizInfo?.total_points} Points</td>
                              </tr>
                              <tr>
                                <td className="text-purple fw-600">No Of Ques:</td>
                                <td className="text-dif-black">{quizInfo?.no_of_question} Questions</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="quiz_Inst">
                      <h4 className="mb-3 text-dif-black poppins fw-600">Instructions</h4>
                      <p className="fs-6 mb-lg-4 mb-md-3 mb-2">{quizInfo?.instruction}</p>
                      {/* This quiz consists of {quizInfo?.no_of_question} multiple-choice questions. To be successful with the quizzes, it’s important
                        to conversant with the topics. Keep the following in mind: */}
                      {/* <p className="fs-6 mb-lg-4 mb-md-3 mb-2">
                        Timing - You need to complete each of your attempts in one sitting, as you are allotted 30 minutes to each attempt. Answers -
                        You may review your answer-choices and compare them to the correct answers after your final attempt.
                      </p>
                      <p className="fs-6 mb-lg-4 mb-md-3 mb-2">To start, click the "Start" button. When finished, click the "Submit " button.</p> */}
                    </div>
                    <div className="text-end pt-2">
                      <Link to={"/start-quiz"} state={quizInfo} className="btn-theme bg-yellow d-inline-block fw-500 text-dif-black">
                        Start Quiz 
                      </Link>
                    </div>
                  </div>
                  {/*end*/}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <PFooter_Dashboard />
    </>
  );
}
