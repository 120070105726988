// import React from 'react'
// import { Helmet } from "react-helmet";

// export default function AiBox({getSubject}) {
// console.log('getSubject :', getSubject);


//   return (
//     <>

//       <p>{getSubject}</p>
//       <div className="modal-dialog-scrollable">
//         <div className="modal-content">
//           <div className="msg-head">
//             <div className="row">
//               <div className="col-8">
//                 <div className="d-flex align-items-center">
//                   <span className="chat-icon">
//                     <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                       <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
//                     </svg>
//                   </span>
//                   <div className="flex-shrink-0 position-relative">
//                     <img className="img-fluid chat_user" src="./assets/images/user/user.png" alt="user img " />
//                     <span className="active" />
//                   </div>
//                   <div className="flex-grow-1 ms-3">
//                     <h3 className="poppins">Mehedi Hasan</h3>
//                     <p>Active Now</p>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-4">
//                 <ul className="moreoption">
//                   <li className="navbar nav-item dropdown">
//                     <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true" /></a>
//                     <ul className="dropdown-menu">
//                       <li><a className="dropdown-item" href="#">Action</a></li>
//                       <li><a className="dropdown-item" href="#">Another action</a></li>
//                       <li>
//                         <hr className="dropdown-divider" />
//                       </li>
//                       <li><a className="dropdown-item" href="#">Something else here</a></li>
//                     </ul>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//           <div className="modal-body">
//             <div className="msg-body">
//               <ul>
//                 <li className="sender">
//                   <div className="d-flex align-items-start">
//                     <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
//                     <div>
//                       <p className="text-dif-black"> Hi, I hope you are doing well, yesterday you have gave a pen This very nice, i am very happy for this.yesterday you have gave a pen This very nice </p>
//                       <span className="time text-end">10:06 am</span>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="sender">
//                   <div className="d-flex align-items-start">
//                     <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
//                     <div>
//                       <p className="text-dif-black"> Hey, Are you there? </p>
//                       <span className="time text-end">10:16 am</span>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="repaly">
//                   <div className="d-flex justify-content-end">
//                     <div className>
//                       <p className="text-dif-black">yea I’m well, Thank you, i am very happy for this.yesterday you have gave a pen This very nice</p>
//                       <span className="time text-start">10:20 am</span>
//                     </div>
//                     <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
//                   </div>
//                 </li>
//                 <li className="sender">
//                   <div className="d-flex align-items-start">
//                     <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
//                     <div>
//                       <p className="text-dif-black"> Hey, Are you there? </p>
//                       <span className="time text-end">10:26 am</span>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="sender">
//                   <div className="d-flex align-items-start">
//                     <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
//                     <div>
//                       <p className="text-dif-black"> Hey, Are you there? </p>
//                       <span className="time text-end">10:32 am</span>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="repaly">
//                   <div className="d-flex justify-content-end">
//                     <div className>
//                       <p className="text-dif-black">How are you?</p>
//                       <span className="time text-start">10:35 am</span>
//                     </div>
//                     <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
//                   </div>
//                 </li>
//                 <li>
//                   <div className="divider">
//                     <h6>Today</h6>
//                   </div>
//                 </li>
//                 <li className="repaly">
//                   <div className="d-flex justify-content-end">
//                     <div className>
//                       <p className="text-dif-black"> yes, tell me</p>
//                       <span className="time text-start">10:36 am</span>
//                     </div>
//                     <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
//                   </div>
//                 </li>
//                 <li className="repaly">
//                   <div className="d-flex justify-content-end">
//                     <div className>
//                       <p className="text-dif-black">yes... on it</p>
//                       <span className="time text-start">junt now</span>
//                     </div>
//                     <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
//                   </div>
//                 </li>
//               </ul>
//             </div>
//           </div>
//           <div className="send-box">
//             <form action className="position-relative">
//               <input type="text" className="form-control rounded-pill" aria-label="message…" placeholder="Enter text" />
//               <a href="#" className="send_icons"><i className="fa fa-paper-plane" aria-hidden="true" /></a>
//               <div className="send-btns">
//                 <div className="attach">
//                   <div className="button-wrapper position-relative">
//                     <span className="label">
//                       <svg width={16} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                         <path d="M12.9297 2.94612C12.0719 2.08831 10.6797 2.08831 9.82187 2.94612L3.35313 9.41487C1.87305 10.8949 1.87305 13.2926 3.35313 14.7727C4.8332 16.2528 7.23086 16.2528 8.71094 14.7727L14.0547 9.42893C14.4379 9.04573 15.0637 9.04573 15.4469 9.42893C15.8301 9.81213 15.8301 10.4379 15.4469 10.8211L10.1031 16.1649C7.85312 18.4149 4.21094 18.4149 1.96094 16.1649C-0.289062 13.9149 -0.289062 10.2727 1.96094 8.02268L8.42969 1.55393C10.0574 -0.0738037 12.6941 -0.0738037 14.3219 1.55393C15.9496 3.18166 15.9496 5.81838 14.3219 7.44612L8.13437 13.6336C7.12891 14.6391 5.49766 14.6391 4.49219 13.6336C3.48672 12.6281 3.48672 10.9969 4.49219 9.99143L9.55469 4.92893C9.93789 4.54573 10.5637 4.54573 10.9469 4.92893C11.3301 5.31213 11.3301 5.93791 10.9469 6.32112L5.88438 11.3836C5.64883 11.6192 5.64883 12.0059 5.88438 12.2414C6.11992 12.477 6.50664 12.477 6.74219 12.2414L12.9297 6.05393C13.7875 5.19612 13.7875 3.80393 12.9297 2.94612Z" fill="#131313" />
//                       </svg>
//                     </span>
//                     <input type="file" name="upload" id="upload" className="upload-box" placeholder="Upload File" aria-label="Upload File" />
//                   </div> 
//                 </div>
//               </div>
//             </form> 
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// import React, { useState } from 'react';
// import axios from 'axios';
// import { Helmet } from 'react-helmet';

// export default function AiBox({ getSubject }) {
//   const [message, setMessage] = useState('');
//   const [response, setResponse] = useState('');
//   console.log('gptresponse :', response);
//   const [loading, setLoading] = useState(false);

//   // API key (make sure to secure this key in a real application)
//   const apiKey = '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';

//   // Handle input change
//   const handleInputChange = (e) => {
//     setMessage(e.target.value);
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!message.trim()) return;

//     setLoading(true);

//     try {
//       // Prepare the prompt with subject context
//       const prompt = `Subject: ${getSubject}\nQuestion: ${message}\nAnswer:`;

//       // Make a request to the OpenAI API
//       const result = await axios.post(
//         'https://api.openai.com/v1/chat/completions',
//         {
//           model: 'gpt-4', // Use GPT-4 or another available model
//           messages: [{ role: 'user', content: prompt }],
//           max_tokens: 150,
//           temperature: 0.7,
//           n: 1,
//         },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${apiKey}`,
//           },
//         }
//       );

//       // Update response state
//       setResponse(result.data.choices[0].message.content.trim());
//     } catch (error) {
//       console.error('Error fetching response:', error);
//       setResponse('There was an error processing your request.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <>
//       <Helmet>
//         <title>AI Chat Box</title>
//       </Helmet>
//       <p>{getSubject}</p>
//       <div className="modal-dialog-scrollable">
//         <div className="modal-content">
//           <div className="msg-head">
//             <div className="row">
//               <div className="col-8">
//                 <div className="d-flex align-items-center">
//                   <span className="chat-icon">
//                     <svg
//                       width={10}
//                       height={18}
//                       viewBox="0 0 10 18"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z"
//                         fill="#131313"
//                       />
//                     </svg>
//                   </span>
//                   <div className="flex-shrink-0 position-relative">
//                     <img
//                       className="img-fluid chat_user"
//                       src="./assets/images/user/user.png"
//                       alt="user img "
//                     />
//                     <span className="active" />
//                   </div>
//                   <div className="flex-grow-1 ms-3">
//                     <h3 className="poppins">Mehedi Hasan</h3>
//                     <p>Active Now</p>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-4">
//                 <ul className="moreoption">
//                   <li className="navbar nav-item dropdown">
//                     <a
//                       className="nav-link dropdown-toggle"
//                       href="#"
//                       role="button"
//                       data-bs-toggle="dropdown"
//                       aria-expanded="false"
//                     >
//                       <i className="fa fa-ellipsis-v" aria-hidden="true" />
//                     </a>
//                     <ul className="dropdown-menu">
//                       <li>
//                         <a className="dropdown-item" href="#">
//                           Action
//                         </a>
//                       </li>
//                       <li>
//                         <a className="dropdown-item" href="#">
//                           Another action
//                         </a>
//                       </li>
//                       <li>
//                         <hr className="dropdown-divider" />
//                       </li>
//                       <li>
//                         <a className="dropdown-item" href="#">
//                           Something else here
//                         </a>
//                       </li>
//                     </ul>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//           <div className="modal-body">
//             <div className="msg-body">
//               <ul>
//                 <li className="sender">
//                   <div className="d-flex align-items-start">
//                     <img
//                       className="img-fluid chat_user me-2"
//                       src="./assets/images/user/user.png"
//                       alt="user img"
//                     />
//                     <div>
//                       <p className="text-dif-black">
//                         {' '}
//                         Hi, I hope you are doing well, yesterday you have gave a
//                         pen This very nice, i am very happy for this.yesterday
//                         you have gave a pen This very nice{' '}
//                       </p>
//                       <span className="time text-end">10:06 am</span>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="sender">
//                   <div className="d-flex align-items-start">
//                     <img
//                       className="img-fluid chat_user me-2"
//                       src="./assets/images/user/user.png"
//                       alt="user img"
//                     />
//                     <div>
//                       <p className="text-dif-black"> Hey, Are you there? </p>
//                       <span className="time text-end">10:16 am</span>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="repaly">
//                   <div className="d-flex justify-content-end">
//                     <div className>
//                       <p className="text-dif-black">
//                         yea I’m well, Thank you, i am very happy for
//                         this.yesterday you have gave a pen This very nice
//                       </p>
//                       <span className="time text-start">10:20 am</span>
//                     </div>
//                     <img
//                       className="img-fluid chat_user ms-2"
//                       src="./assets/images/user/user.png"
//                       alt="user img"
//                     />
//                   </div>
//                 </li>
//                 <li className="sender">
//                   <div className="d-flex align-items-start">
//                     <img
//                       className="img-fluid chat_user me-2"
//                       src="./assets/images/user/user.png"
//                       alt="user img"
//                     />
//                     <div>
//                       <p className="text-dif-black"> Hey, Are you there? </p>
//                       <span className="time text-end">10:26 am</span>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="sender">
//                   <div className="d-flex align-items-start">
//                     <img
//                       className="img-fluid chat_user me-2"
//                       src="./assets/images/user/user.png"
//                       alt="user img"
//                     />
//                     <div>
//                       <p className="text-dif-black"> Hey, Are you there? </p>
//                       <span className="time text-end">10:32 am</span>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="repaly">
//                   <div className="d-flex justify-content-end">
//                     <div className>
//                       <p className="text-dif-black">How are you?</p>
//                       <span className="time text-start">10:35 am</span>
//                     </div>
//                     <img
//                       className="img-fluid chat_user ms-2"
//                       src="./assets/images/user/user.png"
//                       alt="user img"
//                     />
//                   </div>
//                 </li>
//                 <li>
//                   <div className="divider">
//                     <h6>Today</h6>
//                   </div>
//                 </li>
//                 <li className="repaly">
//                   <div className="d-flex justify-content-end">
//                     <div className>
//                       <p className="text-dif-black"> yes, tell me</p>
//                       <span className="time text-start">10:36 am</span>
//                     </div>
//                     <img
//                       className="img-fluid chat_user ms-2"
//                       src="./assets/images/user/user.png"
//                       alt="user img"
//                     />
//                   </div>
//                 </li>
//                 <li className="repaly">
//                   <div className="d-flex justify-content-end">
//                     <div className>
//                       <p className="text-dif-black">yes... on it</p>
//                       <span className="time text-start">just now</span>
//                     </div>
//                     <img
//                       className="img-fluid chat_user ms-2"
//                       src="./assets/images/user/user.png"
//                       alt="user img"
//                     />
//                   </div>
//                 </li>
//                 {/* User Message */}
//                 {message && (
//                   <li className="repaly">
//                     <div className="d-flex justify-content-end">
//                       <div className>
//                         <p className="text-dif-black">{message}</p>
//                         <span className="time text-start">just now</span>
//                       </div>
//                       <img
//                         className="img-fluid chat_user ms-2"
//                         src="./assets/images/user/user.png"
//                         alt="user img"
//                       />
//                     </div>
//                   </li>
//                 )}
//                 {/* ChatGPT Response */}
//                 {response && (
//                   <li className="sender">
//                     <div className="d-flex align-items-start">
//                       <img
//                         className="img-fluid chat_user me-2"
//                         src="./assets/images/user/user.png"
//                         alt="user img"
//                       />
//                       <div>
//                         <p className="text-dif-black">{response}</p>
//                         <span className="time text-end">just now</span>
//                       </div>
//                     </div>
//                   </li>
//                 )}
//                 {loading && (
//                   <li className="sender">
//                     <div className="d-flex align-items-start">
//                       <img
//                         className="img-fluid chat_user me-2"
//                         src="./assets/images/user/user.png"
//                         alt="user img"
//                       />
//                       <div>
//                         <p className="text-dif-black">Loading...</p>
//                       </div>
//                     </div>
//                   </li>
//                 )}
//               </ul>
//             </div>
//           </div>
//           <div className="send-box">
//             <form onSubmit={handleSubmit} className="position-relative">
//               <input
//                 type="text"
//                 className="form-control rounded-pill"
//                 aria-label="message…"
//                 placeholder="Enter text"
//                 value={message}
//                 onChange={handleInputChange}
//               />
//               <button type="submit" className="send_icons">
//                 <i className="fa fa-paper-plane" aria-hidden="true" />
//               </button>
//               <div className="send-btns">
//                 <div className="attach">
//                   <div className="button-wrapper position-relative">
//                     <span className="label">
//                       <svg
//                         width={16}
//                         height={18}
//                         viewBox="0 0 16 18"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <path
//                           d="M12.9297 2.94612C12.0719 2.08831 10.6797 2.08831 9.82187 2.94612L3.35313 9.41487C1.87305 10.8949 1.87305 13.2926 3.35313 14.7727C4.8332 16.2528 7.23086 16.2528 8.71094 14.7727L14.0547 9.42893C14.4379 9.04573 15.0637 9.04573 15.4469 9.42893C15.8301 9.81213 15.8301 10.4379 15.4469 10.8211L10.1031 16.1649C7.85312 18.4149 4.21094 18.4149 1.96094 16.1649C-0.289062 13.9149 -0.289062 10.2727 1.96094 8.02268L8.42969 1.55393C10.0574 -0.0738037 12.6941 -0.0738037 14.3219 1.55393C15.9496 3.18166 15.9496 5.81838 14.3219 7.44612L8.13437 13.6336C7.12891 14.6391 5.49766 14.6391 4.49219 13.6336C3.48672 12.6281 3.48672 10.9969 4.49219 9.99143L9.55469 4.92893C9.93789 4.54573 10.5637 4.54573 10.9469 4.92893C11.3301 5.31213 11.3301 5.93791 10.9469 6.32112L5.88438 11.3836C5.64883 11.6192 5.64883 12.0059 5.88438 12.2414C6.11992 12.477 6.50664 12.477 6.74219 12.2414L12.9297 6.05393C13.7875 5.19612 13.7875 3.80393 12.9297 2.94612Z"
//                           fill="#131313"
//                         />
//                       </svg>
//                     </span>
//                     <input
//                       type="file"
//                       name="upload"
//                       id="upload"
//                       className="upload-box"
//                       placeholder="Upload File"
//                       aria-label="Upload File"
//                     />
//                   </div>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </> 
//   );
// }

// import React, { useState } from 'react';
// import axios from 'axios';

// export default function AiBox({ getSubject }) {
//   const [message, setMessage] = useState('');
//   const [response, setResponse] = useState('');
//   const [loading, setLoading] = useState(false);

//   // API key (make sure to secure this key in a real application)
//   const apiKey = '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'; // Replace this with your actual key

//   // Handle input change
//   const handleInputChange = (e) => {
//     setMessage(e.target.value);
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!message.trim()) return;

//     setLoading(true);

//     try {
//       // Prepare the prompt with subject context
//       const prompt = `Subject: ${getSubject}\nQuestion: ${message}\nAnswer:`;

//       // Make a request to the OpenAI API
//       const result = await axios.post(
//         'https://api.openai.com/v1/chat/completions',
//         {
//           model: 'gpt-4', // Use GPT-4 or another available model
//           messages: [{ role: 'user', content: prompt }],
//           max_tokens: 150,
//           temperature: 0.7,
//           n: 1,
//         },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${apiKey}`,
//           },
//         }
//       );

//       // Update response state
//       setResponse(result.data.choices[0].message.content.trim());
//     } catch (error) {
//       console.error('Error fetching response:', error);
//       setResponse('There was an error processing your request.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div>
//       <h1>Chat with AI</h1>
//       <form onSubmit={handleSubmit}>
//         <input
//           type="text"
//           value={message}
//           onChange={handleInputChange}
//           placeholder="Enter your question"
//         />
//         <button type="submit" disabled={loading}>
//           {loading ? 'Loading...' : 'Submit'}
//         </button>
//       </form>
//       {response && <p>Response: {response}</p>}
//     </div>
//   );
// }


// import React, { useState } from 'react';
// import axios from 'axios';
// import { AI_URL } from '../../../Config'; // Ensure this points to your API key

// const AiBox = ({ getSubject }) => {
//   const [input, setInput] = useState(''); // State to store user input
//   const [response, setResponse] = useState(''); // State to store AI response
//   const [loading, setLoading] = useState(false); // State to manage loading state
//   const [error, setError] = useState(''); // State to manage error messages

//   const sendMessage = async () => {
//     setLoading(true);
//     setError('');
//     setResponse('');
  
//     const apiUrl = 'https://api.openai.com/v1/chat/completions';

//     const headers = {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${AI_URL}`, // Use the imported AI_URL which should contain the API key
//     };

//     const requestBody = {
//       model: 'gpt-3.5-turbo',
//       messages: [{ role: 'user', content: input }],
//     };
//     console.log('requestBody :', requestBody);
  
//     let attempts = 0;
//     const maxAttempts = 5;
//     const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  
//     while (attempts < maxAttempts) {
//       try {
//         const { data } = await axios.post(apiUrl, requestBody, { headers });
//         console.log('dataofChat :', data);
//         setResponse(data.choices[0].message.content.trim());
//         break; // Exit loop if request is successful
//       } catch (error) {
//         if (error.response && error.response.status === 429) {
//           // Handle rate limit error
//           attempts += 1;
//           const backoffDelay = Math.pow(2, attempts) * 1000; // Exponential backoff
//           await delay(backoffDelay);
//         } else {
//           setError('There was an error processing your request.');
//           break; // Exit loop on other errors
//         }
//       } finally {
//         setLoading(false);
//       }
//     }
//   };
  
//   return (
//     <>
//       <p>{getSubject}</p>
//       <div style={{ maxWidth: '600px', margin: '0 auto', textAlign: 'center' }}>
//         <h1>Chat with AI</h1>
//         <div>
//           <input
//             type="text"
//             value={input}
//             onChange={(e) => setInput(e.target.value)}
//             placeholder="Type your message..."
//             style={{
//               width: '80%',
//               padding: '10px',
//               fontSize: '16px',
//               borderRadius: '4px',
//               border: '1px solid #ccc',
//               marginBottom: '10px',
//             }}
//           />
//           <button
//             onClick={sendMessage}
//             disabled={loading}
//             style={{
//               padding: '10px 20px',
//               fontSize: '16px',
//               borderRadius: '4px',
//               border: 'none',
//               backgroundColor: '#007bff',
//               color: '#fff',
//               cursor: 'pointer',
//               marginLeft: '10px',
//             }}
//           >
//             {loading ? 'Sending...' : 'Send'}
//           </button>
//         </div>
//         <div
//           style={{
//             marginTop: '20px',
//             padding: '15px',
//             borderRadius: '4px',
//             backgroundColor: '#f7f7f7',
//             minHeight: '100px',
//           }}
//         >
//           {error && <p style={{ color: 'red' }}>{error}</p>}
//           {response && <p style={{ fontSize: '16px', color: '#333' }}>{response}</p>}
//         </div>
//       </div>
//     </>
//   );
// };

// export default AiBox;

// import React, { useState } from 'react';
// import axios from 'axios';
// import { AI_URL } from '../../../Config'; // Ensure this points to your API key

// const AiBox = () => {
//   const [selectedSubject, setSelectedSubject] = useState(null); // State to store selected subject
//   const [input, setInput] = useState(''); // State to store user input
//   const [conversation, setConversation] = useState([]); // State to store the conversation as pairs of question and answer
//   const [loading, setLoading] = useState(false); // State to manage loading state
//   const [error, setError] = useState(''); // State to manage error messages

//   const subjects = ['Maths', 'English']; // List of subjects

//   const selectSubject = async (subject) => {
//     setSelectedSubject(subject);
//     setInput('');
//     setConversation([]); // Clear previous conversation when a new subject is selected
//     setError('');
    
//     // Send the selected subject to the API to initialize the conversation
//     setLoading(true);
//     try {
//       const apiUrl = 'https://api.openai.com/v1/chat/completions';
//       const headers = {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${AI_URL}`,
//       };
//       const requestBody = {
//         model: 'gpt-3.5-turbo',
//         messages: [
//           { role: 'system', content: `You are an expert in ${subject}. Please answer questions only related to ${subject}. If the question is unrelated, ask the user to rephrase their question.` }
//         ],
//       };

//       await axios.post(apiUrl, requestBody, { headers });
//     } catch (error) {
//       setError('There was an error initializing the conversation.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const sendMessage = async () => {
//     if (!input) return;

//     setLoading(true);
//     setError('');

//     const apiUrl = 'https://api.openai.com/v1/chat/completions';
//     const headers = {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${AI_URL}`,
//     };
//     const requestBody = {
//       model: 'gpt-3.5-turbo',
//       messages: [
//         { role: 'system', content: `You are an expert in ${selectedSubject}. Answer only ${selectedSubject} related questions.` },
//         { role: 'user', content: input }
//       ],
//     };

//     try {
//       const { data } = await axios.post(apiUrl, requestBody, { headers });
//       const newResponse = data.choices[0].message.content.trim();
//       setConversation((prevConversation) => [
//         ...prevConversation,
//         { question: input, answer: newResponse },
//       ]); // Add question and answer to conversation
//       setInput(''); // Clear input after sending
//     } catch (error) {
//       setError('There was an error processing your request.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div style={{ maxWidth: '600px', margin: '0 auto', textAlign: 'center' }}>
//       <h1>Chat with AI</h1>

//       {!selectedSubject && (
//         <div>
//           <h2>Select a subject:</h2>
//           {subjects.map((subject) => (
//             <button
//               key={subject}
//               onClick={() => selectSubject(subject)}
//               style={{
//                 padding: '10px 20px',
//                 fontSize: '16px',
//                 margin: '10px',
//                 cursor: 'pointer',
//                 borderRadius: '4px',
//                 border: '1px solid #007bff',
//                 backgroundColor: '#007bff',
//                 color: '#fff',
//               }}
//             >
//               {subject}
//             </button>
//           ))}
//         </div>
//       )}

//       {selectedSubject && (
//         <>
//           <h2>Selected Subject: {selectedSubject}</h2>
//           <div>
//             <input
//               type="text"
//               value={input}
//               onChange={(e) => setInput(e.target.value)}
//               placeholder={`Type your ${selectedSubject} question...`}
//               style={{
//                 width: '80%',
//                 padding: '10px',
//                 fontSize: '16px',
//                 borderRadius: '4px',
//                 border: '1px solid #ccc',
//                 marginBottom: '10px',
//               }}
//             />
//             <button
//               onClick={sendMessage}
//               disabled={loading}
//               style={{
//                 padding: '10px 20px',
//                 fontSize: '16px',
//                 borderRadius: '4px',
//                 border: 'none',
//                 backgroundColor: '#007bff',
//                 color: '#fff',
//                 cursor: 'pointer',
//                 marginLeft: '10px',
//               }}
//             >
//               {loading ? 'Sending...' : 'Send'}
//             </button>
//           </div>
//           <div
//             style={{
//               marginTop: '20px',
//               padding: '15px',
//               borderRadius: '4px',
//               backgroundColor: '#f7f7f7',
//               minHeight: '100px',
//               textAlign: 'left',
//             }}
//           >
//             {error && <p style={{ color: 'red' }}>{error}</p>}
//             {conversation.length > 0 &&
//               conversation.map((chat, index) => (
//                 <div key={index} style={{ marginBottom: '10px' }}>
//                   <p><strong>Question :</strong> {chat.question}</p>
//                   <p><strong>Answer :</strong> {chat.answer}</p>
//                 </div>
//               ))}
//           </div>
//           <button
//             onClick={() => setSelectedSubject(null)}
//             style={{
//               marginTop: '20px',
//               padding: '10px 20px',
//               fontSize: '16px',
//               borderRadius: '4px',
//               border: '1px solid #ff0000',
//               backgroundColor: '#ff0000',
//               color: '#fff',
//               cursor: 'pointer',
//             }}
//           >
//             Change Subject
//           </button>
//         </>
//       )}
//     </div>
//   );
// };

// export default AiBox;


import React, { useState } from 'react';
import axios from 'axios';
import { AI_URL } from '../../../Config'; // Ensure this points to your API key
import { Helmet } from 'react-helmet';

const AiBox = () => {
  const [selectedSubject, setSelectedSubject] = useState(null); // State to store selected subject
  const [input, setInput] = useState(''); // State to store user input
  const [conversation, setConversation] = useState([]); // State to store the conversation as pairs of question and answer
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [error, setError] = useState(''); // State to manage error messages

  const subjects = ['Maths', 'English']; // List of subjects

  const selectSubject = async (subject) => {
    setSelectedSubject(subject);
    setInput('');
    setConversation([]); // Clear previous conversation when a new subject is selected
    setError('');
    
    // Send the selected subject to the API to initialize the conversation
    // setLoading(true);
    try {
      const apiUrl = 'https://api.openai.com/v1/chat/completions';
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AI_URL}`,
      };
      const requestBody = {
        model: 'gpt-3.5-turbo',
        messages: [
          { role: 'system', content: `You are an expert in ${subject}. Please answer questions only related to ${subject}. If the question is unrelated, ask the user to rephrase their question.` }
        ],
      };

      await axios.post(apiUrl, requestBody, { headers });
    } catch (error) {
      setError('There was an error initializing the conversation.');
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async () => {
    if (!input) return;

    setLoading(true);
    setError('');

    const apiUrl = 'https://api.openai.com/v1/chat/completions';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AI_URL}`,
    };
    const requestBody = {
      model: 'gpt-3.5-turbo',
      messages: [
        { role: 'system', content: `You are an expert in ${selectedSubject}. Answer only ${selectedSubject} related questions.` },
        { role: 'user', content: input }
      ],
    };
    console.log('requestBody :', requestBody);

    try {
      const { data } = await axios.post(apiUrl, requestBody, { headers });
      const newResponse = data.choices[0].message.content.trim();
      setConversation((prevConversation) => [
        ...prevConversation,
        { question: input, answer: newResponse },
      ]); // Add question and answer to conversation
      setInput(''); // Clear input after sending
    } catch (error) {
      setError('There was an error processing your request.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Helmet>
<style>
  {`
          .scrollableContainer::-webkit-scrollbar {
            width: 4px;
          }
          .scrollableContainer::-webkit-scrollbar-track {
            background: #f7f7f7; /* Match the container background */
          }
          .scrollableContainer::-webkit-scrollbar-thumb {
            background-color: #570861; /* Custom scrollbar color */
          }
          .scrollableContainer::-webkit-scrollbar-thumb:hover {
            background-color: #3c0d5d; /* Darker color on hover */
          }
  `}
</style>
    </Helmet>
    <div style={{ maxWidth: '600px', margin: '0 auto', textAlign: 'center' }}>
      <h2>Chat with AI Virtual Tutor</h2>

      {!selectedSubject && (
        <div>
          <h2>Select a subject :</h2>
          {subjects.map((subject) => (
            <button
              key={subject}
              onClick={() => selectSubject(subject)}
              style={{
                padding: '10px 20px',
                fontSize: '16px',
                margin: '10px',
                border: 'none',
                borderRadius: '4px',
                backgroundColor: '#FFC924',
                color: 'black',
                cursor: 'pointer',
                marginLeft: '10px',
              }}
            >
              {subject}
            </button>
          ))}
        </div>
      )}

      {selectedSubject && (
        <>
          <h2 >Selected Subject : <span style={{color:'#570861'}}>{selectedSubject}</span></h2>
          <div className='mt-2'>
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={`Type your ${selectedSubject} question...`}
              style={{
                width: '80%',
                padding: '10px',
                fontSize: '16px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                marginBottom: '10px',
              }}
            />
            <button
              onClick={sendMessage}
              disabled={loading}
              style={{
                padding: '10px 20px',
                fontSize: '16px',
                borderRadius: '4px',
                border: 'none',
                backgroundColor: '#FFC924',
                color: 'black',
                cursor: 'pointer',
                marginLeft: '10px',
              }}
            >
              {loading ? 'Sending...' : 'Send'}
            </button>
          </div>
          <div className='scrollableContainer'
            style={{
              marginTop: '20px',
              padding: '15px',
              borderRadius: '4px',
              backgroundColor: '#f7f7f7',
              maxHeight: '300px', // Set a maximum height
              overflowY: 'auto',  // Add vertical scroll if content overflows
              textAlign: 'left',
              scrollbarColor: '#570861',
            }}
          >
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {conversation.length > 0 &&
              conversation.map((chat, index) => (
                <div key={index} style={{ marginBottom: '10px' }}>
                  <p><strong style={{color:'#570861'}}>Question :</strong> {chat.question}</p>
                  <p><strong style={{color:'#570861'}}>Answer :</strong> {chat.answer}</p>
                </div>
              ))}
          </div>
          <button
            onClick={() => setSelectedSubject(null)}
            style={{
              marginTop: '20px',
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#FFC924',
              color: 'black',
              cursor: 'pointer',
              marginLeft: '10px',
            }}
          >
            Change Subject
          </button>
        </>
      )}
    </div>
    </>
  );
};

export default AiBox;


// import React, { useState } from 'react';
// import axios from 'axios';
// import { AI_URL } from '../../../Config'; // Ensure this points to your API key

// const AiBox = () => {
//   const [selectedSubject, setSelectedSubject] = useState(null); // State to store selected subject
//   const [input, setInput] = useState(''); // State to store user input
//   const [response, setResponse] = useState(''); // State to store AI response
//   const [loading, setLoading] = useState(false); // State to manage loading state
//   const [error, setError] = useState(''); // State to manage error messages

//   const subjects = ['Maths', 'English']; // List of subjects

//   const selectSubject = async (subject) => {
//     setSelectedSubject(subject);
//     setInput('');
//     setResponse('');
//     setError('');
    
//     // Send the selected subject to the API to initialize the conversation
//     setLoading(true);
//     try {
//       const apiUrl = 'https://api.openai.com/v1/chat/completions';
//       const headers = {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${AI_URL}`,
//       };
//       const requestBody = {
//         model: 'gpt-3.5-turbo',
//         messages: [{ role: 'system', content: `The user has selected ${subject}.` }],
//       };

//       await axios.post(apiUrl, requestBody, { headers });
//     } catch (error) {
//       setError('There was an error initializing the conversation.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const sendMessage = async () => {
//     if (!input) return;

//     setLoading(true);
//     setError('');
//     setResponse('');

//     const apiUrl = 'https://api.openai.com/v1/chat/completions';
//     const headers = {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${AI_URL}`,
//     };
//     const requestBody = {
//       model: 'gpt-3.5-turbo',
//       messages: [{ role: 'user', content: input }],
//     };

//     try {
//       const { data } = await axios.post(apiUrl, requestBody, { headers });
//       setResponse(data.choices[0].message.content.trim());
//     } catch (error) {
//       setError('There was an error processing your request.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div style={{ maxWidth: '600px', margin: '0 auto', textAlign: 'center' }}>
//       <h1>Chat with AI</h1>

//       {!selectedSubject && (
//         <div>
//           <h2>Select a subject:</h2>
//           {subjects.map((subject) => (
//             <button
//               key={subject}
//               onClick={() => selectSubject(subject)}
//               style={{
//                 padding: '10px 20px',
//                 fontSize: '16px',
//                 margin: '10px',
//                 cursor: 'pointer',
//                 borderRadius: '4px',
//                 border: '1px solid #007bff',
//                 backgroundColor: '#007bff',
//                 color: '#fff',
//               }}
//             >
//               {subject}
//             </button>
//           ))}
//         </div>
//       )}

//       {selectedSubject && (
//         <>
//           <h2>Selected Subject: {selectedSubject}</h2>
//           <div>
//             <input
//               type="text"
//               value={input}
//               onChange={(e) => setInput(e.target.value)}
//               placeholder={`Type your ${selectedSubject} question...`}
//               style={{
//                 width: '80%',
//                 padding: '10px',
//                 fontSize: '16px',
//                 borderRadius: '4px',
//                 border: '1px solid #ccc',
//                 marginBottom: '10px',
//               }}
//             />
//             <button
//               onClick={sendMessage}
//               disabled={loading}
//               style={{
//                 padding: '10px 20px',
//                 fontSize: '16px',
//                 borderRadius: '4px',
//                 border: 'none',
//                 backgroundColor: '#007bff',
//                 color: '#fff',
//                 cursor: 'pointer',
//                 marginLeft: '10px',
//               }}
//             >
//               {loading ? 'Sending...' : 'Send'}
//             </button>
//           </div>
//           <div
//             style={{
//               marginTop: '20px',
//               padding: '15px',
//               borderRadius: '4px',
//               backgroundColor: '#f7f7f7',
//               minHeight: '100px',
//             }}
//           >
//             {error && <p style={{ color: 'red' }}>{error}</p>}
//             {response && <p style={{ fontSize: '16px', color: '#333' }}>{response}</p>}
//           </div>
//           <button
//             onClick={() => setSelectedSubject(null)}
//             style={{
//               marginTop: '20px',
//               padding: '10px 20px',
//               fontSize: '16px',
//               borderRadius: '4px',
//               border: '1px solid #ff0000',
//               backgroundColor: '#ff0000',
//               color: '#fff',
//               cursor: 'pointer',
//             }}
//           >
//             Change Subject
//           </button>
//         </>
//       )}
//     </div>
//   );
// };

// export default AiBox;
