import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import JqueryLoad from "../../../../JqueryLoad/JqueryLoad";
import PFooter_Dashboard from "../../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../../Pinc/PHeader_Dashboard";

export default function POnsiteClass() {
  let photos = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let able_tms_slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let class_slider = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <JqueryLoad />
      <PHeader_Dashboard />
      <main className="dashboard-app d-lg-flex">
        <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
          <div className="align-items-center justify-content-between d-flex">
            <div className="d-flex align-items-center">
              <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                <i className="fa fa-bars" />
              </a>
              <a href="#" className="d-lg-none d-block brand-logo">
                <img src="./assets/images/logo.png" alt="logo" />
              </a>
            </div>
          </div>
        </header>
        <div className="class-content custom_btm_brdr">
          {/*start*/}
          <a href="#" className="mb-4 arrow_left mb-3 d-block" onClick="window.history.back()">
            <span>
              <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
              </svg>
              <span className="ms-2">Back</span>
            </span>
          </a>
          {/*end*/}
          {/*start*/}
          <section>
            <h2>Find Classes</h2>
            <div className="class_tag my-lg-5 my-4 d-flex align-items-center">
              <span>
                <svg width={30} height={28} viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M29.2389 9.16506C28.3584 7.35918 26.846 6.34807 25.3829 5.36908C24.2925 4.63859 23.2643 3.94881 22.5359 2.96125L22.401 2.77917C21.9725 2.20292 21.4841 1.54955 21.4091 1.00114C21.3341 0.446314 20.8971 0.0607187 20.2759 0.0778563C19.7146 0.116416 19.2798 0.583415 19.2798 1.14681V20.2338C18.3822 19.6897 17.2768 19.3555 16.0665 19.3555C13.1145 19.3555 10.711 21.277 10.711 23.6399C10.711 26.0027 13.1145 27.9243 16.0665 27.9243C19.0206 27.9243 21.422 26.0027 21.422 23.6399V11.1552C23.0308 11.77 25.6635 13.3359 26.3876 16.9926C26.2526 17.1919 26.1241 17.4082 25.9741 17.5775C25.5821 18.0209 25.6271 18.6978 26.0705 19.0877C26.5118 19.4819 27.1888 19.4347 27.5808 18.9913C28.9218 17.4682 29.8451 15.0561 29.9886 12.6975C30.0593 11.5343 29.8001 10.3133 29.2389 9.16506ZM1.0711 2.21791H13.9243C14.5155 2.21791 14.9954 1.73806 14.9954 1.14681C14.9954 0.555566 14.5155 0.0757141 13.9243 0.0757141H1.0711C0.479852 0.0757141 0 0.555566 0 1.14681C0 1.73806 0.479852 2.21791 1.0711 2.21791ZM1.0711 8.6445H13.9243C14.5155 8.6445 14.9954 8.16465 14.9954 7.5734C14.9954 6.98216 14.5155 6.50231 13.9243 6.50231H1.0711C0.479852 6.50231 0 6.98216 0 7.5734C0 8.16465 0.479852 8.6445 1.0711 8.6445ZM7.49769 12.9289H1.0711C0.479852 12.9289 0 13.4087 0 14C0 14.5912 0.479852 15.0711 1.0711 15.0711H7.49769C8.08894 15.0711 8.56879 14.5912 8.56879 14C8.56879 13.4087 8.08894 12.9289 7.49769 12.9289ZM7.49769 19.3555H1.0711C0.479852 19.3555 0 19.8353 0 20.4266C0 21.0178 0.479852 21.4977 1.0711 21.4977H7.49769C8.08894 21.4977 8.56879 21.0178 8.56879 20.4266C8.56879 19.8353 8.08894 19.3555 7.49769 19.3555Z"
                    fill="#FFC924"
                  />
                </svg>
              </span>
              <span className="ms-2 fw-bold text-purple">Music</span>
            </div>
            <div className="class_dtls_sec">
              <h1 className="mb-2">Get private writing tutoring on a weekly basis to improve your skills and receive personalized feedback.</h1>
              <div className="row">
                <div className="bg-transparent col-lg-6">
                  <div className="justify-content-between">
                    <div>
                      <div className="clss_detls_ctn mt-5">
                        <p className="text-capitalize">
                          The purpose of this private tutoring session is to assist a student in enhancing their skills as both a writer and a
                          researcher. The goal is to help them improve and excel in these areas.{" "}
                        </p>
                      </div>
                      <div className="payment-week bg-transparent my-4">
                        <div>
                          <span className="payment_price fw-bold text-purple">$15.00</span>
                        </div>
                      </div>
                      <ul className="class_tag d-flex flex-wrap">
                        <li className="px-3 py-2 rounded-3">
                          <span className="text-gray45 fw-500 text-capitalize">Age</span>
                          <span className="fw-600 d-block">5-7 Age</span>
                        </li>
                        <li className="px-3 py-2 rounded-3">
                          <span className="text-gray45 fw-500 text-capitalize">class meets</span>
                          <span className="fw-600 d-block">Once A Week</span>
                        </li>
                        <li className="px-3 py-2 rounded-3">
                          <span className="text-gray45 fw-500 text-capitalize">Class Length</span>
                          <span className="fw-600 d-block">Ongoing Weekly</span>
                        </li>
                        <li className="px-3 py-2 rounded-3">
                          <span className="text-gray45 fw-500 text-capitalize">Class Duration</span>
                          <span className="fw-600 d-block">30 minute</span>
                        </li>
                        <li className="px-3 py-2 rounded-3">
                          <span className="text-gray45 fw-500 text-capitalize">Class size</span>
                          <span className="fw-600 d-block">2-7 Student</span>
                        </li>
                        <li className="px-3 py-2 rounded-3">
                          <span className="text-gray45 fw-500 text-capitalize">Class Type</span>
                          <span className="fw-600 d-block">Live Online Class</span>
                        </li>
                        <li className="px-3 py-2 rounded-3">
                          <span className="text-gray45 fw-500 text-capitalize">Number Of Learners Completed This Class</span>
                          <span className="fw-600 d-block">20 Learners</span>
                        </li>
                      </ul>
                      <div className="mt-4 mb-lg-0 mb-4 find_btm_class">
                        <div className="d-flex mb-3">
                          <span className="find_btm_class_left fw-500 fs-6 text-purple text-capitalize">Pre-Requisite :</span>
                          <p className=" find_btm_class_right fw-500 fs-6 text-capitalize">
                            Get private writing tutoring on a weekly basis to improve your skills and receive personalized feedback.
                          </p>
                        </div>
                        <div className="d-flex">
                          <span className="find_btm_class_left fw-500 fs-6 text-purple text-capitalize">Next-Class :</span>
                          <p className="find_btm_class_right fw-500 fs-6 text-capitalize">
                            Get private writing tutoring on a weekly basis to improve your skills and receive personalized feedback.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="class_image_box col-lg-6">
                  <div className="class_img position-relative">
                    <div className="video-container position-static pb-0">
                      {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                      <video id="video" className="position-static rounded-3" loop width="100%" controls="hide">
                        <source src="./assets/video/Classroom -teachers (2).mp4" type="video/mp4" />
                      </video>
                    </div>
                    <div className="d-flex bottom-info align-items-center justify-content-between position-static bg-diff-yellow-op-1 rounded-3">
                      <div className="d-flex align-items-center">
                        <img src="./assets/images/user1.png" alt="user1" />
                        <span className="tech_nm ms-2">Celina Mark</span>
                      </div>
                      <div className="star_rat">
                        <span>
                          <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                              fill="#FDC840"
                            />
                          </svg>
                        </span>
                        <span className="fw-600">
                          4.87<span className="text-gray fw-500">(8426)</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <ul className="btm_cls_dtls mt-4 d-flex align-items-center justify-content-end">
                    <li className="me-4">
                      <a href="#">
                        <span>
                          <svg width={29} height={25} viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M26.1155 2.47995C23.0354 -0.689867 18.0248 -0.689867 14.9455 2.47995L14.2303 3.21577L13.5155 2.47995C10.4363 -0.690296 5.4252 -0.690296 2.34595 2.47995C-0.670779 5.5854 -0.690368 10.5079 2.30052 13.9304C5.02841 17.0509 13.0737 23.7925 13.4151 24.0778C13.6375 24.2646 13.9158 24.3664 14.2028 24.3662L14.2299 24.3657C14.5166 24.3795 14.8055 24.2782 15.0447 24.0778C15.3861 23.7925 23.4322 17.0509 26.1609 13.93C29.1514 10.5079 29.1318 5.5854 26.1155 2.47995ZM24.3021 12.2082C22.1752 14.6405 16.3289 19.6569 14.2299 21.437C12.1309 19.6574 6.28587 14.6413 4.15941 12.2086C2.07295 9.8214 2.05336 6.42162 4.11398 4.3004C5.16637 3.21748 6.54845 2.67559 7.93053 2.67559C9.3126 2.67559 10.6947 3.21705 11.7471 4.3004L13.3192 5.91877C13.5012 6.10507 13.7361 6.22693 13.9898 6.26673C14.1945 6.3124 14.407 6.30451 14.608 6.2438C14.809 6.18309 14.9919 6.07149 15.1402 5.9192L16.7131 4.3004C18.8183 2.13413 22.2427 2.13456 24.3467 4.3004C26.4073 6.42162 26.3877 9.8214 24.3021 12.2082Z"
                              fill="#570861"
                            />
                          </svg>
                        </span>
                        <span className="ms-2">Save</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>
                          <svg width={30} height={28} viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.3438 8.37432C18.123 8.37432 18.75 7.74737 18.75 6.96807V6.49932V5.56182V2.74932L28.125 11.1868L18.75 19.6243V16.8118V15.8743V15.4056C18.75 14.6263 18.123 13.9993 17.3438 13.9993H16.875H11.25C7.10742 13.9993 3.75 17.3567 3.75 21.4993C3.75 21.9856 3.79102 22.4427 3.86719 22.8587C2.80664 21.4817 1.875 19.513 1.875 16.8118C1.875 12.1536 5.6543 8.37432 10.3125 8.37432H16.875H17.3438ZM16.875 16.8118V17.7493V19.6243C16.875 20.3626 17.3086 21.0364 17.9883 21.3353C18.668 21.6341 19.4531 21.511 20.0039 21.0188L29.3789 12.5814C29.7715 12.2239 30 11.72 30 11.1868C30 10.6536 29.7773 10.1497 29.3789 9.79229L20.0039 1.35479C19.4531 0.856741 18.6621 0.733694 17.9883 1.03838C17.3145 1.34307 16.875 2.01104 16.875 2.74932V4.62432V5.56182V6.49932H15H10.3125C4.61719 6.49932 0 11.1165 0 16.8118C0 23.4505 4.77539 26.4153 5.87109 27.013C6.01758 27.095 6.18164 27.1243 6.3457 27.1243C6.98438 27.1243 7.5 26.6028 7.5 25.97C7.5 25.5306 7.24805 25.1263 6.92578 24.8274C6.375 24.306 5.625 23.2806 5.625 21.4993C5.625 18.3939 8.14453 15.8743 11.25 15.8743H15H16.875V16.8118Z"
                              fill="#570861"
                            />
                          </svg>
                        </span>
                        <span className="ms-2">Share</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          {/*end*/}
        </div>
        {/*start*/}
        <div className="class-content custom_btm_brdr">
          <div className="row location_card">
            <div className="col-xl-5 mb-xl-0 mb-5 left">
              <div>
                <img src="./assets/images/location_card.png" alt="location_card" />
              </div>
            </div>
            <div className="col-xl-7 right">
              <div className="location_photo">
                <h3 className="mb-5">Location Photos:</h3>
                <div className="location_slider_pic slick_silder_button slick_slider_white">
                  <Slider {...photos}>
                    <div className="loction_box">
                      <img src="./assets/images/classroom/class1.png" alt="class1" className="fis_pic" />
                      <img src="./assets/images/classroom/class1.png" alt="class1" className="sec_pic" />
                    </div>
                    <div className="loction_box">
                      <img src="./assets/images/classroom/class1.png" alt="class1" className="fis_pic" />
                      <img src="./assets/images/classroom/class1.png" alt="class1" className="sec_pic" />
                    </div>
                    <div className="loction_box">
                      <img src="./assets/images/classroom/class1.png" alt="class1" className="fis_pic" />
                      <img src="./assets/images/classroom/class1.png" alt="class1" className="sec_pic" />
                    </div>
                    <div className="loction_box">
                      <img src="./assets/images/classroom/class1.png" alt="class1" className="fis_pic" />
                      <img src="./assets/images/classroom/class1.png" alt="class1" className="sec_pic" />
                    </div>
                    <div className="loction_box">
                      <img src="./assets/images/classroom/class1.png" alt="class1" className="fis_pic" />
                      <img src="./assets/images/classroom/class1.png" alt="class1" className="sec_pic" />
                    </div>
                  </Slider>
                </div>
                <div className="locat_add mt-5">
                  <h3>Class Address:</h3>
                  <p>
                    4517 Washington Ave. Manchester,
                    <br /> Kentucky 39495.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end*/}
        <div className="class-content custom_btm_brdr">
          <section className="avilable_time slick_silder_button">
            <h2 className="mb-2">Available Times</h2>
            <div className="d-flex align-items-center mb-4">
              <span>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.70469 14.9531L3.14531 14.1844C3.53437 13.5047 4.17188 13.0031 4.92656 12.7875L7.64062 12.0141C8.44687 11.7844 9 11.0484 9 10.2094V8.33906C9 7.82344 9.29062 7.35469 9.75 7.125C10.2094 6.89531 10.5 6.42656 10.5 5.91094V4.08281C10.5 3.35156 11.1984 2.82187 11.9016 3.02344C12.6562 3.23906 13.2422 3.84375 13.4344 4.60781L13.5656 5.13281C13.7625 5.925 14.2781 6.60469 14.9859 7.00781L15.3656 7.22344C16.0687 7.62188 16.5 8.37187 16.5 9.17812V9.56719C16.5 10.1625 16.2609 10.7344 15.8391 11.1562L15.6562 11.3391C15.2344 11.7609 14.6625 12 14.0672 12H12.0469C11.5266 12 11.0109 12.1359 10.5562 12.3937L8.93906 13.3172C8.7375 13.4344 8.58281 13.6219 8.50781 13.8422C8.35781 14.2922 8.55937 14.7797 8.98594 14.9906L9.2625 15.1313C9.57188 15.2859 9.93281 15.3141 10.2609 15.2016L11.3484 14.8406C11.7328 14.7141 12.1547 14.8594 12.375 15.1922C12.5953 15.5203 12.5719 15.9562 12.3187 16.2609L11.6812 17.025C11.2125 17.5875 11.2172 18.4078 11.6953 18.9609L12.4313 19.8188C12.8438 20.3016 12.9094 20.9906 12.5953 21.5391L12.4828 21.7359C12.3187 21.7453 12.1594 21.75 11.9953 21.75C7.64531 21.75 3.95625 18.8953 2.70469 14.9531ZM21.75 12C21.75 13.725 21.3 15.3469 20.5125 16.7578L19.3125 16.275C18.5766 15.9797 18.1969 15.1594 18.4453 14.4094L19.2375 12.0328C19.4016 11.5453 19.8 11.175 20.2969 11.0531L21.6609 10.7109C21.7172 11.1328 21.7453 11.5641 21.7453 12H21.75ZM24 12C24 5.37188 18.6281 0 12 0C5.37188 0 0 5.37188 0 12C0 18.6281 5.37188 24 12 24C18.6281 24 24 18.6281 24 12Z"
                    fill="#570861"
                  />
                </svg>
              </span>
              <span className="ms-2">Indian Standard Time</span>
            </div>
            {/*start*/}
            <div className="avilable_box able_tms_slider  pt-2">
              <Slider {...able_tms_slider}>
                <div className="pe-3">
                  <div className="tme_box" data-bs-toggle="modal" data-bs-target="#subscription_modal_1">
                    <h2 className="poppins fw-600 text-dif-black">Next On Friday, May 16th</h2>
                    <div className="actul_tme my-4">
                      <span className="text-gray45 fw-600">Class Time</span>
                      <p className="fs-5">08:00am - 10:00am</p>
                    </div>
                    <div className="num_spot">
                      <span className="fw-600 mb-2 text-purple d-block">Number Of Spot Left</span>
                      <span className="text-purple">2</span>
                    </div>
                    <div className="button mt-2">
                      <a href="#" className="fw-500 btn-theme d-block text-center" tabIndex={0}>
                        Subscribe
                      </a>
                    </div>
                  </div>
                </div>
                <div className="pe-3">
                  <div className="tme_box" data-bs-toggle="modal" data-bs-target="#subscription_modal_1">
                    <h2 className="poppins fw-600 text-dif-black">Next On Friday, May 16th</h2>
                    <div className="actul_tme my-4">
                      <span className="text-gray45 fw-600">Class Time</span>
                      <p className="fs-5">08:00am - 10:00am</p>
                    </div>
                    <div className="num_spot">
                      <span className="fw-600 mb-2 text-purple d-block">Number Of Spot Left</span>
                      <span className="text-purple">2</span>
                    </div>
                    <div className="button mt-2">
                      <a href="#" className="fw-500 btn-theme d-block text-center" tabIndex={0}>
                        Subscribe
                      </a>
                    </div>
                  </div>
                </div>
                <div className="pe-3">
                  <div className="tme_box" data-bs-toggle="modal" data-bs-target="#subscription_modal_1">
                    <h2 className="poppins fw-600 text-dif-black">Next On Friday, May 16th</h2>
                    <div className="actul_tme my-4">
                      <span className="text-gray45 fw-600">Class Time</span>
                      <p className="fs-5">08:00am - 10:00am</p>
                    </div>
                    <div className="num_spot">
                      <span className="fw-600 mb-2 text-purple d-block">Number Of Spot Left</span>
                      <span className="text-purple">2</span>
                    </div>
                    <div className="button mt-2">
                      <a href="#" className="fw-500 btn-theme d-block text-center" tabIndex={0}>
                        Subscribe
                      </a>
                    </div>
                  </div>
                </div>
                <div className="pe-3">
                  <div className="tme_box" data-bs-toggle="modal" data-bs-target="#subscription_modal_1">
                    <h2 className="poppins fw-600 text-dif-black">Next On Friday, May 16th</h2>
                    <div className="actul_tme my-4">
                      <span className="text-gray45 fw-600">Class Time</span>
                      <p className="fs-5">08:00am - 10:00am</p>
                    </div>
                    <div className="num_spot">
                      <span className="fw-600 mb-2 text-purple d-block">Number Of Spot Left</span>
                      <span className="text-purple">2</span>
                    </div>
                    <div className="button mt-2">
                      <a href="#" className="fw-500 btn-theme d-block text-center" tabIndex={0}>
                        Subscribe
                      </a>
                    </div>
                  </div>
                </div>
                <div className="pe-3">
                  <div className="tme_box" data-bs-toggle="modal" data-bs-target="#subscription_modal_1">
                    <h2 className="poppins fw-600 text-dif-black">Next On Friday, May 16th</h2>
                    <div className="actul_tme my-4">
                      <span className="text-gray45 fw-600">Class Time</span>
                      <p className="fs-5">08:00am - 10:00am</p>
                    </div>
                    <div className="num_spot">
                      <span className="fw-600 mb-2 text-purple d-block">Number Of Spot Left</span>
                      <span className="text-purple">2</span>
                    </div>
                    <div className="button mt-2">
                      <a href="#" className="fw-500 btn-theme d-block text-center" tabIndex={0}>
                        Subscribe
                      </a>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            {/*end*/}
            <span className="text-center d-block mb-4">Don’t See What You’re looking For?</span>
            <div className="d-xl-flex justify-content-center text-center">
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#request_another_time"
                className="btn-theme bg-purple d-inline-block me-xl-4 mb-xl-0 mb-4"
              >
                Request Another Time
              </a>
              <a href="#" className="btn-theme bg-purple d-inline-block">
                Request A Private Class
              </a>
            </div>
          </section>
        </div>
        <div className="class-content pt-lg-5">
          <section className="description_ctn">
            <h2 className="mb-5">Description</h2>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="poppins fw-600 accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Class Experience
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue Lorem ipsum dolor sit amet consectetur.In eu augue sed
                    ectetur.In eu augue Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue Lorem ipsum dolor sit amet
                    consectetur.In eu augue sed ectetur.In eu augue Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue Lorem
                    ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="poppins fw-600 accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Learning goals
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Quisque sapien augue, ornare id leo a, tristique elementum justo. Praesent non nulla sagittis, sollicitudin justo id, varius erat.
                    Nunc sed pharetra nisl. Cras et suscipit felis, in lacinia sapien. Integer venenatis sagittis massa, eu eleifend nibh venenatis
                    in. Pellentesque a aliquet urna. Curabitur tortor metus, ultrices sed mi at, sagittis imperdiet turpis. Suspendisse nec luctus
                    nunc. Fusce in arcu quis lacus mollis ultrices.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="poppins fw-600 accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Homework
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Praesent nec ipsum scelerisque dui condimentum pellentesque eu at lectus. Vivamus purus purus, bibendum in vestibulum ac, pharetra
                    sit amet sapien. Nunc luctus, orci vel luctus cursus, nibh nisl ullamcorper ipsum, eu malesuada arcu augue id nisi. In auctor mi
                    ac ante tincidunt tincidunt.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="poppins fw-600 accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Assessment
                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Praesent nec ipsum scelerisque dui condimentum pellentesque eu at lectus. Vivamus purus purus, bibendum in vestibulum ac, pharetra
                    sit amet sapien. Nunc luctus, orci vel luctus cursus, nibh nisl ullamcorper ipsum, eu malesuada arcu augue id nisi. In auctor mi
                    ac ante tincidunt tincidunt.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="poppins fw-600 accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Parental guidance
                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Praesent nec ipsum scelerisque dui condimentum pellentesque eu at lectus. Vivamus purus purus, bibendum in vestibulum ac, pharetra
                    sit amet sapien. Nunc luctus, orci vel luctus cursus, nibh nisl ullamcorper ipsum, eu malesuada arcu augue id nisi. In auctor mi
                    ac ante tincidunt tincidunt.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSix">
                  <button
                    className="poppins fw-600 accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Content
                  </button>
                </h2>
                <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Praesent nec ipsum scelerisque dui condimentum pellentesque eu at lectus. Vivamus purus purus, bibendum in vestibulum ac, pharetra
                    sit amet sapien. Nunc luctus, orci vel luctus cursus, nibh nisl ullamcorper ipsum, eu malesuada arcu augue id nisi. In auctor mi
                    ac ante tincidunt tincidunt.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingsevan">
                  <button
                    className="poppins fw-600 accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsesevan"
                    aria-expanded="false"
                    aria-controls="collapsesevan"
                  >
                    Learner supply
                  </button>
                </h2>
                <div id="collapsesevan" className="accordion-collapse collapse" aria-labelledby="headingsevan" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Praesent nec ipsum scelerisque dui condimentum pellentesque eu at lectus. Vivamus purus purus, bibendum in vestibulum ac, pharetra
                    sit amet sapien. Nunc luctus, orci vel luctus cursus, nibh nisl ullamcorper ipsum, eu malesuada arcu augue id nisi. In auctor mi
                    ac ante tincidunt tincidunt.
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="meet_techer">
            <h2>Meet the Teacher</h2>
            <div className="meet_details mb-5 d-lg-flex align-items-center">
              <div className="meet_img mb-lg-0 mb-4">
                <Link
                  to="/teachers_details"
                  className="meet_img mb-lg-0 mb-4"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  data-bs-title="Teacher Details"
                >
                  <img src="./assets/images/meet_techer.png" alt="meet_techer" />
                </Link>
              </div>
              <div className="meet_right">
                <div className="d-xl-flex mb-2 align-items-center">
                  <div className="deg_name d-flex mb-xl-0 mb-3 align-items-center">
                    <h2 className="poppins fw_500 text-purple">Celina Mark </h2>
                    <span className=" ms-2 text-dif-black">(MSC.BED)</span>
                  </div>
                  <div className="d-flex align-items-center ms-xl-2">
                    <a href="#" className="btn-theme py-2 ms-2 d-block text-dif-black text-center" tabIndex={0}>
                      Follow
                    </a>
                    <span className="ms-3 text-dif-black fw-500">(36 Followers)</span>
                  </div>
                </div>
                <div className="live_canada align-items-center mb-3 d-flex">
                  <span className="fs-5">Lives in Canada</span>
                  <img src="./assets/images/country_flag.png" alt="country_flag" className="ms-3" />
                </div>
                <p className="fs-5 text-dif-black mb-3">Experienced Multi-Subject Teacher: Creative Arts &amp; World Travels</p>
                <div className="mb-3 d-lg-flex align-items-center">
                  <ul className="d-flex mb-lg-0 mb-2 align-items-center">
                    <li className="me-2">
                      <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.4701 1.125C17.1612 0.4375 16.5084 0 15.7915 0C15.0745 0 14.4276 0.4375 14.1128 1.125L10.365 9.39375L1.99495 10.7188C1.2955 10.8313 0.712632 11.3563 0.496971 12.075C0.281309 12.7938 0.456169 13.5875 0.957438 14.1188L7.03094 20.5625L5.59708 29.6688C5.48051 30.4188 5.77194 31.1813 6.34898 31.625C6.92602 32.0688 7.68958 32.125 8.31908 31.7688L15.7973 27.4875L23.2755 31.7688C23.905 32.125 24.6686 32.075 25.2456 31.625C25.8227 31.175 26.1141 30.4188 25.9975 29.6688L24.5578 20.5625L30.6313 14.1188C31.1326 13.5875 31.3133 12.7938 31.0918 12.075C30.8703 11.3563 30.2933 10.8313 29.5938 10.7188L21.218 9.39375L17.4701 1.125Z"
                          fill="#FDC840"
                        />
                      </svg>
                    </li>
                    <li className="me-2">
                      <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.4701 1.125C17.1612 0.4375 16.5084 0 15.7915 0C15.0745 0 14.4276 0.4375 14.1128 1.125L10.365 9.39375L1.99495 10.7188C1.2955 10.8313 0.712632 11.3563 0.496971 12.075C0.281309 12.7938 0.456169 13.5875 0.957438 14.1188L7.03094 20.5625L5.59708 29.6688C5.48051 30.4188 5.77194 31.1813 6.34898 31.625C6.92602 32.0688 7.68958 32.125 8.31908 31.7688L15.7973 27.4875L23.2755 31.7688C23.905 32.125 24.6686 32.075 25.2456 31.625C25.8227 31.175 26.1141 30.4188 25.9975 29.6688L24.5578 20.5625L30.6313 14.1188C31.1326 13.5875 31.3133 12.7938 31.0918 12.075C30.8703 11.3563 30.2933 10.8313 29.5938 10.7188L21.218 9.39375L17.4701 1.125Z"
                          fill="#FDC840"
                        />
                      </svg>
                    </li>
                    <li className="me-2">
                      <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.4701 1.125C17.1612 0.4375 16.5084 0 15.7915 0C15.0745 0 14.4276 0.4375 14.1128 1.125L10.365 9.39375L1.99495 10.7188C1.2955 10.8313 0.712632 11.3563 0.496971 12.075C0.281309 12.7938 0.456169 13.5875 0.957438 14.1188L7.03094 20.5625L5.59708 29.6688C5.48051 30.4188 5.77194 31.1813 6.34898 31.625C6.92602 32.0688 7.68958 32.125 8.31908 31.7688L15.7973 27.4875L23.2755 31.7688C23.905 32.125 24.6686 32.075 25.2456 31.625C25.8227 31.175 26.1141 30.4188 25.9975 29.6688L24.5578 20.5625L30.6313 14.1188C31.1326 13.5875 31.3133 12.7938 31.0918 12.075C30.8703 11.3563 30.2933 10.8313 29.5938 10.7188L21.218 9.39375L17.4701 1.125Z"
                          fill="#FDC840"
                        />
                      </svg>
                    </li>
                    <li className="me-2">
                      <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.4701 1.125C17.1612 0.4375 16.5084 0 15.7915 0C15.0745 0 14.4276 0.4375 14.1128 1.125L10.365 9.39375L1.99495 10.7188C1.2955 10.8313 0.712632 11.3563 0.496971 12.075C0.281309 12.7938 0.456169 13.5875 0.957438 14.1188L7.03094 20.5625L5.59708 29.6688C5.48051 30.4188 5.77194 31.1813 6.34898 31.625C6.92602 32.0688 7.68958 32.125 8.31908 31.7688L15.7973 27.4875L23.2755 31.7688C23.905 32.125 24.6686 32.075 25.2456 31.625C25.8227 31.175 26.1141 30.4188 25.9975 29.6688L24.5578 20.5625L30.6313 14.1188C31.1326 13.5875 31.3133 12.7938 31.0918 12.075C30.8703 11.3563 30.2933 10.8313 29.5938 10.7188L21.218 9.39375L17.4701 1.125Z"
                          fill="#FDC840"
                        />
                      </svg>
                    </li>
                    <li>
                      <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.4701 1.125C17.1612 0.4375 16.5084 0 15.7915 0C15.0745 0 14.4276 0.4375 14.1128 1.125L10.365 9.39375L1.99495 10.7188C1.2955 10.8313 0.712632 11.3563 0.496971 12.075C0.281309 12.7938 0.456169 13.5875 0.957438 14.1188L7.03094 20.5625L5.59708 29.6688C5.48051 30.4188 5.77194 31.1813 6.34898 31.625C6.92602 32.0688 7.68958 32.125 8.31908 31.7688L15.7973 27.4875L23.2755 31.7688C23.905 32.125 24.6686 32.075 25.2456 31.625C25.8227 31.175 26.1141 30.4188 25.9975 29.6688L24.5578 20.5625L30.6313 14.1188C31.1326 13.5875 31.3133 12.7938 31.0918 12.075C30.8703 11.3563 30.2933 10.8313 29.5938 10.7188L21.218 9.39375L17.4701 1.125Z"
                          fill="#FDC840"
                        />
                      </svg>
                    </li>
                  </ul>
                  <span className="fs-5 fw-600 ms-lg-3">4.87 Reviews</span>
                  <span className="fw-bold text-gray d-sm-inline-block d-flex fs-5 ms-sm-2">(8426 Total Reviews)</span>
                </div>
                <span className="text-gray mb-3 d-block fw-600">238 Completed Class</span>
                <div className="d-xl-flex align-items-center">
                  <a href="#!" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" data-bs-toggle="modal" data-bs-target="#ask_the_teacher">
                    Ask A Question
                  </a>
                  <Link to="/teachers_details" className="btn-theme d-inline-block text-dif-black text-center ms-xl-3" tabIndex={0}>
                    Watch My Video Introduction
                  </Link>
                </div>
              </div>
            </div>
            <div className="meet_about mb-5">
              <h2 className="poppins fw-600 text-purple text-capitalize mb-2 fs-4">About Me</h2>
              <p className="text-capitalize">
                I am a friendly, articulate, professional, college-educated teacher, and artist versed in a variety of disciplines, a "Renaissance
                Man" at heart. As Ben Franklin was quoted: "An investment in knowledge pays the best interest". I'm very patient and .......
                <a href="#!" className="text-purple fw-600">
                  Show More
                </a>
              </p>
            </div>
            <div className="mb-4 d-sm-flex align-items-center">
              <ul className="d-flex mb-sm-0 mb-2 align-items-center">
                <li className="me-2">
                  <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.4701 1.125C17.1612 0.4375 16.5084 0 15.7915 0C15.0745 0 14.4276 0.4375 14.1128 1.125L10.365 9.39375L1.99495 10.7188C1.2955 10.8313 0.712632 11.3563 0.496971 12.075C0.281309 12.7938 0.456169 13.5875 0.957438 14.1188L7.03094 20.5625L5.59708 29.6688C5.48051 30.4188 5.77194 31.1813 6.34898 31.625C6.92602 32.0688 7.68958 32.125 8.31908 31.7688L15.7973 27.4875L23.2755 31.7688C23.905 32.125 24.6686 32.075 25.2456 31.625C25.8227 31.175 26.1141 30.4188 25.9975 29.6688L24.5578 20.5625L30.6313 14.1188C31.1326 13.5875 31.3133 12.7938 31.0918 12.075C30.8703 11.3563 30.2933 10.8313 29.5938 10.7188L21.218 9.39375L17.4701 1.125Z"
                      fill="#FDC840"
                    />
                  </svg>
                </li>
              </ul>
              <span className="fs-5 fw-600 ms-lg-3">4.87 Reviews</span>
              <span className="fw-bold text-gray d-sm-inline-block d-flex fs-5 ms-sm-2">(8426 Total Reviews)</span>
            </div>
            <div className="review_dack">
              <div className="card review_box mb-4">
                <div className="review_top">
                  <div className="d-lg-flex justify-content-between mb-3 align-items-satrt">
                    <div className="review_user d-flex align-items-center">
                      <img src="./assets/images/user/user1.png" alt="user1" />
                      <div className="ms-3">
                        <span className="d-block fs-5">Celina Marlk</span>
                        <ul className="d-flex d-lg-none align-items-center">
                          <li className="me-2">
                            <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.8942 25.5637C20.5767 25.5637 20.2608 25.4662 19.9888 25.2721L13.5953 20.687L7.20182 25.2721C6.93692 25.4627 6.61859 25.5647 6.29227 25.5636C5.96595 25.5624 5.64832 25.4582 5.38476 25.2658C5.12094 25.0745 4.92417 24.8049 4.82243 24.4953C4.7207 24.1858 4.71918 23.852 4.8181 23.5415L7.2026 15.8457L0.866469 11.3809C0.603876 11.1871 0.40886 10.9156 0.309004 10.6049C0.209148 10.2942 0.209508 9.95997 0.310033 9.64947C0.411351 9.33973 0.607448 9.06967 0.87061 8.87744C1.13377 8.68522 1.45069 8.58056 1.77657 8.57826L9.62404 8.56647L12.1225 1.06166C12.2256 0.752496 12.4234 0.483606 12.6878 0.293068C12.9522 0.102529 13.2698 0 13.5957 0C13.9216 0 14.2393 0.102529 14.5037 0.293068C14.7681 0.483606 14.9658 0.752496 15.0689 1.06166L17.525 8.56647L25.4125 8.57826C25.7388 8.5801 26.0562 8.6847 26.3196 8.87722C26.583 9.06973 26.7791 9.34035 26.8799 9.65065C26.9808 9.96095 26.9813 10.2951 26.8814 10.6057C26.7815 10.9163 26.5862 11.1876 26.3234 11.3809L19.9873 15.8457L22.3718 23.5415C22.4709 23.8519 22.4696 24.1857 22.368 24.4953C22.2663 24.8049 22.0697 25.0745 21.8059 25.2658C21.5415 25.4597 21.2221 25.5641 20.8942 25.5637Z"
                                fill="#CEC9C1"
                              />
                            </svg>
                          </li>
                          <li className="me-2">
                            <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z"
                                fill="#FFAC33"
                              />
                            </svg>
                          </li>
                          <li className="me-2">
                            <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z"
                                fill="#FFAC33"
                              />
                            </svg>
                          </li>
                          <li className="me-2">
                            <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z"
                                fill="#FFAC33"
                              />
                            </svg>
                          </li>
                          <li>
                            <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z"
                                fill="#FFAC33"
                              />
                            </svg>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <ul className="d-lg-flex d-none mb-lg-0 mb-2 align-items-center">
                      <li className="me-2">
                        <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M20.8942 25.5637C20.5767 25.5637 20.2608 25.4662 19.9888 25.2721L13.5953 20.687L7.20182 25.2721C6.93692 25.4627 6.61859 25.5647 6.29227 25.5636C5.96595 25.5624 5.64832 25.4582 5.38476 25.2658C5.12094 25.0745 4.92417 24.8049 4.82243 24.4953C4.7207 24.1858 4.71918 23.852 4.8181 23.5415L7.2026 15.8457L0.866469 11.3809C0.603876 11.1871 0.40886 10.9156 0.309004 10.6049C0.209148 10.2942 0.209508 9.95997 0.310033 9.64947C0.411351 9.33973 0.607448 9.06967 0.87061 8.87744C1.13377 8.68522 1.45069 8.58056 1.77657 8.57826L9.62404 8.56647L12.1225 1.06166C12.2256 0.752496 12.4234 0.483606 12.6878 0.293068C12.9522 0.102529 13.2698 0 13.5957 0C13.9216 0 14.2393 0.102529 14.5037 0.293068C14.7681 0.483606 14.9658 0.752496 15.0689 1.06166L17.525 8.56647L25.4125 8.57826C25.7388 8.5801 26.0562 8.6847 26.3196 8.87722C26.583 9.06973 26.7791 9.34035 26.8799 9.65065C26.9808 9.96095 26.9813 10.2951 26.8814 10.6057C26.7815 10.9163 26.5862 11.1876 26.3234 11.3809L19.9873 15.8457L22.3718 23.5415C22.4709 23.8519 22.4696 24.1857 22.368 24.4953C22.2663 24.8049 22.0697 25.0745 21.8059 25.2658C21.5415 25.4597 21.2221 25.5641 20.8942 25.5637Z"
                            fill="#CEC9C1"
                          />
                        </svg>
                      </li>
                      <li className="me-2">
                        <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z"
                            fill="#FFAC33"
                          />
                        </svg>
                      </li>
                      <li className="me-2">
                        <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z"
                            fill="#FFAC33"
                          />
                        </svg>
                      </li>
                      <li className="me-2">
                        <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z"
                            fill="#FFAC33"
                          />
                        </svg>
                      </li>
                      <li>
                        <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z"
                            fill="#FFAC33"
                          />
                        </svg>
                      </li>
                    </ul>
                  </div>
                  <div className="review_text row mb-4">
                    <div className="col-lg-6">
                      <p className="fw-500">My son says this is one of his absolute favourite classes on Outschool (and he does a lot of classes).</p>
                    </div>
                  </div>
                  <span className="text-gray fs-6">March 16,2020</span>
                </div>
              </div>
              <div className="card review_box mb-4">
                <div className="review_top">
                  <div className="d-lg-flex justify-content-between mb-3 align-items-satrt">
                    <div className="review_user d-flex align-items-center">
                      <img src="./assets/images/user/user1.png" alt="user1" />
                      <div className="ms-3">
                        <span className="d-block fs-5">Celina Marlk</span>
                        <ul className="d-flex d-lg-none align-items-center">
                          <li className="me-2">
                            <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.8942 25.5637C20.5767 25.5637 20.2608 25.4662 19.9888 25.2721L13.5953 20.687L7.20182 25.2721C6.93692 25.4627 6.61859 25.5647 6.29227 25.5636C5.96595 25.5624 5.64832 25.4582 5.38476 25.2658C5.12094 25.0745 4.92417 24.8049 4.82243 24.4953C4.7207 24.1858 4.71918 23.852 4.8181 23.5415L7.2026 15.8457L0.866469 11.3809C0.603876 11.1871 0.40886 10.9156 0.309004 10.6049C0.209148 10.2942 0.209508 9.95997 0.310033 9.64947C0.411351 9.33973 0.607448 9.06967 0.87061 8.87744C1.13377 8.68522 1.45069 8.58056 1.77657 8.57826L9.62404 8.56647L12.1225 1.06166C12.2256 0.752496 12.4234 0.483606 12.6878 0.293068C12.9522 0.102529 13.2698 0 13.5957 0C13.9216 0 14.2393 0.102529 14.5037 0.293068C14.7681 0.483606 14.9658 0.752496 15.0689 1.06166L17.525 8.56647L25.4125 8.57826C25.7388 8.5801 26.0562 8.6847 26.3196 8.87722C26.583 9.06973 26.7791 9.34035 26.8799 9.65065C26.9808 9.96095 26.9813 10.2951 26.8814 10.6057C26.7815 10.9163 26.5862 11.1876 26.3234 11.3809L19.9873 15.8457L22.3718 23.5415C22.4709 23.8519 22.4696 24.1857 22.368 24.4953C22.2663 24.8049 22.0697 25.0745 21.8059 25.2658C21.5415 25.4597 21.2221 25.5641 20.8942 25.5637Z"
                                fill="#CEC9C1"
                              />
                            </svg>
                          </li>
                          <li className="me-2">
                            <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z"
                                fill="#FFAC33"
                              />
                            </svg>
                          </li>
                          <li className="me-2">
                            <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z"
                                fill="#FFAC33"
                              />
                            </svg>
                          </li>
                          <li className="me-2">
                            <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z"
                                fill="#FFAC33"
                              />
                            </svg>
                          </li>
                          <li>
                            <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z"
                                fill="#FFAC33"
                              />
                            </svg>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <ul className="d-lg-flex d-none mb-lg-0 mb-2 align-items-center">
                      <li className="me-2">
                        <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M20.8942 25.5637C20.5767 25.5637 20.2608 25.4662 19.9888 25.2721L13.5953 20.687L7.20182 25.2721C6.93692 25.4627 6.61859 25.5647 6.29227 25.5636C5.96595 25.5624 5.64832 25.4582 5.38476 25.2658C5.12094 25.0745 4.92417 24.8049 4.82243 24.4953C4.7207 24.1858 4.71918 23.852 4.8181 23.5415L7.2026 15.8457L0.866469 11.3809C0.603876 11.1871 0.40886 10.9156 0.309004 10.6049C0.209148 10.2942 0.209508 9.95997 0.310033 9.64947C0.411351 9.33973 0.607448 9.06967 0.87061 8.87744C1.13377 8.68522 1.45069 8.58056 1.77657 8.57826L9.62404 8.56647L12.1225 1.06166C12.2256 0.752496 12.4234 0.483606 12.6878 0.293068C12.9522 0.102529 13.2698 0 13.5957 0C13.9216 0 14.2393 0.102529 14.5037 0.293068C14.7681 0.483606 14.9658 0.752496 15.0689 1.06166L17.525 8.56647L25.4125 8.57826C25.7388 8.5801 26.0562 8.6847 26.3196 8.87722C26.583 9.06973 26.7791 9.34035 26.8799 9.65065C26.9808 9.96095 26.9813 10.2951 26.8814 10.6057C26.7815 10.9163 26.5862 11.1876 26.3234 11.3809L19.9873 15.8457L22.3718 23.5415C22.4709 23.8519 22.4696 24.1857 22.368 24.4953C22.2663 24.8049 22.0697 25.0745 21.8059 25.2658C21.5415 25.4597 21.2221 25.5641 20.8942 25.5637Z"
                            fill="#CEC9C1"
                          />
                        </svg>
                      </li>
                      <li className="me-2">
                        <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z"
                            fill="#FFAC33"
                          />
                        </svg>
                      </li>
                      <li className="me-2">
                        <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z"
                            fill="#FFAC33"
                          />
                        </svg>
                      </li>
                      <li className="me-2">
                        <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z"
                            fill="#FFAC33"
                          />
                        </svg>
                      </li>
                      <li>
                        <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z"
                            fill="#FFAC33"
                          />
                        </svg>
                      </li>
                    </ul>
                  </div>
                  <div className="review_text row mb-4">
                    <div className="col-lg-6">
                      <p className="fw-500">My son says this is one of his absolute favourite classes on Outschool (and he does a lot of classes).</p>
                    </div>
                  </div>
                  <span className="text-gray fs-6">March 16,2020</span>
                </div>
              </div>
              <div className="button mt-5 text-center">
                <a href="#" className="btn-theme d-inline-block text-center fw-600 text-dif-black" tabIndex={0}>
                  See More Reviews
                </a>
              </div>
            </div>
          </section>
          {/*start-slider*/}
          <section className="explore_new_class slick_silder_button slick_slider_white py-5">
            <div>
              <div className="title mb-4">
                <h2>Other classes by Celina Mark</h2>
              </div>
              <div className=" class_slider">
                <Slider {...class_slider}>
                  <div>
                    <div className="single-box">
                      <div className="class_image_box">
                        <div className="class_img position-relative">
                          <div className="video-container">
                            {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                            <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                              <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                            </video>
                          </div>
                          <div className="toggle like">
                            <input type="checkbox" tabIndex={0} />
                            <label />
                          </div>
                          <a href="#" className="play_btn">
                            <img src="./assets/images/play.png" alt="play" />
                          </a>
                        </div>
                      </div>
                      <div className="d-flex bottom-info align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/user1.png" alt="user1" />
                          <span className="tech_nm ms-2">Celina Mark</span>
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>
                          <span className="fw-600">
                            4.87<span className="text-gray fw-500">(8426)</span>
                          </span>
                        </div>
                      </div>
                      <div className="class_room_info">
                        <div className="tag mb-3">
                          <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                            Music
                          </a>
                          <a href="#" className="px-3 ms-2 bg-purple text-white border-purple d-inline-block" tabIndex={0}>
                            Follow Me
                          </a>
                        </div>
                        <div className="class_title mb-4">
                          <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class</span>
                            <span className="fw-600">08:00am - 10:00am</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                            <span className="fw-600">5-7 Age</span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                            <span className="fw-600">Monday To Friday</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                            <span className="fw-600">150$</span>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="fw-500 btn-theme d-block text-center">
                            Subscribe
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-box">
                      <div className="class_image_box">
                        <div className="class_img position-relative">
                          <div className="video-container">
                            {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                            <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                              <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                            </video>
                          </div>
                          <div className="toggle like">
                            <input type="checkbox" tabIndex={0} />
                            <label />
                          </div>
                          <a href="#" className="play_btn">
                            <img src="./assets/images/play.png" alt="play" />
                          </a>
                        </div>
                      </div>
                      <div className="d-flex bottom-info align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/user1.png" alt="user1" />
                          <span className="tech_nm ms-2">Celina Mark</span>
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>
                          <span className="fw-600">
                            4.87<span className="text-gray fw-500">(8426)</span>
                          </span>
                        </div>
                      </div>
                      <div className="class_room_info">
                        <div className="tag mb-3">
                          <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                            Music
                          </a>
                          <a href="#" className="px-3 ms-2 bg-purple text-white border-purple d-inline-block" tabIndex={0}>
                            Follow Me
                          </a>
                        </div>
                        <div className="class_title mb-4">
                          <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class</span>
                            <span className="fw-600">08:00am - 10:00am</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                            <span className="fw-600">5-7 Age</span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                            <span className="fw-600">Monday To Friday</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                            <span className="fw-600">150$</span>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="fw-500 btn-theme d-block text-center">
                            Subscribe
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-box">
                      <div className="class_image_box">
                        <div className="class_img position-relative">
                          <div className="video-container">
                            {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                            <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                              <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                            </video>
                          </div>
                          <div className="toggle like">
                            <input type="checkbox" tabIndex={0} />
                            <label />
                          </div>
                          <a href="#" className="play_btn">
                            <img src="./assets/images/play.png" alt="play" />
                          </a>
                        </div>
                      </div>
                      <div className="d-flex bottom-info align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/user1.png" alt="user1" />
                          <span className="tech_nm ms-2">Celina Mark</span>
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>
                          <span className="fw-600">
                            4.87<span className="text-gray fw-500">(8426)</span>
                          </span>
                        </div>
                      </div>
                      <div className="class_room_info">
                        <div className="tag mb-3">
                          <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                            Music
                          </a>
                          <a href="#" className="px-3 ms-2 bg-purple text-white border-purple d-inline-block" tabIndex={0}>
                            Follow Me
                          </a>
                        </div>
                        <div className="class_title mb-4">
                          <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class</span>
                            <span className="fw-600">08:00am - 10:00am</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                            <span className="fw-600">5-7 Age</span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                            <span className="fw-600">Monday To Friday</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                            <span className="fw-600">150$</span>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="fw-500 btn-theme d-block text-center">
                            Subscribe
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-box">
                      <div className="class_image_box">
                        <div className="class_img position-relative">
                          <div className="video-container">
                            {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                            <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                              <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                            </video>
                          </div>
                          <div className="toggle like">
                            <input type="checkbox" tabIndex={0} />
                            <label />
                          </div>
                          <a href="#" className="play_btn">
                            <img src="./assets/images/play.png" alt="play" />
                          </a>
                        </div>
                      </div>
                      <div className="d-flex bottom-info align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/user1.png" alt="user1" />
                          <span className="tech_nm ms-2">Celina Mark</span>
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>
                          <span className="fw-600">
                            4.87<span className="text-gray fw-500">(8426)</span>
                          </span>
                        </div>
                      </div>
                      <div className="class_room_info">
                        <div className="tag mb-3">
                          <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                            Music
                          </a>
                          <a href="#" className="px-3 ms-2 bg-purple text-white border-purple d-inline-block" tabIndex={0}>
                            Follow Me
                          </a>
                        </div>
                        <div className="class_title mb-4">
                          <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class</span>
                            <span className="fw-600">08:00am - 10:00am</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                            <span className="fw-600">5-7 Age</span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                            <span className="fw-600">Monday To Friday</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                            <span className="fw-600">150$</span>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="fw-500 btn-theme d-block text-center">
                            Subscribe
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-box">
                      <div className="class_image_box">
                        <div className="class_img position-relative">
                          <div className="video-container">
                            {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                            <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                              <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                            </video>
                          </div>
                          <div className="toggle like">
                            <input type="checkbox" tabIndex={0} />
                            <label />
                          </div>
                          <a href="#" className="play_btn">
                            <img src="./assets/images/play.png" alt="play" />
                          </a>
                        </div>
                      </div>
                      <div className="d-flex bottom-info align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/user1.png" alt="user1" />
                          <span className="tech_nm ms-2">Celina Mark</span>
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>
                          <span className="fw-600">
                            4.87<span className="text-gray fw-500">(8426)</span>
                          </span>
                        </div>
                      </div>
                      <div className="class_room_info">
                        <div className="tag mb-3">
                          <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                            Music
                          </a>
                          <a href="#" className="px-3 ms-2 bg-purple text-white border-purple d-inline-block" tabIndex={0}>
                            Follow Me
                          </a>
                        </div>
                        <div className="class_title mb-4">
                          <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class</span>
                            <span className="fw-600">08:00am - 10:00am</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                            <span className="fw-600">5-7 Age</span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                            <span className="fw-600">Monday To Friday</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                            <span className="fw-600">150$</span>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="fw-500 btn-theme d-block text-center">
                            Subscribe
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </section>
          {/*end-slider*/}
          {/*start*/}
          <section className="popular_topic">
            <div className="title mb-md-5 mb-3 text-center">
              <h2>Popular Topics</h2>
            </div>
            <div className="popular_item">
              <ul>
                <li>
                  <a href="#" className="d-flex align-items-center">
                    <div className="topi_icon">
                      <img src="./assets/images/topic/topic1.png" alt="topic1" />
                    </div>
                    <span className="ms-4">Arts</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-flex align-items-center">
                    <div className="topi_icon">
                      <img src="./assets/images/topic/topic2.png" alt="topic2" />
                    </div>
                    <span className="ms-4">Health and Wellness</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-flex align-items-center">
                    <div className="topi_icon">
                      <img src="./assets/images/topic/topic3.png" alt="topic3" />
                    </div>
                    <span className="ms-4">Languages</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-flex align-items-center">
                    <div className="topi_icon">
                      <img src="./assets/images/topic/topic4.png" alt="topic4" />
                    </div>
                    <span className="ms-4">Life Skills</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-flex align-items-center">
                    <div className="topi_icon">
                      <img src="./assets/images/topic/topic5.png" alt="topic5" />
                    </div>
                    <span className="ms-4">Coding &amp; Tech</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-flex align-items-center">
                    <div className="topi_icon">
                      <img src="./assets/images/topic/topic6.png" alt="topic6" />
                    </div>
                    <span className="ms-4">Music</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-flex align-items-center">
                    <div className="topi_icon">
                      <img src="./assets/images/topic/topic7.png" alt="topic7" />
                    </div>
                    <span className="ms-4">Professional Training</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-flex align-items-center">
                    <div className="topi_icon">
                      <img src="./assets/images/topic/topic8.png" alt="topic8" />
                    </div>
                    <span className="ms-4">In-Person Classes</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-flex align-items-center">
                    <div className="topi_icon">
                      <img src="./assets/images/topic/topic9.png" alt="topic9" />
                    </div>
                    <span className="ms-4">Academic</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-flex align-items-center">
                    <div className="topi_icon">
                      <img src="./assets/images/topic/topic10.png" alt="topic9" />
                    </div>
                    <span className="ms-4">Privateschool</span>
                  </a>
                </li>
              </ul>
            </div>
          </section>
          {/*end*/}
          {/*start-slider*/}
          <section className="explore_new_class slick_silder_button slick_slider_white py-5">
            <div>
              <div className="title mb-4">
                <h2>Similar Classes Like This</h2>
              </div>
              <div className=" class_slider">
                <Slider {...class_slider}>
                  <div>
                    <div className="single-box">
                      <div className="class_image_box">
                        <div className="class_img position-relative">
                          <div className="video-container">
                            {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                            <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                              <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                            </video>
                          </div>
                          <div className="toggle like">
                            <input type="checkbox" tabIndex={0} />
                            <label />
                          </div>
                          <a href="#" className="play_btn">
                            <img src="./assets/images/play.png" alt="play" />
                          </a>
                        </div>
                      </div>
                      <div className="d-flex bottom-info align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/user1.png" alt="user1" />
                          <span className="tech_nm ms-2">Celina Mark</span>
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>
                          <span className="fw-600">
                            4.87<span className="text-gray fw-500">(8426)</span>
                          </span>
                        </div>
                      </div>
                      <div className="class_room_info">
                        <div className="tag mb-3">
                          <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                            Music
                          </a>
                          <a href="#" className="px-3 ms-2 bg-purple text-white border-purple d-inline-block" tabIndex={0}>
                            Follow Me
                          </a>
                        </div>
                        <div className="class_title mb-4">
                          <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class</span>
                            <span className="fw-600">08:00am - 10:00am</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                            <span className="fw-600">5-7 Age</span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                            <span className="fw-600">Monday To Friday</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                            <span className="fw-600">150$</span>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="fw-500 btn-theme d-block text-center">
                            Subscribe
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-box">
                      <div className="class_image_box">
                        <div className="class_img position-relative">
                          <div className="video-container">
                            {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                            <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                              <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                            </video>
                          </div>
                          <div className="toggle like">
                            <input type="checkbox" tabIndex={0} />
                            <label />
                          </div>
                          <a href="#" className="play_btn">
                            <img src="./assets/images/play.png" alt="play" />
                          </a>
                        </div>
                      </div>
                      <div className="d-flex bottom-info align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/user1.png" alt="user1" />
                          <span className="tech_nm ms-2">Celina Mark</span>
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>
                          <span className="fw-600">
                            4.87<span className="text-gray fw-500">(8426)</span>
                          </span>
                        </div>
                      </div>
                      <div className="class_room_info">
                        <div className="tag mb-3">
                          <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                            Music
                          </a>
                          <a href="#" className="px-3 ms-2 bg-purple text-white border-purple d-inline-block" tabIndex={0}>
                            Follow Me
                          </a>
                        </div>
                        <div className="class_title mb-4">
                          <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class</span>
                            <span className="fw-600">08:00am - 10:00am</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                            <span className="fw-600">5-7 Age</span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                            <span className="fw-600">Monday To Friday</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                            <span className="fw-600">150$</span>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="fw-500 btn-theme d-block text-center">
                            Subscribe
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-box">
                      <div className="class_image_box">
                        <div className="class_img position-relative">
                          <div className="video-container">
                            {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                            <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                              <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                            </video>
                          </div>
                          <div className="toggle like">
                            <input type="checkbox" tabIndex={0} />
                            <label />
                          </div>
                          <a href="#" className="play_btn">
                            <img src="./assets/images/play.png" alt="play" />
                          </a>
                        </div>
                      </div>
                      <div className="d-flex bottom-info align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/user1.png" alt="user1" />
                          <span className="tech_nm ms-2">Celina Mark</span>
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>
                          <span className="fw-600">
                            4.87<span className="text-gray fw-500">(8426)</span>
                          </span>
                        </div>
                      </div>
                      <div className="class_room_info">
                        <div className="tag mb-3">
                          <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                            Music
                          </a>
                          <a href="#" className="px-3 ms-2 bg-purple text-white border-purple d-inline-block" tabIndex={0}>
                            Follow Me
                          </a>
                        </div>
                        <div className="class_title mb-4">
                          <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class</span>
                            <span className="fw-600">08:00am - 10:00am</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                            <span className="fw-600">5-7 Age</span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                            <span className="fw-600">Monday To Friday</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                            <span className="fw-600">150$</span>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="fw-500 btn-theme d-block text-center">
                            Subscribe
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-box">
                      <div className="class_image_box">
                        <div className="class_img position-relative">
                          <div className="video-container">
                            {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                            <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                              <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                            </video>
                          </div>
                          <div className="toggle like">
                            <input type="checkbox" tabIndex={0} />
                            <label />
                          </div>
                          <a href="#" className="play_btn">
                            <img src="./assets/images/play.png" alt="play" />
                          </a>
                        </div>
                      </div>
                      <div className="d-flex bottom-info align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/user1.png" alt="user1" />
                          <span className="tech_nm ms-2">Celina Mark</span>
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>
                          <span className="fw-600">
                            4.87<span className="text-gray fw-500">(8426)</span>
                          </span>
                        </div>
                      </div>
                      <div className="class_room_info">
                        <div className="tag mb-3">
                          <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                            Music
                          </a>
                          <a href="#" className="px-3 ms-2 bg-purple text-white border-purple d-inline-block" tabIndex={0}>
                            Follow Me
                          </a>
                        </div>
                        <div className="class_title mb-4">
                          <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class</span>
                            <span className="fw-600">08:00am - 10:00am</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                            <span className="fw-600">5-7 Age</span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                            <span className="fw-600">Monday To Friday</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                            <span className="fw-600">150$</span>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="fw-500 btn-theme d-block text-center">
                            Subscribe
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-box">
                      <div className="class_image_box">
                        <div className="class_img position-relative">
                          <div className="video-container">
                            {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                            <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                              <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                            </video>
                          </div>
                          <div className="toggle like">
                            <input type="checkbox" tabIndex={0} />
                            <label />
                          </div>
                          <a href="#" className="play_btn">
                            <img src="./assets/images/play.png" alt="play" />
                          </a>
                        </div>
                      </div>
                      <div className="d-flex bottom-info align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/user1.png" alt="user1" />
                          <span className="tech_nm ms-2">Celina Mark</span>
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>
                          <span className="fw-600">
                            4.87<span className="text-gray fw-500">(8426)</span>
                          </span>
                        </div>
                      </div>
                      <div className="class_room_info">
                        <div className="tag mb-3">
                          <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                            Music
                          </a>
                          <a href="#" className="px-3 ms-2 bg-purple text-white border-purple d-inline-block" tabIndex={0}>
                            Follow Me
                          </a>
                        </div>
                        <div className="class_title mb-4">
                          <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class</span>
                            <span className="fw-600">08:00am - 10:00am</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                            <span className="fw-600">5-7 Age</span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                            <span className="fw-600">Monday To Friday</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                            <span className="fw-600">150$</span>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="fw-500 btn-theme d-block text-center">
                            Subscribe
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </section>
          {/*end-slider*/}
          {/*start-slider*/}
          <section className="explore_new_class slick_silder_button slick_slider_white py-5">
            <div>
              <div className="title mb-4">
                <h2>Recently Viewed Classes by You</h2>
              </div>
              <div className=" class_slider">
                <Slider {...class_slider}>
                  <div>
                    <div className="single-box">
                      <div className="class_image_box">
                        <div className="class_img position-relative">
                          <div className="video-container">
                            {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                            <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                              <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                            </video>
                          </div>
                          <div className="toggle like">
                            <input type="checkbox" tabIndex={0} />
                            <label />
                          </div>
                          <a href="#" className="play_btn">
                            <img src="./assets/images/play.png" alt="play" />
                          </a>
                        </div>
                      </div>
                      <div className="d-flex bottom-info align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/user1.png" alt="user1" />
                          <span className="tech_nm ms-2">Celina Mark</span>
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>
                          <span className="fw-600">
                            4.87<span className="text-gray fw-500">(8426)</span>
                          </span>
                        </div>
                      </div>
                      <div className="class_room_info">
                        <div className="tag mb-3">
                          <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                            Music
                          </a>
                          <a href="#" className="px-3 ms-2 bg-purple text-white border-purple d-inline-block" tabIndex={0}>
                            Follow Me
                          </a>
                        </div>
                        <div className="class_title mb-4">
                          <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class</span>
                            <span className="fw-600">08:00am - 10:00am</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                            <span className="fw-600">5-7 Age</span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                            <span className="fw-600">Monday To Friday</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                            <span className="fw-600">150$</span>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="fw-500 btn-theme d-block text-center">
                            Subscribe
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-box">
                      <div className="class_image_box">
                        <div className="class_img position-relative">
                          <div className="video-container">
                            {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                            <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                              <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                            </video>
                          </div>
                          <div className="toggle like">
                            <input type="checkbox" tabIndex={0} />
                            <label />
                          </div>
                          <a href="#" className="play_btn">
                            <img src="./assets/images/play.png" alt="play" />
                          </a>
                        </div>
                      </div>
                      <div className="d-flex bottom-info align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/user1.png" alt="user1" />
                          <span className="tech_nm ms-2">Celina Mark</span>
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>
                          <span className="fw-600">
                            4.87<span className="text-gray fw-500">(8426)</span>
                          </span>
                        </div>
                      </div>
                      <div className="class_room_info">
                        <div className="tag mb-3">
                          <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                            Music
                          </a>
                          <a href="#" className="px-3 ms-2 bg-purple text-white border-purple d-inline-block" tabIndex={0}>
                            Follow Me
                          </a>
                        </div>
                        <div className="class_title mb-4">
                          <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class</span>
                            <span className="fw-600">08:00am - 10:00am</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                            <span className="fw-600">5-7 Age</span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                            <span className="fw-600">Monday To Friday</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                            <span className="fw-600">150$</span>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="fw-500 btn-theme d-block text-center">
                            Subscribe
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-box">
                      <div className="class_image_box">
                        <div className="class_img position-relative">
                          <div className="video-container">
                            {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                            <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                              <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                            </video>
                          </div>
                          <div className="toggle like">
                            <input type="checkbox" tabIndex={0} />
                            <label />
                          </div>
                          <a href="#" className="play_btn">
                            <img src="./assets/images/play.png" alt="play" />
                          </a>
                        </div>
                      </div>
                      <div className="d-flex bottom-info align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/user1.png" alt="user1" />
                          <span className="tech_nm ms-2">Celina Mark</span>
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>
                          <span className="fw-600">
                            4.87<span className="text-gray fw-500">(8426)</span>
                          </span>
                        </div>
                      </div>
                      <div className="class_room_info">
                        <div className="tag mb-3">
                          <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                            Music
                          </a>
                          <a href="#" className="px-3 ms-2 bg-purple text-white border-purple d-inline-block" tabIndex={0}>
                            Follow Me
                          </a>
                        </div>
                        <div className="class_title mb-4">
                          <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class</span>
                            <span className="fw-600">08:00am - 10:00am</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                            <span className="fw-600">5-7 Age</span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                            <span className="fw-600">Monday To Friday</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                            <span className="fw-600">150$</span>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="fw-500 btn-theme d-block text-center">
                            Subscribe
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-box">
                      <div className="class_image_box">
                        <div className="class_img position-relative">
                          <div className="video-container">
                            {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                            <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                              <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                            </video>
                          </div>
                          <div className="toggle like">
                            <input type="checkbox" tabIndex={0} />
                            <label />
                          </div>
                          <a href="#" className="play_btn">
                            <img src="./assets/images/play.png" alt="play" />
                          </a>
                        </div>
                      </div>
                      <div className="d-flex bottom-info align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/user1.png" alt="user1" />
                          <span className="tech_nm ms-2">Celina Mark</span>
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>
                          <span className="fw-600">
                            4.87<span className="text-gray fw-500">(8426)</span>
                          </span>
                        </div>
                      </div>
                      <div className="class_room_info">
                        <div className="tag mb-3">
                          <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                            Music
                          </a>
                          <a href="#" className="px-3 ms-2 bg-purple text-white border-purple d-inline-block" tabIndex={0}>
                            Follow Me
                          </a>
                        </div>
                        <div className="class_title mb-4">
                          <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class</span>
                            <span className="fw-600">08:00am - 10:00am</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                            <span className="fw-600">5-7 Age</span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                            <span className="fw-600">Monday To Friday</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                            <span className="fw-600">150$</span>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="fw-500 btn-theme d-block text-center">
                            Subscribe
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-box">
                      <div className="class_image_box">
                        <div className="class_img position-relative">
                          <div className="video-container">
                            {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                            <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                              <source src="./assets/video/Classroom -parents.mp4" type="video/mp4" />
                            </video>
                          </div>
                          <div className="toggle like">
                            <input type="checkbox" tabIndex={0} />
                            <label />
                          </div>
                          <a href="#" className="play_btn">
                            <img src="./assets/images/play.png" alt="play" />
                          </a>
                        </div>
                      </div>
                      <div className="d-flex bottom-info align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/user1.png" alt="user1" />
                          <span className="tech_nm ms-2">Celina Mark</span>
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>
                          <span className="fw-600">
                            4.87<span className="text-gray fw-500">(8426)</span>
                          </span>
                        </div>
                      </div>
                      <div className="class_room_info">
                        <div className="tag mb-3">
                          <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                            Music
                          </a>
                          <a href="#" className="px-3 ms-2 bg-purple text-white border-purple d-inline-block" tabIndex={0}>
                            Follow Me
                          </a>
                        </div>
                        <div className="class_title mb-4">
                          <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class</span>
                            <span className="fw-600">08:00am - 10:00am</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                            <span className="fw-600">5-7 Age</span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                            <span className="fw-600">Monday To Friday</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                            <span className="fw-600">150$</span>
                          </div>
                        </div>
                        <div className="button">
                          <a href="#" className="fw-500 btn-theme d-block text-center">
                            Subscribe
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </section>
          {/*end-slider*/}
        </div>
      </main>
      <PFooter_Dashboard />
    </>
  );
}
