import React, { useEffect, useState } from 'react'
import { main_category_list, subject_category_list } from '../../api/apiHandler';
import { useLocation, useNavigate } from 'react-router-dom';
import { encryptURL } from '../../api/apiClient';


export default function CommonClassSubject() {
    const navigate = useNavigate();
    const path = useLocation();
    const [data, setData] = useState([]);


    const OpenCategoryModel = async () => {
        try {
            const response = await main_category_list();

            if (response?.code === '1') {

                const mainCategoryData = response?.data;

                const updatedMainCategoryData = await Promise.all(mainCategoryData.map(async (mainCategory) => {
                    try {
                        const subCategoriesResponse = await SubCategoryList(mainCategory.category_id);
                        if (subCategoriesResponse?.code === '1') {
                            return {
                                ...mainCategory,
                                subcategories: subCategoriesResponse.data
                            };
                        }
                    } catch (subCategoryError) {
                        console.error("Error fetching subcategories:", subCategoryError);
                    }
                    return mainCategory;
                }));

                setData(updatedMainCategoryData);
                console.log(data, 'dataaaaaaaaaaaaa');
            } else {
                console.log("Data Not Found");
                setData([]);
            }
        } catch (error) {
            console.error("Error in OpenCategoryModel:", error);
        }
    };

    useEffect(() => {
        OpenCategoryModel();
        // console.log(data,'dataaaaaaaaaaaaaaa');
    }, []);

    const SubCategoryList = async (categoryId) => {
        try {
            const response = await subject_category_list({ category_id: categoryId });
            console.log(response?.data, 'dataaaaaaaaaaaaaaaa');
            return response;
        } catch (error) {
            console.error("Error in SubCategoryList:", error);
            return { code: 0, data: [] };
        }
    };


    const [selectedMainCategory, setSelectedMainCategory] = useState(null);

    const handleCategoryClick = (mainCategory) => {
        setSelectedMainCategory(mainCategory);

    };

    const handleSubject = (mainCategory, sub_category) => {
        const modal = document.getElementById('select_subcategories');
        modal.classList.remove('show');
        modal.setAttribute('aria-hidden', 'true');
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.querySelector('.modal-backdrop');
        if (modalBackdrop) {
            modalBackdrop.parentNode.removeChild(modalBackdrop);
        }

        // Re-enable scroll on the body element
        document.body.style.overflow = 'auto';
        document.body.style.paddingRight = '0';

        // const encodedMainCategory = encodeURIComponent(mainCategory ? mainCategory.category_name : selectedMainCategory.category_name);
        // const encodedSubCategory = encodeURIComponent(sub_category);

        // console.log(encodedSubCategory, 'selectedsub_category');
        // console.log(encodedMainCategory, 'selectedMainCategory');
          
    const queryParams = new URLSearchParams({
        main_category: mainCategory ? mainCategory.category_name : selectedMainCategory.category_name,
        sub_category: sub_category
      }).toString();
    
      console.log('queryParams :', queryParams);
    
      const encryptedURL = encryptURL(`/search_class?${queryParams}`);
      // return
      navigate(encryptedURL);

        // navigate(`/search_class?main_category=${encodedMainCategory}&sub_category=${encodedSubCategory}`);
    };



    return (
        <>
            <section className={path.pathname == path.pathname.match("/dashboard") ? "class_by_subject pt-0 d-lg-block d-none" : "bg-light-gray class_by_subject pt-0 d-lg-block d-none"}>
                <div className="container-fluid px-lg-5">
                    <div className="title text-center mb-lg-5 mb-md-3 mb-3">
                        <h2 className="text-capitalize">Classes By Subject</h2>
                    </div>
                    <div className="row">
                        {data.map((mainCategory) => (
                            <div className="col-lg-6 height_rows" key={mainCategory.category_id}>
                                <ul className="sub_categroy">
                                    <li>
                                        <div className="indside_hover" >
                                            <div className="icon">
                                                <img src={mainCategory.main_category_image} alt={mainCategory.category_name} style={{ height: "55px", borderRadius: "50%" }} />
                                            </div>
                                            <div className="subject_ctn">
                                                <h3 className="mb-2 text-purple">{mainCategory.category_name}</h3>
                                                <ul className="d-flex flex-wrap">
                                                    {mainCategory.subcategories?.slice(0, 5).map((subcategory, index) => (
                                                        <React.Fragment key={subcategory.category_id}>
                                                            {subcategory.category ? (
                                                                (subcategory?.category || []).slice(0, 5).map((subject) => (
                                                                    <li key={subject?.subject_category_id} onClick={() => { handleSubject(mainCategory, subject?.subject_name) }}>{subject?.subject_name}</li>
                                                                ))
                                                            ) : (
                                                                <li key={subcategory?.subject_category_id} onClick={() => { handleSubject(mainCategory, subcategory?.subject_name) }}>{subcategory?.subject_name}</li>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </ul>

                                                <button className="fw-200 btn-theme d-block text-center mt-2" onClick={() => handleCategoryClick(mainCategory)} data-bs-toggle="modal" data-bs-target="#select_subcategories">View More</button>

                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        ))}

                    </div>
                </div>
            </section>
            <div className="modal fade for_cate_modal" id="select_subcategories" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel" style={{color : '#570861' }}
                            
                            >
                                List The Subcategories
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className="popular_item">
                                {selectedMainCategory && selectedMainCategory.subcategories?.map((subcategory, index) => {
                                    console.log(subcategory);
                                    return (
                                        <React.Fragment key={index}>
                                            {subcategory?.category ? (
                                                <>
                                                    <h4 className="fs-6 mb-4" style={{color : '#570861' }}>{subcategory?.category_name}</h4>
                                                    <ul className=''>
                                                        {subcategory?.category.map((category, index1) => (
                                                            <li key={index1} className=''>
                                                                <a
                                                                    className="d-flex align-items-center"
                                                                    onClick={(e) => {
                                                                        handleSubject(null, category?.subject_name)
                                                                    }}
                                                                >
                                                                    <div className="topi_icon">
                                                                        <img src={category?.subject_category_image} alt="maths" />
                                                                    </div>
                                                                    <span className="ms-3">{category?.subject_name}</span>
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </>
                                            ) : (
                                            
                                                <>  
                                                    <h4 className="fs-6 mb-4">{subcategory?.category_name}</h4>
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="d-flex align-items-center"
                                                                onClick={(e) => {
                                                                    handleSubject(null, subcategory?.category_name)
                                                                }}
                                                            >
                                                                <div className="topi_icon">
                                                                    <img src={subcategory?.subject_category_image} alt="maths" />
                                                                </div>
                                                                <span className="ms-3">{subcategory?.subject_name}</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </div>



                        </div>
                        <div className="modal-footer justify-content-center">
                            <div className="col-lg-4 col-12">
                                <button type="button" className="btn-theme w-100 bg-yellow fw-600" data-bs-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </>
    )
}