import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const CommonVideoPlayer = ({ 
  src,
  width = '100%',
  height = 'auto',
  autoPlay = false,
  loop = false,
  muted = false,
  controls = true,
  className = '',
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      // Handle looping if enabled
      if (loop) {
        video.addEventListener('ended', () => {
          video.currentTime = 0;
          video.play();
        });
      }

      // Add click event listener to play/pause the video
      const togglePlay = () => {
        if (video.paused) {
          video.play();
        } else {
          video.pause();
        }
      };
      
      video.addEventListener('click', togglePlay);

      // Cleanup function
      return () => {
        if (loop) {
          video.removeEventListener('ended', () => {});
        }
        video.removeEventListener('click', togglePlay);
      };
    }
  }, [loop]);

  return (
    <div className={`video-player ${className}`}>
      <video 
        ref={videoRef}
        src={src}
        width={width}
        height={height}
        autoPlay={autoPlay}
        muted={muted}
        controls={controls}
        style={{ maxWidth: '100%', cursor: 'pointer' }}
      >
        Your browser does not support the video tag or the video format.
      </video>
    </div>
  );
};

CommonVideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  controls: PropTypes.bool,
  className: PropTypes.string,
};

export default CommonVideoPlayer;