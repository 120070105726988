import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { verify_otp, resendOtpApi } from "../../api/apiHandler";
import { TOAST_ERROR, TOAST_INFO, TOAST_SUCCESS } from "../../utils/common.service";
export default function SendOtpModel({ message, type, setIsOpen, setIsOpenReset, isLoader, reset1, setValue1 , setPhone , setCountry }) {
  const navigate = useNavigate();
  const publicUrl = process.env.PUBLIC_URL;

  const handleResendOTP = () => {
    isLoader(true)
    let submitData = {
      user_id: localStorage.getItem("PAid") ? localStorage.getItem("PAid") : localStorage.getItem("Tid"),
      type: "resend"
    };

    // if (type === 'forgot') {
    //     submitData.otp_type = 'Forgot';
    // } else {
    //     submitData.otp_type = 'Signup';
    // }
    console.log("submitData :", submitData);

    resendOtpApi(submitData).then((res) => {
      console.log("res :", res);

      if (res.code == 1) {
        isLoader(false)
        TOAST_SUCCESS(res.message);
      } else {
        isLoader(false)
        TOAST_ERROR(res.message);
      }
    });
    reset();
  };

  const location = useLocation();

  console.log(location.pathname);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      digit1: '',
      digit2: '',
      digit3: '',
      digit4: ''
    }
  });

  const onSubmit = async (value) => {
    // localStorage.setItem('MAisLogin', true)
    isLoader(true)
    try {
      const enteredOtp = Object.values(value).join("");
      if (location.pathname === "/forgot-password") {
        var submitData = {
          // "otp_type": "forgot",
          userr_id: localStorage.getItem("PAid") ? localStorage.getItem("PAid") : localStorage.getItem("Tid"),
          otp: enteredOtp,
        };
      } else {
        var submitData = {
          userr_id: localStorage.getItem("PAid") ? localStorage.getItem("PAid") : localStorage.getItem("Tid"),

          otp: enteredOtp,
        };
      }
      console.log(submitData, "submitdata");
      // return
      verify_otp(submitData).then((res) => {
        console.log("otp_res :", res);
        // return
        if (res.code == 1) {

          if (location.pathname === "/forgot-password") {
            isLoader(false)
            navigate("/reset-password");
          } else {
            localStorage.removeItem("Otp");
            console.log(type);
            if (localStorage.getItem("userType") == "below_18") {
              // localStorage.setItem("PASignupStep", res.data.data?.signup_step);
              localStorage.setItem("PAtoken", res.data.token);
              TOAST_SUCCESS(res.message);
              navigate("/sign_in_step3");
              isLoader(false)
            } else if (localStorage.getItem("userType") == "above_18") {
              // alert('test')
              localStorage.setItem("PAtoken", res.data.token);
              TOAST_SUCCESS(res.message);
              navigate('/login')
              TOAST_INFO('You must verify your email first before you can login.')
              isLoader(false)
              localStorage.clear()
            } else if (localStorage.getItem("userType") == "after_school") {
              localStorage.setItem("PAtoken", res.data.token);
              TOAST_SUCCESS(res.message);
              navigate("/sign_in_after_school_step3");
              isLoader(false)
            } else if (localStorage.getItem("type") == "teacher") {
              localStorage.setItem("Ttoken", res.data.token);
              TOAST_SUCCESS(res.message);
              navigate("/signup-teacher");
              isLoader(false)
            } else if (localStorage.getItem("type") == "organization") {
              localStorage.setItem("Ttoken", res.data.token);
              TOAST_SUCCESS(res.message);
              navigate("/organization-step");
              isLoader(false)
            } else if (localStorage.getItem("type") == "org_teacher") {
              localStorage.setItem("Ttoken", res.data.token);
              TOAST_SUCCESS(res.message);
              navigate("/signup-teacher");
              isLoader(false)
            } else {
              isLoader(false)
              setIsOpenReset(true);
            }
            // TOAST_SUCCESS(res.message);
          }
        } else {
          isLoader(false)
          TOAST_ERROR(res.message);
        }
      });
      reset();
    } catch (err) {
      isLoader(false)
      TOAST_ERROR(err.message);
    }
  };

  const handleChange = (e, index) => {
    const { value } = e.target;

    // Only allow numbers
    if (value.match(/^[0-9]$/) || value === '') {
      setValue(`digit${index}`, value);
      trigger(`digit${index}`);

      // Move to next input if a number was entered
      if (value.match(/^[0-9]$/) && index < 4) {
        const nextInput = document.getElementById(`digit${index + 1}`);
        if (nextInput) nextInput.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !e.target.value) {
      if (index > 1) {
        const prevInput = document.getElementById(`digit${index - 1}`);
        if (prevInput) {
          prevInput.focus();
          setValue(`digit${index - 1}`, '');
          trigger(`digit${index - 1}`);
        }
      }
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    reset1();
    setValue1("phone", "");
    setPhone('+1');
    setCountry('ca');
  };

  return (
    <>

      <div className="modal-dialog otp-model">
        <div className="modal-content">
          <div className="modal-header pb-0 border-bottom-0 position-relative">
            <h5 className="modal-title" id="staticBackdropLabel">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={handleClose}
                aria-label="Close"
              ></button>
            </h5>
          </div>
          <div className="modal-body">
            <div className="text-center mb-4">
              <h4 className="modal-title" id="staticBackdropLabel">
                Verify OTP
              </h4>
              <p className="fs-6 text-gray mt-3 mb-2">
                We’ve sent a One Time Password (OTP) to the your number. Please enter it to complete verification {message}
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="custom_form verf_otp mt-4" action id>
              <div className="form-group d-flex justify-content-center mb-4">
                {/* <input className="otp form-control " type="text" oninput="digitValidate(this)" onkeyup="tabChange(1)" maxLength="{1}" />
                  <input className="otp form-control " type="text" oninput="digitValidate(this)" onkeyup="tabChange(2)" maxLength="{1}" />
                  <input className="otp form-control " type="text" oninput="digitValidate(this)" onkeyup="tabChange(3)" maxLength="{1}" />
                  <input className="otp form-control " type="text" oninput="digitValidate(this)" onkeyup="tabChange(4)" maxLength="{1}" /> */}


                {[1, 2, 3, 4].map((index) => (
                  <input
                    key={index}
                    {...register(`digit${index}`, { required: "Please enter OTP", pattern: { value: /^[0-9]$/, message: "Invalid OTP" } })}
                    aria-invalid={errors[`digit${index}`] ? "true" : "false"}
                    className="otp form-control"
                    type="text"
                    id={`digit${index}`}
                    maxLength="1"
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                ))}
              </div>
              {Object.keys(errors).length > 0 && (
                <label className="errorc pass form-text-fild mt-1" style={{ color: "red" }}>
                  {errors[Object.keys(errors)[0]]?.message}
                </label>
              )}

              <div className="modal-footer border-top-0 justify-content-center pt-0 ">

                <button type="submit" className="col-md-5 btn-theme bg-yellow fw-600 mt-3">
                  Verify
                </button>
              </div>
            </form>
          </div>

          <div className="modal-footer justify-content-center">
            <p className="fs-6">
              Didn’t receive?{" "}
              <Link onClick={handleResendOTP} className="text-purple text-uppercase">
                Resend
              </Link>
            </p>
          </div>
        </div>
      </div>

    </>
  );
}
