import { useEffect, useState } from "react";
import Slider from "react-slick";
import { TOAST_ERROR } from "../../../utils/common.service";
import { getSimilarClasses, getSimilarClubs, getSimilarCompetitions, getSimilarQuizes } from "../../../api/apiHandler";
import { BASE_NAME } from "../../../Config";
import ViewTeacherLink from "./viewTeacherLink";

const SimilarItemsLikeThis = ({ title, category_Details, handleFollowUnfollow, handleFavorite, handleSubscription1Modaldata, isLoader }) => {
  const [data, setData] = useState(null);
  console.log(data, 'similar');
  const userType = localStorage.getItem('userType')
  const handleFollow = (teacher_id) => {
    handleFollowUnfollow(teacher_id);
    handleGetList();
  };

  const handleFav = (id) => {
    handleFavorite(id);
    handleGetList();
  };

  const handleGetList = async () => {
    try {
      let tempData;
      switch (title) {
        case "Classes":
          tempData = await getSimilarClasses(category_Details);
          break;
        case "Clubs":
          tempData = await getSimilarClubs(category_Details);
          break;
        case "Competitions":
          tempData = await getSimilarCompetitions(category_Details);
          break;
        case "Quizes":
          tempData = await getSimilarQuizes(category_Details);
          break;
        default:
          break;
      }
      if (tempData?.code == 1) {
        setData(tempData.data);
      } else {

      }

    } catch (err) {
      TOAST_ERROR(err.message);
    }
  };

  useEffect(() => {
    handleGetList();
  }, [title, category_Details]);

  let class_slider = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="explore_new_class slick_silder_button slick_slider_white py-5">
      <div>
        <div className="title mb-4">
          <h2>Similar {title} Like This</h2>
        </div>
        <div className=" class_slider">
          {data && data.length > 0 ? (
            <Slider {...class_slider}>
              {data.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="single-box">
                      <div className="class_image_box">
                        <div className="class_img position-relative">
                          <div className="video-container">
                            {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                            <video id="video" loop width="100%" controls="hide" poster="/assets/images/video_image.png">
                              <source src={item.video_file} type="video/mp4" />
                            </video>
                          </div>
                          <div className="toggle like">
                            <input type="checkbox" defaultChecked={item.is_favourite} onChange={() => handleFav(item.id)} />
                            <label />
                          </div>
                          {/* <a href="#" className="play_btn">
                            <img src={`${BASE_NAME}/assets/images/play.png`} alt="play" />
                          </a> */}
                        </div>
                      </div>
                      <ViewTeacherLink data={item} />
                      <div className="class_room_info">
                        <div className="tag mb-3">
                          <a className="px-3 bg-yellow d-inline-block" tabIndex={0}>
                            {item.main_category}
                          </a>
                          <a
                            onClick={() => handleFollow(item.teacher_id)}
                            className="px-3 ms-2 bg-purple text-white border-purple d-inline-block"
                            tabIndex={0}
                          >
                            {item.is_follow === 1 ? "Following" : "Follow Me"}
                          </a>
                        </div>
                        <div className="class_title mb-4">
                          <p className="text-break custom-truncate">{item.title}</p>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Time</span>
                            <span className="fw-600">
                              {item.start_time}-{item.end_time}{" "}
                            </span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                            <span className="fw-600">
                              {item.min_age}-{item.max_age}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-8">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                            <span className="fw-600">Monday To Friday</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                            <span className="fw-600">{item?.currency_code} {item?.currency_symbol}{item?.price}</span>
                          </div>
                        </div>
                        {userType !== 'learner' &&
                          <div className="button">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#subscription_modal_1"
                              className="fw-500 btn-theme d-block text-center"
                              onClick={() => handleSubscription1Modaldata(item)}
                            >
                              Subscribe
                            </a>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          ) : (
            <p>Data Not Found.</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default SimilarItemsLikeThis;
