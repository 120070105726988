import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import TFooter_Dashboard from '../Include/TFooter_Dashboard'
import THeader_Dashboard from '../Include/THeader_Dashboard'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import { uploadImageOnAWS } from '../../aws/Service';
import { main_category_list, subject_category_list, teacher_add_club, teacher_select_class_list } from '../../../api/apiHandler';
import Modals from 'react-modal';

var COMMON_STYLE_FOR_POPUP = {
  content: {
    // backgroundColor: 'rgb(0 0 0 / 56%)',
    background: 'none',
    border: '0px',
  },
}

var model_css = {
  content: {
    display: 'flex',
    border: '1px solid rgb(224, 224, 224)',
    borderRadius: '4px',
    position: 'absolute',
    right: '5%',
    flexDirection: 'column',
    padding: '1em',
    width: '215px',
  }
};

export default function SetupClub({ isLoader }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();

  const schema = yup.object().shape({
    club_title: yup.string()
      .required("Please enter club title")
      .test('exclamation-mark', 'Only one exclamation mark is allowed', (value) => (value.match(/!/g) || []).length <= 1)
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        
      .matches(/^[a-zA-Z0-9\s!]+$/, "Special characters are not allowed")
      .test(
        'no-only-whitespace',
        'Club title cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      )
      .min(20, 'The club title must be at least 20 characters long'),

    club_summary: yup.string()
      .required("Please enter club summary")
      .test('exclamation-mark', 'Only one exclamation mark is allowed', (value) => (value.match(/!/g) || []).length <= 1)
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        
      .matches(/^[a-zA-Z0-9\s!]+$/, "Special characters are not allowed")
      .test(
        'no-only-whitespace',
        'Club summary cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      )
      .min(50, 'The club summary must be at least 50 characters long'),

    // learner_summary: yup.string().required("Learner summary is required"),
    // class_image: yup
    //   .mixed()
    //   .required("Class image is required")
    //   .test("fileSize", "File size is too large", (value) => {
    //     if (value) {
    //       return value && value[0] && value[0].size <= 1024000; // 1 MB
    //     }
    //     return true; // Handle cases where no file is uploaded
    //   })
    //   .test("fileType", "Invalid file type", (value) => {
    //     if (value) {
    //       return value && value[0] && ["image/jpeg", "image/png"].includes(value[0].type);
    //     }
    //     return true; // Handle cases where no file is uploaded
    //   }),
    profileVideo: yup
      .mixed()
      .required("Please upload club video"),
    // .test("fileSize", "Video file size is too large", (value) => {
    //   if (value) {
    //     return value && value[0] && value[0].size <= 52428800; // 50 MB
    //   }
    //   return true; // Handle cases where no file is uploaded
    // })
    // .test("fileType", "Invalid video file type", (value) => {
    //   if (value) {
    //     return value && value[0] && ["video/mp4", "video/mpeg", "video/quicktime"].includes(value[0].type);
    //   }
    //   return true; // Handle cases where no file is uploaded
    // }),
    // subject_category: yup.string().required("Subject category is required"),
    // subject_sub_category: yup.string().required("Subject Subcategory is required"),
    class_description: yup.string()
      .required("Please enter club description")
      .test(
        'no-only-whitespace',
        'Club description cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      )
      .min(200, 'Must be at least 200 characters')
      .max(500, 'Cannot exceed 500 characters'),
    // p_guide: yup.string().required("guide description is required"),
  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  console.log('errors :', errors);
  const [selectType, setSelectedType] = useState("Online");
  const [selectedRadio, setSelectedRadio] = useState("One-Time");
  const [startageRanges, setstartAgeRanges] = useState(3)
  var [ageRanges, setAgeRanges] = useState(6)
  const [startLearner, setStartLearner] = useState(1)
  const [endLearner, setEndLearner] = useState(6)
  const [price, setPrice] = useState('');
  const [Estimatedprice, setEstimatedprice] = useState(0);
  const [endEstimatedprice, setendEstimatedprice] = useState(0);
  const [pricingLevel, setPricingLevel] = useState('Very low');
  const [modalIsOpen, setIsOpen] = useState(false);

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.nextSibling.textContent)
  };

  // const handleRadioChange1 = (event) => {
  //   setSelectedType(event.target.nextSibling.textContent)
  // };
  const ageRange = (data) => {

    if (data < 16) {
      if (startageRanges >= 6) {
        setstartAgeRanges(3);
      }
      setEndLearner(6)
    } else {

    }
    if (data > 6 && data <= 12) {
      if (startageRanges >= 12 || startageRanges < 6) {
        setstartAgeRanges(6);
      }
      setEndLearner(9)
    }
    if (data > 12 && data <= 18) {
      if (startageRanges >= 18 || startageRanges < 12) {
        setstartAgeRanges(12);
      }
      setEndLearner(12)
    }
    if (data == "adults") {
      setstartAgeRanges(3);
      setEndLearner(20)
    }

    setAgeRanges(data);
  }

  const startageRange = (data) => {
    if (data < 16) {
      setAgeRanges(parseInt(data) + 3);
      setstartAgeRanges(data);
      ageRange(parseInt(data) + 3)

    } else if (data == 'adults') {
      setAgeRanges('adults');
      setstartAgeRanges(data);
      ageRange('adults')
    } else {

      setAgeRanges(18);
      setstartAgeRanges(data);
      ageRange(18)
    }
    setStartLearner(1)
    setstartAgeRanges(data);
  }

  const StartLearner = (data) => {
    setStartLearner(data);
  }

  const EndLearner = (data) => {
    setEndLearner(data);
  }

  const [classSize, setClassSize] = useState('group');
  const handleClassChange = (data) => {
    setClassSize(data)
  }

  const SetPriceData = () => {

    if (ageRanges <= 6) {
      // console.log('ageRanges :', ageRanges);
      if (classSize == 'private') {
        if (classHoursData >= 1) {
          setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $30 to $60')
        } else {
          if (classMinutesData >= 45) {
            setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $25 to $55')
          } else {
            if (classMinutesData >= 30) {
              setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $10 to $40')
            }
          }
        }
      } else {
        if (classHoursData >= 1) {
          setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $15 to $45')
        } else {
          if (classMinutesData >= 45) {
            setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $10 to $35')

          } else {
            if (classMinutesData >= 30) {
              setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $5 to $25')

            }
          }
        }
      }
    } else if (ageRanges <= 12) {
      // console.log('ageRanges :', ageRanges);

      if (classSize == 'private') {
        if (classHoursData >= 1) {
          setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $40 to $70')
        } else {
          if (classMinutesData >= 45) {
            setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $30 to $60')

          } else {
            if (classMinutesData >= 30) {
              setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $20 to $50')

            }
          }
        }
      } else {
        if (classHoursData >= 1) {
          setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $15 to $45')
        } else {
          if (classMinutesData >= 45) {
            setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $10 to $35')

          } else {
            if (classMinutesData >= 30) {
              setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $5 to $25')

            }
          }
        }
      }
    } else if (ageRanges <= 18) {
      // console.log('ageRanges :', ageRanges);

      if (classSize == 'private') {
        if (classHoursData >= 1) {
          setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $50 to $80')
        } else {
          if (classMinutesData >= 45) {
            setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $40 to $70')

          } else {
            if (classMinutesData >= 30) {
              setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $30 to $60')

            }
          }
        }
      } else {
        if (classHoursData >= 1) {
          setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $15 to $45')
        } else {
          if (classMinutesData >= 45) {
            setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $10 to $35')

          } else {
            if (classMinutesData >= 30) {
              setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $5 to $25')

            }
          }
        }
      }
    } else {
      // console.log('fsdffds');

      if (classSize == 'private') {
        if (classHoursData >= 1) {
          setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $50 to $100')
        } else {
          if (classMinutesData >= 45) {
            setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $40 to $90')

          } else {
            if (classMinutesData >= 30) {
              setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $30 to $70')

            }
          }
        }
      } else {
        if (classHoursData >= 1) {
          setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $30 to $60')
        } else {
          if (classMinutesData >= 45) {
            setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $20 to $50')

          } else {
            if (classMinutesData >= 30) {
              setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of club is between $10 to $40')

            }
          }
        }
      }
    }
  }


  var [printEstimation, setPrintEstimation] = useState("")


  useEffect(() => {
    // Check if classHoursData and classMinutesData are both set
    if (classHoursData !== null && classMinutesData !== null) {
      // Call SetPriceData
      SetPriceData();
    }
  }, [startageRanges, classSize]);

  var [classHoursData, setClassHoursData] = useState('0')
  var [classMinutesData, setClassMinutesData] = useState('55')
  var [classSessionData, setClassSessionData] = useState('0')
  var [classWeekData, setClassWeekData] = useState('0')

  var [totalHours, setTotalHours] = useState(0);

  useEffect(() => {
    // Ensure that state values are treated as numbers for calculations
    var total = (((parseInt(classHoursData) + parseInt(classMinutesData) / 60) * classSessionData * classWeekData).toFixed(1));
    setTotalHours(total);
  }, [classHoursData, classMinutesData, classSessionData, classWeekData]);


  const classHours = (data) => {
    setClassHoursData(data)
  }

  const classsMinutesData = (data) => {
    // alert(data)
    setClassMinutesData(data)
  }

  const classSessionDetails = (selectedValue) => {
    setClassSessionData(selectedValue)
  };
  const classWeek = (data) => {
    setClassWeekData(data)
  }

  // const ageRange = (data) => {
  //   if (data <= 6) {

  //     setEndLearner(6)
  //   }
  //   if (data > 6 && data < 12) {
  //     setEndLearner(9)
  //   }
  //   if (data >= 12) {
  //     setEndLearner(12)
  //   }

  //   setAgeRanges(data);
  // }

  const handlePriceChange = (e) => {

    const inputPrice = e.target.value;
    setPrice(inputPrice);
    var start_est_ear = parseInt((startLearner * inputPrice) * 0.6);
    var end_est_ear = parseInt((endLearner * inputPrice) * 0.6);
    setEstimatedprice(start_est_ear)
    setendEstimatedprice(end_est_ear)
    // Validate if the input is not empty and is a valid number
    if (inputPrice !== "" && !isNaN(inputPrice)) {
      const newPrice = parseFloat(inputPrice);
      // Determine pricing level based on the input value
      if (newPrice > 50) {
        setPricingLevel('High');
      } else if (newPrice >= 20) {
        setPricingLevel('Medium');
      } else {
        setPricingLevel('Very low');
      }
    } else {
      // Handle invalid or empty input (e.g., reset pricing level or show an error)
      setPricingLevel('Very low');
    }
  };

  const progressBarWidth = `${Math.max(0, Math.min((price - 20) / 50 * 100, 100))}%`;

  const [profileImageState, setProfileImageState] = useState('')

  const handleImageChange = async (image) => {
    setValue("profileImage", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState(bufferImage);
    clearErrors("profileImage");
  }

  const [profileVideoState, setProfileVideoState] = useState(null)
  const handleVideoChange = async (Video) => {
    setValue("profileVideo", Video);
    const bufferVideo = await convertToBase64(Video);

    setProfileVideoState(bufferVideo);
    clearErrors("profileVideo");
  }

  var [selectClass, setselectClass] = useState([]);

  const selectClassData = () => {

    teacher_select_class_list().then((resposnse) => {

      if (resposnse.code == 1) {

        setselectClass(resposnse?.data)

      } else {
         
      }
    });
  }

  useEffect(() => {
    selectClassData();
  }, []);

  let onSubmit = async (data) => {
    isLoader(true)
    if (data.profileImage != null) {
      var image = await uploadImageOnAWS(data?.profileImage, "class_image");
    }
    var video = await uploadImageOnAWS(data?.profileVideo, "class_image");

    // data.start_age = startageRanges
    data.end_age = ageRanges
    let submitData = {
      length: selectedRadio,
      title: data.club_title,
      description: data.class_description,
      summary: data.club_summary,
      learner_summary: (data.learner_summary != undefined ? data.learner_summary : ''),
      parental_guide: data.p_guide,
      main_category: subjectCategory,
      subject_category: SelectedSubCategory,
      min_age: data.start_age,
      max_age: data.end_age,
      language: data.lang,
      duration: data.class_hours + " Hour" + (data.class_hours != 1 ? "s " : " ") + data.class_min + " Minutes",
      size: classSize,
      pre_requisites: data.pre_req,
      next_class: data.next_class,
      homeworks: data.homework,
      learning_goals: data.learning_goals,
      assessment: data.assesment,
      sources: data.sources,
      price: price,
      video: video,
      supply_list: data.supply_list,
    }

    if (selectedRadio != "offline course" && selectedRadio != "One-Time") {
      submitData.session_per_week = (classSessionData != undefined ? classSessionData : '')
      submitData.number_of_week = (classWeekData != undefined ? classWeekData : '')
    }
    if (classSize == "group") {
      submitData.min_learner = startLearner
      submitData.max_learner = endLearner
    }
    if (data.profileImage != null) {
      submitData.image = image
    }
    // console.log('teacher_add_clubsubmitData :', submitData);
    teacher_add_club(submitData).then((res) => {
      if (res.code == 1) {
        isLoader(false)
        TOAST_SUCCESS(res.message);
        navigate('/teachers_my_class');
        reset();
      } else {
        isLoader(false)
        TOAST_ERROR(res.message);
      }
    });
  }
  const [subjectCategory, setSubjectCategory] = useState('Academic');
  const [subjectCategoryId, setSubjectCategoryId] = useState('1');
  var [MainCatdata, setMainCatdata] = useState([]);
  var [SelectedSubCategory, SetSelectedSubCategory] = useState('');
  var [SelectedSubCat, setSelectedSubCat] = useState([]);
  var [SelectedSubCatErr, setSelectedSubCatErr] = useState(null);

  const handleSubjectCategoryChange = (e) => {
    setSubjectCategory((e.target.value)?.split(",")[1]);
    setSubjectCategoryId((e.target.value)?.split(",")[0])
  };

  const handleSubjectSubCategoryChange = (e) => {
    SetSelectedSubCategory(e.target.value)
  };

  const MainCategory = () => {
    isLoader(true)
    main_category_list().then((resposnse) => {
      if (resposnse.code == 1) {
        setMainCatdata(resposnse?.data)
        isLoader(false)
        // setSelectedCatID([resposnse.data[0].category_id])
        // SetSelectedCategory([resposnse.data[0].category_name])
      } else {
         
        setMainCatdata([])
        isLoader(false)
      }
    });
  }

  useEffect(() => {
    MainCategory();
  }, []);

  const SubCategoryList = () => {
    isLoader(true)
    subject_category_list({ category_id: subjectCategoryId }).then((resposnse) => {
      if (resposnse.code == 1) {
        setSelectedSubCat(resposnse?.data)
        isLoader(false)
        if (resposnse?.data?.length > 0) {
          if (resposnse.data[0]?.category_name) {
            SetSelectedSubCategory(resposnse.data[0]?.category[0]?.subject_name)
          } else {
            SetSelectedSubCategory(resposnse?.data[0]?.subject_name)
          }
          setSelectedSubCatErr('abcd')
        }
      } else {
         
        isLoader(false)
      }
    });
  }

  useEffect(() => {
    SubCategoryList()
  }, [subjectCategoryId]);

  return (
    <>
      <JqueryLoad />
      <THeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}
              <div className>
                <h3 className="text-purple mb-3">Setup Your Clubs</h3>
                {/* <div className='mb-4'>
                  <h3 className="mb-2">Select the Club Type</h3>
                  <div className='row'>
                    <div className='col-lg-2'>
                      <div className="radio-item mb-4">
                        <input name="radio" id="radio6" type="radio" checked={selectType === "Online"}
                          onChange={handleRadioChange1} />
                        <label htmlFor="radio6" className="d-block text-center">Online</label>
                      </div>
                    </div>
                    <div className='col-lg-2'>
                      <div className="radio-item mb-4">
                        <input name="radio" id="radio7" type="radio" checked={selectType === "In-Person"}
                          onChange={handleRadioChange1} />
                        <label htmlFor="radio7" className="d-block text-center">In-Person</label>
                      </div>
                    </div>
                    <div className='col-lg-2'>
                      <div className="radio-item mb-4">
                        <input name="radio" id="radio8" type="radio" checked={selectType === "Offline Course"}
                          onChange={handleRadioChange1} />
                        <label htmlFor="radio8" className="d-block text-center">Offline Course</label>
                      </div>
                    </div>
                  </div>
                </div> */}

                <h3 className="mb-2 text-capitalize">Select the Club Length</h3>
                <p className="fs-6 mb-4 text-capitalize">You cannot change this setting once the course is listed.</p>
                <div className="row">
                  <div className="col-xl-7">
                    <form>
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                          <div className="radio-item mb-4">
                            <input name="radio" id="radio1" type="radio" checked={selectedRadio === "One-Time"} onChange={handleRadioChange} />
                            <label htmlFor="radio1" className="d-block text-center">One-Time</label>
                          </div>
                          <p className="fs-6">These clubs meet LIVE over video a single time. Discussions may occur by messaging on the classroom page before or after class. Learners pay one time.</p>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                          <div className="radio-item mb-4">
                            <input name="radio" id="radio2" type="radio" checked={selectedRadio === "Multi-Day"} onChange={handleRadioChange} />
                            <label htmlFor="radio2" className="d-block text-center">Multi-Day</label>
                          </div>
                          <p className="fs-6"> These clubs  meet LIVE over video over multiple days. Discussions occur by messaging on the classroom page between meetings. It is sold as a package. Learners pay one time.</p>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                          <div className="radio-item mb-4">
                            <input name="radio" id="radio3" type="radio" checked={selectedRadio === "Ongoing Weekly"} onChange={handleRadioChange} />
                            <label htmlFor="radio3" className="d-block text-center">Ongoing Weekly</label>
                          </div>
                          <p className="fs-6">Learners subscribe to these clubs, which meet LIVE over video on a weekly schedule. Learners can join or leave at any time. Learners pay weekly.</p>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                          <div className="radio-item mb-4">
                            <input name="radio" id="radio4" type="radio" checked={selectedRadio === "Ongoing Monthly"} onChange={handleRadioChange} />
                            <label htmlFor="radio4" className="d-block text-center">Ongoing Monthly</label>
                          </div>
                          <p className="fs-6"> Learners subscribe to these clubs, which meet LIVE over video on a weekly schedule. Learners can join at any time and leave at the end of the month. Learners pay monthly.</p>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                          <div className="radio-item mb-4">
                            <input name="radio" id="radio5" type="radio" checked={selectedRadio === "offline course"} onChange={handleRadioChange} />
                            <label htmlFor="radio5" className="d-block text-center">offline course</label>
                          </div>
                          <p className="fs-6">Learners subscribe to these clubs, will not meet LIVE over video. Learners will get access to material. Learners pay one time.</p>
                        </div>
                      </div>
                    </form>
                    <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                      <div className="form-group mb-4">
                        <h3 className="mb-1 text-capitalize">Club Title</h3>
                        {/* <p className="fs-6 mb-4 text-capitalize">Choose a catchy and descriptive title. Use title case (eg. "Introduction to Extrasolar Planets"), no more than 1 exclamation mark, and no emojis or coupon codes.</p> */}
                        <p className="fs-6 mb-1 text-capitalize">Choose A Catchy And Descriptive Title. </p>
                        <p className="fs-6 mb-2 text-capitalize">E.G., "Reading Club for Young Teens", No More Than 1 Exclamation Mark, And No Emojis Or Coupon Codes.</p>

                        <div className="position-relative club_title">
                          <textarea type="text" className="form-control" maxLength={50} {...register("club_title")} name="club_title" rows={2} defaultValue={""} />
                          <span className="position-absolute word_count">{50 - (watch("club_title")?.split('')?.length ?? 0)} Words</span>
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.club_title?.message}</span></p>

                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <h3 className="mb-1 text-capitalize">Club summary</h3>
                        <p className="fs-6 mb-4 text-capitalize">Add A Single Sentence Or Phrase To Describe Your Club. The Summary Should Be Clear And Descriptive Rather Than A Teaser, And Include Keywords. Use No More Than One Exclamation Mark, And No Emojis Or Coupon Codes</p>
                        <div className="position-relative class_summary">
                          <textarea
                            type="text"
                            className="form-control"
                            {...register("club_summary")}
                            name="club_summary"
                            maxLength={200}
                            rows={2}
                          />
                          {/* <textarea type="text" className="form-control" maxLength={200} placeholder id {...register("")} name="class_summary" /> */}
                          <span className="position-absolute word_count">{200 - (watch("club_summary")?.split('')?.length ?? 0)} Words</span>

                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.club_summary?.message}</span></p>
                        </div>
                      </div>
                      {/* <div className="form-group mb-4">
                        <h3 className="mb-1 text-capitalize">Learner Summary(optional)</h3>
                        <p className="fs-6 mb-4 text-capitalize">Add a single sentence or phrase to describe your club to learners in your target age group. Remember to write in an age-appropriate tone and style.</p>
                        <label className="form-label">Club Summary</label>
                        <input type="text" className="form-control" placeholder id {...register("learner_summary")} name="learner_summary" />
                      </div> */}
                      {/* <div className="form-group mb-4 class_image">
                        <h3 className="mb-1">Class Image</h3>
                        <p className="fs-6 mb-4 text-capitalize">Choose an image that is engaging, free of words or text, age-appropriate and at least 600 pixels wide. Make sure you have permission to use your image (no copyrighted images, please).</p>
                        <label className="form-label">Program Image</label>
                        <Controller name="profileImage" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                          <>
                            <div className="imageWrapper position-relative">
                              <div className="d-flex align-items-center justify-content-center">
                                <img className="image" src={profileImageState ? profileImageState : "./assets/images/image_upload.png"} alt="image_upload" style={{ "width": profileImageState ? "100%" : "" }} />
                              </div>

                              <label htmlFor='profileImage'>
                                <button className="file-upload">
                                  <input type="file" className="file-input" id="profileImage" accept="image/*" onInput={(e) => handleImageChange(e.target.files[0])} />Choose File
                                </button>
                              </label>
                            </div>
                          </>
                        )} />
           
                      </div> */}
                      <div className="form-group mb-4 class_image">
                        <h3 className="mb-1">Club Video</h3>
                        <p className="fs-6 mb-4 text-capitalize">Describe to parents and families what to expect from club. Recommended length: max 30 seconds limit</p>

                        <Controller name="profileVideo" control={control} defaultValue={null} rules={{ required: "Please upload video" }} render={({ field }) => (
                          <>
                            <div className="imageWrapper position-relative">
                              <div className="d-flex align-items-center justify-content-center">
                                {profileVideoState ?
                                  <video src={profileVideoState} width="100%" height="100%" className="video" loop muted autoPlay playsInline controls></video>
                                  :
                                  <img className="video" src="./assets/images/video_upload.png" style={{ "width": profileVideoState ? "100%" : "" }} alt="video_upload" />}
                              </div>

                              <label htmlFor='profileVideo'>
                                <button className="file-upload">
                                  <input type="file" className="file-input" id="profileVideo" accept="video/*" onChange={(e) => handleVideoChange(e.target.files[0])} />Choose File
                                </button>
                              </label>
                            </div>
                          </>
                        )} />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.profileVideo?.message}</span></p>
                      </div>
                      <div className="form-group mb-4">
                        <h3 className="mb-2">Subject Category</h3>
                        <label className="mb-2" style={{ textTransform: 'none' }}>Select the Subject Category</label>
                        <select className="form-select" aria-label="Default select example" name='subject_category' {...register("subject_category")} onChange={handleSubjectCategoryChange}>
                          {MainCatdata?.map((mainCategory, index) => {
                            return (
                              <option
                                key={index}
                                selected={subjectCategory === mainCategory.category_id ? 'selected' : ''}
                                value={`${mainCategory.category_id},${mainCategory.category_name}`}
                              >
                                {mainCategory.category_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.subject_category?.message}</span></p> */}
                      <div className="form-group mb-4">
                        <label className="mb-2" style={{ textTransform: 'none' }}>Select the Subject Sub-Category</label>
                        <select className="form-select fw-500" aria-label="Default select example" name='subject_sub_category' {...register("subject_sub_category")} onChange={handleSubjectSubCategoryChange}>
                          {SelectedSubCat[0]?.category_name ? (<>
                            {SelectedSubCat?.map((SubCat, index) => {
                              return (
                                <>
                                  <optgroup label={SubCat.category_name}>
                                    {SubCat.category?.map((SubCat1, index1) => {
                                      return (
                                        <option value={SubCat1.subject_name}>{SubCat1.subject_name}</option>
                                      )
                                    })}
                                  </optgroup>
                                </>)
                            })}
                          </>) : (<>
                            {SelectedSubCat?.map((SubCat, index) => {
                              return (
                                <>
                                  <option value={SubCat.subject_name}>{SubCat.subject_name}</option>
                                </>)
                            })}
                          </>)}
                        </select>
                      </div>

                      <div className="form-group mb-4 class_image">
                        <h3 className="mb-1">Learner age range</h3>
                        <p className="fs-6 mb-4 text-capitalize">Age ranges cannot exceed 3 years for group classes, For Learners Under 18 Years Old. Learners tend to thrive in classes with others who match their developmental stage.</p>
                        <div className="d-md-flex">
                          <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                            <select className="form-select mb-md-0 mb-3 fw-500 w-auto pe-5 rounded-pill"
                              {...register("start_age")} name="start_age" onChange={(e) => startageRange(e.target.value)} aria-label="Default select example">

                              <option selected={startageRanges == '3' ? 'selected' : ''} value="3" >3 Years</option>
                              <option selected={startageRanges == '4' ? 'selected' : ''} value="4">4 Years</option>
                              <option selected={startageRanges == '5' ? 'selected' : ''} value="5">5 Years</option>
                              <option selected={startageRanges == '6' ? 'selected' : ''} value="6" >6 Years</option>
                              <option selected={startageRanges == '7' ? 'selected' : ''} value="7">7 Years</option>
                              <option selected={startageRanges == '8' ? 'selected' : ''} value="8">8 Years</option>
                              <option selected={startageRanges == '9' ? 'selected' : ''} value="9">9 Years</option>
                              <option selected={startageRanges == '10' ? 'selected' : ''} value="10">10 Years</option>
                              <option selected={startageRanges == '11' ? 'selected' : ''} value="11">11 Years</option>
                              <option selected={startageRanges == '12' ? 'selected' : ''} value="12">12 Years</option>
                              <option selected={startageRanges == '13' ? 'selected' : ''} value="13">13 Years</option>
                              <option selected={startageRanges == '14' ? 'selected' : ''} value="14">14 Years</option>
                              <option selected={startageRanges == '15' ? 'selected' : ''} value="15">15 Years</option>
                              <option selected={startageRanges == '16' ? 'selected' : ''} value="16">16 Years</option>
                              <option selected={startageRanges == '17' ? 'selected' : ''} value="17">17 Years</option>
                              <option selected={startageRanges == '18' ? 'selected' : ''} value="18">18 Years</option>
                              <option selected={startageRanges == 'adults' ? 'selected' : ''} value="adults">Adults</option>


                            </select>
                            <span className="ms-md-3">To</span>
                          </div>
                          <div className="d-flex ms-md-3 align-items-center">
                            <select className="form-select w-auto pe-5 fw-500 rounded-pill" aria-label="Default select example" {...register("end_age")} name='end_age' onChange={(e) => ageRange(e.target.value)} disabled>

                              <option selected={ageRanges == '3' ? 'selected' : ''} value="3" >3 Years</option>
                              <option selected={ageRanges == '4' ? 'selected' : ''} value="4">4 Years</option>
                              <option selected={ageRanges == '5' ? 'selected' : ''} value="5">5 Years</option>
                              <option selected={ageRanges == '6' ? 'selected' : ''} value="6" >6 Years</option>
                              <option selected={ageRanges == '7' ? 'selected' : ''} value="7">7 Years</option>
                              <option selected={ageRanges == '8' ? 'selected' : ''} value="8">8 Years</option>
                              <option selected={ageRanges == '9' ? 'selected' : ''} value="9">9 Years</option>
                              <option selected={ageRanges == '10' ? 'selected' : ''} value="10">10 Years</option>
                              <option selected={ageRanges == '11' ? 'selected' : ''} value="11">11 Years</option>
                              <option selected={ageRanges == '12' ? 'selected' : ''} value="12">12 Years</option>
                              <option selected={ageRanges == '13' ? 'selected' : ''} value="13">13 Years</option>
                              <option selected={ageRanges == '14' ? 'selected' : ''} value="14">14 Years</option>
                              <option selected={ageRanges == '15' ? 'selected' : ''} value="15">15 Years</option>
                              <option selected={ageRanges == '16' ? 'selected' : ''} value="16">16 Years</option>
                              <option selected={ageRanges == '17' ? 'selected' : ''} value="17">17 Years</option>
                              <option selected={ageRanges == '18' ? 'selected' : ''} value="18">18 Years</option>
                              <option selected={ageRanges == 'adults' ? 'selected' : ''} value="adults">Adults</option>
                            </select>
                            <span className="ms-3 text-capitalize">years old</span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <h3 className="mb-2">Select Club Size</h3>
                        <select
                          className="form-select mb-4 fw-500"
                          onClick={(e) => handleClassChange(e.target.value)}

                          {...register("class_size")} // Use the register function to bind the select value to the form data
                          name='class_size' >
                          <option value="group" selected>Group Clubs</option>
                          {/* <option value="private">1-on-1 Private class</option> */}
                        </select>


                        {classSize == "group" ?
                          <>
                            <p className="fs-6 mb-2 text-capitalize">Group Class Sizes :</p>
                            <p className="fs-6 mb-2 text-capitalize">-For Ages 6 And Below : Up To 6 Learners</p>
                            <p className="fs-6 mb-2 text-capitalize">-For Ages 6-12 : Up To 9 Learners</p>
                            <p className="fs-6 mb-2 text-capitalize">-For Ages 12-18 : Up To 12 Learners</p>
                            <p className="fs-6 mb-2 text-capitalize">-For Adults : Up To 20 Learners</p>
                            <div className="d-md-flex">
                              <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                <select className="form-select mb-md-0 mb-3 w-auto pe-5 fw-500 rounded-pill"  {...register("start_learner_age")} name='start_learner_age' aria-label="Default select example" onChange={(e) => StartLearner(e.target.value)}>
                                  {ageRanges <= 6 ? (

                                    <>
                                      <option value="1" selected>1 Learner</option>
                                      <option value="2">2 Learners</option>
                                      <option value="3">3 Learners</option>
                                      <option value="4">4 Learners</option>
                                      <option value="5">5 Learners</option>
                                      <option value="6">6 Learners</option>
                                    </>
                                  ) : ageRanges <= 12 ? (
                                    <>
                                      <option value="1" selected>1 Learner</option>
                                      <option value="2" >2 Learners</option>
                                      <option value="3" >3 Learners</option>
                                      <option value="4" >4 Learners</option>
                                      <option value="5" >5 Learners</option>
                                      <option value="6" >6 Learners</option>
                                      <option value="7" >7 Learners</option>
                                      <option value="8" >8 Learners</option>
                                      <option value="9" >9 Learners</option>
                                    </>
                                  ) : ageRanges <= 18 ? (
                                    <>
                                      <option value="1" selected>1 Learner</option>
                                      <option value="2" >2 Learners</option>
                                      <option value="3">3 Learners</option>
                                      <option value="4">4 Learners</option>
                                      <option value="5" >5 Learners</option>
                                      <option value="6" >6 Learners</option>
                                      <option value="7" >7 Learners</option>
                                      <option value="8" >8 Learners</option>
                                      <option value="9" >9 Learners</option>
                                      <option value="10" >10 Learners</option>
                                      <option value="11" >11 Learners</option>
                                      <option value="12" >12 Learners</option>
                                    </>
                                  ) : (
                                    <>
                                      <option value="1" selected>1 Learner</option>
                                      <option value="2" >2 Learners</option>
                                      <option value="3">3 Learners</option>
                                      <option value="4">4 Learners</option>
                                      <option value="5" >5 Learners</option>
                                      <option value="6" >6 Learners</option>
                                      <option value="7" >7 Learners</option>
                                      <option value="8" >8 Learners</option>
                                      <option value="9" >9 Learners</option>
                                      <option value="10" >10 Learners</option>
                                      <option value="11" >11 Learners</option>
                                      <option value="12" >12 Learners</option>
                                      <option value="13">13 Learners</option>
                                      <option value="14">14 Learners</option>
                                      <option value="15">15 Learners</option>
                                      <option value="16">16 Learners</option>
                                      <option value="17">17 Learners</option>
                                      <option value="18">18 Learners</option>
                                      <option value="19">19 Learners</option>
                                      <option value="20">20 Learners</option>
                                    </>

                                  )}
                                </select>
                                <span className="ms-3">To</span>
                              </div>
                              <div className="ms-md-3">
                                <select className="form-select w-auto fw-500 pe-5 rounded-pill"
                                  {...register("end_learner_age")} name='end_learner_age' aria-label="Default select example" onChange={(e) => EndLearner(e.target.value)}>

                                  {ageRanges <= 6 ? (

                                    <>
                                      <option value="1" >1 Learner</option>
                                      <option value="2" >2 Learners</option>
                                      <option value="3" >3 Learners</option>
                                      <option value="4" >4 Learners</option>
                                      <option value="5" >5 Learners</option>
                                      <option value="6" selected>6 Learners</option>
                                    </>
                                  ) : ageRanges <= 12 ? (
                                    <>
                                      <option value="1"  >1 Learner</option>
                                      <option value="2" >2 Learners</option>
                                      <option value="3" >3 Learners</option>
                                      <option value="4" >4 Learners</option>
                                      <option value="5" >5 Learners</option>
                                      <option value="6" >6 Learners</option>
                                      <option value="7" >7 Learners</option>
                                      <option value="8" >8 Learners</option>
                                      <option value="9" selected>9 Learners</option>
                                    </>
                                  ) : ageRanges <= 18 ? (
                                    <>
                                      <option value="1" >1 Learner</option>
                                      <option value="2" >2 Learners</option>
                                      <option value="3">3 Learners</option>
                                      <option value="4">4 Learners</option>
                                      <option value="5" >5 Learners</option>
                                      <option value="6" >6 Learners</option>
                                      <option value="7" >7 Learners</option>
                                      <option value="8" >8 Learners</option>
                                      <option value="9" >9 Learners</option>
                                      <option value="10" >10 Learners</option>
                                      <option value="11" >11 Learners</option>
                                      <option value="12" selected>12 Learners</option>
                                    </>
                                  ) : (
                                    <>
                                      <option value="1" >1 Learner</option>
                                      <option value="2" >2 Learners</option>
                                      <option value="3">3 Learners</option>
                                      <option value="4">4 Learners</option>
                                      <option value="5" >5 Learners</option>
                                      <option value="6" >6 Learners</option>
                                      <option value="7" >7 Learners</option>
                                      <option value="8" >8 Learners</option>
                                      <option value="9" >9 Learners</option>
                                      <option value="10" >10 Learners</option>
                                      <option value="11" >11 Learners</option>
                                      <option value="12" >12 Learners</option>
                                      <option value="13">13 Learners</option>
                                      <option value="14">14 Learners</option>
                                      <option value="15">15 Learners</option>
                                      <option value="16">16 Learners</option>
                                      <option value="17">17 Learners</option>
                                      <option value="18">18 Learners</option>
                                      <option value="19">19 Learners</option>
                                      <option value="20" selected>20 Learners</option>
                                    </>

                                  )}
                                </select>
                              </div>
                            </div>

                          </>
                          : <></>}

                      </div>
                      <div className="form-group mb-4 class_image">
                        <h3 className="mb-1 text-capitalize">Schedule</h3>

                        <h4 className="mb-1 text-capitalize">Meeting duration</h4>
                        <p className="fs-6 mb-4 text-capitalize">Select how often you will meet and for how long. Course type : <span className="text-capitalize fw-600 text-dif-black">{selectedRadio}</span></p>
                        <div className="d-md-flex align-items-center">
                          <p className="fs-6 mb-md-0 mb-3 text-capitalize">Length of the club in Hours </p>
                          <div className="d-md-flex ms-md-3">
                            <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                              <select className="form-select mb-md-0 mb-3 w-auto pe-5 fw-500 rounded-pill"
                                {...register("class_hours")} onChange={(e) => classHours(e.target.value)} name='class_hours' aria-label="Default select example">
                                {/* <option selected>0 Hours</option> */}
                                <option selected>0</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>

                              </select>
                              <span className="ms-3 text-capitalize">and minutes</span>
                            </div>
                            <div className="ms-md-3">
                              <select className="form-select w-auto pe-5 rounded-pill fw-500"
                                {...register("class_min")} onChange={(e) => classsMinutesData(e.target.value)} name='class_min' aria-label="Default select example">
                                <option>0 </option>
                                <option>5 </option>
                                <option>10 </option>
                                <option>15 </option>
                                <option>20 </option>
                                <option>25 </option>
                                <option>30 </option>
                                <option>35 </option>
                                <option>40 </option>
                                <option>45 </option>
                                <option>50 </option>
                                <option selected>55 </option>
                              </select>
                            </div>

                          </div>
                          {selectedRadio === "Multi-Day" ?
                            <>
                              <div class="MuiBox-root outschool-vuszil" style={model_css}>
                                <h6 class="MuiTypography-root MuiTypography-subtitle1 outschool-y2285n">Summary</h6>
                                <div role="presentation" class="outschool-l2z0vi"></div>
                                <h6 class="MuiTypography-root MuiTypography-h6 outschool-18oerlv">{classWeekData} weeks</h6>
                                <h6 class="MuiTypography-root MuiTypography-h6 outschool-18oerlv">{classSessionData * classWeekData} sessions</h6>
                                <h6 class="MuiTypography-root MuiTypography-h6 outschool-18oerlv">{totalHours} total hours</h6>
                              </div>
                            </> :
                            (selectedRadio === "Ongoing Weekly" || selectedRadio === "Ongoing Monthly") ?
                              <>
                                <div class="MuiBox-root outschool-vuszil" style={model_css}>
                                  <h6 class="MuiTypography-root MuiTypography-subtitle1 outschool-y2285n">Summary</h6>
                                  <div role="presentation" class="outschool-l2z0vi"></div>
                                  <h6 class="MuiTypography-root MuiTypography-h6 outschool-18oerlv"></h6>
                                  <h6 class="MuiTypography-root MuiTypography-h6 outschool-18oerlv">{classSessionData * classWeekData} sessions</h6>
                                  <h6 class="MuiTypography-root MuiTypography-h6 outschool-18oerlv">{totalHours} total hours</h6>
                                </div>
                              </> :
                              <></>
                          }

                        </div>
                        {classHoursData == '0' && classMinutesData == '0' && (
                          <p className="text-danger mt-1">Meeting duration cannot be 0 hours and 0 minutes. Please select a valid duration.</p>
                        )}
                      </div>

                      {selectedRadio != "offline course" && selectedRadio != "One-Time" ?
                        <>
                          <div className="form-group mb-4 class_image">
                            <h3 className="mb-1 text-capitalize">Meetings per Week</h3>
                            {/* <p className="fs-6 mb-4 text-capitalize">Select how often you will meet and for how long. Course type : <span className="text-capitalize fw-600 text-dif-black">{selectedRadio}</span></p> */}
                            <div className="d-md-flex align-items-center">
                              {/* <p className="fs-6 mb-md-0 mb-3 text-capitalize">Length of the class in Hours </p> */}
                              <div className="d-md-flex ms-md-3">
                                <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                  <select className="form-select mb-md-0 mb-3 w-auto pe-5 fw-500 rounded-pill"
                                    {...register("class_session_count", { onChange: (e) => classSessionDetails(e.target.value) })}
                                    name='class_session_count' aria-label="Default select example">

                                    <option value="1" selected>1 </option>
                                    <option value="2" >2 </option>
                                    <option value="3">3 </option>
                                    <option value="4">4 </option>
                                    <option value="5" >5 </option>
                                    <option value="6" >6 </option>
                                    <option value="7" >7 </option>
                                    <option value="8" >8 </option>
                                    <option value="9" >9 </option>
                                    <option value="10" >10 </option>
                                    <option value="11" >11 </option>
                                    <option value="12" >12 </option>
                                    <option value="13">13 </option>
                                    <option value="14">14 </option>
                                    <option value="15">15 </option>
                                    <option value="16">16 </option>
                                    <option value="17">17 </option>
                                    <option value="18">18 </option>
                                    <option value="19">19 </option>
                                    <option value="20" >20 </option>

                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          {selectedRadio == "Multi-Day" ?
                            <>
                              <div className="form-group mb-4 class_image">
                                <h3 className="mb-1 text-capitalize">Number of Weeks</h3>
                                {/* <p className="fs-6 mb-4 text-capitalize">Select how often you will meet and for how long. Course type : <span className="text-capitalize fw-600 text-dif-black">{selectedRadio}</span></p> */}
                                <div className="d-md-flex align-items-center">
                                  {/* <p className="fs-6 mb-md-0 mb-3 text-capitalize">Length of the class in Hours </p> */}
                                  <div className="d-md-flex ms-md-3">
                                    <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                      <select className="form-select mb-md-0 mb-3 w-auto pe-5 fw-500 rounded-pill"
                                        {...register("session_week", { onChange: (e) => classWeek(e.target.value) })} name='session_week' aria-label="Default select example">

                                        <option value="1" selected>1 </option>
                                        <option value="2" >2 </option>
                                        <option value="3">3 </option>
                                        <option value="4">4 </option>
                                        <option value="5" >5 </option>
                                        <option value="6" >6 </option>
                                        <option value="7" >7 </option>
                                        <option value="8" >8 </option>
                                        <option value="9" >9 </option>
                                        <option value="10" >10 </option>
                                        <option value="11" >11 </option>
                                        <option value="12" >12 </option>
                                        <option value="13">13 </option>
                                        <option value="14">14 </option>
                                        <option value="15">15 </option>
                                        <option value="16">16 </option>
                                        <option value="17">17 </option>
                                        <option value="18">18 </option>
                                        <option value="19">19 </option>
                                        <option value="20" >20 </option>

                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </> : <></>}
                        </> : <></>}
                      <div className="form-group mb-4">
                        <h3 className="mb-2 text-capitalize">Pre-requisites(Optional)</h3>
                        <p className="fs-6 mb-4 text-capitalize">Please Select Any Pre-Requisites Classes</p>
                        <select className="form-select" {...register("pre_req")} name="pre_req" aria-label="Default select example"  >
                          <option value='' selected disabled>Select the Class</option>
                          {selectClass?.map((coupon, index) => {
                            return (
                              <option key={index} value={coupon?.class_id} >{coupon?.title}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <h3 className="mb-2 text-capitalize">Language of instruction</h3>
                        <p className="fs-6 mb-4 text-capitalize">Select The Class Language Of Instruction</p>
                        <select className="form-select fw-500 mb-4" {...register("lang")} name='lang' aria-label="Default select example">
                          {/* <option selected>Class Description</option> */}
                          <option selected>English</option>
                          <option>French</option>
                          <option>Mandarin</option>
                          <option>Cantonese</option>
                          <option>Spanish</option>
                          <option>Hindi</option>
                          <option>Japanese</option>
                          <option>Arabic</option>
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <h3 className="mb-2 text-capitalize">Club Description</h3>
                        <p className="fs-6 mb-2 text-capitalize">Explain additional club details. The longer the club, the more details you should provide. For a half hour class, a few sentences is sufficient. For a semester-long course, please include a detailed curriculum.</p>
                        <p className="fs-6 mb-2 text-capitalize">Multi-week classes should specify which topics are covered each week. Classes running week after week should do the same for at least the first 4 weeks, unless the topics are learner-directed or responsive to current events.</p>
                        <p className="fs-6 mb-2 text-capitalize"> A class that meets 6 or more times should have a description of at least 200 words.</p>
                        <p className="fs-6 mb-2 text-capitalize">Topics to include:</p>
                        <ol style={{ listStyleType: 'circle !important' }}>
                          <li>- What will be taught?</li>
                          <li>- What topics will you cover?</li>
                          <li>- How is your class structured?</li>
                          <li>- How will you teach?</li>
                          <li>- What's your teaching style?</li>
                          <li>- How much will learners get to interact with you and each other (mention specifics like: lecture, games, slides, video clips, discussion)?</li>
                        </ol>

                        <div className="position-relative class_summary">

                          <textarea className="form-control" name='class_description' {...register("class_description")} maxLength={500} rows={6} />
                          <span className="position-absolute word_count">{500 - (watch("class_description")?.split('')?.length ?? 0)} Words</span>
                        </div>
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.class_description?.message}</span></p>

                      </div>
                      {/* <div className="form-group mb-4">
                        <h3 className="mb-2 text-capitalize">Learning Goals (Optional)</h3>
                        <p className="fs-6 mb-4 text-capitalize">What Will You Teach? Be As Specific As Possible. Max 200 Characters</p>
                        <div className="position-relative class_title">

                          <textarea className="form-control" maxLength={200} rows={6} defaultValue={""} {...register("learning_goals")} name='learning_goals' />
                          <span className="position-absolute word_count">{200 - (watch("learning_goals")?.split('')?.length ?? 0)} Words</span>
                        </div>
                      </div> */}
                      <div className="form-group mb-4">
                        <h3 className="mb-2 text-capitalize">Homework (optional)</h3>
                        <p className="fs-6 mb-4 text-capitalize">What kind of homework is assigned? Will learners be completing projects individually or as a group? How long will homework take each week? For a class requiring reading, approximately how many pages will learners be expected to read independently?Max 200 characters</p>
                        <div className="position-relative class_summary">

                          <textarea className="form-control" maxLength={200} rows={6} defaultValue={""} {...register("homework")} name='homework' />
                          <span className="position-absolute word_count">{200 - (watch("homework")?.split('')?.length ?? 0)} Words</span>
                        </div>                      </div>
                      <div className="form-group mb-4">
                        <h3 className="mb-2 text-capitalize">Assessment (optional)</h3>
                        <p className="fs-6 mb-4 text-capitalize">How Is Learner Progress Assessed ?</p>
                        <textarea className="form-control" {...register("assesment")} name='assesment' rows={6} defaultValue={""} />
                      </div>
                      <div className="form-group mb-4">
                        <h3 className="mb-2 text-capitalize">Parental Guidance (Optional) - For Learners Under 18 Years Old</h3>
                        <p className="fs-6 mb-4 text-capitalize">Identify Any Class Content That May Be Upsetting Or Scary To Some Learners, Or That Requires Learners To Take Extra Safety Precautions. Include The Official Rating Of Any Media Content You're Using. If You Are Using An Approved Third-Party Tool, Inform Families About How The Tool Will Be Used And Whether Or Not An Account Is Required. If Your Class Doesn't Require Any Content Or Safety Warnings, Leave This Section Blank.</p>

                        {ageRanges == "adults" ? <textarea className="form-control" rows={6} defaultValue={""} /> : <> <textarea className="form-control" {...register("p_guide")} rows={6} defaultValue={""} />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.p_guide?.message}</span></p></>
                        }                      </div>
                      <div className="form-group mb-4">
                        <h3 className="mb-2 text-capitalize">Sources (Required For Certain Topics – Optional)</h3>
                        <p className="fs-6 mb-4 text-capitalize">Books, Articles, Websites, Or Other Sources That Are Used In The Class, Or Provide Source Material For The Curriculum. Reference</p>
                        <textarea className="form-control"  {...register("sources")} name='sources' rows={6} defaultValue={""} />
                      </div>
                      <div className="form-group mb-4">
                        <h3 className="mb-2 text-capitalize">Learner Supply List(optional)</h3>
                        <p className="fs-6 mb-4 text-capitalize">List any materials learners will need to complete the class. This may include ingredients for a recipe, supplies for a project, or books/textbooks. If the supplies are not commonly found around the home, include the price. If you will supply curriculum or materials, note that here.</p>
                        <textarea className="form-control" {...register("supply_list")} name='supply_list' rows={6} defaultValue={""} />
                      </div>
                      {/* <div className="form-group mb-4">
                        <h3 className="mb-2 text-capitalize">Student Welcome Post</h3>
                        <p className="fs-6 mb-4 text-capitalize">Welcome Post This message will be displayed as the first post on the classroom page, and is included in the welcome email each enrolled family receives before class. Please include anything needed for the first class in this message. Changes will only affect newly-created sections, not existing ones.</p>
                        <textarea className="form-control" {...register("welcome_post")} name='welcome_post' rows={6} defaultValue={""} />
                      </div> */}
                      {/* <div className="button mb-4">
                        <a href="#" className="btn-theme bg-yellow d-inline-block fw-600">Add A Video</a>
                      </div> */}
                      <div className="form-group mb-4">
                        <h3 className="mb-2 text-capitalize">Next Class(Optional)</h3>
                        <p className="fs-6 mb-4 text-capitalize">Please Select Next Class</p>
                        <label className="mb-2">Select the Class</label>
                        {/* <select className="form-select mb-4 fw-500" aria-label="Default select example" {...register("next_class")} name='next_class'>
                                                    <option selected>Class 1</option>
                                                    <option>Class 1</option>
                                                    <option>Class 2</option>
                                                    <option>Class 3</option>
                                                    <option>Class 4</option>
                                                </select> */}
                        <select className="form-select" {...register("next_class")} name="next_class" aria-label="Default select example"  >
                          <option value='' selected disabled>Select the Class</option>
                          {selectClass?.map((coupon, index) => {
                            return (
                              <option key={index} value={coupon?.class_id} >{coupon?.title}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <h3 className="mb-2 text-capitalize">Pricing</h3>
                        <p className="fs-6 mb-4 text-capitalize">How Much Will You Charge for the Lesson ?</p>
                        <p className="fs-6 fw-600 mb-4 text-capitalize">{printEstimation}</p>
                        <div className="row">
                          <div className="col-lg-6 mb-lg-0 mb-4">
                            <div className="usd_learner flex-wrap mb-1 d-flex align-items-center">
                              <span>$</span>
                              <input
                                type="number"
                                className="form-control mx-3 w-auto"
                                name="price"
                                value={price}
                                onChange={handlePriceChange}
                                min={10}
                                max={70}
                                autoComplete="off"

                              />
                              <span className="text-capitalize">USD per learner</span>

                            </div>
                            <div className="pricing_guide mt-2">
                              <span className="mb-2 d-block text-capitalize">{pricingLevel}</span>
                              <div className="progress rounded-pill">
                                <div className="progress-bar bg-warning rounded-pill" role="progressbar" style={{ width: progressBarWidth }} aria-valuenow={price} aria-valuemin={20} aria-valuemax={70} />

                              </div>
                              <div className="mt-1 mb-2 d-flex justify-content-between align-items-center ">
                                <span>(20 $)</span>
                                <span>(50 $)</span>
                                <span>(70 $)</span>
                              </div>
                              <p className="fs-6 text-capitalize">Pricing Guide: Please Refer to Similar Class Prices Set by Other Teachers, Subject Category and Ages of the Learners.</p>
                            </div>
                          </div>
                          {classSize == "group" ?

                            <div className="col-lg-6 mt-5">
                              <div className="pricing_guide">
                                <span className="fs-6 fw-600 mb-2 text-capitalize">Estimated Earnings</span>
                                <h3 className="font-18 mb-2 text-capitalize">${Estimatedprice} - {endEstimatedprice} USD per week</h3>
                                <p className="fs-6 text-capitalize">How This is Calculated: The Minimum and Maximum Price Range is Listed. Price Per Learner, Class Size, And Studentscholar’s 40% Service Fee is Taken into Account.</p>
                              </div>
                            </div>
                            :
                            <div className="col-lg-6">
                              <div className="pricing_guide">
                                <span className="fs-6 fw-600 mb-2 text-capitalize">Estimated Earnings</span>
                                <h3 className="font-18 mb-2 text-capitalize">${Estimatedprice} USD per learner</h3>
                                <p className="fs-6 text-capitalize">How This is Calculated: The Minimum and Maximum Price Range is Listed. Price Per Learner, Class Size, And Studentscholar’s 40% Service Fee is Taken into Account.</p>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <h3 className="mb-2 text-capitalize">Refund Policy</h3>
                        <p className="fs-6 mb-4 text-capitalize">All One-Time classes have a standard refund policy: "Full refund until a week in advance, or before 24 hours of purchase (until class starts)." <a className="fw-600" data-bs-toggle="modal" data-bs-target="#safety_tips1" onClick={(e) => setIsOpen(true)}>Learn More</a></p>
                      </div>
                      <div className="row mt-5">
                        <div className="col-lg-3">
                          {/* <a href="#" className="btn-theme bg-yellow d-block text-center fw-600">Create Club</a> */}
                          <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                            Create Club
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >

        {/* <div className="modal fade yello_left_border" id="safety_tips1" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
        <div className="modal-dialog modal-lg modal-dialog-scrollable yello_left_border">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title poppins fw_500" id="exampleModalLabel">
                <span className="me-2">
                  <svg width={23} height={30} viewBox="0 0 43 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.2886 15.4744C16.0354 15.4744 11.7617 19.748 11.7617 25.0007C11.7617 30.2541 16.0354 34.5276 21.2886 34.5276C26.5415 34.5276 30.8149 30.2541 30.8149 25.0008C30.8149 19.7479 26.5415 15.4744 21.2886 15.4744ZM26.9446 23.2068L20.1197 30.0316C19.9836 30.1681 19.8219 30.2763 19.6438 30.3501C19.4658 30.4239 19.2749 30.4618 19.0821 30.4616C18.8894 30.4618 18.6986 30.424 18.5206 30.3502C18.3426 30.2764 18.181 30.1681 18.045 30.0316L15.4368 27.4235C14.8639 26.8509 14.8639 25.922 15.4368 25.3491C16.0094 24.7761 16.9383 24.7761 17.5112 25.3491L19.0821 26.92L24.8702 21.1324C25.4428 20.5593 26.3717 20.5593 26.9446 21.1324C27.5176 21.7049 27.5176 22.6338 26.9446 23.2068Z" fill="#570861" />
                    <path d="M42.528 13.5663L42.5266 13.5274C42.5038 13.0097 42.4888 12.4918 42.4815 11.9737C42.4407 9.21675 40.2491 6.93629 37.4919 6.78229C31.7432 6.46139 27.296 4.58688 23.4962 0.883177L23.4638 0.852318C22.2244 -0.284106 20.3548 -0.284106 19.115 0.852318L19.0826 0.883177C15.2828 4.58688 10.8356 6.46139 5.08688 6.78258C2.33005 6.93629 0.138066 9.21685 0.0972458 11.974C0.090081 12.492 0.0751053 13.0098 0.052324 13.5274L0.0499802 13.6177C-0.061836 19.4798 -0.200703 26.7754 2.23992 33.3973C3.582 37.0389 5.61442 40.2042 8.28053 42.8062C11.317 45.7695 15.2942 48.122 20.1015 49.7982C20.2597 49.8532 20.4216 49.8973 20.5859 49.9302C20.8194 49.9767 21.0544 50 21.2894 50C21.5244 50 21.7597 49.9767 21.9928 49.9302C22.1578 49.8971 22.3203 49.8527 22.4791 49.7973C27.2807 48.1181 31.2537 45.7645 34.2875 42.8017C36.9525 40.1989 38.985 37.0327 40.3281 33.3904C42.7779 26.7487 42.6394 19.4393 42.528 13.5663ZM21.2886 37.4614C14.4175 37.4614 8.82789 31.8718 8.82789 25.0008C8.82789 18.1297 14.4176 12.5401 21.2886 12.5401C28.1593 12.5401 33.7493 18.1297 33.7493 25.0008C33.7493 31.8719 28.1593 37.4614 21.2886 37.4614Z" fill="#570861" />
                  </svg>
                </span>
                Refunds &amp; Subscription Policy:
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => setIsOpen(false)} />
            </div>
            <div className="modal-body">
              <div className="safety_list">
                <p >We care about your experience with StudentScholars. Therefore, we have introduced a variety of Refund and Subscription Policies to assist our learners.</p>
                <h5 className="modal-title poppins fw_500 mt-2">Refund Policy</h5>
                <p className='mt-2'>To request a refund, please contact the teacher WITHIN FIVE DAYS (5) days after the day of the class.</p>
                <h5 className="modal-title poppins fw_500 mt-2">Full Refunds</h5>
                <p className='mt-2'>In the following situations, you are entitled to request for and to receive a full refund for a class or a session.</p>
                <ol className='my-2'>
                  <li>
                    <p>You may receive a full refund for a class, club or competition if you withdraw from the class: within 24 hours of purchase or before 24 hours of the class start time for an on-going weekly, or monthly lesson or a one-time lesson,  or at least 7 days before the start of the first session for a multi-day class.</p>
                  </li>
                  <li>
                    <p>In the event that a teacher misses a one-time class, you may request a full refund for the class, or communicate with the teacher to reschedule the class.</p>
                  </li>
                  <li>
                    <p>In the event that a teacher reschedules a session of the class before the first session starts, you may request a full refund for the class.</p>
                  </li>

                </ol>
                <h5 className="modal-title poppins fw_500 mt-2">Prorated Refunds For Multi-day Classes</h5>
                <p className='mt-2'>In the following situations, you will be entitled to request for and receive a prorated refund based on the number of sessions remaining in your class：</p>
                <ol className='my-2'>
                  <li>
                    <p>If the sessions purchased by you are rescheduled, you will receive, upon request, a prorated refund based on the number of sessions rescheduled that you (i) did not attend and (ii) did not consent to such rescheduling. However, if you did not attend the rescheduled session where you have consented to such rescheduling, you will not be eligible for a refund for such session.</p>
                  </li>
                  <li>
                    <p>If the teacher misses some of the sessions listed in the class, you will receive a prorated refund based on the number of sessions which the teacher missed upon request.</p>
                  </li>
                  <li>
                    <p>If the teacher cancels a class in which you have any uncompleted sessions, you are eligible to receive a prorated refund for the class according to the proportion of sessions that were canceled out of all sessions you have paid for in the class.</p>
                  </li>
                </ol>
                <h5 className="modal-title poppins fw_500 mt-2">No Refunds</h5>
                <ol className='my-2'>
                  <li>
                    <p>We do not offer a refund for missed classes, unless the learners/parents inform the teacher 24 hours before the class start time. If you miss 3 consecutive class meetings without communicating with the teacher, you forfeit the class fee and the teacher may cancel the class or withdraw the learner from the class.
                    </p>
                  </li>
                  <li>
                    <p>If your learner has not joined the meeting 10 minutes after the class’s start time, the meeting may be cancelled, at the teacher’s discretion. This is considered a missed meeting and you are not entitled to a refund.</p>
                  </li>

                </ol>
                <h5 className="modal-title poppins fw_500 mt-2">Subscription Policy</h5>
                <h5 className="modal-title poppins fw_500 mt-2">Weekly & Monthly Subscription Policy</h5>
                <ol className='my-2'>
                  <li>
                    <p>A complete single subscription cycle is from 00:00:00（Canadian Eastern Time) every Monday to 23:59:59 the following Sunday (Subscription Cycle);
                    </p>
                  </li>
                  <li>
                    <p>A subscription shall become effective once you make payment for the subscription fees of the first Subscription Cycle. Your payment for the first Subscription Cycle might be adjusted as the price reflected on the subscription page depending on the remaining session(s) in the current week.</p>
                  </li>
                  <li>
                    <p> Deductions will be made from your designated payment method on a regular basis starts from every Sunday at 23:50:00（Canadian Eastern Time）(Fixed Deduction Time) for the next Subscription Cycle, unless you cancel the subscription in accordance with provisions below before the Fixed Deduction Time. Should any deduction fail to pass through on the Fixed Deduction Time, the subscription will be automatically cancelled.</p>
                  </li>
                  <li>
                    <p>Subscriptions made by a subscriber are non-transferrable (to the next person).</p>
                  </li>
                </ol>

                <h5 className="modal-title poppins fw_500 mt-2">Cancelling Subscriptions
                </h5>
                <ol className='my-2'>
                  <li>
                    <p> Subscribers may cancel their subscriptions at any time, subject to the payment of all outstanding fees for any Subscriptions Cycles that have already been redeemed by the subscriber. Please refer to our refund policy.
                    </p>
                  </li>
                  <li>
                    <p>We maintain the right to suspend or cancel any existing subscriptions in the event that:</p>
                    <ol type='a'>
                      <li>There is insufficient enrollment for a class, or the class is cancelled for whatever reason, we may automatically cancel your subscription for the class, and we will refund, prior to the commencement of the cancelled class, the full amount (including the used coupons) to the original form of payment used for your subscription;</li>
                      <li>A class is cancelled by the conducting teacher (including any available classes in a study plan that is conducted by the teacher which you have subscribed for), we will refund the subscription fees already paid for the class that has not commenced;</li>
                      <li>The subscription is automatically cancelled after the end of the last Subscription Cycle (including cancellation due to non-payment);</li>
                      <li>The subscription payment order is unsuccessful (including payments to be made on the Fixed Deduction Time), the subscription will be automatically cancelled.</li>
                    </ol>
                  </li>

                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Modals>
      <TFooter_Dashboard />
    </>
  )
}

