import React, { useState, useEffect } from 'react'
import Select from 'react-select';
// import 'react-select/dist/react-select.css';
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import { Controller, useForm } from "react-hook-form";
import moment from 'moment-timezone';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import TFooter_Dashboard from '../Include/TFooter_Dashboard'
import THeader_Dashboard from '../Include/THeader_Dashboard'
import { buy_subscription, create_payment_link, main_category_list, prefered_teacher, subject_category_list, subscription_detail, subscription_listing, sub_category_request_class, teacherViewProfile, user_subscription_listing } from '../../../api/apiHandler';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import Modals from 'react-modal';
import { async } from '@firebase/util';
import dayjs from 'dayjs';
var COMMON_STYLE_FOR_POPUP = {
  content: {
    background: 'none',
    border: '0px',
  },
}

export default function PreferTeacher({ isLoader }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();

  const [ClassDurationData, setClassDurationData] = useState("30 minutes")

  let schema;

  if (ClassDurationData === "Specify") {
    schema = yup.object().shape({
      // org_name: yup.string().required("Organization name is required"),
      // profile_page: yup.string().required("Profile page is required"),
      subCategoriess: yup.string().required("Please select subcategory"),
      // Categories : yup.string().required("Categories is required"),
      spe_duration: yup.string().required("Please select specific duration") // Adding this validation when ClassDurationData is "Specify"
    });
  } else {
    schema = yup.object().shape({
      // org_name: yup.string().required("Organization name is required"),
      // profile_page: yup.string().required("Profile page is required"),
      subCategoriess: yup.string().required("Please select subcategory"),
      // Categories : yup.string().required("Categories is required"),
    });
  }

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const [selectedDays, setSelectedDays] = useState([{ value: 'Monday', label: 'Monday' }]);
  const [selectedTimes, setSelectedTimes] = useState([{ value: 'O1', label: 'No Preference: Flexible', image: './assets/images/dropdown_icon/time.jpg' },]);

  // Options for the dropdown
  const options = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' }
    // ... add other days here
  ];

  const Timeoptions = [
    { value: 'O1', label: 'No Preference: Flexible', image: './assets/images/dropdown_icon/time.jpg' },
    { value: 'O2', label: 'Morning: 9:00 AM- 12:00 PM', image: './assets/images/dropdown_icon/time.jpg' },
    { value: 'O3', label: 'Afternoon: 12:00 - 4:00 PM', image: './assets/images/dropdown_icon/time.jpg' },
    { value: 'O4', label: 'Evening: 4:00 - 7:00 PM', image: './assets/images/dropdown_icon/time.jpg' },
    { value: 'O5', label: 'Night: 7:00 - 10:00 PM', image: './assets/images/dropdown_icon/time.jpg' },
  ];

  // Handler for when an option is selected or deselected
  const handleSelectChange = (selectedOptions) => {
    setSelectedDays(selectedOptions);
  };

  const handleSelectTimeChange = (selectedOptions) => {
    setSelectedTimes(selectedOptions);
  };


  const [selectedTimezone, setSelectedTimezone] = useState('');
  const timezones = moment.tz.names().map((tz) => ({
    value: tz,
    label: `${tz} (UTC ${moment.tz(tz).format('Z')})`,
  }));

  useEffect(() => {
    // Detect and set the user's timezone as the default value.
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimezone(userTimezone);
  }, []);

  let handleChange = (selectedOption) => {
    setSelectedTimezone(selectedOption.value);
  };

  const ClassDuration = (data) => {
    setClassDurationData(data)
  }

  var [data, setData] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalSubscribe, setIsOpenSubscribe] = useState(false);
  var [SelectedCatID, setSelectedCatID] = useState([]);
  var [SelectedCategory, SetSelectedCategory] = useState([]);
  var [SelectedSubCat, setSelectedSubCat] = useState([]);
  var [SelectedSubCatErr, setSelectedSubCatErr] = useState(null);

  var [SelectedSubCatID, setSelectedSubCatID] = useState([]);
  var [SelectedSubCategory, SetSelectedSubCategory] = useState([]);
  const [teacherDetail, setTeacherDetail] = useState('');
  console.log('teacherDetail :', teacherDetail);


  const OpenCategoryModel = () => {
    isLoader(true)
    main_category_list().then((resposnse) => {
      if (resposnse?.code == 1) {
        isLoader(false)
        setData(resposnse.data)
        setSelectedCatID([resposnse.data[0].category_id])
        SetSelectedCategory([resposnse.data[0].category_name])
        setIsOpen(true)
      } else {
        isLoader(false)
        setData([])
      }
    });
  }

  useEffect(() => {
    OpenCategoryModel();
  }, []);

  const SubCategoryList = () => {
    isLoader(true)
    sub_category_request_class({ category_id: SelectedCatID }).then((resposnse) => {
      if (resposnse?.code == 1) {
        isLoader(false)
        setSelectedSubCat(resposnse.data)
        if (SelectedSubCatID.length > 0) {
          setSelectedSubCatErr('abcd')
          // alert(SelectedSubCatErr)
        }
        // setSelectedSubCatID([resposnse.data[0].subject_category_id])
        // SetSelectedSubCategory([resposnse.data[0].subject_name])
        setIsOpen(true)
      } else {
        isLoader(false)
      }
    });
  }

  useEffect(() => {
    SubCategoryList()
  }, [SelectedCatID]);

  const handleItemClick = (id, cat_name) => {
    if (SelectedCatID != id) {
      setSelectedSubCat([])
      setSelectedSubCatID([])
      SetSelectedSubCategory([])
    }
    setSelectedCatID(id)
    SetSelectedCategory(cat_name)
  }

  const handleItemClick2 = (sub_id, sub_cat_name) => {
    // setSelectedSubCatID(sub_id)
    // SetSelectedSubCategory(sub_cat_name)
    if (SelectedSubCatID.includes(sub_id)) {
      // selectSubject.includes(subjectName)
      let filteredArray = SelectedSubCatID.filter(item => item !== sub_id);
      setSelectedSubCatID(filteredArray)
      let filteredArray1 = SelectedSubCategory.filter(item => item !== sub_cat_name);
      SetSelectedSubCategory(filteredArray1)
    } else {
      setSelectedSubCatID(prevSelectSubject => [...new Set([...prevSelectSubject, sub_id])]);
      SetSelectedSubCategory(prevSelectSubject => [...new Set([...prevSelectSubject, sub_cat_name])]);
    }
  };

  useEffect(() => {
    if (SelectedSubCatID.length > 0) setValue('subCategoriess', "e.value", { shouldValidate: true });
  }, [SelectedSubCatID]);

  const TeacherDetail = async () => {
    try {
      isLoader(true)
      const response = await teacherViewProfile();
      if (response?.code == 1) {
        isLoader(false)
        setTeacherDetail(response?.data)
      } else {
        isLoader(false)
        setTeacherDetail('')
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    TeacherDetail();
  }, [])

  // Handle form submission
  let onSubmit = async (data) => {
    isLoader(true)
    var selectedValues = selectedDays.map(day => day.value).join(',');
    var selectedValuesTimes = selectedTimes.map(day => day.label).join(',');
    var datatimezone = selectedTimezone
    var preferData = {
      org_name: data.org_name,
      profile_link: data.profile_page,
      timezone: selectedTimezone,
      category_list: SelectedCategory,
      subcategory_list: SelectedSubCategory.join(','),
      class_size: data.class_size,
      student_age: data.class_teach_year,
      class_price: data.class_teach_year,
      class_duration: ClassDurationData,
      availability: selectedValues,
      time_preference: selectedValuesTimes,
    }
    if (ClassDurationData == "Specify") {
      preferData.specify_time = (data.spe_duration != undefined ? data.spe_duration : '')
    }
    prefered_teacher(preferData).then((res) => {
      if (res.code == 1) {
        isLoader(false)
        TOAST_SUCCESS(res.message);
        navigate('/new_leads')
      } else {
        isLoader(false)
        TOAST_ERROR(res.message);
      }
    });
  }

  const schemaSubscribe = yup.object().shape({
    // cardNumber: yup.string().required("Card Number is required").matches(/^\d{16}$/, "Card Number must be 16 digits"),
    // expiration: yup.string().required("Expiration date is required").matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, "Expiration date must be in MM/YY format"),
    // cvc: yup.string().required("CVC is required").matches(/^\d{3}$/, "CVC must be 3 digits"),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    clearErrors: clearErrors2,
    reset: reset2,
    watch: watch2,
    control: control2,
    getValues: getValues2,
    formState: { errors: errors2 },
  } = useForm({
    resolver: yupResolver(schemaSubscribe), // Pass the schema2 resolver
  });

  const onSubmit2 = async (data) => {
    try {
      let sendData = {
        subscription_id: monthYearPrice,
        // amount: price,
        // card_number: data.cardNumber.slice(-4),
        // exp_month: data?.expiration?.split('/')[0],
        // exp_year: data?.expiration?.split('/')[1],
      };
      if (selectedPlan == '1') {
        sendData.type = subType;
        // sendData.amount = monthYearPrice?.monthly_price
      } else {
        sendData.type = subType;
        // sendData.amount = price
      }
      isLoader(true)
      const response = await create_payment_link(sendData);
      if (response?.code == 1) {
        isLoader(false)
        window.open(response?.data?.session, '_blank')
        // TOAST_SUCCESS(response?.message)
        SubData();
        reset2();
        setIsOpenSubscribe(false)
      } else {
        isLoader(false)
        TOAST_ERROR(response?.message)
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const [subData, setSubData] = useState([]);
  console.log('subData :', subData);

  const SubData = async () => {
    try {
      isLoader(true)
      const response = await user_subscription_listing();
      if (response.code == 1) {
        isLoader(false)  
        setSubData(response.data[0])
      } else {
        isLoader(false)           
        setSubData([])
      }
    } catch (error) {
      console.log('error :', error);
    }
  }

  useEffect(() => {
    SubData();
  }, [])

  // const [selectedPlan, setSelectedPlan] = useState('1');
  // const [price, setPrice] = useState('');
  // const [monthYearPrice, setmonthYearPrice] = useState('1');
  // console.log('monthYearPrice :', monthYearPrice);
  // const [subType, setSubType] = useState('monthly');
  // const [planData, setPlanData] = useState([]);
  // console.log('planData :', planData);

  // const handleSelectedPackage = (data) => {
  //   console.log('data123 :', data);
  //   var main = JSON.parse(data);
  //   console.log('main :', main);
  //   setmonthYearPrice(main)
  //   PlanDetails();
  // }

  // const handleSelectedPlan = async (data) => {
  //   setSelectedPlan(data)
  //   if (data == '1') {
  //     setSubType("monthly")
  //     PlanDetails();
  //   } else if (data == '2') {
  //     setSubType("annually")
  //     PlanDetails();
  //   }
  // }

  // const Plan = async () => {
  //   try {
  //     const response = await subscription_listing();
  //     console.log('subscription_listing :', response?.data);
  //     if (response?.code == 1) {

  //       setPlanData(response?.data)

  //       setmonthYearPrice(response?.data[0]?.id)


  //       PlanDetails();
  //     } else {
  //       setPlanData([])
  //     }
  //   } catch (error) {

  //   }
  // }

  // const PlanDetails = async () => {
  //   try {
  //     let sendData = {
  //       subscription_id : monthYearPrice,
  //       type : subType
  //     }
  //     console.log('sendData :', sendData);

  //     const response = await subscription_detail(sendData);
  //     console.log('subscription_detail :', response?.data);
  //     if (response?.code == 1) {
  //       setPrice(response?.data[0]?.price)
  //     } else {
  //       setPrice([])
  //     }
  //   } catch (error) {

  //   } 
  // }
  // const handleExpirationInput = (e) => {
  //   const { value } = e.target;
  //   if (/^\d{2}$/.test(value)) {
  //     e.target.value = `${value}/`;
  //   }
  // };

  const [selectedPlan, setSelectedPlan] = useState('1');
  const [price, setPrice] = useState('');
  const [monthYearPrice, setMonthYearPrice] = useState('1');
  const [subType, setSubType] = useState('monthly');
  const [planData, setPlanData] = useState([]);

  useEffect(() => {
    Plan();
  }, []);

  useEffect(() => {
    if (monthYearPrice && subType) {
      PlanDetails();
    }
  }, [monthYearPrice, subType]);

  const handleSelectedPackage = (data) => {
    setMonthYearPrice(data);
  };

  const handleSelectedPlan = (data) => {
    setSelectedPlan(data);
    setSubType(data === '1' ? 'monthly' : 'annually');
  };

  const Plan = async () => {
    try {
      isLoader(true)
      const response = await subscription_listing();
      console.log('subscription_listing :', response);
      if (response?.code == 1) {
        isLoader(false)
        setPlanData(response?.data);
        setMonthYearPrice(response?.data[0]?.id);
      } else {
        isLoader(false)
        setPlanData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const PlanDetails = async () => {
    try {
      const sendData = {
        subscription_id: monthYearPrice,
        type: subType,
      };
      isLoader(true)
      const response = await subscription_detail(sendData);
      if (response?.code == 1) {
        isLoader(false)
        setPrice(response?.data[0]?.price);
      } else {
        isLoader(false)
        setPrice('');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <JqueryLoad />
      <THeader_Dashboard />
      {/*
      <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js"></script>
        <style>{`
                  .css-3w2yfm-ValueContainer {
                    color: #999;
                }
                
                .css-1p3m7a8-multiValue {
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                    min-width: 0;
                    background-color: #570861;
                    border-radius: 2px;
                    margin: 2px;
                    box-sizing: border-box;
                }

                .css-wsp0cs-MultiValueGeneric {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  border-radius: 2px;
                  color: hsl(0deg 0% 96.28%);
                  font-size: 85%;
                  padding: 3px;
                  padding-left: 6px;
                  box-sizing: border-box;
              }

              .css-12a83d4-MultiValueRemove:hover {
                background-color: #f1f1f1;
                color: #333;

                .for_cate_modal .popular_item ul li {
                  margin-right: 20px;
                  margin-bottom: 20px;
                  flex: 1 !important;
              }
              
            }

            .css-olqui2-singleValue {
              grid-area: 1/1/2/3;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: hsl(0deg 0% 6.3%);
              margin-left: 2px;
              margin-right: 2px;
              box-sizing: border-box;
          }
          .css-1vx9cxd-control {
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            cursor: default;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            min-height: 38px;
            outline: 0!important;
            position: relative;
            -webkit-transition: all 100ms;
            transition: all 100ms;
            border-color: hsl(0, 0%, 90%);
            border-radius: 12px;
            border-style: solid;
            border-width: 1px;
            box-sizing: border-box;
            border: 1px solid rgba(102, 102, 102, 0.35);
            padding: 5px 10px;
            height: 46px;
            background-color: #e9ecef;
          }
            
        `}</style>
      </Helmet> 
      */}
      <div>
        <main className="dashboard-app">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            {/*start*/}
            <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
              <span>
                <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                </svg>
                <span className="ms-2">Back</span>
              </span></a>
            {/*end*/}
            {subData?.days_left != 0 && subData != '' ? <>

              <p className="subscription-custom-font mt-4">{`Your ${subData?.name} plan is active until ${dayjs(subData?.end_date).format('MMMM D, YYYY')}. You have ${subData?.days_left} days left.`}</p></> :
              <div className="button w-100">
                <a className="btn-theme px-xl-4 px-3 bg-yellow d-inline-block" onClick={(e) => { setIsOpenSubscribe(true) }}>Subscribe now</a>
              </div>
            }
            <section className="requirement_form mt-4">
              <div className="text-center mb-4">
                <h2 className="fw-600 poppins text-capitalize text-purple">Be listed as a Preferred Teacher</h2>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-8">
                  <div className="card border-purple p-4">
                    <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                      <div className="form-group mb-4">
                        <label className="mb-2">Teacher’s Name:</label>
                        <input type="text" {...register("org_name")} className="form-control" name="org_name" value={teacherDetail?.full_name} readOnly />

                        {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.org_name?.message}</span></p> */}
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Link To Your Profile Page:</label>
                        <input type="text" {...register("profile_page")} className="form-control" name="profile_page" value={"http://13.234.122.246/studentscholar/my_class_details"} readOnly />
                        {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.profile_page?.message}</span></p> */}
                      </div>
                      <div className="form-group mb-4">
                        {/*automatically comeup*/}
                        <label className="mb-2">Time Zone:</label>
                        <Select
                          options={timezones}
                          value={timezones.find((tz) => tz.value === selectedTimezone)}
                          onChange={handleChange}
                          isSearchable={true}
                          placeholder="Search for a timezone..."
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              border: '1px solid rgba(102, 102, 102, 0.35)',
                              borderRadius: '12px',
                              padding: '5px 10px', // Adjust padding to make the component smaller
                              height: '46px', // Set the height of the component
                            }),
                            indicatorsContainer: (provided) => ({
                              ...provided,
                              display: 'none', // This hides the dropdown arrow
                            }),
                          }}
                          isDisabled />
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Categories of Programs you teach</label>
                        <input className="form-select" name="Categories" onClick={() => OpenCategoryModel()} data-bs-toggle="modal" data-bs-target="#select_learner_like" value={SelectedCategory} aria-label="Default select example" />

                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Subjects of Programs you teach</label>
                        <input className="form-select" name="subCategories" autocomplete="off" onClick={() => SubCategoryList()} data-bs-toggle="modal" data-bs-target="#select_subcategories" value={SelectedSubCategory.join(',')} aria-label="Default select example" />
                        <input type="hidden" name='subCategoriess' autocomplete="off" {...register("subCategoriess")} />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.subCategoriess?.message}</span></p>

                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Age of the learners you teach</label>
                        <select className="form-select" {...register("class_teach_year")} aria-label="Default select example">
                          <option value="3 Years" selected>3 Years</option>
                          <option value="4 Years">4 Years</option>
                          <option value="5 Years">5 Years</option>
                          <option value="6 Years">6 Years</option>
                          <option value="7 Years">7 Years</option>
                          <option value="8 Years">8 Years</option>
                          <option value="9 Years">9 Years</option>
                          <option value="10 Years">10 Years</option>
                          <option value="11 Years">11 Years</option>
                          <option value="12 Years">12 Years</option>
                          <option value="13 Years">13 Years</option>
                          <option value="14 Years">14 Years</option>
                          <option value="15 Years">15 Years</option>
                          <option value="16 Years">16 Years</option>
                          <option value="17 Years">17 Years</option>
                          <option value="18 Years">18 Years</option>
                          <option value="Adults">Adults</option>

                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">What are your class prices? In USD</label>
                        <select className="form-select" {...register("class_price")} aria-label="Default select example">
                          <option value="Under $25" selected>Under $25</option>
                          <option value="Under $25-$50">Under $25-$50</option>
                          <option value="Under $50-75">Under $50-75</option>
                          <option value="Under $75-$100">Under $75-$100</option>
                          <option value="Over $100+">Over $100+</option>
                          <option value="Does not matter">Does not matter</option>

                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Class duration:</label>
                        <select className="form-select" {...register("class_duration")} onChange={(e) => { ClassDuration(e.target.value) }} aria-label="Default select example">
                          <option value="30 minutes">30 minutes</option>
                          <option value="45 minutes">45 minutes</option>
                          <option value="1 hour">1 hour</option>
                          <option value="2 hours">2 hours</option>
                          <option value="Does not matter">Does not matter</option>
                          <option value="Specify">Specify</option>
                        </select>
                      </div>
                      {ClassDurationData == "Specify" ?
                        <>
                          <div className="form-group mb-4">
                            <label className="mb-2">Add duration according to you </label>
                            <input type="text" className="form-control" {...register("spe_duration")} name="spe_duration" placeholder />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.spe_duration?.message}</span></p>
                          </div>
                        </> : <></>

                      }
                      <div className="form-group mb-4">
                        <label className="mb-2">class Size</label>
                        <select className="form-select" {...register("class_size")} aria-label="Default select example">
                          <option value="Individual/private" selected>Individual/private</option>
                          <option value="Group">Group</option>

                        </select>
                      </div>

                      <div className="form-group mb-4">
                        <label className="mb-2">What is your availability?</label>
                        <Select
                          isMulti
                          name="days"
                          options={options}
                          value={selectedDays} // Set the selected values
                          onChange={handleSelectChange} // Update the selected values
                        />
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">What Is Your Time Preference?</label>
                        <Select
                          isMulti
                          value={selectedTimes}
                          onChange={handleSelectTimeChange}
                          options={Timeoptions}
                          getOptionLabel={(option) => (
                            <div>
                              <img src={option.image} alt="" width="24" height="24" className="rounded-circle h-20px me-2" />
                              {option.label}
                            </div>
                          )}
                          placeholder="Select time preferences"
                        />
                      </div>
                      {/* <div className="button mt-5">
                        <Link to="/teacher_dashboard" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">A learner can contact me</Link>
                      </div> */}
                      <div className="button d-flex justify-content-center">
                        {/* <Link to="/tech_detail" className="btn-theme bg-yellow d-inline-block fw-600">Create Homework </Link> */}
                        <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                          A learner can contact me
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <div class="col-lg-6 order-lg-last order-first">
        			<div class="">
        				<img src="./assets/images/request_a_class.png" alt="request_a_class">
        			</div>
        		</div> */}
              </div>
            </section>
          </div>
        </main>
        {/* Modal */}
        <div className="modal fade for_cate_modal" id="select_learner_like" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Select Your Category</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setIsOpen(false)} aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="popular_item">
                  <ul>
                    {data?.map((mainCat, index) => {
                      return (
                        <li key={index} > {/* Add onClick handler to <li> */}
                          <a className="d-flex align-items-center" onClick={(e) => handleItemClick(mainCat.category_id, mainCat.category_name)}> {/* Add onClick handler to <a> */}
                            <div className={`topi_icon ${SelectedCatID == mainCat.category_id ? 'active' : ''}`}>
                              <img src={mainCat.main_category_image} alt="topic9" />
                            </div>
                            <span className="ms-3">{mainCat.category_name}</span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <div className="col-lg-4 col-12">
                  <button type="button" className="btn-theme w-100 bg-yellow fw-600" onClick={() => setIsOpen(false)} data-bs-dismiss="modal">Done</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end*/}
        {/*start*/}
        {/* Modal */}
        <div className="modal fade for_cate_modal" id="select_subcategories" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Select Your Subjects</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="popular_item">
                  {SelectedSubCat[0]?.category_name ? (
                    <>
                      {SelectedSubCat?.map((SubCat, index) => {
                        return (
                          <>
                            <h4 className="fs-6 mb-4">{SubCat.category_name}</h4>
                            <ul style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', padding: 0, margin: 0, listStyle: 'none' }}>
                              {SubCat.category?.map((category, index1) => {
                                return (
                                  <li key={index1} style={{ width: 'calc(20% - 20px)', textAlign: 'center', boxSizing: 'border-box' }}>
                                    <a className="d-flex align-items-center" style={{ flexDirection: 'column', justifyContent: 'center' }} onClick={() => handleItemClick2(category.subject_category_id, category.subject_name)}>
                                      <div className={`topi_icon ${SelectedSubCatID.includes(category.subject_category_id) ? 'active' : ''}`} style={{ width: '60px', height: '60px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={category.subject_category_image} alt={category.subject_name} />
                                      </div>
                                      <span className="ms-3" style={{ marginTop: '10px' }}>{category.subject_name}</span>
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <ul style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', padding: 0, margin: 0, listStyle: 'none' }}>
                        {SelectedSubCat?.map((SubCat, index) => {
                          return (
                            <li key={index} style={{ width: 'calc(20% - 20px)', textAlign: 'center', boxSizing: 'border-box' }}>
                              <a className="d-flex align-items-center" style={{ flexDirection: 'column', justifyContent: 'center' }} onClick={() => handleItemClick2(SubCat.subject_category_id, SubCat.subject_name)}>
                                <div className={`topi_icon ${SelectedSubCatID.includes(SubCat.subject_category_id) ? 'active' : ''}`} style={{ width: '60px', height: '60px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <img src={SubCat.subject_category_image} alt={SubCat.subject_name} />
                                </div>
                                <span className="ms-3" style={{ marginTop: '10px' }}>{SubCat.subject_name}</span>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <div className="col-lg-4 col-12">
                  <button type="button" className="btn-theme w-100 bg-yellow fw-600" data-bs-dismiss="modal">Done</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modals isOpen={modalSubscribe} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 pb-0">
              <h5 className="modal-title" id="exampleModalLabel" />
              <button type="button" className="btn-close" onClick={() => {
                setIsOpenSubscribe(false);
                setSelectedPlan("1");
                reset2();
              }} />
            </div>
            <div className="modal-body">
              <h3 className='text-capitalize text-purple' style={{ textAlign: 'center' }}>Subscription Plan</h3>
              <div className="card_details">
                <form className="custom_form" onSubmit={handleSubmit2(onSubmit2)}>
                  <div className="form-group mb-4">
                    <select className="form-select" onChange={(e) => handleSelectedPackage(e.target.value)}>
                      {planData?.map((Package, index) => (
                        <option key={index} value={Package?.id}>{Package?.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-4">
                    {monthYearPrice == 1 ? (
                      <h6 className='font-bold text-purple'>Select up to 3 subjects from any category. You can also add multiple categories by filling out the form for each category. </h6>
                    ) : monthYearPrice == 2 ? (
                      <h6 className='font-bold text-purple'>Select up to 6 subjects from any category. You can also add multiple categories by filling out the form for each category.   </h6>
                    ) : monthYearPrice == 3 ? (
                      <h6 className='font-bold text-purple'>Select up to 9 subjects from any category. You can also add multiple categories by filling out the form for each category. </h6>
                    ) : null}
                  </div>

                  <h6></h6>
                  <div className="form-group mb-4">
                    <select
                      onChange={(e) => handleSelectedPlan(e.target.value)}
                      name="learner_name"
                      className="form-select"
                      aria-label="Default select example"
                      value={selectedPlan}
                    >
                      <option value="1">Monthly</option>
                      <option value="2">Annually</option>
                    </select>
                  </div>
                  {/* <div className="form-group mb-4">
                    <label className="mb-2">Card Number</label>
                    <input
                      type="text"
                      {...register2("cardNumber")}
                      className={`form-control ${errors2.cardNumber ? 'is-invalid' : ''}`}
                      name="cardNumber"
                      maxLength={16}
                      placeholder="Enter card number"
                    />
                    <p className='mt-2 font-bold col-red'>  
                      <span style={{ color: 'red' }}>{errors2.cardNumber?.message}</span>
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mb-4">
                        <label className="mb-2">Expiration</label>
                        <input
                          type="text"
                          {...register2("expiration")}
                          className={`form-control ${errors2.expiration ? 'is-invalid' : ''}`}
                          name="expiration"
                          placeholder="MM/YY"
                        />
                        <p className='mt-2 font-bold col-red'>
                          <span style={{ color: 'red' }}>{errors2.expiration?.message}</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-4">
                        <label className="mb-2">CVC</label>
                        <input
                          type="text"
                          {...register2("cvc")}
                          className={`form-control ${errors2.cvc ? 'is-invalid' : ''}`}
                          name="cvc"
                          maxLength={4}
                          placeholder="CVC"
                        />
                        <p className='mt-2 font-bold col-red'>
                          <span style={{ color: 'red' }}>{errors2.cvc?.message}</span>
                        </p>
                      </div>
                    </div>
                  </div> */}

                  <div className='row'>
                    <div className='col-6'>
                      <button type="submit" className="btn-theme bg-yellow text-center d-block col-12">Pay CA ${price} CAD</button>
                      {/* <button type="submit" className="btn-theme bg-yellow text-center d-block col-12">Continue To Subscribe</button> */}
                    </div>
                    <div className='col-6'>
                      <button className="btn-theme bg-yellow text-center d-block col-12" onClick={() => {
                        setIsOpenSubscribe(false);
                        setSelectedPlan("1");
                        reset2();
                      }}>Cancel</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </Modals>
      <TFooter_Dashboard />
    </>
  )
}
