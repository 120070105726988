import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import PFooter_Dashboard from "../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../Pinc/PHeader_Dashboard";
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css";
import dayjs from "dayjs";
import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";
import { Link, useLocation, useNavigate } from "react-router-dom";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import {
  my_statiticks,
  interested_class,
  latest_news,
  calendar_data,
  learner_list_parents,
  teacher_schedule_calender,
  scheduled_date_list,
  loginApi,
  parent_data,
  contact_us_details,
  after_school_class_list,
} from "../../../api/apiHandler";
import { loginRedirectCallWithDataStore } from "../../Common/RedirectPathMange";
import CommonClassSubject from "../../Home/commonClassSubject";
import $ from 'jquery';
import { encryptURL } from "../../../api/apiClient";

export default function ParentDashboard({ isLoader }) {
  const navigate = useNavigate();
  const path = useLocation();

  const [showAllClasses, setShowAllClasses] = useState(false);
  const [classList, setclassList] = useState([])
  const [selectedLearner, setSelectedLearner] = useState("");
  const [selectedLearnerSchedule, setSelectedLearnerSchedule] = useState("");

  var [LearnerList, setLearnerList] = useState([]);
  var [MyStatiticks, setMyStatiticks] = useState([]);
  var [userData, setUserData] = useState([]);
  const [switchProfile, setSwitchProfile] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState("");

  // console.log(data1,"17")
  var [data2, setData2] = useState([]);
  var [newsData, setNewsData] = useState([]);
  var [calender, setcalender] = useState([]);
  console.log("🚀 ~ ParentDashboard ~ calender:", calender);
  console.log("🚀 ~ ParentDashboard ~ newsData:", newsData);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [scheduledDateList, setScheduledDateList] = useState([]);
  const currentDate = dayjs();
  const defaultValue = {
    year: currentDate.year(),
    month: currentDate.month() + 1,
    day: currentDate.date(),
  };
  const [selectedDay, setSelectedDay] = useState(defaultValue);

  const userType = localStorage.getItem("userType");

  const isparent = localStorage.getItem("isparent")

  const getUserData = () => {
    console.log('test');
    isLoader(true)
    contact_us_details().then((response) => {
      console.log('user_data', response);
      if (response?.code === "1") {
        setUserData(response?.data);
        isLoader(false)
      } else {
        isLoader(false)
        console.log("Data Not Found");
      }
    });
  }

  const getClassData = () => {
    after_school_class_list().then((response) => {
      console.log('classData', response);
      if (response?.code === "1") {
        setclassList(response?.data);
      } else {
        console.log("Data Not Found");
      }
    });
  }



  // const enrolledClassValue = statistics[0]?.['classes Enrolled'][0]?.Enrolled_class;
  // const enrolledClassValue = statistics[0]?.['classes Enrolled'][0]?.Enrolled_class ?? 0;
  // const enrolledClubValue = statistics[0]?.['club Enrolled'][0]?.Enrolled_class;
  // const enrolledQuizesValue = statistics[0]?.['Quizes Enrolled'][0]?.Enrolled_class;
  // const enrolledCompetitionValue = statistics[0]?.['Competition Enrolled'][0]?.Enrolled_class;

  const handleFilterChange = (event) => {
    const filterValue = event.target.value;
    setSelectedFilter(filterValue);
  };

  useEffect(() => {
    getUserData();
    interested_class_details();
    latest_news_details();
    getClassData();
  }, [selectedLearner, selectedClassId]);


  useEffect(() => {
    learner_list_parents_details();
    setSwitchProfile(false);
  }, [switchProfile, selectedClassId]);


  const handleSelectChange = (e) => {
    const selectedLearnerId = e.target.value;
    setSelectedLearner(selectedLearnerId);
  };

  useEffect(() => {
    calender_details();
  }, [selectedFilter, selectedLearnerSchedule, selectedDay]);

  useEffect(() => {
    getScheduledDates();
  }, [selectedLearnerSchedule]);

  const my_statiticks_details = () => {
    my_statiticks({ learners_id: selectedLearner }).then((response) => {
      if (response?.code === "1") {
        setMyStatiticks(response?.data);
      } else {
        console.log("Data Not Found");
      }
    });
  };

  useEffect(() => {
    // Fetch initial data when the component mounts or when the selected learner changes
    if (selectedLearner) {
      my_statiticks_details();
    }
  }, [selectedLearner]);

  const learner_list_parents_details = () => {
    if (userType == "learner") {
      console.log('got to parent');
      parent_data().then((resposnse) => {
        if (resposnse?.code == 1) {
          console.log('resposnse', resposnse);
          setLearnerList(resposnse?.data);
          getUserData()
        } else {
          console.log("Data Not Found");
        }
      });
    } else {
      console.log('got to learner');

      learner_list_parents({ class_id: selectedClassId }).then((resposnse) => {
        console.log('resposnse', resposnse);
        if (resposnse?.code == 1) {
          setLearnerList(resposnse?.data);
          getUserData()
        } else {
          console.log("Data Not Found");
        }
      });
    }

  };
  console.log('iddd', selectedClassId);
  const interested_class_details = () => {
    const data = {}



    if (selectedLearner !== null && selectedLearner !== undefined) {
      data.learnerId = selectedLearner
    }

    if (selectedClassId !== null && selectedClassId !== undefined) {
      data.class_id = selectedClassId
    }
    console.log('data321 :', data);

    interested_class(data).then((resposnse) => {

      console.log('resposnse1 :', resposnse);
      if (resposnse?.code == 1) {
        setData2(resposnse?.data);
      } else {
        console.log("Data Not Found");
      }
    });
  };

  const latest_news_details = () => {
    latest_news().then((resposnse) => {
      if (resposnse?.code == 1) {
        setNewsData(resposnse?.data);
      } else {
        console.log("Data Not Found");
      }
    });
  };

  const handleCalendarSelect = (day) => {
    setSelectedDay(day);
  };

  const calender_details = () => {
    let body = {};
    if (selectedLearnerSchedule) body.learnerId = selectedLearnerSchedule;
    if (selectedFilter) body.class = selectedFilter;
    if (selectedDay) body.custom_date = `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`;

    calendar_data(body).then((resposnse) => {
      console.log(resposnse, 'calender');
      if (resposnse?.code == 1) {
        setcalender(resposnse?.data);
      } else {
        setcalender(resposnse?.data);
      }
    });
  };

  const getScheduledDates = () => {
    let body = {};
    if (selectedLearnerSchedule) body.learner_id = selectedLearnerSchedule;
    scheduled_date_list(body).then((resposnse) => {
      if (resposnse?.code == 1) {
        const scheduledDates = [];
        for (let item of resposnse?.data) {
          if (item?.date) {
            let tempDate = item?.date?.split("-");
            scheduledDates.push({ year: +tempDate[0], month: +tempDate[1], day: +tempDate[2], className: "highlight-date" });
          }
        }
        setScheduledDateList(scheduledDates);
      } else {
        setScheduledDateList([]);
      }
    });
  };

  let inter_slider = {
    dots: false,
    arrows: false,
    infinite: true,
    // autoplay:true,
    // autoplaySpeed:3000,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var class_news = {
    slidesToShow: 4,
    slidesToScroll: 3,
    autoplay: false,
    // infinite: true,
    // autoplaySpeed: 2000,
    adaptiveHeight: false,
    // arrows:true,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleLogin = (data) => {
    console.log('dataaaa', data);
    isLoader(true)
    loginApi({ email: data.learner_email || data.email, password: data.learner_password || data.password, login_type: "S" })
      .then((resposnse) => {
        console.log('resposnse', resposnse);
        loginRedirectCallWithDataStore(resposnse?.data);
        localStorage.setItem("isparent", 1)
        learner_list_parents_details()

        navigate("/dashboard");
        setSwitchProfile(true);

        my_statiticks_details();

        isLoader(false)

      })
      .catch((err) => console.log("🚀 ~ loginApi ~ err:", err));
  }

  const showLearnerList = LearnerList.map((data, index) => {
    return (
      <option key={index} value={data.id}>
        {data.learner_name}
      </option>
    );
  });

  const showClassList = classList.map((data, index) => {
    return (
      <option key={index} value={data.id}>
        {data.class_name}
      </option>
    );
  });

  const showLearnerList1 = LearnerList.map((data, index) => {
    return (
      <li key={index}>
        <a
          className="dropdown-item"

          onClick={() => handleLogin(data)}
        >
          <img src={data.learner_image ? data.learner_image : "./assets/images/kids-profile-switch.png"} alt={data.learner_name || data.full_name} />
          <span className="ps-2">{data.learner_name || "Back To Parent"} </span>
        </a>
      </li>
    );
  });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // useEffect(() => {
  //   if (MyStatiticks?.length > 0) {
  //     $(function () {
  //       $('.chart')?.easyPieChart({
  //         size: 100,
  //         barColor: "#0833C9",
  //         scaleLength: 0,
  //         lineWidth: 10,
  //         trackColor: "#fff",
  //         lineCap: "circle",
  //         animate: 2000,
  //       });

  //       $('.chart1')?.easyPieChart({
  //         size: 100,
  //         barColor: "#FF24F6",
  //         scaleLength: 0,
  //         lineWidth: 10,
  //         trackColor: "#fff",
  //         lineCap: "circle",
  //         animate: 2000,
  //       });

  //       $('.chart2')?.easyPieChart({
  //         size: 100,
  //         barColor: "#3CDCBF",
  //         scaleLength: 0,
  //         lineWidth: 10,
  //         trackColor: "#fff",
  //         lineCap: "circle",
  //         animate: 2000,
  //       });

  //       $('.chart3')?.easyPieChart({
  //         size: 100,
  //         barColor: "#FFC924",
  //         scaleLength: 0,
  //         lineWidth: 10,
  //         trackColor: "#fff",
  //         lineCap: "circle",
  //         animate: 2000,
  //       });
  //     });

  //   }
  // }, [MyStatiticks]);

  const generatePath = (data) => {
    var classType = data?.classes_type
    const basePath = classType === "club"
      ? "/my_courses_details_clubs"
      : classType === "quiz"
        ? "/quiz-info"
        : classType === "competition"
          ? "/my_courses_details_competitions"
          : "/my_courses_detail";

    const queryParams = new URLSearchParams({
      class_id: data?.class_id,
      teacher_id: data?.teacher_id,
      id: data?.class_id,
      type: data?.classes_type,
      learner_data: JSON.stringify({
        id: data?.learner_id,
        learner_age: data?.learner_age,
        learner_name: data?.learner_name,
        learner_profile_img: data?.learner_profile_img,
      }
      ),
      class_type: data?.type
    }).toString();

    const encryptedURL = encryptURL(`${basePath}?${queryParams}`);
    return encryptedURL;
  };

  return (
    <>
      <JqueryLoad />
      <PHeader_Dashboard isLoader={isLoader}/>
      <Helmet>
        {/* <script src="../assets/js/script.js"></script>   */}
        <style>
          {`
        .drop_calendar.filter_box {
          min-width: 523px;
        }
        .Calendar__weekDays {
          color: #570861;
        }
        .Calendar__day.-selected,
        .Calendar__day.-selectedStart,
        .Calendar__day.-selectedEnd {
          background: #ffc924;
          color: #212529;
        }
        .Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
          color: #212529;
        }
        .Calendar__day.-selectedBetween {
          background: rgba(241, 241, 241, 0.65);
          color: #060706;
          border-radius: 0;
        }
        .scrollable-content {
          max-height: 200px;
          overflow-y: auto;
        }
        
        .scrollable-content::-webkit-scrollbar {
          width: 5px;
        }
        
        .scrollable-content::-webkit-scrollbar-thumb {
          background-color: #570861;
          border-radius: 10px;
        }
        
        .scrollable-content::-webkit-scrollbar-track {
          background-color: #f0f0f0;
        }
        
        .off_box.card .off_bg_img img.off_user {
          position: absolute;
          bottom: -22px;
          left: 20px;
          width: 65px;
          height: 65px;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }
        .learn_stati .card {
          height: 150px; /* Adjust as needed */
        }
        
        .chart, .chart1, .chart2, .chart3 {
          width: 100px !important;
          height: 100px !important;
        }
        
        
        `}
        </style>
      </Helmet>
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
              {userType !== "learner" && userType !== "above_18" && (
                <div className="d-lg-none d-lg-block">
                  <div className="dropdown switch-profile">
                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Switch Profile
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item">Switch To : </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <img src="./assets/images/kids-profile-demo.png" alt="kids-profile-demo" />
                          <span className="ps-2">Celina Mark</span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <img src="./assets/images/kids-profile-switch.png" alt="kids-profile-switch" />
                          <span className="ps-2">Josheph Celina</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-between w-100 mt-lg-0 mt-4">
              <div>
                <h3 className="d-block text-purple poppins fw-500">Welcome, {userData[0]?.user_name ?? userData[0]?.learner_name}</h3>
                <div id="greetings" />
              </div>
              {!(userType === "learner" && isparent !== "1") && userType !== "above_18" && (
                <div className="d-lg-block d-none">
                  <div className="dropdown switch-profile">
                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Switch Profile
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item">Switch To:</a>
                      </li>
                      {showLearnerList1}
                    </ul>
                  </div>
                </div>
              )}

            </div>
          </header>
          <div className="dashboard-content">
            <div className="container-fluid">
              <div className="row dashboard_part">
                <div className="col-xl-9 right">
                  {/*start-video-section*/}
                  <div className="video_section video_boxs_sec position-relative dash_video border-0 p-0">
                    <div className="video_box">
                      <video id="video" loop height={564} controls="controls" poster="./assets/images/video_bg_dash.png">
                        <source src={'https://studentscholars.s3.amazonaws.com/video/Welcome Parents & Guardians.mp4'} type="video/mp4" />
                      </video>
                    </div>
                    {/* <div className="cancel_btn position-absolute">
                      <a href="javascript:void(0)" className="colse_div_video">
                        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" height={25} viewBox="0 -960 960 960" width={25}>
                          <path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
                        </svg>
                      </a>
                    </div> */}
                  </div>
                  {(userType === "below_18" || userType === "after_school") && (
                    <div className="learn_stati">
                      <div className="d-flex mt-md-0 mt-4 justify-content-between">
                        <div className="title mb-4">
                          <h3>My Statistics</h3>
                        </div>
                        <div>
                          {userType === "after_school" && (
                            <select
                              className="border-purple bg-purple text-white fs-6 rounded-cricle rounded-pill px-2 py-2 mx-2"
                              onChange={(e) => setSelectedClassId(e.target.value)}
                              // value={selectedLearnerSchedule}
                              defaultValue={""}
                            >
                              <option value={""}>Class Name</option>
                              {showClassList}
                            </select>
                          )}
                          <select
                            className="bg-purple text-white border-purple fs-6 rounded-circle rounded-pill ms-sm-3 px-2 py-2"
                            value={selectedLearner}
                            onChange={handleSelectChange}
                          // onMouseEnter={resetdata}
                          >
                            <option value="">Select a learner</option>
                            {showLearnerList}
                          </select>


                        </div>
                      </div>



                      <div className="row">
                        <div className="col-lg-6 mb-4">
                          <div className="card">
                            <div className="d-sm-flex align-items-center">
                              <div
                                className=" mb-sm-0 mb-3 position-relative"
                                data-percent={MyStatiticks[0]?.["classes Enrolled"][0]?.Enrolled_class}
                                data-scale-color="#ffb400"
                              >
                                <div className="circle_inner">
                                  <span className="percent_text">{MyStatiticks[0]?.["classes Enrolled"][0]?.Enrolled_class ?? 0}</span>
                                </div>
                              </div>
                              <div className="lear_stati_info ps-4">
                                <h3 className="poppins fw_500 text-capitalize" style={{ fontSize: "larger" }}>
                                  Number of enrolled classes
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 mb-4">
                          <div className="card">
                            <div className="d-sm-flex align-items-center">
                              <div
                                className=" mb-sm-0 mb-3 position-relative"
                                data-percent={MyStatiticks[1]?.["club Enrolled"][0]?.Enrolled_clubs}
                                data-scale-color="#FF24F6"
                              >
                                <div className="circle_inner">
                                  <span className="percent_text">{MyStatiticks[1]?.["club Enrolled"][0]?.Enrolled_clubs ?? 0}</span>
                                </div>
                              </div>
                              <div className="lear_stati_info ps-4">
                                <h3 className="poppins fw_500 text-capitalize" style={{ fontSize: "larger" }}>
                                  Number of enrolled clubs
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 mb-4">
                          <div className="card">
                            <div className="d-sm-flex align-items-center">
                              <div
                                className=" mb-sm-0 mb-3 position-relative"
                                data-percent={MyStatiticks[2]?.["Quizzes Enrolled"][0]?.Enrolled_quiz}
                                data-scale-color="#ffb400"
                              >
                                <div className="circle_inner">
                                  <span className="percent_text">{MyStatiticks[2]?.["Quizzes Enrolled"][0]?.Enrolled_quiz ?? 0}</span>
                                </div>
                              </div>
                              <div className="lear_stati_info ps-4">
                                <h3 className="poppins fw_500 text-capitalize" style={{ fontSize: "larger" }}>
                                  Number of Completed Quizzes
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="card">
                            <div className="d-sm-flex align-items-center">
                              <div
                                className=" mb-sm-0 mb-3 position-relative"
                                data-percent={MyStatiticks[3]?.["Competitions Enrolled"][0]?.Enrolled_competition}
                                data-scale-color="#ffb400"
                              >
                                <div className="circle_inner">
                                  <span className="percent_text">{MyStatiticks[3]?.["Competitions Enrolled"][0]?.Enrolled_competition ?? 0}</span>
                                </div>
                              </div>
                              <div className="lear_stati_info ps-4">
                                <h3 className="poppins fw_500 text-capitalize" style={{ fontSize: "larger" }}>
                                  Number of Completed Competitions
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {userType !== "learner" && (
                    <div className="find-cls-teacher bg-purple border-radius-10 mb-md-5 mb-4">
                      <div className="container">
                        <div className="row align-items-center">
                          <div className="col-lg-7 mb-lg-0 mb-5">
                            <h2 className="fw-bold text-white mb-4">Need help with finding a class or teacher?</h2>
                            <p className="fw-bold text-white mb-3 text-capitalize">Teachers that match your requirements will contact you.</p>
                            <Link to="/request_class_form" className="btn-theme bg-yellow d-inline-block">
                              Request a class
                            </Link>
                          </div>
                          <div className="col-lg-5 right">
                            <div className="video_boxs position-relative">
                              <video id="video" loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                                <source src={'https://studentscholars.s3.amazonaws.com/video/Find a teacher.mp4'} type="video/mp4" />
                              </video>
                              <div className="height_qul d-md-block d-none position-absolute">
                                <img src="./assets/images/height_edu.png" alt="height_edu" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="maybe_inter">
                    <div className="title d-flex justify-content-between mb-3 align-items-center">
                      <h3>Classes you may be interested in</h3>
                      {/* <a href="#" className="fs-6 text-gray">See All</a> */}
                    </div>
                    {data2.length > 0 ? (
                      <Slider {...inter_slider}>
                        {data2.map((view_data, index) => (
                          <div key={index} className>
                            <div className="single-box">
                              <div className="class_image_box">
                                <div className="class_img position-relative">
                                  <div className="video-container">
                                    {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                                    {/* <video id="video" className loop width="100%" controls="hide" >
                                      <source src={view_data.video_file} type="video/mp4" />
                                    </video> */}
                                    <video id="video" loop width="100%" src={view_data?.video_file} controls />

                                  </div>

                                  {/* <div className="toggle like">
                                      <input type="checkbox" defaultChecked={view_data.is_favourite} onChange={() => handleFavorite(view_data.id)} />
                                      <label></label>
                                    </div> */}

                                  <div className="d-flex bottom-info align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                      <Link to={"/teacher-profile-details"} state={view_data.teacher_id}>

                                        <div className="d-flex align-items-center">
                                          <img
                                            src={view_data.profile_image ? view_data?.profile_image : "./assets/images/NicePng_watsapp-icon-png_9332131.png"}
                                            alt="user1"
                                          />
                                          <span className="tech_nm ms-2">{view_data.full_name}</span>
                                          <img
                                            src={view_data?.teacher_country || "./assets/images/flag/canada.png"}
                                            alt="canada"
                                            className="ms-2 det_flag_class"
                                          />
                                        </div>
                                      </Link>
                                      {/* <span className="tech_nm ms-2">{view_data.full_name}</span> */}
                                    </div>
                                    <div className="star_rat">
                                      <span>
                                        <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z" fill="#FDC840" />
                                        </svg>
                                      </span>
                                      <span className="fw-600">{view_data.teacher_ratings}<span className="text-gray fw-500">({view_data.teacher_review})</span></span>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div className="class_room_info">
                                <div className="tag mb-3">
                                  <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>{view_data.main_category}</a>
                                  {/* {localStorage.getItem('Tid') != view_data?.teacher_id  ?
                                    <button style={{ border: '1px solid #FFC924', borderRadius: '10px', }} className="px-3 ms-2  bg-purple text-white border-purple d-inline-block" onClick={() => handleFollowUnfollow(view_data.teacher_id)} >{view_data.is_follow === 1 ? "Following" : "Follow Me"}</button>
                                    : <div></div>
                                  } */}
                                </div>
                                <Link
                                  to={
                                    userType === "above_18"
                                      ? view_data.userType === "In-Person"
                                        ? `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                        : `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                      : userType === "below_18"
                                        ? view_data.userType === "In-Person"
                                          ? `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                          : `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                        : userType === "teacher"
                                          ? view_data.userType === "In-Person"
                                            ? "/class_detail_onsite"
                                            : "/class_detail"
                                          : userType === "organization"
                                            ? view_data.userType === "In-Person"
                                              ? "/onsite-class-detail"
                                              : "/class-details"
                                            : "/login"
                                  }
                                  state={{ id: view_data?.id, teacher_id: view_data?.teacher_id }}
                                >
                                  <div className="class_title mb-4">
                                    <p>Lorem ipsum dolor sit amet consectetur.In eu augue sed ectetur.In eu augue </p>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-8">
                                      <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Time</span>
                                      <span className="fw-600">{view_data.start_time} - {view_data.end_time}</span>
                                    </div>
                                    <div className="col-4">
                                      <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                                      <span className="fw-600">{view_data.min_age}-{view_data.max_age} Age</span>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-8">
                                      <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                                      <span className="fw-600">Monday To Friday</span>
                                    </div>
                                    <div className="col-4">
                                      <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                                      <span className="fw-600">{view_data.price}$</span>
                                    </div>
                                  </div>
                                </Link>
                                <div className="button">
                                  <a style={{ cursor: 'pointer' }}
                                    className={`fw-500 btn-theme d-block text-center ${userType === 'learner' ? 'disabled' : ''}`}
                                    data-bs-toggle={userType && userType !== 'learner' ? "modal" : ""}
                                    data-bs-target={userType && userType !== 'learner' ? "#subscription_modal_1" : ""}
                                  // onClick={() => type !== 'learner' && handleSubscribe(view_data)}
                                  >
                                    Subscribe
                                  </a>

                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    ) : (
                      <p>
                        <strong>Data Not Found For Classes You May Be Interested</strong>
                      </p>
                    )}
                  </div>

                  <CommonClassSubject />

                  <section className="news-section padd-100 slick_silder_button position-relative">
                    <div className="row mb-4">
                      <div className="col-8">
                        <h3>Find out about our latest news</h3>
                        <p className="fs-5 fw-500 text-capitalize">Here are some of our favorite articles:</p>
                      </div>
                    </div>
                    <div className="news_box news_boxslider">
                      {newsData.length > 0 ? (
                        <Slider {...class_news}>
                          {newsData.map((news, index) => (
                            <div key={index} className="slick-slideshow__slide">
                              <div className="news_info bg-white">
                                <div className="nw_img" style={{ height: "300px", overflow: "hidden" }}>
                                  {Array.isArray(news?.blog_media) && news.blog_media.length > 0 ? (
                                    news.blog_media[0].type === "video" ? (
                                      <video
                                        controls
                                        className="w-100 img-fluid border-radius-10"
                                        style={{ height: "100%", objectFit: "cover" }} // Ensures the video fits the container
                                      >
                                        <source src={news.blog_media[0].media} type="video/mp4" />
                                      </video>
                                    ) : (
                                      <img
                                        src={news.blog_media[0].media}
                                        alt="blog"
                                        className="w-100 img-fluid border-radius-10"
                                        style={{ height: "100%", objectFit: "cover" }} // Ensures the image fits the container
                                      />
                                    )
                                  ) : (
                                    <div>No media available</div>
                                  )}
                                </div>
                                <div className="nw_ctn">
                                  <span className="text-purple mb-2">{dayjs(news?.posted_date).format("DD MMMM YYYY")}</span>
                                  {/* <p className="mb-4 fw-600">{news.title}</p> */}
                                  <p className="mb-4 fw-600">
                                    {news.title.length > 25 ? `${news.title.slice(0, 25)}...` : news.title}
                                  </p>
                                  <div className="d-lg-flex justify-content-center">
                                    <Link to="/blog_details" state={news.id} className="btn-theme btn-prple-border">
                                      See more details
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <p>
                          <strong>No Latest News Listed Yet, Please Check Again Later</strong>
                        </p>
                      )}
                    </div>
                  </section>
                  {/*end*/}
                </div>
                <div className="col-xl-3 left position-relative">
                  <div className="mb-5">
                    <div className="avatar-upload">
                      <div className="avatar-preview">
                        <div
                          id="imagePreview"
                          style={{
                            backgroundImage: userData[0]?.profile_image ? `url(${userData[0]?.profile_image})` : 'url("./assets/images/kids-profile.png")',
                          }}
                        ></div>
                      </div>

                    </div>
                    <div className="text-center avatar_info">
                      <span className="text-di
                      f-black">{userData[0]?.user_name ?? userData[0]?.learner_name}</span>
                      <p className="text-gray">Code:#{userData[0]?.code_generate}</p>
                    </div>
                  </div>
                  <div className="wrappers" style={{ marginLeft: "30px" }}>
                    <Calendar
                      value={selectedDay}
                      onChange={(day) => {
                        handleCalendarSelect(day);
                        // Close the dropdown if needed
                      }}
                      shouldHighlightWeekends
                      customDaysClassName={scheduledDateList}
                    />
                  </div>
                  <div className="sche_sec">
                    <div className="title mb-2">
                      <h2 className="poppins fw-600 my-auto">Schedule</h2>
                    </div>

                    <div className="d-flex mb-4">
                      {userType === "after_school" && (
                        <select
                          className="border-purple bg-purple text-white fs-6 rounded-cricle rounded-pill px-2 py-2 mx-2"
                          onChange={(e) => setSelectedClassId(e.target.value)}

                          // value={selectedLearnerSchedule}
                          defaultValue={""}
                        >
                          <option value={""}>Class Name</option>
                          {showClassList}
                        </select>
                      )}
                      {(userType === "below_18" || userType === "after_school") && (
                        <select
                          className="border-purple bg-purple text-white fs-6 rounded-cricle rounded-pill px-2 py-2"
                          onChange={(e) => setSelectedLearnerSchedule(e.target.value)}
                          value={selectedLearnerSchedule}
                          defaultValue={""}
                        >
                          <option value={""}>Select a learner</option>
                          {showLearnerList}
                        </select>
                      )}
                    </div>
                    <div className="schedule_list">
                      {calender && Array.isArray(calender) && calender.length > 0 ? (
                        <ul>
                          {calender.map((item, index) => (
                            console.log('item :', item),
                            <li key={index}>
                              <Link
                                // to={`/my_courses_detail_classes/${item?.class_id}?type=classroom`}
                                to={generatePath(item)}

                                className="d-flex align-items-center justify-content-between"
                              >
                                {/* <a href="#" className="d-flex align-items-center justify-content-between"> */}
                                <div>
                                  <h3 className="text-break">{item.title}</h3>
                                  <p>Time : {item.format_time}</p>
                                  <p>Date : {item.format_date}</p>

                                  <span className="text-dif-black teac_schud_nam">
                                    Teacher’s Name : <span>{item.teacher_name}</span>
                                  </span>
                                </div>
                                <div className="arrs">
                                  <svg width={7} height={12} viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M1.19727 10.6209L5.0006 6.81753C5.44977 6.36836 5.44977 5.63336 5.0006 5.18419L1.19727 1.38086"
                                      stroke="#131313"
                                      strokeOpacity="0.4"
                                      strokeWidth="1.5"
                                      strokeMiterlimit={10}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                                {/* </a> */}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div className="col-12">
                          <p style={{ color: "purple" }}>You have not yet purchased any classes for this date</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end-here*/}
      </main>

      <Helmet>
        <script src="./assets/js/custom_calendar.js"></script>
      </Helmet>
      {/* <PFooter_Dashboard /> */}
    </>
  );
}


