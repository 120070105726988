import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { invoice_pdf, main_category_list, my_earnings_teacher, org_teacher_permission_listing, subject_category_list, teacher_earnings, teacher_select_class_list } from '../../../api/apiHandler';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import TFooter_Dashboard from '../Include/TFooter_Dashboard'
import THeader_Dashboard from '../Include/THeader_Dashboard'
import fileDownload from 'js-file-download';
import { Helmet } from 'react-helmet'
import { formatDate, NoDataComponent } from '../../../utils/common.service';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';

export default function MyEarning({ isLoader }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();

  // const PermissionList = () => {
  //   org_teacher_permission_listing().then((response) => {
  //     console.log("org_teacher_permission_listing", response)
  //     if (response?.code == 1) {
  //       var P_message = response.data[0]?.teacherData[0].my_earnings
  //       if (P_message == "0") {
  //         navigate("/no-permission");
  //       }
  //     } else {
  //        
  //     }
  //   });
  // }

  // useEffect(() => {
  //   PermissionList();
  // }, []);



  const [data, setData] = useState();
  console.log('data :', data);


  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const Start_Date = (data) => {
    setStartDate(data)
  }

  const End_Date = (data) => {
    setEndDate(data)
  }

  const [CategoriesData, setCategoriesData] = useState("Academic")
  const [subCategoriesData, setSubCategoriesData] = useState([])
  var [MainCatdata, setMainCatdata] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  var [SelectedCatID, setSelectedCatID] = useState([]);
  var [SelectedCategory, SetSelectedCategory] = useState([]);
  var [SelectedSubCat, setSelectedSubCat] = useState([]);
  var [SelectedSubCatErr, setSelectedSubCatErr] = useState(null);

  var [SelectedSubCatID, setSelectedSubCatID] = useState([]);
  var [SelectedSubCategory, SetSelectedSubCategory] = useState([]);

  const MainCategory = () => {
    main_category_list().then((response) => {

      if (response?.code == 1) {

        setMainCatdata(response?.data)
        setSelectedCatID([response.data[0].category_id])
        SetSelectedCategory([response.data[0].category_name])
        setIsOpen(true)

      } else {
         
        setMainCatdata([])

      }
    });
  }


  useEffect(() => {
    MainCategory();
  }, []);

  const SubCategoryList = () => {
    subject_category_list({ category_id: SelectedCatID }).then((response) => {

      if (response?.code == 1) {
        setSelectedSubCat(response.data)
        if (SelectedSubCatID.length > 0) {
          setSelectedSubCatErr('abcd')
          // alert(SelectedSubCatErr)

        }
        // setSelectedSubCatID([response.data[0].subject_category_id])
        // SetSelectedSubCategory([response.data[0].subject_name])

        setIsOpen(true)

      } else {
         
      }
    });
  }

  useEffect(() => {
    SubCategoryList()
  }, [SelectedCatID]);

  const handleItemClick = (id, cat_name) => {
    if (SelectedCatID != id) {
      setSelectedSubCat([])
      setSelectedSubCatID([])
      SetSelectedSubCategory([])
      setSubCategoriesData([])
    }

    setSelectedCatID(id)
    SetSelectedCategory(cat_name)
    setCategoriesData(cat_name)
  }


  var [subCategoriesDataId, setSubCategoriesDataId] = useState([])
  const [selectedClassName, setSelectedClassName] = useState('')


  const handleSelectedIngredients = (Categories_data, subCatId) => {

    if (subCategoriesData.includes(Categories_data)) {
      let filteredArray = subCategoriesData.filter(item => item !== Categories_data);
      setSubCategoriesData(filteredArray)
    } else {
      setSubCategoriesData(prevSelectSubject => [...new Set([...prevSelectSubject, Categories_data])]);
    }


    if (subCategoriesDataId.includes(subCatId)) {
      let filteredArray = subCategoriesDataId.filter(item => item !== subCatId);
      setSubCategoriesDataId(filteredArray)
    } else {
      setSubCategoriesDataId(prevSelectSubject => [...new Set([...prevSelectSubject, subCatId])]);
    }
  }

  const ClassName = async (cname) => {
    setSelectedClassName(cname)
  }
  // const TeacherEarning = async () => {
  //   var search_item = {};
  //   if (CategoriesData && subCategoriesData?.[0]) {
  //     search_item.main_category = CategoriesData
  //     search_item.sub_category = subCategoriesData
  //   }
  //   if (startDate) {
  //     search_item.start_date = formatDate(startDate, 'DD-MM-YYYY')
  //   }
  //   if (endDate) {
  //     search_item.end_date = formatDate(endDate, 'DD-MM-YYYY')
  //   }
  //   console.log('search_item :', search_item);
  //   isLoader(true)
  //   try {
  //     const response = await my_earnings_teacher(search_item);
  //     console.log('response123 :', response);
  //     if (response?.code == 1) {
  //       setData(response?.data)
  //       isLoader(false)

  //     } else { 
  //       setData([])
  //       isLoader(false)

  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // useEffect(() => {
  //   TeacherEarning();
  // }, [CategoriesData, subCategoriesData, startDate, endDate])

  const TeacherEarning1 = async () => {
    var search_item = {};
    if (selectedClassName) {
      search_item.search = selectedClassName
    }
    if (startDate) {
      search_item.start_date = formatDate(startDate, 'DD-MM-YYYY')
    }
    if (endDate) {
      search_item.end_date = formatDate(endDate, 'DD-MM-YYYY')
    }
    console.log('search_item :', search_item);
    isLoader(true)
    try {
      const response = await teacher_earnings(search_item);
      console.log('teacher_earnings :', response);
      if (response?.code == 1) {
        setData(response?.data)
        isLoader(false)

      } else {
        setData([])
        isLoader(false)

      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    TeacherEarning1();
  }, [selectedClassName, startDate, endDate])

  // const columns = [
  //   {
  //     name: 'Id',
  //     selector: (row, index) => index + 1,
  //     cellExport: (row, index) => index + 1,
  //     sortable: true,
  //     width: '75px'
  //   },
  //   {
  //     name: 'Teacher Name',
  //     selector: row => row.teacher_name,
  //     sortable: true,
  //     cellExport: row => row.teacher_name,
  //   },
  //   {
  //     name: 'Subject Category',
  //     selector: row => row.subject_category,
  //     sortable: true,
  //     cellExport: row => row.subject_category,
  //   },

  //   {
  //     name: 'Date Time',
  //     selector: row => row.joined_date,
  //     sortable: true,
  //     cellExport: row => row.joined_date
  //   },
  //   {
  //     name: 'Title',
  //     selector: row => row.activity_title,
  //     sortable: true,
  //     cellExport: row => row.activity_title
  //   },

  //   // {
  //   //     name: 'Status',
  //   //     center: true,
  //   //     selector: row => row.is_active,
  //   //     cell: (row) => <button type="button" className="btn btn-default waves-effect rounded waves-float" id={row.id} onClick={()=> careerPostActive(row.id)}> {row.is_active == '0' ? "🔴 InActive" : "🟢 Active"}</button>
  //   // },
  //   {
  //     name: 'Action',
  //     selector: row => row.id,
  //     center: true,
  //     cell: (row) => <>
  //       {/* <button type="button" className="btn" style={{ boxShadow: 'none' }} id={row.id} onClick={() => { navigate(`/jobappli`, {state:{id:row.id}}) }} ><i className="material-icons" id={row.id} onClick={() => { navigate(`/jobappli`,{state:{id:row.id}}) }} >visibility</i>  </button>
  //           <button type="button" className="btn" style={{ boxShadow: 'none' }} id={row.id} onClick={() => { setPost(''); setPost(row); }} data-toggle="modal" data-target="#editModal"><i className="material-icons" id={row.id} onClick={() => { setPost(''); setPost(row); }} >create</i>  </button> */}
  //       <button type="button" className="btn" style={{ boxShadow: 'none' }} id={row.id} ><a className="material-icons" onClick={() => downloadPdf(row.activity_id, row.activity_type)} id={row.id} >  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  //         <path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649929 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.39341 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.4721 23.3336C29.1203 20.8668 30 17.9667 30 15C30 11.0218 28.4197 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0ZM19.9206 24.6625H10.0794C9.74786 24.6625 9.42992 24.5308 9.1955 24.2964C8.96108 24.062 8.82938 23.744 8.82938 23.4125C8.82938 23.081 8.96108 22.763 9.1955 22.5286C9.42992 22.2942 9.74786 22.1625 10.0794 22.1625H19.9206C20.2522 22.1625 20.5701 22.2942 20.8045 22.5286C21.0389 22.763 21.1706 23.081 21.1706 23.4125C21.1706 23.744 21.0389 24.062 20.8045 24.2964C20.5701 24.5308 20.2522 24.6625 19.9206 24.6625ZM20.8044 15.26L15.88 20.1875C15.6456 20.4218 15.3277 20.5535 14.9963 20.5535C14.6648 20.5535 14.3469 20.4218 14.1125 20.1875L9.19563 15.2713C8.96075 15.0369 8.8286 14.7188 8.82824 14.387C8.82789 14.0551 8.95937 13.7368 9.19376 13.5019C9.42814 13.267 9.74623 13.1348 10.0781 13.1345C10.4099 13.1341 10.7283 13.2656 10.9631 13.5L13.75 16.2869V5.22813C13.75 4.8966 13.8817 4.57866 14.1161 4.34424C14.3505 4.10982 14.6685 3.97813 15 3.97813C15.3315 3.97813 15.6495 4.10982 15.8839 4.34424C16.1183 4.57866 16.25 4.8966 16.25 5.22813V16.2794L19.0369 13.4925C19.2726 13.2648 19.5884 13.1388 19.9161 13.1417C20.2439 13.1445 20.5574 13.276 20.7892 13.5077C21.0209 13.7395 21.1524 14.053 21.1552 14.3807C21.1581 14.7085 21.0321 15.0242 20.8044 15.26Z" fill="#570861" />
  //       </svg></a> </button></>
  //   },
  // ];
  const columns = [
    {
      name: 'Id',
      selector: (row, index) => index + 1,
      cellExport: (row, index) => index + 1,
      sortable: true,
      width: '75px'
    },
    {
      name: 'Date',
      selector: row => dayjs(row.created_at).format("DD-MM-YYYY"),
      sortable: true,
      cellExport: row => row.created_at,
    },
    {
      name: 'Class Name',
      selector: row => row.activity_title,
      sortable: true,
      cellExport: row => row.activity_title,
    },

    // {
    //   name: 'Class Name',
    //   selector: row => row.activity_title,
    //   sortable: true,
    //   cell: row => (
    //     <a onClick={() => handleClassClick({ 
    //       activityId: row.activity_id, 
    //       typeClass: row.activity_type, 
    //       type: "online"
    //     })}>
    //       {row.activity_title}
    //     </a>
    //   ),
    //   cellExport: row => row.activity_title,
    // },
    {
      name: 'Amount',
      selector: row => `$ ${row.teacher_amount}`,
      sortable: true,
      cellExport: row => row.teacher_amount
    },

    {
      name: 'Action',
      selector: row => row.id,
      center: true,
      cell: (row) => <>

        <button type="button" className="btn" style={{ boxShadow: 'none' }} id={row.id} ><a className="material-icons" onClick={() => downloadPdf(row.activity_id, row.activity_type)} id={row.id} >  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649929 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.39341 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.4721 23.3336C29.1203 20.8668 30 17.9667 30 15C30 11.0218 28.4197 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0ZM19.9206 24.6625H10.0794C9.74786 24.6625 9.42992 24.5308 9.1955 24.2964C8.96108 24.062 8.82938 23.744 8.82938 23.4125C8.82938 23.081 8.96108 22.763 9.1955 22.5286C9.42992 22.2942 9.74786 22.1625 10.0794 22.1625H19.9206C20.2522 22.1625 20.5701 22.2942 20.8045 22.5286C21.0389 22.763 21.1706 23.081 21.1706 23.4125C21.1706 23.744 21.0389 24.062 20.8045 24.2964C20.5701 24.5308 20.2522 24.6625 19.9206 24.6625ZM20.8044 15.26L15.88 20.1875C15.6456 20.4218 15.3277 20.5535 14.9963 20.5535C14.6648 20.5535 14.3469 20.4218 14.1125 20.1875L9.19563 15.2713C8.96075 15.0369 8.8286 14.7188 8.82824 14.387C8.82789 14.0551 8.95937 13.7368 9.19376 13.5019C9.42814 13.267 9.74623 13.1348 10.0781 13.1345C10.4099 13.1341 10.7283 13.2656 10.9631 13.5L13.75 16.2869V5.22813C13.75 4.8966 13.8817 4.57866 14.1161 4.34424C14.3505 4.10982 14.6685 3.97813 15 3.97813C15.3315 3.97813 15.6495 4.10982 15.8839 4.34424C16.1183 4.57866 16.25 4.8966 16.25 5.22813V16.2794L19.0369 13.4925C19.2726 13.2648 19.5884 13.1388 19.9161 13.1417C20.2439 13.1445 20.5574 13.276 20.7892 13.5077C21.0209 13.7395 21.1524 14.053 21.1552 14.3807C21.1581 14.7085 21.0321 15.0242 20.8044 15.26Z" fill="#570861" />
        </svg></a> </button></>
    },
  ];

  function handleClassClick(params) {
    const { activityId, typeClass, type } = params; // Destructure the object properties

    // Create a query string from the parameters
    const queryString = new URLSearchParams({ activityId, typeClass, type }).toString();

    // Define your key and IV (Initialization Vector)
    var key = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNfTmDXHWVkQWdanYOm');
    var iv = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNf');

    // Encrypt the query string
    const encryptedData = CryptoJS.AES.encrypt(queryString, key, { iv: iv }).toString();

    // Encode the encrypted data for use in a URL
    const encryptedUrl = encodeURIComponent(encryptedData);

    // Construct the URL with the encrypted query parameter
    navigate(`/teach-detail?data=${encryptedUrl}`);

  }

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: '14px'
      },
    },
  };

  const ResetFilter = () => {
    setStartDate('')
    setEndDate('')
    setSelectedClassName('')
    setSubCategoriesDataId([])
    setSubCategoriesData([])

  }

  var [selectClass, setselectClass] = useState([]);
  const selectClassData = () => {
    teacher_select_class_list().then((response) => {
      if (response?.code == 1) {
        setselectClass(response?.data)
      } else {
         
      }
    });
  }
  useEffect(() => {
    selectClassData();
  }, []);


  const downloadPdf = async (a, b) => {
    try {
      const response = await invoice_pdf({ activity_id: a.toString(), activity_type: b });

      console.log('Response:', response);

      if (response?.code == "1" && response?.data) {
        const pdfUrl = response?.data;

        const pdfResponse = await fetch(pdfUrl);
        const pdfBlob = await pdfResponse.blob();

        const randomName = `invoice_${Date.now()}.pdf`;

        const pdfFile = new File([pdfBlob], randomName, { type: 'application/pdf' });

        const pdfDataUrl = URL.createObjectURL(pdfFile);

        const downloadLink = document.createElement('a');
        downloadLink.href = pdfDataUrl;
        downloadLink.download = randomName;

        downloadLink.click();

        console.log('Download started.');
      } else {
        console.error('Invalid response or PDF URL not found.');
      }
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  return (
    <>
      <JqueryLoad />
      <THeader_Dashboard />
      <Helmet>
        <style>
          {`
          
                                      .hQPXLq {
                                        display: flex;
                                        width: 100%;
                                        color: #570861;
                                        font-size: 12px;
                                        font-weight: 500;
                                    }
                                    
                                    .drop_calendar.filter_box {
                                        min-width: 523px;
                                    }
                                    .Calendar__weekDays {
                                        color : #570861
                                    }
                                    .Calendar__day.-selected, .Calendar__day.-selectedStart, .Calendar__day.-selectedEnd {
                                        background: #FFC924;
                                        color: #212529;
                                    }
                                    .Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
                                        color: #212529;
                                    }
                                    .Calendar__day.-selectedBetween {
                                        background: rgba(241, 241, 241, 0.65);
                                        color: #060706;
                                        border-radius: 0
                                    }
                                    .scrollable-content {
                                        max-height: 360px; /* Set your preferred max height */
                                        overflow-y: auto; /* Add vertical scroll */
                                        margin-right: -100px; /* Add right margin to the scrollbar */
                                        width: 290px;
                                      }
                                    .dropdown-menu.subjects_drp.filter_box {
                                        min-width: 496px;
                                    }
                                    .scrollable-content::-webkit-scrollbar {
                                        width: 5px; /* Width of the scrollbar */
                                      }
                                      
                                      .scrollable-content::-webkit-scrollbar-thumb {
                                        background-color: #570861; /* Color of the thumb */
                                        border-radius: 10px; /* Rounded corners for the thumb */
                                      }
                                      
                                      .scrollable-content::-webkit-scrollbar-track {
                                        background-color: #f0f0f0; /* Color of the track */
                                      }
                                      
                                      #inputPlaceholderBlack::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                            color: #212529;
                                            }
                                            #inputPlaceholderBlack::-moz-placeholder { /* Firefox 19+ */
                                            color: #212529;
                                            }
                                            #inputPlaceholderBlack::-ms-input-placeholder { /* IE 10+ */
                                            color: #212529;
                                            }
                                            #inputPlaceholderBlack::-moz-placeholder { /* Firefox 18- */
                                            color: #212529;
                                            }

                                            .card {
                                                position: relative;
                                                display: flex;
                                                flex-direction: column;
                                                /* min-width: 0; */
                                                word-wrap: break-word;
                                                background-color: #fff;
                                                background-clip: border-box;
                                                border: 1px solid rgba(0,0,0,.125);
                                                border-radius: 0.25rem;
                                            }

                                            .plan-details {
                                                border: 1px solid #ffefbd;
                                                border-radius: 0.5em;
                                                cursor: pointer;
                                                height: 100%;
                                                padding: 14px;
                                                transition: border-color 0.2s ease-out;
                                            }
                                            .pb-5 {
                                                padding-bottom: 0rem!important;
                                            }
                                            .dwnl_img img {
                                              width: 100%;
                                              height: auto;
                                              display: block;
                                          }
                                          .data-table-extensions > .data-table-extensions-action > button {
                                            float: right;
                                            display: none;
                                            width: 30px;
                                            height: 30px;
                                            background-repeat: no-repeat;
                                            background-position: center center;
                                            background-color: transparent;
                                            border: none;
                                            cursor: pointer;
                                            padding: 5px;
                                            border-radius: 0.3rem;
                                            outline: none;
                                            margin-right: 0.3rem;
                                        }
                                        .data-table-extensions {
                                          display: none;
                                          width: 100%;
                                          box-sizing: border-box;
                                          padding: 0.7rem 1.2rem;
                                      }
                                    //   .dropdown-toggle::after {
                                    //     display: inline-block;
                                    //     margin-left: 0.255em;
                                    //     vertical-align: 0.255em;
                                    //     content: "";
                                    //     border : 0;
                                    //     background-image : url('./assets/images/arrow-down.png');
                                    //     background-position : centre right 5px;
                                    //     background-size : auto;
                                        
                                    // }

                                  //   .dropdown-toggle::after {
                                  //     display: inline-block;
                                  //     margin-left: 0.255em;
                                  //     vertical-align: 0.255em; 
                                  //     content: "";
                                  //     border: 0;
                                  //     background-image: url('path/to/your/image.png'); /* Replace with the actual URL */
                                  //     background-position: center right 5px;
                                  //     background-size: auto;
                                  //     background-repeat: no-repeat;
                                  //     width: 10px; /* Adjust based on your image size */
                                  //     height: 10px; /* Adjust based on your image size */
                                  // }
                                  .selection-2{
                                    position: relative;
                                     
                                  }
                                  .selection-2 .btn{
                                    height: 40px;
                                    display: flex;
                                    align-items: center;
                                  }
                                  .selection-2 .dropdown-toggle::after {
                                    vertical-align: baseline;
                                    background-image: url('./assets/images/arrow-down.svg');
                                    display: inline-block;
                                    border: none;
                                    color: black;
                                    /* position: absolute; */
                                    right: 0;
                                    top: 20px;
                                    /* line-height: 35px; */
                                    width: 13px;
                                    height: 24px;
                                    background-repeat: no-repeat;
                                    background-size: 100% 44%;
                                    /* margin-top: 10px; */
                                    top: 6px;
                                    position: relative;
                                    right: -8px;
                                }
                                    `}
        </style>
      </Helmet>
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}
              <div className>
                <div className=" ">
                  {/*start*/}
                  <div className="mb-4 d-md-flex align-items-center justify-content-between">
                    <h2 className="mb-md-0 mb-4 poppins fw-600">my Earnings</h2>
                    <div className="fliter_list">
                      <ul className="list-unstyled slider_filter_list d-flex flex-wrap align-items-center">
                        {localStorage.getItem("type") == "org_teacher" && (
                          <li className="mx-3"><Link to={"/org-teacher-earning"} className="text-decoration-underline text-purple">Show My Earning Details</Link></li>)}


                        {/* <li className="ms-2 mb-2 d-md-none d-inline-block">
                          <button className="btn border-purple rounded-cricle rounded-pill" type="button" data-bs-toggle="offcanvas" data-bs-target="#rightOffcanvas" aria-controls="rightOffcanvas">
                            Categories
                          </button>
                        </li>
                        <li className="ms-2 mb-2 d-md-inline-block d-none">
                          <div className="dropdown">
                            <button className="btn border-purple rounded-cricle rounded-pill dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                              Categories
                            </button>
                            <div className="dropdown-menu subjects_drp filter_box">
                              <div className="d-flex align-items-start">

                                <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                  {MainCatdata?.map((mainCat, index) => {
                                    return (
                                      <button className={`nav-link ${SelectedCatID == mainCat.category_id ? 'active' : ''}`} onClick={(e) => handleItemClick(mainCat.category_id, mainCat.category_name)} id="v-pills-Academic-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Academic" type="button" role="tab" aria-controls="v-pills-Academic" aria-selected="true">{mainCat.category_name}</button>
                                    );
                                  })}
                                </div>
                                <div className="tab-content" id="v-pills-tabContent">

                                  <div className="tab-pane fade show active" id="v-pills-Academic" role="tabpanel" aria-labelledby="v-pills-Academic-tab" tabIndex={0}>
                                    <div className="row">
                                      <ul className="col-md-12">
                                        <div className="scrollable-content" >
                                          {SelectedSubCat[0]?.category_name ? (
                                            <>
                                              {SelectedSubCat?.map((SubCat, index) => {
                                                return (
                                                  <>
                                                    <li className="headings"><span className="text-purple fw-600">{SubCat.category_name}</span></li>
                                                    {SubCat.category?.map((SubCat1, index1) => {
                                                      return (
                                                        <li>
                                                          <a>
                                                            <div className="form-check">
                                                              <input onChange={(e) => handleSelectedIngredients(SubCat1.subject_name, SubCat1.subject_category_id, e.target.checked)} checked={subCategoriesDataId.indexOf(SubCat1.subject_category_id) > -1} className="form-check-input" type="checkbox" defaultValue id={`flexCheckDefault_${index1}`} />
                                                              <label className="form-check-label" htmlFor={`flexCheckDefault_${index1}`}>{SubCat1.subject_name}</label>
                                                            </div>
                                                          </a>
                                                        </li>
                                                      );
                                                    })}
                                                  </>
                                                );
                                              })}
                                            </>
                                          ) : (
                                            <>
                                              <ul>
                                                {SelectedSubCat?.map((SubCat, index) => {
                                                  return (
                                                    <>
                                                      <li>
                                                        <a>
                                                          <div className="form-check">
                                                            <input onChange={(e) => handleSelectedIngredients(SubCat.subject_name, SubCat.subject_category_id, e.target.checked)} checked={subCategoriesDataId.indexOf(SubCat.subject_category_id) > -1} className="form-check-input" type="checkbox" defaultValue id={`flexCheckDefault_${index}`} />
                                                            <label className="form-check-label" htmlFor={`flexCheckDefault_${index}`}>{SubCat.subject_name}</label>
                                                          </div>
                                                        </a>
                                                      </li>
                                                    </>
                                                  );
                                                })}
                                              </ul>
                                            </>
                                          )}
                                        </div>


                                      </ul>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </li> */}

                        <li>
                          <span className='custom-select'>
                            <select className="btn border-purple rounded-cricle rounded-pill" onChange={(e) => ClassName(e.target.value)} name="pre_req" aria-label="Default select example"  >
                              <option selected={selectedClassName == "" ? 'selected' : ''} value='' disabled>Select the Class</option>
                              {selectClass?.map((coupon, index) => {
                                return (
                                  <option key={index} value={coupon?.class_id} >{coupon?.title}</option>
                                )
                              })}

                            </select>
                          </span>

                        </li>

                        <li className="ms-2 ">
                          <div className="dropdown selection-2">
                            <button className="btn border-purple rounded-cricle rounded-pill dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Select Date
                            </button>
                            <div className="dropdown-menu class_size_start filter_box">
                              <form className action id>
                                <div className="form-group mb-4">
                                  <label className="mb-2">From Date</label>
                                  <input type="date" className="form-control" id onChange={(e) => { Start_Date(e.target.value) }} placeholder="Start" name value={startDate} />
                                </div>
                                <div className="form-group mb-4">
                                  <label className="mb-2">To Date</label>
                                  <input type="date" className="form-control" min={startDate} id onChange={(e) => { End_Date(e.target.value) }} placeholder="Start" value={endDate} name />
                                </div>
                              </form>
                            </div>
                          </div>
                        </li>
                        <li className="ms-2"><Link onClick={(e) => ResetFilter()} className="text-purple fw-500">Reset Filters</Link></li>
                      </ul>
                    </div>
                  </div>
                  {/*end*/}
                  {/*start*/}
                  <div className="row">
                    <DataTableExtensions columns={columns} data={data}>
                      <DataTable columns={columns} data={data} responsive pagination customStyles={customStyles} noDataComponent={<NoDataComponent />} />
                    </DataTableExtensions>
                    {/* {data?.length >= 0 ?
                      data?.map((EarningData, index) => {
                        return (

                          <div className="col-xl-4 col-lg-6 col-md-6 mb-4">
                            <div className="certi_box">
                              <div className="certi_images text-center">
                                <img src="./assets/images/pdf_icon.png" alt="pdf_icon" />
                              </div>
                              <div className="certi_ctn">
                                <span className="bg-yellow text-purple rounded-3 d-inline-block px-3 mb-2">{EarningData.subject_category}</span>
                                <h2 className="poppins fw-bold mb-2">{EarningData.activity_title}</h2>
                                <span className="text-dif-black font-14">{EarningData.joined_date} </span>
                                <div className="dwnload_btm d-flex mt-3 justify-content-between align-items-center">
                                  <div className="d-flex align-items-center">
                                    <div className="dwnl_img rounded-circle overflow-hidden">
                                      <img src={EarningData.profile_image} alt="center_div_img2" />
                                    </div>
                                    <div className="ms-3">
                                      <span className="font-14 fw-bold">{EarningData.teacher_name}</span>
                                    </div>
                                  </div>
                                  <div className>
                                    <a onClick={() => downloadPdf(EarningData.activity_id, EarningData.activity_type)}>
                                      <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649929 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.39341 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.4721 23.3336C29.1203 20.8668 30 17.9667 30 15C30 11.0218 28.4197 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0ZM19.9206 24.6625H10.0794C9.74786 24.6625 9.42992 24.5308 9.1955 24.2964C8.96108 24.062 8.82938 23.744 8.82938 23.4125C8.82938 23.081 8.96108 22.763 9.1955 22.5286C9.42992 22.2942 9.74786 22.1625 10.0794 22.1625H19.9206C20.2522 22.1625 20.5701 22.2942 20.8045 22.5286C21.0389 22.763 21.1706 23.081 21.1706 23.4125C21.1706 23.744 21.0389 24.062 20.8045 24.2964C20.5701 24.5308 20.2522 24.6625 19.9206 24.6625ZM20.8044 15.26L15.88 20.1875C15.6456 20.4218 15.3277 20.5535 14.9963 20.5535C14.6648 20.5535 14.3469 20.4218 14.1125 20.1875L9.19563 15.2713C8.96075 15.0369 8.8286 14.7188 8.82824 14.387C8.82789 14.0551 8.95937 13.7368 9.19376 13.5019C9.42814 13.267 9.74623 13.1348 10.0781 13.1345C10.4099 13.1341 10.7283 13.2656 10.9631 13.5L13.75 16.2869V5.22813C13.75 4.8966 13.8817 4.57866 14.1161 4.34424C14.3505 4.10982 14.6685 3.97813 15 3.97813C15.3315 3.97813 15.6495 4.10982 15.8839 4.34424C16.1183 4.57866 16.25 4.8966 16.25 5.22813V16.2794L19.0369 13.4925C19.2726 13.2648 19.5884 13.1388 19.9161 13.1417C20.2439 13.1445 20.5574 13.276 20.7892 13.5077C21.0209 13.7395 21.1524 14.053 21.1552 14.3807C21.1581 14.7085 21.0321 15.0242 20.8044 15.26Z" fill="#570861" />
                                      </svg>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                      :
                      <h4 className="d-flex justify-content-center align-items-center" style={{ "color": "#570861", minHeight: "60vh" }}>{data?.message}
                      </h4>
                    } */}
                    {/* <div className="col-xl-4 col-lg-6 col-md-6 mb-4">
                      <div className="certi_box">
                        <div className="certi_images text-center">
                          <img src="./assets/images/pdf_icon.png" alt="pdf_icon" />
                        </div>
                        <div className="certi_ctn">
                          <span className="bg-yellow text-purple rounded-3 d-inline-block px-3 mb-2">Music</span>
                          <h2 className="poppins fw-bold mb-2">What Traveling Greece For 2 Weeks Taught Me About Life</h2>
                          <span className="text-dif-black font-14">Jun 21, 2021 </span>
                          <div className="dwnload_btm d-flex mt-3 justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <div className="dwnl_img">
                                <img src="./assets/images/center_div_img2.png" alt="center_div_img2" />
                              </div>
                              <div className="ms-3">
                                <span className="font-14 fw-bold">George Costanza</span>
                              </div>
                            </div>
                            <div className>
                              <a href="#">
                                <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649929 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.39341 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.4721 23.3336C29.1203 20.8668 30 17.9667 30 15C30 11.0218 28.4197 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0ZM19.9206 24.6625H10.0794C9.74786 24.6625 9.42992 24.5308 9.1955 24.2964C8.96108 24.062 8.82938 23.744 8.82938 23.4125C8.82938 23.081 8.96108 22.763 9.1955 22.5286C9.42992 22.2942 9.74786 22.1625 10.0794 22.1625H19.9206C20.2522 22.1625 20.5701 22.2942 20.8045 22.5286C21.0389 22.763 21.1706 23.081 21.1706 23.4125C21.1706 23.744 21.0389 24.062 20.8045 24.2964C20.5701 24.5308 20.2522 24.6625 19.9206 24.6625ZM20.8044 15.26L15.88 20.1875C15.6456 20.4218 15.3277 20.5535 14.9963 20.5535C14.6648 20.5535 14.3469 20.4218 14.1125 20.1875L9.19563 15.2713C8.96075 15.0369 8.8286 14.7188 8.82824 14.387C8.82789 14.0551 8.95937 13.7368 9.19376 13.5019C9.42814 13.267 9.74623 13.1348 10.0781 13.1345C10.4099 13.1341 10.7283 13.2656 10.9631 13.5L13.75 16.2869V5.22813C13.75 4.8966 13.8817 4.57866 14.1161 4.34424C14.3505 4.10982 14.6685 3.97813 15 3.97813C15.3315 3.97813 15.6495 4.10982 15.8839 4.34424C16.1183 4.57866 16.25 4.8966 16.25 5.22813V16.2794L19.0369 13.4925C19.2726 13.2648 19.5884 13.1388 19.9161 13.1417C20.2439 13.1445 20.5574 13.276 20.7892 13.5077C21.0209 13.7395 21.1524 14.053 21.1552 14.3807C21.1581 14.7085 21.0321 15.0242 20.8044 15.26Z" fill="#570861" />
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-4">
                      <div className="certi_box">
                        <div className="certi_images text-center">
                          <img src="./assets/images/pdf_icon.png" alt="pdf_icon" />
                        </div>
                        <div className="certi_ctn">
                          <span className="bg-yellow text-purple rounded-3 d-inline-block px-3 mb-2">Music</span>
                          <h2 className="poppins fw-bold mb-2">What Traveling Greece For 2 Weeks Taught Me About Life</h2>
                          <span className="text-dif-black font-14">Jun 21, 2021 </span>
                          <div className="dwnload_btm d-flex mt-3 justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <div className="dwnl_img">
                                <img src="./assets/images/center_div_img2.png" alt="center_div_img2" />
                              </div>
                              <div className="ms-3">
                                <span className="font-14 fw-bold">George Costanza</span>
                              </div>
                            </div>
                            <div className>
                              <a href="#">
                                <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649929 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.39341 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.4721 23.3336C29.1203 20.8668 30 17.9667 30 15C30 11.0218 28.4197 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0ZM19.9206 24.6625H10.0794C9.74786 24.6625 9.42992 24.5308 9.1955 24.2964C8.96108 24.062 8.82938 23.744 8.82938 23.4125C8.82938 23.081 8.96108 22.763 9.1955 22.5286C9.42992 22.2942 9.74786 22.1625 10.0794 22.1625H19.9206C20.2522 22.1625 20.5701 22.2942 20.8045 22.5286C21.0389 22.763 21.1706 23.081 21.1706 23.4125C21.1706 23.744 21.0389 24.062 20.8045 24.2964C20.5701 24.5308 20.2522 24.6625 19.9206 24.6625ZM20.8044 15.26L15.88 20.1875C15.6456 20.4218 15.3277 20.5535 14.9963 20.5535C14.6648 20.5535 14.3469 20.4218 14.1125 20.1875L9.19563 15.2713C8.96075 15.0369 8.8286 14.7188 8.82824 14.387C8.82789 14.0551 8.95937 13.7368 9.19376 13.5019C9.42814 13.267 9.74623 13.1348 10.0781 13.1345C10.4099 13.1341 10.7283 13.2656 10.9631 13.5L13.75 16.2869V5.22813C13.75 4.8966 13.8817 4.57866 14.1161 4.34424C14.3505 4.10982 14.6685 3.97813 15 3.97813C15.3315 3.97813 15.6495 4.10982 15.8839 4.34424C16.1183 4.57866 16.25 4.8966 16.25 5.22813V16.2794L19.0369 13.4925C19.2726 13.2648 19.5884 13.1388 19.9161 13.1417C20.2439 13.1445 20.5574 13.276 20.7892 13.5077C21.0209 13.7395 21.1524 14.053 21.1552 14.3807C21.1581 14.7085 21.0321 15.0242 20.8044 15.26Z" fill="#570861" />
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-4">
                      <div className="certi_box">
                        <div className="certi_images text-center">
                          <img src="./assets/images/pdf_icon.png" alt="pdf_icon" />
                        </div>
                        <div className="certi_ctn">
                          <span className="bg-yellow text-purple rounded-3 d-inline-block px-3 mb-2">Music</span>
                          <h2 className="poppins fw-bold mb-2">What Traveling Greece For 2 Weeks Taught Me About Life</h2>
                          <span className="text-dif-black font-14">Jun 21, 2021 </span>
                          <div className="dwnload_btm d-flex mt-3 justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <div className="dwnl_img">
                                <img src="./assets/images/center_div_img2.png" alt="center_div_img2" />
                              </div>
                              <div className="ms-3">
                                <span className="font-14 fw-bold">George Costanza</span>
                              </div>
                            </div>
                            <div className>
                              <a href="#">
                                <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649929 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.39341 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.4721 23.3336C29.1203 20.8668 30 17.9667 30 15C30 11.0218 28.4197 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0ZM19.9206 24.6625H10.0794C9.74786 24.6625 9.42992 24.5308 9.1955 24.2964C8.96108 24.062 8.82938 23.744 8.82938 23.4125C8.82938 23.081 8.96108 22.763 9.1955 22.5286C9.42992 22.2942 9.74786 22.1625 10.0794 22.1625H19.9206C20.2522 22.1625 20.5701 22.2942 20.8045 22.5286C21.0389 22.763 21.1706 23.081 21.1706 23.4125C21.1706 23.744 21.0389 24.062 20.8045 24.2964C20.5701 24.5308 20.2522 24.6625 19.9206 24.6625ZM20.8044 15.26L15.88 20.1875C15.6456 20.4218 15.3277 20.5535 14.9963 20.5535C14.6648 20.5535 14.3469 20.4218 14.1125 20.1875L9.19563 15.2713C8.96075 15.0369 8.8286 14.7188 8.82824 14.387C8.82789 14.0551 8.95937 13.7368 9.19376 13.5019C9.42814 13.267 9.74623 13.1348 10.0781 13.1345C10.4099 13.1341 10.7283 13.2656 10.9631 13.5L13.75 16.2869V5.22813C13.75 4.8966 13.8817 4.57866 14.1161 4.34424C14.3505 4.10982 14.6685 3.97813 15 3.97813C15.3315 3.97813 15.6495 4.10982 15.8839 4.34424C16.1183 4.57866 16.25 4.8966 16.25 5.22813V16.2794L19.0369 13.4925C19.2726 13.2648 19.5884 13.1388 19.9161 13.1417C20.2439 13.1445 20.5574 13.276 20.7892 13.5077C21.0209 13.7395 21.1524 14.053 21.1552 14.3807C21.1581 14.7085 21.0321 15.0242 20.8044 15.26Z" fill="#570861" />
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-4">
                      <div className="certi_box">
                        <div className="certi_images text-center">
                          <img src="./assets/images/pdf_icon.png" alt="pdf_icon" />
                        </div>
                        <div className="certi_ctn">
                          <span className="bg-yellow text-purple rounded-3 d-inline-block px-3 mb-2">Music</span>
                          <h2 className="poppins fw-bold mb-2">What Traveling Greece For 2 Weeks Taught Me About Life</h2>
                          <span className="text-dif-black font-14">Jun 21, 2021 </span>
                          <div className="dwnload_btm d-flex mt-3 justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <div className="dwnl_img">
                                <img src="./assets/images/center_div_img2.png" alt="center_div_img2" />
                              </div>
                              <div className="ms-3">
                                <span className="font-14 fw-bold">George Costanza</span>
                              </div>
                            </div>
                            <div className>
                              <a href="#">
                                <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649929 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.39341 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.4721 23.3336C29.1203 20.8668 30 17.9667 30 15C30 11.0218 28.4197 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0ZM19.9206 24.6625H10.0794C9.74786 24.6625 9.42992 24.5308 9.1955 24.2964C8.96108 24.062 8.82938 23.744 8.82938 23.4125C8.82938 23.081 8.96108 22.763 9.1955 22.5286C9.42992 22.2942 9.74786 22.1625 10.0794 22.1625H19.9206C20.2522 22.1625 20.5701 22.2942 20.8045 22.5286C21.0389 22.763 21.1706 23.081 21.1706 23.4125C21.1706 23.744 21.0389 24.062 20.8045 24.2964C20.5701 24.5308 20.2522 24.6625 19.9206 24.6625ZM20.8044 15.26L15.88 20.1875C15.6456 20.4218 15.3277 20.5535 14.9963 20.5535C14.6648 20.5535 14.3469 20.4218 14.1125 20.1875L9.19563 15.2713C8.96075 15.0369 8.8286 14.7188 8.82824 14.387C8.82789 14.0551 8.95937 13.7368 9.19376 13.5019C9.42814 13.267 9.74623 13.1348 10.0781 13.1345C10.4099 13.1341 10.7283 13.2656 10.9631 13.5L13.75 16.2869V5.22813C13.75 4.8966 13.8817 4.57866 14.1161 4.34424C14.3505 4.10982 14.6685 3.97813 15 3.97813C15.3315 3.97813 15.6495 4.10982 15.8839 4.34424C16.1183 4.57866 16.25 4.8966 16.25 5.22813V16.2794L19.0369 13.4925C19.2726 13.2648 19.5884 13.1388 19.9161 13.1417C20.2439 13.1445 20.5574 13.276 20.7892 13.5077C21.0209 13.7395 21.1524 14.053 21.1552 14.3807C21.1581 14.7085 21.0321 15.0242 20.8044 15.26Z" fill="#570861" />
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mb-4">
                      <div className="certi_box">
                        <div className="certi_images text-center">
                          <img src="./assets/images/pdf_icon.png" alt="pdf_icon" />
                        </div>
                        <div className="certi_ctn">
                          <span className="bg-yellow text-purple rounded-3 d-inline-block px-3 mb-2">Music</span>
                          <h2 className="poppins fw-bold mb-2">What Traveling Greece For 2 Weeks Taught Me About Life</h2>
                          <span className="text-dif-black font-14">Jun 21, 2021 </span>
                          <div className="dwnload_btm d-flex mt-3 justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <div className="dwnl_img">
                                <img src="./assets/images/center_div_img2.png" alt="center_div_img2" />
                              </div>
                              <div className="ms-3">
                                <span className="font-14 fw-bold">George Costanza</span>
                              </div>
                            </div>
                            <div className>
                              <a href="#">
                                <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649929 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.39341 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.4721 23.3336C29.1203 20.8668 30 17.9667 30 15C30 11.0218 28.4197 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0ZM19.9206 24.6625H10.0794C9.74786 24.6625 9.42992 24.5308 9.1955 24.2964C8.96108 24.062 8.82938 23.744 8.82938 23.4125C8.82938 23.081 8.96108 22.763 9.1955 22.5286C9.42992 22.2942 9.74786 22.1625 10.0794 22.1625H19.9206C20.2522 22.1625 20.5701 22.2942 20.8045 22.5286C21.0389 22.763 21.1706 23.081 21.1706 23.4125C21.1706 23.744 21.0389 24.062 20.8045 24.2964C20.5701 24.5308 20.2522 24.6625 19.9206 24.6625ZM20.8044 15.26L15.88 20.1875C15.6456 20.4218 15.3277 20.5535 14.9963 20.5535C14.6648 20.5535 14.3469 20.4218 14.1125 20.1875L9.19563 15.2713C8.96075 15.0369 8.8286 14.7188 8.82824 14.387C8.82789 14.0551 8.95937 13.7368 9.19376 13.5019C9.42814 13.267 9.74623 13.1348 10.0781 13.1345C10.4099 13.1341 10.7283 13.2656 10.9631 13.5L13.75 16.2869V5.22813C13.75 4.8966 13.8817 4.57866 14.1161 4.34424C14.3505 4.10982 14.6685 3.97813 15 3.97813C15.3315 3.97813 15.6495 4.10982 15.8839 4.34424C16.1183 4.57866 16.25 4.8966 16.25 5.22813V16.2794L19.0369 13.4925C19.2726 13.2648 19.5884 13.1388 19.9161 13.1417C20.2439 13.1445 20.5574 13.276 20.7892 13.5077C21.0209 13.7395 21.1524 14.053 21.1552 14.3807C21.1581 14.7085 21.0321 15.0242 20.8044 15.26Z" fill="#570861" />
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {/*end*/}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>

      <TFooter_Dashboard />
    </>
  )
}
