import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import PFooter_Dashboard from "../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../Pinc/PHeader_Dashboard";
import { blog_details } from "../../../api/apiHandler";
import FilterBySearch from "../SearchClass/filterBySearch";
import BackToPrevious from "../../../components/backToPrevious";
import RelativeTime from "../../../utils/RelativeTime";

export default function PBlogs({ isLoader }) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);

  // React Query implementation
  const {
    data: blogResponse,
    isLoading,
    isError,
    error
  } = useQuery({
    queryKey: ['blogs', searchTerm, currentPage],
    queryFn: () => blog_details({ search: searchTerm, page: currentPage }),
    select: (response) => ({
      blogs: response?.data?.result || [],
      totalPages: response?.data?.totalPages || 1
    }),
    staleTime: 5 * 60 * 1000, // Data considered fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Cache kept for 30 minutes
    retry: 2,
    onError: (error) => {
      console.error("Error fetching blogs:", error);
    }
  });
  console.log('blogResponse :', blogResponse);

  // Update loader state based on query loading state
  useEffect(() => {
    isLoader(isLoading);
  }, [isLoading, isLoader]);

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < blogResponse?.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isError) {
    return <div className="text-center text-danger">Error: {error.message}</div>;
  }

  return (
    <>
      <JqueryLoad />
      <PHeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              <BackToPrevious />
              <div className>
                <div className=" ">
                  <div className="mb-4">
                    <h2 className="mb-2 poppins fw-bold">Blogs</h2>
                  </div>
                  <FilterBySearch search={searchTerm} handleSearch={(val) => setSearchTerm(val)} />
                  <div className="row mb-5 blogs_lists">
                    {blogResponse?.blogs?.length > 0 ? (
                      blogResponse.blogs.map((b_data, index) => (
                        <div key={index} className="col-xl-4 col-lg-6 col-md-6 mb-4">
                          <div className="certi_box bg-transparent shadow-none">
                            <div className="certi_images position-relative text-center bg-transparent p-0 border-bottom-0">
                              <div className="blog_img">
                                {b_data?.blog_media && b_data?.blog_media[0]?.type === "video" ? (
                                  <video controls className="w-100 img-fluid border-radius-10" style={{ height: "300px", objectFit: "cover" }}>
                                    <source src={b_data?.blog_media[0]?.media} type="video/mp4" />
                                  </video>
                                ) : (
                                  <img 
                                    src={b_data?.blog_media[0]?.media} 
                                    alt="blog" 
                                    className="w-100 img-fluid border-radius-10" 
                                    style={{ height: "300px", objectFit: "cover" }} 
                                  />
                                )}
                              </div>
                              <span className="bg-yellow border-radius-10 music-tag d-inline-block px-3 text-capitalize">
                                {b_data.media_type}
                              </span>
                            </div>
                            <Link to="/blog_details" state={b_data.id} className="d-block certi_ctn px-0">
                              <h2 className="fw-600 text-dif-black text-capitalize poppins mb-2 text-truncate">
                                {b_data.title}
                              </h2>
                              <div className="dwnload_btm d-flex mt-3 justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <div className="dwnl_img">
                                    <img src={b_data.profile_image} alt="center_div_img2" className="rounded-circle" />
                                  </div>
                                  <div className="ms-3">
                                    <span className="font-14 fw-bold">{b_data.admin_name}</span>
                                  </div>
                                </div>
                                <div>
                                  <span>{b_data?.formatted_date}</span> •&nbsp;
                                  <RelativeTime dateString={b_data.created_at} />
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))
                    ) : (
                      <h4 className="d-flex justify-content-center" style={{ color: "#570861" }}>
                        No Blogs Listed Yet, Please Check Again Later
                      </h4>
                    )}
                  </div>
                  <div className="custom_pagenation">
                    <ul className="d-flex align-items-center justify-content-center">
                      <li className={`circle ${currentPage === 1 ? 'disabled' : ''}`}>
                        <a href="#" className="text-purple font-w" onClick={handlePreviousPage}>
                          Pre
                        </a>
                      </li>
                      {[...Array(blogResponse?.totalPages || 0).keys()].map((pageNumber) => (
                        <li 
                          key={pageNumber} 
                          className={`circle ${pageNumber + 1 === currentPage ? 'active' : ''}`}
                        >
                          <a 
                            href="#" 
                            className="text-purple font-w" 
                            onClick={() => handlePaginationClick(pageNumber + 1)}
                          >
                            {pageNumber + 1}
                          </a>
                        </li>
                      ))}
                      <li className={`circle ${currentPage === blogResponse?.totalPages ? 'disabled' : ''}`}>
                        <a href="#" className="text-purple font-w" onClick={handleNextPage}>
                          Next
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <PFooter_Dashboard />
    </>
  );
}