// src/components/TextToSpeech.js
import React, { useState, useEffect, useRef } from 'react';
import { findAll } from 'highlight-words-core';

const TextToSpeech = ({ text }) => {
  const [highlightedText, setHighlightedText] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const utteranceRef = useRef(null);

  useEffect(() => {
    setHighlightedText([]);
    setCurrentWordIndex(0);
  }, [text]);

  const handleSpeech = () => {
    const words = text.split(' ');
    const utterance = new SpeechSynthesisUtterance(text);
    utteranceRef.current = utterance;

    utterance.onboundary = (event) => {
      if (event.name === 'word') {
        const charIndex = event.charIndex;
        const wordStartIndexes = [];
        let currentIndex = 0;

        // Calculate the start index of each word
        for (const word of words) {
          wordStartIndexes.push(currentIndex);
          currentIndex += word.length + 1; // +1 for the space
        }

        const wordIndex = wordStartIndexes.findIndex(
          (startIndex) =>
            charIndex >= startIndex &&
            charIndex < startIndex + words[wordStartIndexes.indexOf(startIndex)].length
        );
        if (wordIndex !== -1) {
          setCurrentWordIndex(wordIndex);
          highlightWords(wordIndex);
        }
      }
    };

    speechSynthesis.speak(utterance);
  };

//   const highlightWords = (index) => {
//     const ranges = findAll({
//       textToHighlight: text,
//       searchWords: text.split(' '),
//       sanitize: (word) => word,
//     });

//     const highlighted = ranges.map((range, idx) => ({
//       ...range,
//       highlight: idx === index,
//     }));

//     setHighlightedText(highlighted);
//   };

  const highlightWords = (index) => {
    const ranges = findAll({
      textToHighlight: text,
      searchWords: text.split(' '),
      sanitize: (word) => word,
    });
  
    const highlighted = ranges.map((range, idx) => ({
      ...range,
      highlight: idx == index,
    }));
  
    setHighlightedText(highlighted);
  };
  

  const stopSpeech = () => {
    if (utteranceRef.current) {
      speechSynthesis.cancel();
      setHighlightedText([]);
      setCurrentWordIndex(0);
    }
  };

  return (
    <div>
      <button onClick={handleSpeech}>Speak</button>
      <button onClick={stopSpeech}>Stop</button>
      <div>
        {highlightedText.length > 0
          ? highlightedText.map((word, index) => (
              <span
                key={index}
                style={{
                  backgroundColor: word.highlight ? 'yellow' : 'transparent',
                }}
              >
                {text.substring(word.start, word.end)}{' '}
              </span>
            ))
          : text}
      </div>
    </div>
  );
};

export default TextToSpeech;
