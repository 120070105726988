// import React, { useState, useEffect } from 'react'
// import { Link, useNavigate, useLocation } from 'react-router-dom';
// import { Controller, useForm } from "react-hook-form";
// import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
// import TFooter_Dashboard from '../../Include/TFooter_Dashboard'
// import THeader_Dashboard from '../../Include/THeader_Dashboard'
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { classroom_edit_lesson, classroom_lesson_list } from '../../../../api/apiHandler';
// import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/common.service';
// import { uploadImageOnAWS } from '../../../aws/Service';


// export default function EditLesson() {
//     useEffect(() => {
//         window.scrollTo(0, 0)
//     }, [])

//     const location = useLocation();
//     const navigate = useNavigate();

//     var edit_id = location?.state;

//     const schema = yup.object().shape({
//         lesson_topic: yup.string().required("Lesson topic is required"),
//     });

//     const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
//         resolver: yupResolver(schema)
//     });

//     const [lessonData, setLessonData] = useState([])
//     console.log('lessonData :', lessonData);
//     const [attachmentData, setAttachmentData] = useState([])
//     console.log('attachmentData :', attachmentData);
//     var [classTitleLength, setclassTitleLength] = useState(0);
//     var [classHoursData, setClassHoursData] = useState('0')
//     var [classMinutesData, setClassMinutesData] = useState('55')
//     const classroom_lessonlist = () => {

//         classroom_lesson_list({ classroom_id: edit_id }).then((resposnse) => {
//             console.log('resposnse :', resposnse);

//             if (resposnse.code == 1) {
//                 setclassTitleLength(resposnse?.data?.[0]?.lession_topic.length)
//                 setClassHoursData(resposnse?.data?.[0]?.duration?.split(' ')?.[0])
//                 setClassMinutesData(resposnse?.data?.[0]?.duration?.split(' ')?.[2])
//                 reset({

//                     hours: resposnse?.data?.[0]?.duration?.split(' ')?.[0],
//                     minutes: resposnse?.data?.[0]?.duration?.split(' ')?.[2],
//                     attachments_data: resposnse?.data?.[0]?.attachments

//                 });
//                 setLessonData(resposnse?.data?.[0])
//                 setAttachmentData(resposnse?.data?.[0]?.lessiondata)
//             } else {
//                  
//             }
//         });
//     }

//     let classTitleData = (e) => {

//         setclassTitleLength(0)
//     }

//     useEffect(() => {
//         classroom_lessonlist();
//     }, [])

//     const [lessonDetails, setLessonDetails] = useState([{ description: '', attachment: null }]);

//     const handleAddDescription = () => {
//         setAttachmentData([...attachmentData, { description: '', attachment: null }]);
//     };


//     const [profileImageState, setProfileImageState] = useState('')

//     const [AttachmentData1, setAttachmentData1] = useState({})

//     const handleImageChange = async (image, Attachment_Index, fileIndex, type) => {
//         if (image != null) {
//             TOAST_SUCCESS("Your attachment has been uploaded!");
//         }
//         var str = "attachment_" + Attachment_Index
//         var temp_arr = AttachmentData1
//         if (temp_arr[str]) {
//             var image_name = await uploadImageOnAWS(image, "lesson_attachments");

//             temp_arr[str][fileIndex] = { attachments: image_name, attachment_type: type };
//         } else {
//             var image_name = await uploadImageOnAWS(image, "lesson_attachments");

//             temp_arr[str] = ['', '', '', '', '', '']
//             temp_arr[str][fileIndex] = { attachments: image_name, attachment_type: type }

//         }
//         setAttachmentData1(temp_arr)
//         setValue("attachments_data", setAttachmentData1);

//         const bufferImage = await convertToBase64(image);
//         setProfileImageState(bufferImage);
//         clearErrors("attachments_data");
//     }

//     let onSubmit = async (data) => {

//         let edit_lesson = {
//             classroom_id: edit_id.toString(),
//             lession_topic: data.lesson_topic,
//             duration: data.hours + " Hour" + (data.hours != 1 ? "s " : " ") + data.minutes + " Minutes",
//         }
//         var description_arr = [];
//         attachmentData.map((lesson, index) => {
//             var temp_obj = {}

//             var temp_str = "lesson_description_" + index;
//             var attach_str = "attachment_" + index
//             temp_obj.description = data[temp_str]
//             temp_obj.des_id = lesson.des_id == undefined ? "0" : lesson.des_id.toString()
//             if (AttachmentData1[attach_str]) {
//                 var originalArray = AttachmentData1[attach_str];
//                 var newArray = originalArray.filter(item => item !== '');
//                 temp_obj.attachments = newArray
//             } else {
//                 temp_obj.attachments = []
//             }
//             description_arr.push(temp_obj);

//         });
//         edit_lesson.lessiondata = description_arr
//         console.log('edit_lesson :', edit_lesson);

//         classroom_edit_lesson(edit_lesson).then((res) => {

//             if (res.code == 1) {
//                 TOAST_SUCCESS(res?.message);
//                 navigate('/teach-detail', {
//                     state: { id: lessonData.activity_id, class: lessonData.activity_type }
//                 })
//             } else {
//                 TOAST_ERROR(res?.message);
//             }
//         });
//         reset();

//     }
//     return (
//         <>
//             <JqueryLoad />
//             <THeader_Dashboard />
//             <main>
//                 <div className="dashboard-app d-lg-flex">
//                     <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
//                         <div className="align-items-center justify-content-between d-flex">
//                             <div className="d-flex align-items-center">
//                                 <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
//                                     <i className="fa fa-bars" />
//                                 </a>
//                                 <a href="#" className="d-lg-none d-block brand-logo">
//                                     <img src="./assets/images/logo.png" alt="logo" />
//                                 </a>
//                             </div>
//                         </div>
//                     </header>
//                     <div className="class-content">
//                         <div className>
//                             <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" onclick="window.history.back()">
//                                 <span>
//                                     <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                         <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
//                                     </svg>
//                                     <span onClick={() => navigate(-1)} className="ms-2">Back</span>
//                                 </span></a>
//                             <div className>
//                                 <div className=" ">
//                                     <div className="row">
//                                         <div className="col-xl-8">
//                                             <div className="mb-4">
//                                                 <h2 className="mb-2 poppins fw-bold">Edit Lesson Information</h2>
//                                             </div>

//                                             <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
//                                                 <div className="form-group mb-4">
//                                                     <label className="form-label">Add Lesson Topic</label>
//                                                     <div className="position-relative class_title">
//                                                         <input type="text" {...register("lesson_topic")} maxLength={50} name="lesson_topic" className="form-control" placeholder id defaultValue={lessonData.lession_topic} onKeyUp={(e) => classTitleData(e.target.value)} />
//                                                         <span className="position-absolute word_count">
//                                                             {Math.max((0, 50 - (classTitleLength + ((watch("lesson_topic")?.split('')?.length ?? 0)))))} Words
//                                                         </span>

//                                                         <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.lesson_topic?.message}</span></p>
//                                                     </div>
//                                                 </div>
//                                                 {attachmentData?.map((des_data, index) => {
//                                                     return (<>
//                                                         <div className="form-group mb-4">
//                                                             <label className="form-label">Add Lesson Description</label>
//                                                             <textarea className="form-control" {...register(`lesson_description_${index}`)}
//                                                                 name={`lesson_description_${index}`} rows={7} id>{des_data.description}</textarea>

//                                                         </div>
//                                                         <div className="form-group mb-4 attach_box">
//                                                             <label className="form-label">Add Attachments</label>
//                                                             <div className="position-relative">
//                                                                 <textarea type="text" className="form-control" name rows={7} />
//                                                                 <div className="att_icon_list position-absolute">
//                                                                     <ul className="d-flex align-items-center justify-content-end">
//                                                                         <li>
//                                                                             <label htmlFor={`attach1_${index}`}>
//                                                                                 <a>
//                                                                                     <img src="./assets/images/attach1.png" alt="attach1" />
//                                                                                 </a>
//                                                                             </label>
//                                                                             <input type="file" id={`attach1_${index}`} onInput={(e) => {
//                                                                                 const file = e.target.files[0];
//                                                                                 const fileType = file.type.startsWith('image') ? 'image' : 'video';
//                                                                                 handleImageChange(file, index, 0, fileType);
//                                                                             }} {...register("attachments")} accept="image/*,video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

//                                                                         </li>
//                                                                         <li>
//                                                                             <label htmlFor={`attach2_${index}`}>
//                                                                                 <a >
//                                                                                     <img src="./assets/images/attach2.png" alt="attach2" />
//                                                                                 </a>
//                                                                             </label>
//                                                                             <input type="file" id={`attach2_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

//                                                                         </li>
//                                                                         <li>
//                                                                             <label htmlFor={`attach3_${index}`}>
//                                                                                 <a>
//                                                                                     <img src="./assets/images/attach3.png" alt="attach3" />
//                                                                                 </a>
//                                                                                 <input type="file" id={`attach3_${index}`}  {...register("attachments")} accept="image/*" onInput={(e) => handleImageChange(e.target.files[0], index, 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
//                                                                             </label>
//                                                                         </li>
//                                                                         <li>
//                                                                             <label htmlFor={`attach4_${index}`}>
//                                                                                 <a>
//                                                                                     <img src="./assets/images/attach4.png" alt="attach4" />
//                                                                                 </a>
//                                                                                 <input type="file" id={`attach4_${index}`} {...register("attachments")} accept=".docx" onInput={(e) => handleImageChange(e.target.files[0], index, 3, "docx")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
//                                                                             </label>
//                                                                         </li>
//                                                                         <li>

//                                                                             <label htmlFor={`attach5_${index}`}>

//                                                                                 <a >
//                                                                                     <img src="./assets/images/attach5.png" alt="attach5" />
//                                                                                 </a>
//                                                                                 <input type="file" id={`attach5_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 4, "xlsx")} accept=".xlsx" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

//                                                                             </label>
//                                                                         </li>
//                                                                         <li>
//                                                                             <label htmlFor={`attach6_${index}`}>

//                                                                                 <a >
//                                                                                     <img src="./assets/images/attach6.png" alt="attach6" />
//                                                                                 </a>
//                                                                                 <input type="file" id={`attach6_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 5, "pdf")} accept=".pdf" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
//                                                                             </label>
//                                                                         </li>
//                                                                     </ul>
//                                                                 </div>
//                                                             </div>
//                                                             <div className="d-flex justify-content-end mt-3">
//                                                             </div>
//                                                         </div> </>)
//                                                 })}
//                                                 <div className="d-flex justify-content-end mt-3">
//                                                     <a className="text-decoration-underline text-purple" onClick={handleAddDescription}>
//                                                         + Add another Description
//                                                     </a>
//                                                 </div>

//                                                 <h3 className="mb-3">Lesson duration</h3>
//                                                 <div className="d-md-flex mb-4 align-items-center">
//                                                     <p className="fs-6  mb-md-0 mb-3">Expected Time Spent On Lesson Hours </p>
//                                                     <div className="d-md-flex ms-3">
//                                                         <div className="d-md-flex mb-md-0 mb-3 align-items-center">
//                                                             <select className="form-select mb-md-0 mb-3 w-auto pe-5 rounded-pill" {...register("hours")} name="hours" aria-label="Default select example">
//                                                                 <option selected={classHoursData === '1' ? 'selected' : ''} value={1}>1 </option>
//                                                                 <option selected={classHoursData === '2' ? 'selected' : ''} value={2}>2 </option>
//                                                                 <option selected={classHoursData === '3' ? 'selected' : ''} value={3}>3 </option>
//                                                                 <option selected={classHoursData === '4' ? 'selected' : ''} value={4}>4 </option>
//                                                             </select>
//                                                             <span className="ms-3">Minutes</span>
//                                                         </div>
//                                                         <div className="ms-md-3">
//                                                             <select className="form-select w-auto pe-5 rounded-pill" {...register("minutes")} name="minutes" aria-label="Default select example">
//                                                                 <option selected={classMinutesData === '0' ? 'selected' : null} value={0}>0</option>
//                                                                 <option selected={classMinutesData === '10' ? 'selected' : null} value={10}>10</option>
//                                                                 <option selected={classMinutesData === '15' ? 'selected' : null} value={15}>15</option>
//                                                                 <option selected={classMinutesData === '20' ? 'selected' : null} value={20}>20</option>
//                                                                 <option selected={classMinutesData === '25' ? 'selected' : null} value={25}>25</option>
//                                                                 <option selected={classMinutesData === '30' ? 'selected' : null} value={30}>30</option>
//                                                                 <option selected={classMinutesData === '35' ? 'selected' : null} value={35}>35</option>
//                                                                 <option selected={classMinutesData === '40' ? 'selected' : null} value={40}>40</option>
//                                                                 <option selected={classMinutesData === '45' ? 'selected' : null} value={45}>45</option>
//                                                                 <option selected={classMinutesData === '50' ? 'selected' : null} value={50}>50</option>
//                                                                 <option selected={classMinutesData === '55' ? 'selected' : null} value={55}>55</option>
//                                                             </select>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 <div className="enviorment_div mb-5 align-items-center d-md-flex">
//                                                     <div className="env_img">
//                                                         <img src="./assets/images/enviorment.png" alt="enviorment" />
//                                                     </div>
//                                                     <div className="env_text">
//                                                         <p className="text-500">Help save the envionrment. Cut down on printing. Instead please use Google docs, sheets and slides which is freely available to you.</p>
//                                                     </div>
//                                                 </div>
//                                                 <div className="button">
//                                                     <button className="btn-theme bg-yellow text-center fw-600 d-block" >
//                                                         Edit Lesson
//                                                     </button>
//                                                 </div>
//                                             </form>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//             <TFooter_Dashboard />
//         </>
//     )
// }


import React, { useState, useEffect, forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad';
import TFooter_Dashboard from '../../Include/TFooter_Dashboard';
import THeader_Dashboard from '../../Include/THeader_Dashboard';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { classroom_edit_lesson, classroom_lesson_list } from '../../../../api/apiHandler';
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/common.service';
import { uploadImageOnAWS } from '../../../aws/Service';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = forwardRef(({ value, onChange }, ref) => {
    const quillRef = React.useRef();

    useEffect(() => {
        if (quillRef.current) {
            quillRef.current.focus();
        }
    }, []);

    useImperativeHandle(ref, () => ({
        focus: () => {
            if (quillRef.current) {
                quillRef.current.focus();
            }
        },
        getEditor: () => {
            return quillRef.current?.getEditor();
        }
    }));

    return (
        <ReactQuill
            ref={quillRef}
            value={value}
            onChange={onChange}
            modules={Editor.modules}
            formats={Editor.formats}
            theme="snow"
            placeholder="Write something..."
            style={{ borderRadius: '10px' }}
        />
    );
});

Editor.modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean'],
    ],
};

Editor.formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
    'image',
];


export default function EditLesson({isLoader}) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation();
    const navigate = useNavigate();

    var edit_id = location?.state;
    console.log('edit_id :', edit_id);

    const schema = yup.object().shape({
        lesson_topic: yup.string().required('Please enter lesson topic')
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Lesson topic cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        )
        .min(20, "Lesson topic must contain at least 20 characters long")
        .max(50, "Lesson topic must contain at most 50 characters long"),
      hours: yup.string().test(
        'duration-validation',
        'Duration cannot be 0 hours and 0 minutes',
        function (value) {
          const minutes = this.parent.minutes;
          return !(value === "0" && minutes === "0");
        }
      ),
      minutes: yup.string().test(
        'duration-validation',
        'Duration cannot be 0 hours and 0 minutes',
        function (value) {
          const hours = this.parent.hours;
          return !(hours === "0" && value === "0");
        }
      )
    });

    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [lessonData, setLessonData] = useState([]);
    const [attachmentData, setAttachmentData] = useState([]);
    console.log('attachmentData :', attachmentData);
    var [classTitleLength, setclassTitleLength] = useState(0);
    var [classHoursData, setClassHoursData] = useState('0');
    var [classMinutesData, setClassMinutesData] = useState('55');

    const classroom_lessonlist = () => {
        classroom_lesson_list({ classroom_id: edit_id }).then((response) => {
            if (response?.code == 1) {
                const lessonData = response.data?.[0];
                const descriptions = lessonData?.lessiondata.map((item) => ({
                    description: item.description,
                    attachment: item.attachments[0]?.attachment ?? null,
                })) ?? [];

                setLessonDetails(descriptions);
                setclassTitleLength(response?.data?.[0]?.lession_topic.length);
                setClassHoursData(response?.data?.[0]?.duration?.split(' ')?.[0]);
                setClassMinutesData(response?.data?.[0]?.duration?.split(' ')?.[2]);
                reset({
                    hours: response?.data?.[0]?.duration?.split(' ')?.[0],
                    minutes: response?.data?.[0]?.duration?.split(' ')?.[2],
                    attachments_data: response?.data?.[0]?.attachments
                });
                setLessonData(response?.data?.[0]);
                setAttachmentData(response?.data?.[0]?.lessiondata);
            } else {
                 ;
            }
        });
    }

    let classTitleData = (e) => {
        setclassTitleLength(0);
    }

    useEffect(() => {
        classroom_lessonlist();
    }, []);

    const [lessonDetails, setLessonDetails] = useState([{ description: '', attachment: null }]);

    const handleAddDescription = () => {
        setAttachmentData([...attachmentData, { description: '', attachment: null }]);
    };

    const [profileImageState, setProfileImageState] = useState('');
    const [AttachmentData1, setAttachmentData1] = useState({});

    const handleImageChange = async (image, Attachment_Index, fileIndex, type) => {
        if (image != null) {
            TOAST_SUCCESS("Your attachment has been uploaded!");
        }
        var str = "attachment_" + Attachment_Index;
        var temp_arr = AttachmentData1;
        if (temp_arr[str]) {
            var image_name = await uploadImageOnAWS(image, "lesson_attachments");
            temp_arr[str][fileIndex] = { attachments: image_name, attachment_type: type };
        } else {
            var image_name = await uploadImageOnAWS(image, "lesson_attachments");
            temp_arr[str] = ['', '', '', '', '', ''];
            temp_arr[str][fileIndex] = { attachments: image_name, attachment_type: type };
        }
        setAttachmentData1(temp_arr);
        setValue("attachments_data", setAttachmentData1);

        const bufferImage = await convertToBase64(image);
        setProfileImageState(bufferImage);
        clearErrors("attachments_data");
    }

    let onSubmit = async (data) => {
        let edit_lesson = {
            classroom_id: edit_id.toString(),
            lession_topic: data.lesson_topic,
            duration: data.hours + " Hour" + (data.hours != 1 ? "s " : " ") + data.minutes + " Minutes",
        }
        var description_arr = [];
        attachmentData.map((lesson, index) => {
            var temp_obj = {};
            var temp_str = "lesson_description_" + index;
            var attach_str = "attachment_" + index;
            temp_obj.description = data[temp_str];
            temp_obj.des_id = lesson.des_id == undefined ? "0" : lesson.des_id.toString();
            if (AttachmentData1[attach_str]) {
                var originalArray = AttachmentData1[attach_str];
                var newArray = originalArray.filter(item => item !== '');
                temp_obj.attachments = newArray;
            } else {
                temp_obj.attachments = [];
            }
            description_arr.push(temp_obj);
        });
        edit_lesson.lessiondata = description_arr;
        classroom_edit_lesson(edit_lesson).then((res) => {
            if (res?.code == 1) {
                TOAST_SUCCESS(res?.message);
                navigate('/teach-detail', {
                    state: { id: lessonData.activity_id, class: lessonData.activity_type }
                });
            } else {
                TOAST_ERROR(res?.message);
            }
        });
        reset();
    }

    return (
        <>
            <JqueryLoad />
            <THeader_Dashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className
                                        ="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div className>
                            <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span  className="ms-2">Back</span>
                                </span></a>
                            <div className>
                                <div className=" ">
                                    <div className="row">
                                        <div className="col-xl-8">
                                            <div className="mb-4">
                                                <h2 className="mb-2 poppins fw-bold">Edit Lesson Information</h2>
                                            </div>
                                            <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                                                <div className="form-group mb-4">
                                                    <label className="form-label">Add Lesson Topic</label>
                                                    <div className="position-relative class_title">
                                                        <input type="text" {...register("lesson_topic")} maxLength={50} name="lesson_topic" className="form-control" placeholder id defaultValue={lessonData.lession_topic} onKeyUp={(e) => classTitleData(e.target.value)} />
                                                        <span className="position-absolute word_count">
                                                            {Math.max((0, 50 - (classTitleLength + ((watch("lesson_topic")?.split('')?.length ?? 0)))))} Words
                                                        </span>
                                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.lesson_topic?.message}</span></p>
                                                    </div>
                                                </div>
                                                {attachmentData?.map((des_data, index) => {
                                                    return (<>

                                                        <div key={index}>
                                                            {console.log('des_data :', des_data)}
                                                            <div className="form-group mb-4">
                                                                <label className="form-label">Add Lesson Description</label>
                                                                <Controller
                                                                    name={`lesson_description_${index}`}
                                                                    control={control}
                                                                    defaultValue={des_data.description || ""}
                                                                    render={({ field }) => {
                                                                        console.log("Default Value:", des_data.description); // Log default value
                                                                        console.log("Field Value:", field.value); // Log field value
                                                                        return (
                                                                            <Editor
                                                                                value={field.value || ""}
                                                                                onChange={field.onChange}
                                                                            />
                                                                        );
                                                                    }}
                                                                />

                                                                {errors[`lesson_description_${index}`] && (
                                                                    <p className='mt-2 font-bold col-red'>
                                                                        <span style={{ color: 'red' }}>
                                                                            {errors[`lesson_description_${index}`]?.message}
                                                                        </span>
                                                                    </p>
                                                                )}
                                                            </div>
                                                            <div className="form-group mb-4 attach_box">
                                                                <label className="form-label">Add Attachments</label>
                                                                <div className="position-relative">
                                                                    <textarea type="text" className="form-control" name rows={7} />
                                                                    <div className="att_icon_list position-absolute">
                                                                        <ul className="d-flex align-items-center justify-content-end">
                                                                            <li>
                                                                                <label htmlFor={`attach1_${index}`}>
                                                                                    <a>
                                                                                        <img src="./assets/images/attach1.png" alt="attach1" />
                                                                                    </a>
                                                                                </label>
                                                                                <input type="file" id={`attach1_${index}`} onInput={(e) => {
                                                                                    const file = e.target.files[0];
                                                                                    const fileType = file.type.startsWith('image') ? 'image' : 'video';
                                                                                    handleImageChange(file, index, 0, fileType);
                                                                                }} {...register("attachments")} accept="image/*,video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                            </li>
                                                                            <li>
                                                                                <label htmlFor={`attach2_${index}`}>
                                                                                    <a >
                                                                                        <img src="./assets/images/attach2.png" alt="attach2" />
                                                                                    </a>
                                                                                </label>
                                                                                <input type="file" id={`attach2_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                            </li>
                                                                            <li>
                                                                                <label htmlFor={`attach3_${index}`}>
                                                                                    <a>
                                                                                        <img src="./assets/images/attach3.png" alt="attach3" />
                                                                                    </a>
                                                                                    <input type="file" id={`attach3_${index}`}  {...register("attachments")} accept="image/*" onInput={(e) => handleImageChange(e.target.files[0], index, 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                                </label>
                                                                            </li>
                                                                            <li>
                                                                                <label htmlFor={`attach4_${index}`}>
                                                                                    <a>
                                                                                        <img src="./assets/images/attach4.png" alt="attach4" />
                                                                                    </a>
                                                                                    <input type="file" id={`attach4_${index}`} {...register("attachments")} accept=".docx" onInput={(e) => handleImageChange(e.target.files[0], index, 3, "docx")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                                </label>
                                                                            </li>
                                                                            <li>

                                                                                <label htmlFor={`attach5_${index}`}>

                                                                                    <a >
                                                                                        <img src="./assets/images/attach5.png" alt="attach5" />
                                                                                    </a>
                                                                                    <input type="file" id={`attach5_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 4, "xlsx")} accept=".xlsx" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                                </label>
                                                                            </li>
                                                                            <li>
                                                                                <label htmlFor={`attach6_${index}`}>

                                                                                    <a >
                                                                                        <img src="./assets/images/attach6.png" alt="attach6" />
                                                                                    </a>
                                                                                    <input type="file" id={`attach6_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 5, "pdf")} accept=".pdf" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                                </label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-end mt-3">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>)
                                                })}
                                                <div className="d-flex justify-content-end mt-3">
                                                    <a className="text-decoration-underline text-purple" onClick={handleAddDescription}>
                                                        + Add another Description
                                                    </a>
                                                </div>

                                                <h3 className="mb-3">Lesson duration</h3>
                                                <div className="d-md-flex mb-4 align-items-center">
                                                    <p className="fs-6  mb-md-0 mb-3">Expected Time Spent On Lesson Hours </p>
                                                    <div className="d-md-flex ms-3">
                                                        <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                                            <select className="form-select mb-md-0 mb-3 w-auto pe-5 rounded-pill" {...register("hours")} name="hours" aria-label="Default select example">
                                                                <option selected={classHoursData === '1' ? 'selected' : ''} value={1}>1 </option>
                                                                <option selected={classHoursData === '2' ? 'selected' : ''} value={2}>2 </option>
                                                                <option selected={classHoursData === '3' ? 'selected' : ''} value={3}>3 </option>
                                                                <option selected={classHoursData === '4' ? 'selected' : ''} value={4}>4 </option>
                                                            </select>
                                                            <span className="ms-3">Minutes</span>
                                                        </div>
                                                        <div className="ms-md-3">
                                                            <select className="form-select w-auto pe-5 rounded-pill" {...register("minutes")} name="minutes" aria-label="Default select example">
                                                                <option selected={classMinutesData === '0' ? 'selected' : null} value={0}>0</option>
                                                                <option selected={classMinutesData === '10' ? 'selected' : null} value={10}>10</option>
                                                                <option selected={classMinutesData === '15' ? 'selected' : null} value={15}>15</option>
                                                                <option selected={classMinutesData === '20' ? 'selected' : null} value={20}>20</option>
                                                                <option selected={classMinutesData === '25' ? 'selected' : null} value={25}>25</option>
                                                                <option selected={classMinutesData === '30' ? 'selected' : null} value={30}>30</option>
                                                                <option selected={classMinutesData === '35' ? 'selected' : null} value={35}>35</option>
                                                                <option selected={classMinutesData === '40' ? 'selected' : null} value={40}>40</option>
                                                                <option selected={classMinutesData === '45' ? 'selected' : null} value={45}>45</option>
                                                                <option selected={classMinutesData === '50' ? 'selected' : null} value={50}>50</option>
                                                                <option selected={classMinutesData === '55' ? 'selected' : null} value={55}>55</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="enviorment_div mb-5 align-items-center d-md-flex">
                                                    <div className="env_img">
                                                        <img src="./assets/images/enviorment.png" alt="enviorment" />
                                                    </div>
                                                    <div className="env_text">
                                                        <p className="text-500">Help save the envionrment. Cut down on printing. Instead please use Google docs, sheets and slides which is freely available to you.</p>
                                                    </div>
                                                </div>
                                                <div className="button">
                                                    <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                                                        Edit Lesson
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <TFooter_Dashboard />
        </>
    );
}


