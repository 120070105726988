import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Controller, useForm } from "react-hook-form";
import Modals from 'react-modal';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { reply_on_review, review_list } from '../../../api/apiHandler';
import { formatDate, TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import CryptoJS from 'crypto-js';
import queryString from 'query-string';
const dayjs = require('dayjs');
var key = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNfTmDXHWVkQWdanYOm');
var iv = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNf');

var COMMON_STYLE_FOR_POPUP = {
  content: {
    background: 'none',
    border: '0px',
  },
}

export default function Review() {

  const location = useLocation();

  // var id = location?.state;
  // console.log('id :', id);
  var after = useParams();
  if (location?.search) {
    const queryParams = new URLSearchParams(location?.search);
    const encryptedData = queryParams.get('data');
  
    // Define your key and IV (must be the same as used for encryption)
    // const key = CryptoJS.enc.Utf8.parse('your-key-here');
    // const iv = CryptoJS.enc.Utf8.parse('your-iv-here');
  
    // Decode and decrypt the data
    const decryptedData1 = CryptoJS.AES.decrypt(decodeURIComponent(encryptedData), key, { iv: iv }).toString(CryptoJS.enc.Utf8);
    console.log('decryptedData1 :', decryptedData1);
    const params = new URLSearchParams(decryptedData1);
    console.log('params :', params);

     id = {
      id: parseInt(params?.get('activityId'), 10),  // Assuming id is a number
      class: params?.get('typeClass'),
      type_class: params?.get('type'),
    };;
    console.log('idxyz1 :', id);
    var edit_class_id = id?.id;
    // setDataType(id?.type)
  }else if (location?.state) {
    var id = location?.state;
    console.log('idxyz :', id);
    var edit_class_id = id?.id;
  } else {
    // const { id } = useParams();
    // var type = location?.search?.split("=")[1];
    // console.log('type :', type);
    // var id = after
    // id.class = type
    // console.log('idtype :', id);
    const queryParams = queryString.parse(location.search);

    // Extract specific query parameters
    const type = queryParams.type;

    const typeClass = queryParams?.type_class;

    var id = after
    id.class = type
    id.type_class = typeClass
  }
  const [data, setData] = useState([]);

  const review_listing = (learner_id) => {
    // isLoader(true)

    review_list({ class_id: id.id, type: "class" }).then((response) => {
      console.log("review_list", response)
      // isLoader(false)

      if (response?.code == 1) {
        setData(response.data)
      } else {
         
      }
    });
  }

  useEffect(() => {
    review_listing();
  }, []);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [reviewId, setReviewId] = useState(false);

  const schema = yup.object().shape({
    reply: yup.string().required("This is required"),
  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  let onSubmit = async (data) => {
    console.log('dataonSubmit :', data);

    try {
      let send_data = {
        id: reviewId,
        type_id: id?.id,
        type: id?.class,
        reply: data.reply
      }

      console.log('send_data123 :', send_data);
      const response = await reply_on_review(send_data);
      console.log('reply_on_review :', response);
      if (response?.code == 1) {
        TOAST_SUCCESS(response.message)
        reset();
        setIsOpen(false)
      } else {
        TOAST_ERROR(response.message)

      }
    } catch (error) {
      console.log('error :', error);
    }
  }


  return (
    <>
      {/* <div className="tab-pane fade" id="pills-review" role="tabpanel" aria-labelledby="pills-review-tab"> */}
      {/*start*/}
      <div className>
        {data?.length > 0 ?
          data?.map((review_data, index) => {
            return (

              <div className="card review_box mb-4">
                <div className="review_top">
                  <div className="d-lg-flex justify-content-between mb-3 align-items-satrt">
                    <div className="review_user d-flex align-items-center">
                      <img src="./assets/images/user/user1.png" alt="user1" />
                      <div className="ms-3">
                        <span className="d-block fs-5">{review_data.full_name}</span>
                        {/* <ul className="d-flex d-lg-none align-items-center">
                          <li className="me-2">
                            <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M20.8942 25.5637C20.5767 25.5637 20.2608 25.4662 19.9888 25.2721L13.5953 20.687L7.20182 25.2721C6.93692 25.4627 6.61859 25.5647 6.29227 25.5636C5.96595 25.5624 5.64832 25.4582 5.38476 25.2658C5.12094 25.0745 4.92417 24.8049 4.82243 24.4953C4.7207 24.1858 4.71918 23.852 4.8181 23.5415L7.2026 15.8457L0.866469 11.3809C0.603876 11.1871 0.40886 10.9156 0.309004 10.6049C0.209148 10.2942 0.209508 9.95997 0.310033 9.64947C0.411351 9.33973 0.607448 9.06967 0.87061 8.87744C1.13377 8.68522 1.45069 8.58056 1.77657 8.57826L9.62404 8.56647L12.1225 1.06166C12.2256 0.752496 12.4234 0.483606 12.6878 0.293068C12.9522 0.102529 13.2698 0 13.5957 0C13.9216 0 14.2393 0.102529 14.5037 0.293068C14.7681 0.483606 14.9658 0.752496 15.0689 1.06166L17.525 8.56647L25.4125 8.57826C25.7388 8.5801 26.0562 8.6847 26.3196 8.87722C26.583 9.06973 26.7791 9.34035 26.8799 9.65065C26.9808 9.96095 26.9813 10.2951 26.8814 10.6057C26.7815 10.9163 26.5862 11.1876 26.3234 11.3809L19.9873 15.8457L22.3718 23.5415C22.4709 23.8519 22.4696 24.1857 22.368 24.4953C22.2663 24.8049 22.0697 25.0745 21.8059 25.2658C21.5415 25.4597 21.2221 25.5641 20.8942 25.5637Z" fill="#CEC9C1" />
                            </svg>
                          </li>
                          <li className="me-2">
                            <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33" />
                            </svg>
                          </li>
                          <li className="me-2">
                            <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33" />
                            </svg>
                          </li>
                          <li className="me-2">
                            <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33" />
                            </svg>
                          </li>
                          <li className>
                            <svg width={15} height={16} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33" />
                            </svg>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                    <ul className="d-lg-flex d-none mb-lg-0 mb-2 align-items-center">
                      {/* <li className="me-2">
                        <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20.8942 25.5637C20.5767 25.5637 20.2608 25.4662 19.9888 25.2721L13.5953 20.687L7.20182 25.2721C6.93692 25.4627 6.61859 25.5647 6.29227 25.5636C5.96595 25.5624 5.64832 25.4582 5.38476 25.2658C5.12094 25.0745 4.92417 24.8049 4.82243 24.4953C4.7207 24.1858 4.71918 23.852 4.8181 23.5415L7.2026 15.8457L0.866469 11.3809C0.603876 11.1871 0.40886 10.9156 0.309004 10.6049C0.209148 10.2942 0.209508 9.95997 0.310033 9.64947C0.411351 9.33973 0.607448 9.06967 0.87061 8.87744C1.13377 8.68522 1.45069 8.58056 1.77657 8.57826L9.62404 8.56647L12.1225 1.06166C12.2256 0.752496 12.4234 0.483606 12.6878 0.293068C12.9522 0.102529 13.2698 0 13.5957 0C13.9216 0 14.2393 0.102529 14.5037 0.293068C14.7681 0.483606 14.9658 0.752496 15.0689 1.06166L17.525 8.56647L25.4125 8.57826C25.7388 8.5801 26.0562 8.6847 26.3196 8.87722C26.583 9.06973 26.7791 9.34035 26.8799 9.65065C26.9808 9.96095 26.9813 10.2951 26.8814 10.6057C26.7815 10.9163 26.5862 11.1876 26.3234 11.3809L19.9873 15.8457L22.3718 23.5415C22.4709 23.8519 22.4696 24.1857 22.368 24.4953C22.2663 24.8049 22.0697 25.0745 21.8059 25.2658C21.5415 25.4597 21.2221 25.5641 20.8942 25.5637Z" fill="#CEC9C1" />
                        </svg>
                      </li>
                      <li className="me-2">
                        <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33" />
                        </svg>
                      </li>
                      <li className="me-2">
                        <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33" />
                        </svg>
                      </li>
                      <li className="me-2">
                        <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33" />
                        </svg>
                      </li>
                      <li className>
                        <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20.8473 25.5637C20.5298 25.5637 20.2139 25.4662 19.942 25.2721L13.5485 20.687L7.15494 25.2721C6.89005 25.4627 6.57171 25.5647 6.24539 25.5636C5.91907 25.5624 5.60145 25.4582 5.33788 25.2658C5.07407 25.0745 4.87729 24.8049 4.77556 24.4953C4.67382 24.1858 4.67231 23.852 4.77123 23.5415L7.15573 15.8457L0.819594 11.3809C0.557001 11.1871 0.361985 10.9156 0.262129 10.6049C0.162273 10.2942 0.162633 9.95997 0.263158 9.64947C0.364476 9.33973 0.560573 9.06967 0.823735 8.87744C1.0869 8.68522 1.40381 8.58056 1.7297 8.57826L9.57717 8.56647L12.0756 1.06166C12.1787 0.752497 12.3765 0.483606 12.6409 0.293068C12.9053 0.102529 13.2229 0 13.5488 0C13.8747 0 14.1924 0.102529 14.4568 0.293068C14.7212 0.483606 14.919 0.752497 15.0221 1.06166L17.4781 8.56647L25.3656 8.57826C25.6919 8.5801 26.0093 8.6847 26.2727 8.87722C26.5361 9.06973 26.7322 9.34035 26.8331 9.65065C26.9339 9.96095 26.9344 10.2951 26.8345 10.6057C26.7346 10.9163 26.5394 11.1876 26.2765 11.3809L19.9404 15.8457L22.3249 23.5415C22.424 23.8519 22.4227 24.1857 22.3211 24.4953C22.2195 24.8049 22.0228 25.0745 21.759 25.2658C21.4946 25.4597 21.1752 25.5641 20.8473 25.5637Z" fill="#FFAC33" />
                        </svg>
                      </li> */}
                      <i data-star={review_data.rating}></i>

                    </ul>
                  </div>
                  <div className="review_text row mb-4">
                    <div className="col-lg-6">
                      <p className="fw-500">{review_data.review}</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="text-gray fs-6">{dayjs(review_data.created_at).format('MMMM D, YYYY')}</span>
                    {localStorage.getItem("type") == "teacher" &&
                      <a onClick={(e) => { setIsOpen(true); setReviewId(review_data.review_id) }} data-bs-toggle="modal">Reply</a>
                    }
                  </div>
                </div>
              </div>
            )
          }) :
          <h4 className="d-flex justify-content-center" style={{ "color": "#570861" }}>Data Not Found</h4>
        }
      </div>
      {/*end*/}
      {/* </div>  */}
      <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >

        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title poppins fw-600" id="exampleModalLabel">Give Reply</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => { setIsOpen(false); reset(); }} aria-label="Close" />
            </div>
            <div className="modal-body">
              <div className>
                <form onSubmit={handleSubmit(onSubmit)} className="custom_form" action id>

                  <div className="form-group ">
                    <label className="mb-2">Give Reply</label>
                    <textarea type="text" className="form-control" name='reply' {...register("reply")} />
                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.reply?.message}</span></p>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn-theme bg-yellow d-inline-block fw-500" data-bs-dismiss="modal">Send</button>

                    <button type="button" onClick={() => { setIsOpen(false); reset(); }} className="btn-theme btn-prple-border d-inline-block text-center text-dif-black fw-500">Close</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modals>
    </>
  )
}
