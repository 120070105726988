import { axiosClient } from './apiClient';

export function signUpp(data) {
console.log('signUpp :', data);
  return axiosClient.post('/v1/auth/signup', data);
}

export function add_learner(data) {
  return axiosClient.post('v1/auth/add_learner', data);
}

export function learner_list_parents(data) {
  return axiosClient.post('v1/student_parent/learner_list_parents', data);
}

export function learner_avatar_images(data) {
  return axiosClient.post('v1/student_parent/learner_avatar_images', data);
}

export function learner_view_profile(data) {
  return axiosClient.post('v1/auth/learner_view_profile', data);
}

export function edit_learner(data) {
  return axiosClient.post('/v1/auth/edit_learner', data);
}

export function delete_learner(data) {
  return axiosClient.post('/v1/auth/delete_learner', data);
}

export function delete_class(data) {
  return axiosClient.post('/v1/auth/delete_class', data);
}

export function loginApi(data) {
  return axiosClient.post('/v1/auth/login', data);
}

export function verify_otp(data) {
  return axiosClient.post('/v1/auth/verify_otp', data);
}

export function add_class(data) {
  return axiosClient.post('v1/auth/add_class', data);
}

export function delete_class_data(data) {
  return axiosClient.post('/v1/auth/delete_class', data);
}

export function forgot_password(data) {
  return axiosClient.post('/v1/auth/forgot_password', data);
}

export function reset_password(data) {
  return axiosClient.post('/v1/auth/reset_password', data);
}

export function resendOtpApi(data) {
  return axiosClient.post('/v1/auth/sendotp', data);
}

export function Change_Passsword(data) {
  return axiosClient.post('/v1/auth/change_password', data);
}

export function view_profile(data) {
  return axiosClient.post('/v1/auth/view_profile', data);
}

export function editprofile(data) {
  return axiosClient.post('/v1/auth/editprofile', data);
}

export function add_email_for_newsletter(data) {
  return axiosClient.post('/v1/auth/add_email_for_newsletter', data);
}

export function edit_class_after_school(data) {
  return axiosClient.post('/v1/auth/edit_class_after_school', data);
}

export function add_class_country_list(data) {
  return axiosClient.post('/v1/teacher/student_country_list', data);
}

export function teacher_add_class(data) {
  return axiosClient.post('/v1/teacher/teacher_add_classes', data);
}

export function check_is_meeting_started(data) {
  return axiosClient.post('/v1/teacher/check_is_meeting_started', data);
}

export function teacher_class_listing(data) {
  return axiosClient.post('/v1/teacher/add_class_search', data);
}

export function teacher_class_detail(data) {
  return axiosClient.post('/v1/teacher/class_details_teacher', data);
}

export function teacher_edit_class_detail(data) {
  return axiosClient.post('/v1/teacher/edit_classes', data);
}

export function teacher_delete_class_detail(data) {
  return axiosClient.post('/v1/teacher/teacher_delete_class', data);
}

export function teacher_add_club(data) {
console.log('teacher_add_club :', data);
  return axiosClient.post('/v1/teacher/add_clubs', data);
}

export function teacher_club_listing(data) {
  return axiosClient.post('/v1/teacher/add_club_search', data);
}

export function teacher_delete_club_detail(data) {
  return axiosClient.post('/v1/teacher/teacher_delete_club', data);
}

export function teacher_club_detail(data) {
  return axiosClient.post('v1/teacher/club_details_teacher', data);
}

export function teacher_edit_club_detail(data) {
  return axiosClient.post('/v1/teacher/edit_club', data);
}

export function my_statiticks(data) {
  return axiosClient.post('v1/student_parent/my_statiticks', data);
}

// export function my_statistics(data){

//     return axiosClient.post('/v1/student_parent/my_statiticks',data);
// }

export function interested_class(data) {
  return axiosClient.post('/v1/student_parent/interested_class', data);
}

export function latest_news(data) {
  return axiosClient.post('/v1/student_parent/latest_news', data);
}

export function calendar_data(data) {
  return axiosClient.post('/v1/student_parent/calender', data);
}

export function search_class(data) {
console.log('search_class :', data);
  return axiosClient.post('/v1/class/search_class', data);
}

export function search_class_data(data) {
  return axiosClient.post('/v1/class/search_class', data);
}

export function search_clubs(data) {
console.log('search_club :', data);
  return axiosClient.post('/v1/class/search_clubs', data);
}

export function search_club_data(data) {
  return axiosClient.post('/v1/class/search_clubs', data);
}

export function search_quiz(data) {
  return axiosClient.post('/v1/class/search_quiz', data);
}

export function search_quiz_data(data) {
  return axiosClient.post('/v1/class/search_quiz', data);
}

export function search_competitions(data) {
  return axiosClient.post('v1/class/search_competitions', data);
}

export function search_competitation_data(data) {
  return axiosClient.post('v1/class/search_competitions', data);
}

export function class_details(data) {
  return axiosClient.post('v1/class/class_details', data);
}

export function club_details(data) {
  return axiosClient.post('v1/class/club_details', data);
}

export function quiz_details(data) {
  return axiosClient.post('v1/class/quiz_details', data);
}

export function competition_details(data) {
  return axiosClient.post('v1/class/competition_details', data);
}

export function available_classes(data) {

  return axiosClient.post('v1/class/available_classes', data);
}


export function meet_the_teacher(data) {
  return axiosClient.post('v1/class/meet_the_teacher', data);
}

export function other_classes(data) {
  return axiosClient.post('v1/class/other_classes', data);
}

export function recently_viewed(data) {
  return axiosClient.post('v1/class/recently_viewed', data);
}

export function ask_the_teacher_details(data) {
console.log('data_club :', data);
  return axiosClient.post('v1/class/ask_the_teacher_details', data);
}

export function ask_the_teacher(data) {
  return axiosClient.post('v1/class/ask_the_teacher', data);
}

export function request_another_time(data) {
  return axiosClient.post('v1/class/request_another_time', data);
}

export function request_private_class(data) {
  return axiosClient.post('v1/student_parent/request_private_class', data);
}

export function club_available_time(data) {
  return axiosClient.post('v1/class/club_available_time', data);
}

export function club_meet_the_teacher(data) {
  return axiosClient.post('v1/class/club_meet_the_teacher', data);
}

export function quiz_available_times(data) {
  return axiosClient.post('/v1/class/quiz_available_times', data);
}

export function competition_available_times(data) {
  return axiosClient.post('v1/class/competition_available_times', data);
}

export function club_available_times(data) {
  return axiosClient.post('v1/class/club_available_times', data);
}

export function quiz_meet_the_teacher(data) {
console.log('quiz_meet_the_teacher :', data);
  return axiosClient.post('/v1/class/quiz_meet_the_teacher', data);
}

export function competition_meet_the_teacher(data) {
  return axiosClient.post('v1/class/competition_meet_the_teacher', data);
}

export function my_enrollments_parents(data) {
  return axiosClient.post('v1/student_parent/my_enrollments_parents', data);
}

export function my_enrollments_kids(data) {
  return axiosClient.post('v1/student_parent/my_enrollments_students', data);
}

export function user_homework_list(data) {
  return axiosClient.post('v1/student_parent/user_homework_list', data);
}

export function user_assetment_list(data) {
  return axiosClient.post('v1/student_parent/user_assetment_list', data);
}

export function user_quiz_list(data) {
  return axiosClient.post('v1/student_parent/user_quiz_list', data);
}

export function parents_review_form(data) {
  return axiosClient.post('v1/student_parent/parents_review_form', data);
}

export function parents_feedback_form(data) {

  return axiosClient.post('v1/student_parent/parents_feedback_form', data);
}

export function user_classroom_list(data) {
  return axiosClient.post('v1/student_parent/user_classroom_list', data);
}

export function user_discussion_list(data) {
  return axiosClient.post('v1/student_parent/user_discussion_list', data);
}

export function blog_details(data) {
  return axiosClient.post('v1/pages/blog_details', data);
}

export function blogswise_details(data) {
  return axiosClient.post('v1/pages/blogswise_details', data);
}

export function contact_us(data) {
  return axiosClient.post('v1/pages/contact_us', data);
}

export function privacy_policy(data) {
  return axiosClient.post('v1/pages/privacy_policy', data);
}

export function request_class(data) {
  return axiosClient.post('v1/class/request_class', data);
}

export function new_prev_class(data) {
  return axiosClient.post('v1/class/new_prev_class', data);
}

export function previous_classes(data) {
  return axiosClient.post('v1/class/previous_classes', data);
}

export function refer_friend_code(data) {
  return axiosClient.post('v1/auth/refer_friend_code', data);
}

export function get_user_details(data) {
  return axiosClient.post('v1/auth/get_user_details', data);
}

export function logout(data) {
  return axiosClient.post('v1/auth/logout', data);
}

export function sponsered_teacher(data) {
  return axiosClient.post('v1/class/sponsered_teacher', data);
}

export function respond_teacher(data) {
  return axiosClient.post('v1/class/respond_teacher', data);
}

export function teacher_view_profile(data) {
  return axiosClient.post('v1/teacher/teacher_view_profile', data);
}

export function teacher_reviews_list(data) {
  return axiosClient.post('v1/teacher/teacher_reviews_list', data);
}

export function main_category_list(data) {
  return axiosClient.post('v1/teacher/main_category_list', data);
}

export function sub_category_request_class(data) {
  return axiosClient.post('v1/teacher/sub_category_request_class', data);
}

export function purchase_coupan(data) {
  return axiosClient.post('v1/teacher/purchase_coupan', data);
}

export function state_list(data) {
  return axiosClient.post('v1/teacher/add_class_state_list', data);
}

export function my_favourites_parents_kids(data) {
  return axiosClient.post('v1/student_parent/my_favourites_parents_kids', data);
}

export function parents_class_feedback_details(data) {
  return axiosClient.post('v1/student_parent/parents_class_feedback_details', data);
}

export function request_name_details(data) {
  return axiosClient.post('v1/class/request_name_details', data);
}

export function review_detail_class_name(data) {
  return axiosClient.post('v1/student_parent/review_detail_class_name', data);
}

export function classroom_view_details(data) {
console.log('classroom_view_details :', data);
  return axiosClient.post('v1/student_parent/classroom_view_details', data);
}

export function homework_view_details(data) {
console.log('homework_view_details :', data);
  return axiosClient.post('v1/student_parent/homework_view_details', data);
}

export function assessment_view_details(data) {
console.log('assessment_view_details :', data);
  return axiosClient.post('v1/student_parent/assetment_view_details', data);
}

export function getSimilarClasses(data) {
  return axiosClient.post('v1/class/similar_like_this_classes', data);
}

export function getSimilarClubs(data) {
  return axiosClient.post('v1/class/similar_like_this_clubs', data);
}

export function getSimilarCompetitions(data) {
  return axiosClient.post('v1/class/similar_like_this_competition', data);
}

export function getSimilarQuizes(data) {
  return axiosClient.post('v1/class/similar_like_this_quiz', data);
}

export function getQuizQuestion(data) {
  return axiosClient.post('v1/student_parent/review_of_quiz', data);
}

export function getQuizAnswer(data) {
  return axiosClient.post('v1/student_parent/manage_of_quiz', data);
}

export function getQuizInfo(data) {
  return axiosClient.post('v1/teacher/history_quiz', data);
}

export function insertQuizAnswer(data) {
  return axiosClient.post('v1/student_parent/students_inserting_question_answers', data);
}

export function getQuizResult(data) {
  return axiosClient.post('v1/student_parent/quiz_result', data);
}

export function certificate_quiz(data) {
  return axiosClient.post('v1/student_parent/certificate_quiz', data);
}


export function class_certificate(data) {
  return axiosClient.post('v1/student_parent/class_certificate', data);
}

export function get_user_budget(data) {
  return axiosClient.post('v1/student_parent/get_user_budget', data);
}

export function teacher_subscribed_and_payments_made(data) {

  return axiosClient.post('v1/student_parent/teacher_subscribed_and_payments_made', data);
}

export function get_time_zone_details(data) {
  return axiosClient.post('v1/student_parent/get_time_zone_details', data);
}

export function footer_curreency_details(data) {
  return axiosClient.post('v1/student_parent/footer_curreency_details', data);
}

export function class_meeting_data(data) {

  return axiosClient.post('v1/student_parent/class_meeting_data', data);
}

export function join_the_meetings(data) {

  return axiosClient.post('v1/student_parent/join_the_meetings', data);
}

export function parents_class_requirement_form(data) {

  return axiosClient.post('v1/student_parent/parents_class_requirement_form', data);
}

export function country_list_class(data) {
  return axiosClient.post('/v1/class/country_list_class', data);
}

export function state_listing_classwise(data) {
  return axiosClient.post('/v1/class/state_listing_classwise', data);
}

export function subject_category_list(data) {
  return axiosClient.post('/v1/teacher/subject_category_list', data);
}

export function teacher_schedule_calender(data) {
  return axiosClient.post('/v1/teacher/teacher_schedule_calender', data);
}

export function New_Leads_details(data) {
  return axiosClient.post('/v1/teacher/request_class_detail', data);
}

//////// Stripe api ////////////////
export function create_payment(data) {
  return axiosClient.post('/v1/stripe/create_payment', data);
}

export function add_card(data) {
  return axiosClient.post('/v1/stripe/add_card', data);
}

export function start_pause_subscriptions(data) {
  return axiosClient.post('/v1/stripe/start_stop_pause_subcriptions', data);
}

export function meeting_details(data) {

  return axiosClient.post('/v1/stripe/meeting_details', data);
}

export function chat_list(data) {
  return axiosClient.post('/v1/chat/parent_recent_chat_list', data);
}

export function chat_history(data) {
  return axiosClient.post('/v1/chat/personal_chat_list', data);
}

export function pin_chat(data) {
  return axiosClient.post('/v1/chat/pin_chat', data);
}

export function get_faq_video(data) {
  return axiosClient.post('/v2/admin/faq_training_video_list', data);
}

export function update_read_status_enrollment(data) {
  return axiosClient.post('/v1/teacher/teach_green_dot', data);
}

export function update_read_status_request_class(data) {
  return axiosClient.post('/v1/teacher/green_dot', data);
}

export function update_user_activity_status(data) {
  return axiosClient.post('/v1/chat/user_status', data);
}

export function upload_homework(data) {
  console.log('data2 :', data);
  return axiosClient.post('/v1/student_parent/homework_upload', data);
}

export function upload_assignment(data) {
  return axiosClient.post('/v1/student_parent/assesments_upload', data);
}

export function parent_data(data) {

  return axiosClient.post('/v1/auth/switch_profile', data);
}

export function progress_report_learners_list(data) {
  return axiosClient.post('/v1/student_parent/progress_report_user_list', data);
}

export function progress_report_student_parents(data) {
  return axiosClient.post('/v1/student_parent/progress_report_student_parents', data);
}

export function progress_report_teacher_side(data) {
  return axiosClient.post('/v1/class/progress_report_teacher_side', data);
}

export function notification_listing(data) {
  return axiosClient.post('/v1/auth/notification_listing', data);
}

export function after_school_class_list(data) {
  return axiosClient.post('/v1/student_parent/after_school_class_list', data);
}

export function my_certificate_list(data) {
  return axiosClient.post('/v1/student_parent/my_certificate_list', data);
}

export function get_user_credits_earnings(data) {
  return axiosClient.post('/v1/student_parent/get_user_credits_earnings', data);
}


export function transfer_schedule_list(data) {
  return axiosClient.post('/v1/stripe/transfer_schedule_list', data);
}

export function transfer_schedule_request(data) {

  return axiosClient.post('/v1/stripe/transfer_schedule_request', data);
}

export function coupan_listing(data) {

  return axiosClient.post('/v1/stripe/coupan_details', data);
}
export function coupans_check(data) {

  return axiosClient.post('/v1/stripe/coupans_check', data);
}

export function refund_request_parent_students(data) {
console.log('data refund:', data);

  return axiosClient.post('/v1/stripe/refund_request_parent_students', data);
}



//landig page api

export function explore_new_classes(data) {
  return axiosClient.post('/v1/landing/explore_new_classes', data);
}

export function within_24_hours_cls(data) {
  return axiosClient.post('/v1/landing/hours_24', data);
}

export function under_25_dollor_cls(data) {
  return axiosClient.post('/v1/landing/under_price', data);
}

export function expand_your_career(data) {
  return axiosClient.post('/v1/landing/expand_carrer_opportuninty', data);
}

export function upcoming_events(data) {
  return axiosClient.post('/v1/landing/upcoming_events', data);
}

export function meet_our_global_team(data) {
  return axiosClient.post('/v1/landing/meet_our_global_team', data);
}

export function landing_latest_news(data) {
  return axiosClient.post('/v1/landing/landing_latest_news', data);
}

export function landing_contact_us(data) {
  return axiosClient.post('/v1/landing/landing_contact_us', data);
}

export function terms_conditions(data) {
  return axiosClient.post('/v1/pages/terms_conditions', data);
}


export function generateAgoraToken(data) {
  return axiosClient.post('v1/teacher/generate_agora_token', data);
}

export function updateMeeting(data) {
  return axiosClient.post('v1/teacher/update_meeting', data);
}


export function joinedMeetingUserList(data) {
  return axiosClient.post('v1/teacher/joined_meeting_user_list', data);
}

export function end_meeting(data) {
  return axiosClient.post('v1/teacher/end_meeting', data);
}

export function teacher_add_competition(data) {

  return axiosClient.post('/v1/teacher/add_competition', data);
}

export function teacher_competition_listing(data) {
  return axiosClient.post('/v1/teacher/add_competition_search', data);
}

export function teacher_delete_competition_detail(data) {
  return axiosClient.post('/v1/teacher/teacher_delete_competition', data);
}
// change function name
export function teacher_competition_detail(data) {
  return axiosClient.post('/v1/teacher/competition_details_teacher', data);
}

export function teacher_edit_competition_detail(data) {
  return axiosClient.post('/v1/teacher/edit_competition', data);
}

//  6/11/2023
export function teacher_add_quiz(data) {
console.log('teacher_add_quiz :', data);
  return axiosClient.post('/v1/teacher/add_quizes', data);
}

export function teacher_quiz_question_list(data) {
  return axiosClient.post('/v1/teacher/question_list', data);
}

export function teacher_quiz_question_delete(data) {
  return axiosClient.post('/v1/teacher/delete_question', data);
}

export function teacher_quiz_question_details(data) {
  return axiosClient.post('/v1/teacher/question_details', data);
}

export function teacher_edit_quiz_question(data) {
  return axiosClient.post('/v1/teacher/edit_question', data);
}

export function teacher_add_another_question(data) {
  return axiosClient.post('/v1/teacher/add_another_question', data);
}

export function teacher_add_quiz_listing(data) {
  return axiosClient.post('/v1/teacher/add_quiz_search', data);
}

export function teacher_delete_quiz_detail(data) {
  return axiosClient.post('/v1/teacher/delete_quiz', data);
}

export function teacher_quiz_details(data) {
  return axiosClient.post('/v1/teacher/quiz_details', data);
}

export function teacher_edit_quiz(data) {
  return axiosClient.post('/v1/teacher/edit_quizs', data);
}

export function add_coupon(data) {
  return axiosClient.post('/v1/teacher/add_coupan', data);
}

export function teacher_add_lession(data) {
console.log('teacher_add_lession :', data);
  return axiosClient.post('/v1/teacher/teacher_add_lession', data);
}

export function teacher_add_homework(data) {
  return axiosClient.post('/v1/teacher/teacher_add_homework', data);
}

export function teacher_add_assetment(data) {
  return axiosClient.post('/v1/teacher/teacher_add_assetment', data);
}

export function teacher_add_discussion(data) {
console.log('teacher_add_discussion :', data);
  return axiosClient.post('/v1/teacher/teacher_add_discussion', data);
}

export function teach_class(data) {
  return axiosClient.post('v1/teacher/new_teach_class', data);
}

export function attendance_details(data) {
  return axiosClient.post('v1/teacher/attendance_details', data);
}

export function teach_club(data) {
  return axiosClient.post('/v1/teacher/new_teach_club', data);
}

export function teach_competation(data) {
  return axiosClient.post('/v1/teacher/new_tech_competition', data);
}

export function teach_quiz(data) {
  return axiosClient.post('/v1/teacher/new_teach_quiz', data);
}

export function coupon_class(data) {
  return axiosClient.post('/v1/teacher/get_classes', data);
}

export function coupon_list(data) {
  return axiosClient.post('/v1/teacher/my_coupan', data);
}

export function add_purchase_coupon(data) {
  return axiosClient.post('/v1/teacher/add_purchase_coupan', data);
}

export function purchase_coupon_detail(data) {
  return axiosClient.post('/v1/teacher/puchase_detail_coupan', data);
}

export function classroom_list(data) {
  return axiosClient.post('/v1/teacher/classroom_list', data);
}

export function class_room_listing(data) {
  return axiosClient.post('/v1/teacher/class_room_listing', data);
}

export function classroom_lesson_list(data) {
  return axiosClient.post('/v1/teacher/teacher_classroom_details', data);
}

export function classroom_edit_lesson(data) {
  return axiosClient.post('v1/teacher/teacher_edit_lession', data);
}

export function classroom_delete_lesson(data) {
  return axiosClient.post('/v1/teacher/teacher_classroom_delete', data);
}

export function homework_list(data) {
  return axiosClient.post('/v1/teacher/homework_list', data);
}

export function homework_upload(data) {
  return axiosClient.post('/v1/student_parent/homework_upload', data);
}

export function learner_name_list(data) {
  return axiosClient.post('/v1/teacher/learner_homework_list', data);
}

export function assetment_list_data(data) {
  return axiosClient.post('/v1/teacher/assetment_list', data);
}

export function discussion_name_list(data) {
  return axiosClient.post('/v1/teacher/learner_discussion_list', data);
}

export function assetment_delete_data(data) {
  return axiosClient.post('/v1/teacher/teacher_assetments_delete', data);
}

export function assetment_details(data) {
  return axiosClient.post('/v1/teacher/teacher_assetments_details', data);
}

export function assetment_edit_data(data) {
  return axiosClient.post('/v1/teacher/teacher_edit_assetments', data);
}

export function disscussion_list(data) {
  return axiosClient.post('/v1/teacher/discussion_list', data);
}

export function disscussion_delete_data(data) {
  return axiosClient.post('/v1/teacher/teacher_discussion_delete', data);
}

export function disscussion_data(data) {
  return axiosClient.post('/v1/teacher/teacher_discussion_details', data);
}

export function disscussion_edit_data(data) {
  return axiosClient.post('/v1/teacher/teacher_edit_duscussion', data);
}

export function xyz(data) {
  return axiosClient.post('/v1/class/class_details', data);
}

export function assesment_leraner_list(data) {
  return axiosClient.post('/v1/teacher/learner_assetment_list', data);
}

export function teach_quiz_listing(data) {
  return axiosClient.post('/v1/teacher/quiz_listing', data);
}

export function teacher_feedback_form(data) {
  return axiosClient.post('/v1/teacher/feedback_form', data);
}

export function add_meeting(data) {
  return axiosClient.post('/v1/teacher/setup_meetings', data);
}

export function class_detail_of_teacher(data) {
console.log('class_detail_of_teacher :', data);
  return axiosClient.post('/v1/class/class_details', data);
}

// export function state_list(data) {

//   return axiosClient.post('/v1/class/provinces_list', data);
// }

export function available_times(data) {
  return axiosClient.post('/v1/class/available_classes', data);
}

export function ask_teacher_details(data) {
  return axiosClient.post('/v1/class/ask_the_teacher_details', data);
}

export function teacher_other_class_data(data) {
  return axiosClient.post('/v1/class/other_classes', data);
}

export function refer_friend(data) {
  return axiosClient.post('/v1/auth/refer_friend_code', data);
}

export function reacently_view_class(data) {
  return axiosClient.post('/v1/class/recently_viewed', data);
}

// new leads

export function Teacher_New_Leads(data) {
  return axiosClient.post('/v1/teacher/new_leads_teacher', data);
}

//responded req
export function responded_class_list(data) {
  return axiosClient.post('/v1/teacher/responded_request_classes', data);
}

// Rejected req
export function rejected_class_list(data) {
  return axiosClient.post('/v1/teacher/rejected_class_list', data);
}

export function teacher_detail_of_leads(data) {
  return axiosClient.post('/v1/teacher/teacher_detail_new_leads', data);
}

export function reject_new_leads(data) {

  return axiosClient.post('/v1/teacher/reject_request_class', data);
}

export function teacher_select_class_list(data) {

  return axiosClient.post('/v1/teacher/class_list_new_leads', data);
}

export function accept_request_class(data) {
  return axiosClient.post('/v1/teacher/accept_request_class', data);
}

//country_list
export function country_list(data) {
  return axiosClient.post('/v1/teacher/add_class_country_list', data);
}

export function state_province_list(data) {
  return axiosClient.post('/v1/teacher/add_class_state_list', data);
}

// teach review
export function org_step_2_details(data) {
  return axiosClient.post('/v1/teacher/org_step_2_details', data);
}

export function review_list(data) {
  return axiosClient.post('/v1/teacher/class_reviews', data);
}

export function reply_on_review(data) {
  return axiosClient.post('/v1/teacher/reply_on_review', data);
}

export function prefered_teacher(data) {
  return axiosClient.post('/v1/teacher/prefered_teacher', data);
}

//main category list 

export function teacher_my_statistics(data) {
  return axiosClient.post('/v1/teacher/teacher_my_statistics', data);
}

//view profile of teacher
export function teacherViewProfile(data) {
  return axiosClient.post('/v1/auth/view_profile', data);
}

export function search_teacher_profile(data) {
  return axiosClient.post('/v1/teacher/teacher_view_profile', data);
}

//add meeting
export function teacher_setup_meetings(data) {
  console.log('teacher_setup_meetings :', data);
  return axiosClient.post('/v1/teacher/setup_meetings', data);
}

export function teacher_meetings_list(data) {
  return axiosClient.post('/v1/teacher/upcoming_classes', data);
}

export function teacher_repeat_meetings(data) {
console.log('teacher_repeat_meetings :', data);
  return axiosClient.post('/v1/teacher/repeat_meeting', data);
}

export function teacher_delete_meetings(data) {
  return axiosClient.post('/v1/teacher/delete_meetings', data);
}

export function teacher_edit_meeting_details(data) {
  return axiosClient.post('/v1/teacher/meeting_details', data);
}

export function teacher_edit_meetings(data) {
console.log('teacher_edit_meetings :', data);
  return axiosClient.post('/v1/teacher/edit_meetings', data);
}

export function teacher_show_past_meeting(data) {
  return axiosClient.post('/v1/teacher/show_past_meeting', data);
}

export function teacher_logout(data) {
  return axiosClient.post('/v1/auth/logout', data);
}

export function student_list(data) {
  return axiosClient.post('/v1/teacher/student_list', data);
}

export function teacher_calender_date_list(data) {
  return axiosClient.post('/v1/teacher/teacher_calender_date_list', data);
}

export function calender_schedule_list(data) {
  return axiosClient.post('/v1/org/calender_shedule_list', data);
}

export function org_calender_date_list(data) {
  return axiosClient.post('/v1/org/org_calender_date_list', data);
}

export function teacher_name_list(data) {
  return axiosClient.post('/v1/org/teacher_list', data);
}

// questions of prospective students
export function question_prospective(data) {
  return axiosClient.post('/v1/teacher/question_prospective', data);
}

//similar class 
export function similar_like_this_classes(data) {
  return axiosClient.post('/v1/class/similar_like_this_classes', data);
}

export function highest_generating_class(data) {
  return axiosClient.post('/v1/teacher/highest_generating_class', data);
}

//follow
export function teacher_follow(data) {
  return axiosClient.post('/v1/follow/follows', data);
}


//CLub Details of teacher

export function teacher_club_details(data) {
  return axiosClient.post('/v1/class/club_details', data);
}

export function similar_like_this_clubs(data) {
  return axiosClient.post('/v1/class/similar_like_this_clubs', data);
}

export function recently_viewed_clubs(data) {
  return axiosClient.post('/v1/class/recently_viewed_clubs', data);
}

export function other_clubs_list(data) {
  return axiosClient.post('/v1/class/other_clubs_list', data);
}

//competition details of teacher

export function teacher_competition_details(data) {
  return axiosClient.post('/v1/class/competition_details', data);
}

export function other_competitions_list(data) {
  return axiosClient.post('/v1/class/other_competitions_list', data);
}

export function recently_viewed_competition(data) {
  return axiosClient.post('/v1/class/recently_viewed_competition', data);
}

export function similar_like_this_competition(data) {
  return axiosClient.post('/v1/class/similar_like_this_competition', data);
}

export function meeting_checks(data) {
  return axiosClient.post('/v1/class/meeting_checks', data);
}

export function price_converted(data) {
  return axiosClient.post('/v1/class/price_converted', data);
}



// quiz details of teacher

export function teacher_search_quiz_details(data) {
  return axiosClient.post('/v1/class/quiz_details', data);
}

export function similar_like_this_quiz(data) {
  return axiosClient.post('/v1/class/similar_like_this_quiz', data);
}

export function recently_viewed_quiz(data) {
  return axiosClient.post('/v1/class/recently_viewed_quiz', data);
}

export function other_quizes_list(data) {
  return axiosClient.post('/v1/class/other_quizes_list', data);
}

export function teacher_homework_view_details(data) {
  return axiosClient.post('/v1/teacher/view_homework_details', data);
}

export function student_discussion_list(data) {
  return axiosClient.post('/v1/teacher/student_discussion_list', data);
}

export function discussion_upload_marks(data) {
  return axiosClient.post('/v1/teacher/discussion_upload_marks', data);
}


// quiz view details and start

export function historyQuiz(data) {
  return axiosClient.post('/v1/teacher/history_quiz', data);
}

export function history_quiz(data) {
  return axiosClient.post('/v1/teacher/history_quiz', data);
}

export function insert_quiz_answer(data) {
  return axiosClient.post("/v1/student_parent/inserting_question_answer", data);
}

export function highest_income_teachers(data) {
  return axiosClient.post("/v1/org/highest_income_teachers", data);
}

export function invite_teacher(data) {
  return axiosClient.post("/v1/org/invite_teacher", data);
}

export function manage_teacher_list(data) {
  return axiosClient.post("/v1/org/manage_teacher_list", data);
}

export function faq_list(data) {
  return axiosClient.post("/v1/pages/faq_list", data);
}

export function contact_us_details(data) {
  return axiosClient.post("/v1/pages/contact_us_details", data);
}

export function get_faq_video_details(data) {
  return axiosClient.post("/v2/admin/faq_training_video_detail", data);
}

export function chat_list_t(data) {
  return axiosClient.post("/v1/chat/recent_chat_list", data);
}

export function show_personal_chat_list(data) {
  return axiosClient.post("/v1/chat/show_personal_chat_list", data);
}

export function block_chat(data) {
  return axiosClient.post("/v1/chat/block_chat", data);
}

export function total_lerner(data) {
  return axiosClient.post("/v1/chat/total_lerner", data);
}

export function org_teacher_learner_list(data) {
  return axiosClient.post("/v1/chat/org_teacher_learner_list", data);
}

export function scheduled_date_list(data) {
  return axiosClient.post("/v1/student_parent/parents_calender_date_list", data);
}

export function organization_my_statistics(data) {
  return axiosClient.post("/v1/org/organization_my_statistics", data);
}

export function favorites(data) {
  return axiosClient.post("/v1/class/favorites", data);
}

export function chat_count(data) {
  return axiosClient.post("/v1/chat/chat_count", data);
}

// teacher step 

export function teacher_application(data) {
  return axiosClient.post("/v1/auth/teacher_application", data);
}

export function step_comments(data) {
  return axiosClient.post("/v1/auth/step_comments", data);
}

export function teacher_identity(data) {
  return axiosClient.post("/v1/auth/teacher_identity", data);
}

export function teacher_background_check(data) {
console.log('teacher_background_check :', data);
  return axiosClient.post("/v1/auth/teacher_background_check", data);
}

export function step_1_details(data) {
  return axiosClient.post("/v1/auth/step_1_details", data);
}

export function step_2_details(data) {
  return axiosClient.post("/v1/auth/step_2_details", data);
}

export function step_3_details(data) {
  return axiosClient.post("/v1/auth/step_3_details", data);
}

//org step 

export function org_application(data) {
console.log('org_application :', data);
  return axiosClient.post("/v1/auth/org_application", data);
}

export function org_identity_application(data) {
console.log('org_identity_application :', data);
  return axiosClient.post("/v1/auth/org_identity_application", data);
}

export function org_agreement_application(data) {
console.log('org_agreement_application :', data);
  return axiosClient.post("/v1/auth/org_agreement_application", data);
}

export function org_location_approval_application(data) {
console.log('org_location_approval_application :', data);
  return axiosClient.post("/v1/auth/org_location_approval_application", data);
}

export function org_teacher_permission_listing(data) {
  return axiosClient.post("/v1/auth/org_teacher_permission_listing", data);
}

// manage teacher permission

export function permission_tag_show(data) {
  return axiosClient.post("/v1/org/permission_tag_show", data);
}

export function teacher_permission(data) {
  return axiosClient.post("/v1/org/teacher_permission", data);
}

export function permission_view_details(data) {
  return axiosClient.post("/v1/org/permission_view_details", data);
}

export function my_earnings_teacher(data) {
  return axiosClient.post("/v1/teacher/my_earnings_teacher", data);
}

export function my_earning_list(data) {
  return axiosClient.post("/v1/teacher/my_earning_list", data);
}

export function invoice_pdf(data) {
  return axiosClient.post("/v1/teacher/invoice_pdf", data);
}

export function homework_upload_listing(data) {
  return axiosClient.post("/v1/teacher/homework_upload_listing", data);
}

export function assetment_upload_listing(data) {
  return axiosClient.post("/v1/teacher/assetment_upload_listing", data);
}

export function homework_upload_marks(data) {
  return axiosClient.post("/v1/teacher/homework_upload_marks", data);
}

export function assetment_upload_marks(data) {
  return axiosClient.post("/v1/teacher/assetment_upload_marks", data);
}

export function progress_report_learner_list(data) {
  return axiosClient.post("/v1/class/progress_report_learner_list", data);
}

export function teacher_progress_feedback(data) {
  return axiosClient.post("/v1/class/teacher_progress_feedback", data);
}

export function calculate_discount(data) {
  return axiosClient.post("/v1/teacher/calculate_discount", data);
}

export function class_option_payment_list(data) {
  return axiosClient.post("/v1/stripe/class_option_payment_list", data);
}

export function cancelled_meetings_inserted(data) {
  return axiosClient.post("/v1/stripe/cancelled_meetings_inserted", data);
}

export function start_stop_pause_subcriptions(data) {
  return axiosClient.post("/v1/stripe/start_stop_pause_subcriptions", data);
}

export function schedule_request_list(data) {
  return axiosClient.post("/v1/stripe/schedule_request_list", data);
}

export function transfer_schedule_accept(data) {
  return axiosClient.post("/v1/stripe/transfer_schedule_accept", data);
}

export function refund_data_list(data) {
console.log('refund_data_list :', data);
  return axiosClient.post("/v1/stripe/refund_data_list", data);
}

export function refund_payment_give(data) {
  return axiosClient.post("/v1/stripe/refund_payment", data);
}

export function accept_reject_refund_request(data) {
  return axiosClient.post("/v1/teacher/accept_reject_refund_request", data);
}

export function express_account_callback(data) {
  return axiosClient.post("/v1/stripe/express_account_callback", data);
}

export function bank_provider_list(data) {
  return axiosClient.post("/v1/stripe/bank_provider_list", data);
}

export function payment_listing_type(data) {
  return axiosClient.post("/v1/org/payment_listing_type", data);
}

export function payment_data_get(data) {
  return axiosClient.post("/v1/org/payment_data_get", data);
}

export function payment_setting_teacher(data) {
  return axiosClient.post("/v1/org/payment_setting_teacher", data);
}

export function payment_data_insert(data) {
  return axiosClient.post("/v1/org/payment_data_insert", data);
}

export function payment_setting_classes_list(data) {
  return axiosClient.post("/v1/org/payment_setting_classes_list", data);
}

export function copy_payment_class_list(data) {
  return axiosClient.post("/v1/org/copy_payment_class_list", data);
}

export function copy_structure(data) {
  return axiosClient.post("/v1/org/copy_structure", data);
}

export function edit_payment_settings(data) {
  return axiosClient.post("/v1/org/edit_payment_settings", data);
}

export function create_account_link(data) {
  return axiosClient.post("/v1/stripe/create_account_link", data);
}

export function teacher_payment_received(data) {
  return axiosClient.post("/v1/teacher/teacher_payment_received", data);
}

export function teacher_payment_credits(data) {
  return axiosClient.post("/v1/teacher/credits", data);
}

export function credits_listing(data) {
  return axiosClient.post("/v1/teacher/credits_listing", data);
}

export function teacher_received_pdf(data) {
  return axiosClient.post("/v1/teacher/teacher_received_pdf", data);
}

export function teacher_payment_refunds_listing(data) {
  return axiosClient.post("/v1/teacher/refunds_listing", data);
}

// export function terms_conditions(data) {
//     return axiosClient.post("/v1/pages/terms_conditions", data);
// }

export function check_teacher_balance(data) {
  return axiosClient.post("/v1/teacher/check_teacher_balance", data);
}

export function notification_count(data) {
  return axiosClient.post("/v1/auth/notification_count", data);
}

export function get_payment_setting_data(data) {
  return axiosClient.post("/v1/teacher/get_payment_setting_data", data);
}

export function teacher_earnings(data) {
  return axiosClient.post("/v1/teacher/teacher_earnings", data);
}

export function get_meeting_link(data) {
  return axiosClient.post("/v1/teacher/get_meeting_link", data);
}

export function buy_subscription(data) {
  return axiosClient.post("/v1/teacher/buy_subscription", data);
}

export function create_payment_link(data) {
  return axiosClient.post("/v1/stripe/create_payment_link", data);
}

export function user_subscription_listing(data) {
  return axiosClient.post("/v1/teacher/user_subscription_listing", data);
}

export function subscription_listing(data) {
  return axiosClient.post("/v1/teacher/subscription_listing", data);
}

export function subscription_detail(data) {
  return axiosClient.post("/v1/teacher/subscription_detail", data);
}

export function certificate_competition(data) {
  return axiosClient.post("/v1/student_parent/certificate_competition", data);
}

export function in_person_class_details(data) {
  return axiosClient.post("/v1/class/in_person_class_details", data);
}

export function follow_unfollow(data) {
  return axiosClient.post("v1/follow/follows", data);
}

export function start_recording(data) {
  return axiosClient.post("v1/teacher/start_recording", data);
}

export function stop_recording(data) {
  return axiosClient.post("v1/teacher/stop_recording", data);
}

export function join_meeting(data) {
  return axiosClient.post("v1/teacher/join_meeting", data);
}

export function kick_off_from_meeting(data) {
  return axiosClient.post("v1/teacher/kick_off_from_meeting", data);
}

export function agora_room_already_exists(data) {
  return axiosClient.post("v1/chat/agora_room_already_exists", data);
}

export function agora_room_chat_history(data) {
  return axiosClient.post("v1/chat/agora_room_chat_history", data);
}

// GROUP CHAT 

export function group_chat_history(data) {
  return axiosClient.post("/v1/chat/group_chat_history", data);
}

export function group_room_already_exist(data) {
  return axiosClient.post("/v1/chat/group_room_already_exists", data);
}

export function groupChatHistory(data) {
  return axiosClient.post("/v1/chat/group_chat_history", data);
}

// Delete Org Teacher 

export function DeleteOrgTeacher(data) {
  return axiosClient.post("/v1/org/delete_teacher", data);
}
