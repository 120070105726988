import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form";
import Modals from 'react-modal';
import * as yup from "yup";
import dayjs from 'dayjs';
import { yupResolver } from "@hookform/resolvers/yup";
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import Swal from 'sweetalert2';
import OFooter_Dashboard from '../OInclude/OFooter_Dashboard';
import OHeader_Dashboard from '../OInclude/OHeader_Dashboard';
import { teacher_delete_meetings, teacher_edit_meetings, teacher_edit_meeting_details, teacher_meetings_list, teacher_name_list, teacher_repeat_meetings, teacher_setup_meetings, teacher_show_past_meeting, xyz, teacher_club_details, teacher_competition_details } from '../../../api/apiHandler';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

var COMMON_STYLE_FOR_POPUP = {
  content: {
    background: 'none',
    border: '0px',
  },
}

export default function OSetupClass({ isLoader }) {

  const location = useLocation();
  const navigate = useNavigate();
  var id = location?.state;
  var past_id = location?.state?.id;
  var All_meeting_id = location?.state?.id;
  var actvityType = location?.state?.activity_type;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenRepeat, setIsOpenRepeat] = useState(false);
  const [modalIsOpenEdit, setIsOpenEdit] = useState(false);
  const [isMultiday, setIsMultiday] = useState([]);
  console.log('isMultiday :', isMultiday);
  const [classData, setClassData] = useState([]);
  console.log('classData :', classData);
  const [meetings, setMeetings] = useState([]);
  const [timePrints, setTimePrints] = useState([]);
  const [sessionCount, setSessionCount] = useState(1);
  const [numberOfWeeks, setNumberOfWeeks] = useState(1);

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(customParseFormat);

  const teacherTimezone = classData?.time_zone

  // Helper function to get ordinal suffixes for numbers (1st, 2nd, 3rd, etc.)
  const ordinalSuffix = (i) => {
    const j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  };

  // Generate meeting titles based on sessionCount
  const meetingTitles = Array.from({ length: sessionCount }, (_, index) => `Add ${ordinalSuffix(index + 1)} Meeting`);

  const checkIfMultiday = async () => {
    if (id?.activity_type === "class") {
      isLoader(true)
      xyz({ classes_id: id?.id }).then((response) => {
        console.log('res123 :', response);
        if (response?.code == 1) {
          isLoader(false)
          setClassData(response.data[0]);
          setIsMultiday(response.data[0].length === "Multi-Day" ? true : false)
        } else {
          isLoader(false)
           ;
          setClassData([]);
        }
      });
    } else if (id?.activity_type === "club") {
      isLoader(true)
      teacher_club_details({ club_id: id?.id }).then((response) => {
        console.log('response :', response);
        if (response?.code == 1) {
          isLoader(false)
          setClassData(response.data[0]);
          setIsMultiday(response.data[0].length === "Multi-Day" ? true : false)
        } else {
          isLoader(false)
           ;
          setClassData([]);
        }
      });
    } else {
      isLoader(true)
      teacher_competition_details({ competition_id: id?.id }).then((response) => {
        console.log('response :', response);
        if (response?.code == 1) {
          isLoader(false)
          setClassData(response.data[0]);
          setIsMultiday(response.data[0].length === "Multi-Day" ? true : false)
        } else {
          isLoader(false)
           ;
          setClassData([]);
        }
      });
    }
  };

  useEffect(() => {
    if (modalIsOpen || modalIsOpenRepeat || modalIsOpenEdit) {
      checkIfMultiday();
    }
  }, [modalIsOpen, modalIsOpenRepeat, modalIsOpenEdit]);

  useEffect(() => {
    checkIfMultiday();
  }, [])

  useEffect(() => {
    // Check if classData is an array and has the expected structure before accessing properties
    if (classData?.length === "Multi-Day") {
      const sessionPerWeek = parseInt(classData.session_per_week, 10);
      const weeks = parseInt(classData.number_of_week, 10);
      setSessionCount(sessionPerWeek || 1);
      setNumberOfWeeks(weeks || 1);
    } else {
      setSessionCount(1);
      setNumberOfWeeks(1);
    }
  }, [classData]);

  const [startTime, setStartTime] = useState(new Date().toISOString().split('T')[1].substr(0, 5)); // This will give you the current time in "HH:mm" format.
  const [endTime, setEndTime] = useState(new Date().toISOString().split('T')[1].substr(0, 5)); // Initially set the end time same as start time

  const schema = yup.object().shape({
    // date: yup.string().required("Date is required"),
    // start_time: yup.string().required('Start time is required'),
    // end_time: yup.string()
    //   .required('End time is required')
    //   .test('is-after', 'End time must be after start time', function (value) {
    //     const { start_time } = this.parent;
    //     return value && start_time ? value > start_time : true;
    //   }),
  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const schema1 = yup.object().shape({
    repeat_date: yup.string().required("Please select date"),
  });

  const { register: register1, handleSubmit: handleSubmit1, setValue: setValue1, clearErrors: clearErrors1, reset: reset1, watch: watch1, control: control1, getValues: getValues1, formState: { errors: errors1 } } = useForm({
    resolver: yupResolver(schema1)
  });

  const [selectedRadio, setSelectedRadio] = useState("Public");

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.nextSibling.textContent)
  };

  var minuteOFClass = parseInt(id?.duration?.split(' ')[2])
  console.log('minuteOFClass :', minuteOFClass);

  // const [StartDate, setStartDate] = useState('');
  const [StartDateValidation, setStartDateValidation] = useState(new Date().toISOString().split('T')[0]);
  const [StartDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);

  useEffect(() => {
    // Set the current date when the component mounts
    setStartDate(dayjs().format('YYYY-MM-DD'));
    setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${StartMeredian} – ${EndHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
  }, []);

  const [StartHour, setStartHour] = useState('1');
  const [StartMin, setStartMin] = useState('00');
  const [EndHour, setEndHour] = useState('2');
  const [EndMin, setEndMin] = useState('00');
  const [StartMeredian, setStartMeredian] = useState('AM');
  const [EndMeredian, setEndMeredian] = useState('AM');
  const [TimePrint, setTimePrint] = useState('');
  const [isError, setIsError] = useState(0);
  const [formData, setFormData] = useState({});

  const [selTeacherData, setSelTeacherData] = useState([]);
  const [selTeacherId, setSelTeacherId] = useState(0);
  console.log('selTeacherId :', selTeacherId);
  console.log('selTeacherData :', selTeacherData);

  const Teacherlisting = () => {
    teacher_name_list().then((response) => {
      console.log("teacher_name_list", response)
      if (response?.code == 1) {
        setSelTeacherData(response?.data)
        setSelTeacherId(response?.data[0]?.id)
      } else {
         
      }
    });
  }
  useEffect(() => {
    Teacherlisting();
  }, []);

  const selectTeachername = (tId) => {
    console.log('tId :', tId);
    setSelTeacherId(tId)
  }

  const isMeetingInFuture = (date, time, meridian) => {
    const dateTimeString = `${date} ${time} ${meridian}`;
    const meetingTime = dayjs.tz(dateTimeString, 'YYYY-MM-DD h:mm A', teacherTimezone);
    const now = dayjs().tz(teacherTimezone);
    return meetingTime.isAfter(now);
  };

  // Improved helper function to parse duration string
  const getDurationInMinutes = (durationString) => {
    if (!durationString) return 60; // Default to 60 minutes if duration is missing

    // Handle both "Hours" and "Hour" in the string
    const match = durationString.match(/(\d+)\s*Hours?\s+(\d+)\s*Minutes?/i);
    if (!match) return 60;

    const [hours, minutes] = match.slice(1).map(Number);
    return hours * 60 + minutes;
  };

  // Helper function to format duration for display
  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    if (hours === 0) {
      return `${mins} minutes`;
    }
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} and ${mins} minutes`;
  };

  const onSubmit = async (data) => {
    console.log("Onsubmit:", data);

    // Get the maximum allowed duration from classData
    const maxAllowedDurationMinutes = getDurationInMinutes(classData.duration);
    console.log("Maximum allowed duration in minutes:", maxAllowedDurationMinutes);

    // Validation: Check if multiple meetings are on the same day
    const meetingDates = data.meetings.map((meeting) => meeting.date);
    const hasDuplicateDates = meetingDates.some(
      (date, index) => meetingDates.indexOf(date) !== index
    );

    if (hasDuplicateDates) {
      TOAST_ERROR("You cannot schedule multiple meetings on the same day");
      return;
    }

    // Iterate over each meeting to validate times
    for (const meeting of data.meetings) {
      const {
        date,
        start_time_hour,
        start_time_min,
        start_time_AM_PM,
        end_time_hour,
        end_time_min,
        end_time_AM_PM,
      } = meeting;

      // Convert 12-hour format to 24-hour for better comparison
      const startDateTime = dayjs(
        `${date} ${start_time_hour}:${start_time_min} ${start_time_AM_PM}`,
        'YYYY-MM-DD h:mm A'
      ).tz(teacherTimezone);

      const endDateTime = dayjs(
        `${date} ${end_time_hour}:${end_time_min} ${end_time_AM_PM}`,
        'YYYY-MM-DD h:mm A'
      ).tz(teacherTimezone);

      // Check if end time is before or equal to start time
      if (endDateTime.isBefore(startDateTime) || endDateTime.isSame(startDateTime)) {
        TOAST_ERROR("End time must be after start time for each meeting");
        return;
      }

      // Calculate duration in minutes
      const durationMinutes = endDateTime.diff(startDateTime, 'minute');
      console.log('Meeting duration in minutes:', durationMinutes);

      // Validate against maximum allowed duration
      if (durationMinutes > maxAllowedDurationMinutes) {
        TOAST_ERROR(
          `Meeting duration cannot exceed ${formatDuration(maxAllowedDurationMinutes)}. ` +
          `Your selected duration is ${formatDuration(durationMinutes)}. ` +
          `Please adjust the meeting time.`
        );
        return;
      }

      // Validation: Meeting start time must be in the future
      if (!isMeetingInFuture(date, `${start_time_hour}:${start_time_min}`, start_time_AM_PM)) {
        TOAST_ERROR("Meeting times must be in the future");
        return;
      }
    }

    appendMeeting(data.meetings);
  };

  const appendMeeting = (meetingsData) => {
    const newMeetings = (meetingsData || []).map((meeting, index) => ({
      classes_id: id?.id,
      classes_type: id?.activity_type,
      visibility_mode: selectedRadio,
      assign_teacher: selTeacherId,
      date: meeting.date,
      start_time: `${meeting.start_time_hour}:${meeting.start_time_min} ${meeting.start_time_AM_PM}`,
      end_time: `${meeting.end_time_hour}:${meeting.end_time_min} ${meeting.end_time_AM_PM}`,
      link: `https://meeting-link.com/meeting${meetings.length + index + 1}`
    }));

    setMeetings((prevMeetings) => [...prevMeetings, ...newMeetings]);

    const payload = { meetings: newMeetings, activity_length: classData.length };
    isLoader(true)
    teacher_setup_meetings(payload)
      .then((res) => {
        console.log("teacher_setup_meetings", res);

        if (res?.code == 1) {
          isLoader(false)
          // Update the list of meetings
          Meeting_list();
          setIsOpen(false);
          TOAST_SUCCESS(res?.message);
          reset({
            meetings: Array.from({ length: sessionCount }, (_, index) => ({
              date: new Date().toISOString().split("T")[0],
              start_time_hour: "1",
              start_time_min: "00",
              start_time_AM_PM: "AM",
              end_time_hour: "2",
              end_time_min: "00",
              end_time_AM_PM: "AM",
            })),
          });
          setTimePrint("");
          setTimePrints([]);
          navigate("/organization-scheduling", { state: id });
        } else {
          isLoader(false)
          TOAST_ERROR(res?.message);
        }
      })
      .catch((error) => {
        TOAST_ERROR("An error occurred while saving meetings");
        console.error("API Error:", error);
      });
  };

  const [data, setData] = useState([]);
  const [pastId, setPastId] = useState(null);

  const Meeting_list = () => {
    teacher_meetings_list({ classes_id: id?.id, activity_type: id?.activity_type }).then((resposnse) => {
      console.log("teacher_meetings_list", resposnse)
      if (resposnse?.code == 1) {
        setData(resposnse?.data)
      } else {
         
      }
    });
  }

  useEffect(() => {
    Meeting_list();
  }, []);

  var onSubmit1 = async (dataRepeat) => {
    if (data?.length > 0) {
      let repeat_meeting = {
        classes_id: id?.id,
        end_date: dataRepeat.repeat_date,
      }

      console.log(repeat_meeting, "repeat_meeting")

      teacher_repeat_meetings(repeat_meeting).then((response) => {
        console.log("teacher_setup_meetings", response)

        if (response?.code == 1) {
          Meeting_list()
          setIsOpenRepeat(false);
          TOAST_SUCCESS(response?.message);
          // console.log("inside", id)
          navigate('/organization-scheduling', { state: id });
        } else {
          TOAST_ERROR(response?.message);
        }
      });
      // reset();
    } else {
      TOAST_ERROR("Please create a meeting.");
    }
  }

  const Past_Meeting_list = (past_id_meeting) => {
    teacher_show_past_meeting({ classes_id: past_id_meeting }).then((resposnse) => {
      console.log("teacher_show_past_meeting", resposnse)
      if (resposnse.code == 1) {
        setData(resposnse.data)
      } else {
         
        setData([])
      }
    });
  }

  useEffect(() => {
    if (pastId !== null) {
      Past_Meeting_list(pastId);
    }
  }, [pastId]);

  const [buttonText, setButtonText] = useState('Copy');

  const handleCopyClick = (event) => {
    // Prevent any default browser behavior
    event.preventDefault();

    const textToCopy = document.getElementById('to-copy').innerText;

    navigator.clipboard.writeText(textToCopy).then(() => {
      setButtonText('Copied!');
      setTimeout(() => {
        setButtonText('Copy');
      }, 2000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleStartHourChange = (e, index) => {
    const selectedStartHour = e.target.value;
    setValue(`meetings[${index}].start_time_hour`, selectedStartHour);
    updateTimePrint(index, { ...getValues(`meetings[${index}]`) });
  };

  const handleEditStartHourChange = (e) => {
    const selectedStartHour = e.target.value;
    setStartHour(selectedStartHour);

    // Logic to automatically change end time based on start time
    const selectedEndHour = parseInt(selectedStartHour) + 1;
    // alert(selectedEndHour)
    setEndHour(selectedEndHour.toString());
    if (StartMeredian == EndMeredian) {
      if (parseInt(selectedEndHour) > parseInt(selectedStartHour)) {

        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${selectedStartHour}:${StartMin} ${StartMeredian} – ${selectedEndHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      }
      else {
        setIsError(1)
        setTimePrint('Invalid date range')
      }
    } else {

      if (StartMeredian == 'PM') {
        setTimePrint('Invalid date range')
        setIsError(1)
      } else {
        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${selectedStartHour}:${StartMin} ${StartMeredian} – ${selectedEndHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      }
    }
  };

  const handleEndHourChange = (e) => {
    const selectedEndtHour = e.target.value;
    setEndHour(selectedEndtHour);

    if (StartMeredian == EndMeredian) {
      if (parseInt(selectedEndtHour) >= parseInt(StartHour)) {

        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${StartMeredian} – ${selectedEndtHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      } else {

        setTimePrint('Invalid date range')
        setIsError(1)

      }
    } else {
      if (StartMeredian == 'PM') {
        setTimePrint('Invalid date range')
        setIsError(1)

      } else {
        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${StartMeredian} – ${selectedEndtHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      }
    }
  };

  const handleStartMinChange = (e) => {
    const selectedStartMin = e.target.value;
    setStartMin(selectedStartMin);
    if (StartMeredian == EndMeredian) {
      if (parseInt(EndHour) > parseInt(StartHour)) {

        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${selectedStartMin} ${StartMeredian} – ${EndHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      } else {
        setTimePrint('Invalid date range')
        setIsError(1)

      }
    } else {
      if (StartMeredian == 'PM') {
        setTimePrint('Invalid date range')
        setIsError(1)

      } else {
        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${selectedStartMin} ${StartMeredian} – ${EndHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      }
    }
  };

  const handleEndMinChange = (e) => {
    const selectedEndMin = e.target.value;
    setEndMin(selectedEndMin);
    if (StartMeredian == EndMeredian) {
      if (parseInt(EndHour) > parseInt(StartHour)) {

        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${StartMeredian} – ${EndHour}:${selectedEndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      } else {
        setTimePrint('Invalid date range')
        setIsError(1)

      }
    } else {
      if (StartMeredian == 'PM') {
        setTimePrint('Invalid date range')
        setIsError(1)

      } else {
        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${StartMeredian} – ${EndHour}:${selectedEndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      }
    }
  };

  const handleStartMeredianChange = (e) => {
    const selectedStartMeredian = e.target.value;
    setStartMeredian(selectedStartMeredian);
    if (selectedStartMeredian == EndMeredian) {
      if (parseInt(EndHour) > parseInt(StartHour)) {

        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${selectedStartMeredian} – ${EndHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      } else {
        setTimePrint('Invalid date range')
        setIsError(1)

      }
    } else {
      if (selectedStartMeredian == 'PM') {
        setTimePrint('Invalid date range')
        setIsError(1)

      } else {
        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${selectedStartMeredian} – ${EndHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      }
    }
  };

  const handleEndMeredianChange = (e) => {
    const selectedEndMeredian = e.target.value;
    console.log('selectedEndMeredian :', selectedEndMeredian);
    setEndMeredian(selectedEndMeredian);

    console.log('StartMeredian1 :', StartMeredian);
    if (StartMeredian == selectedEndMeredian) {

      if (parseInt(EndHour) > parseInt(StartHour)) {

        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${StartMeredian} – ${EndHour}:${EndMin} ${selectedEndMeredian} ${teacherTimezone}`))
        setIsError(0)

      } else {
        setTimePrint('Invalid date range')
        setIsError(1)

      }
    } else {
      if (StartMeredian == 'PM') {
        setTimePrint('Invalid date range')
        setIsError(1)

      } else {
        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${StartMeredian} – ${EndHour}:${EndMin} ${selectedEndMeredian} ${teacherTimezone}`))
        setIsError(0)

      }
    }
  };

  const handleDateChange = (e, index) => {
    const selectedDate = e.target.value;
    setValue(`meetings[${index}].date`, selectedDate);
    updateTimePrint(index, { ...getValues(`meetings[${index}]`) });
  };

  const handleEditDateChange = (e) => {
    const selectedDate = e.target.value;
    setStartDate(dayjs(selectedDate).format('YYYY-MM-DD'));
    setTimePrint(dayjs(selectedDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${StartMeredian} – ${EndHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`);
  };

  const updateTimePrint = (index, meetingData) => {
    const {
      date,
      start_time_hour,
      start_time_min,
      start_time_AM_PM,
      end_time_hour,
      end_time_min,
      end_time_AM_PM
    } = meetingData;
    const formattedDate = dayjs(date).format('ddd, MMM D, YYYY');
    const startTime = `${start_time_hour}:${start_time_min} ${start_time_AM_PM}`;
    const endTime = `${end_time_hour}:${end_time_min} ${end_time_AM_PM}`;

    const newTimePrint = `${formattedDate}, ${startTime} – ${endTime} ${teacherTimezone}`;
    setTimePrints(prevTimePrints => {
      const newTimePrints = [...prevTimePrints];
      newTimePrints[index] = newTimePrint;
      return newTimePrints;
    });
  };

  const handleTimeChange = (e, index, field) => {
    const value = e.target.value;
    setValue(`meetings[${index}].${field}`, value);  // Update the field in the form state

    // Get all current values of the meeting at this index
    const meetingData = getValues(`meetings[${index}]`); // Fetch the form values for the current meeting

    updateTimePrint(index, meetingData);  // Recalculate the time print whenever time or date is changed
  };

  const [MeetingID, setMeetingID] = useState('')

  const onEditSubmit = async (data) => {
    // Get current time in teacher's timezone
    const currentDateTime = dayjs().tz(teacherTimezone);

    // Construct start and end times using the date and time input from the form in the teacher's timezone
    const startDateTime = dayjs.tz(
      `${data.date} ${StartHour}:${StartMin} ${StartMeredian}`,
      'YYYY-MM-DD h:mm A',
      teacherTimezone
    );

    const endDateTime = dayjs.tz(
      `${data.date} ${EndHour}:${EndMin} ${EndMeredian}`,
      'YYYY-MM-DD h:mm A',
      teacherTimezone
    );

    // Debugging logs
    console.log('Start DateTime:', startDateTime.format());
    console.log('End DateTime:', endDateTime.format());
    console.log('Current DateTime:', currentDateTime.format());

    // Get the maximum allowed duration from classData
    const maxAllowedDurationMinutes = getDurationInMinutes(classData.duration);
    console.log("Maximum allowed duration in minutes:", maxAllowedDurationMinutes);

    // Check if the meeting start time is in the past
    if (startDateTime.isBefore(currentDateTime)) {
      TOAST_ERROR("Meeting start time must not be in the past.");
      return;
    }

    // Check if end time is before or the same as start time
    if (endDateTime.isBefore(startDateTime) || endDateTime.isSame(startDateTime)) {
      TOAST_ERROR("End time must be after start time for the meeting.");
      return;
    }

    // Calculate duration in minutes
    const durationMinutes = endDateTime.diff(startDateTime, 'minute');
    console.log('Meeting duration in minutes:', durationMinutes);

    // Validate against maximum allowed duration
    if (durationMinutes > maxAllowedDurationMinutes) {
      TOAST_ERROR(
        `Meeting duration cannot exceed ${formatDuration(maxAllowedDurationMinutes)}. ` +
        `Your selected duration is ${formatDuration(durationMinutes)}. ` +
        `Please adjust the meeting time.`
      );
      return;
    }

    // If all validations pass, proceed with the meeting edit
    let Edit_meeting = {
      meeting_id: MeetingID,
      visibility_mode: selectedRadio,
      date: data.date,
      start_time: `${StartHour}:${StartMin} ${StartMeredian}`,
      end_time: `${EndHour}:${EndMin} ${EndMeredian}`,
      link: "https://www.google.com/",
    };

    try {
      const res = await teacher_edit_meetings(Edit_meeting);
      if (res?.code == 1) {
        await Meeting_list();
        setIsOpenEdit(false);
        TOAST_SUCCESS(res?.message);
        navigate('/organization-scheduling', { state: id });
      } else {
        TOAST_ERROR(res?.message);
      }
    } catch (error) {
      console.error("Error editing meeting:", error);
      TOAST_ERROR("An error occurred while editing the meeting");
    }
  };

  const delete_class = (index, id) => {
    // alert(id)

    Swal.fire({
      title: 'Are you sure you want to delete ?',
      text: "You won't be able to revert this!",
      icon: 'question',
      background: 'white',
      showCancelButton: true,
      confirmButtonColor: '#570861',
      cancelButtonColor: '#FFC924',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonClass: 'swal-cancel-button',
    }).then((result) => {
      if (result.isConfirmed) {
        teacher_delete_meetings({ meeting_id: id }).then((response) => {
          if (response.code == 1) {
            setData(data.filter(o => o.id !== id));
            TOAST_SUCCESS(response.message);

          } else {
            TOAST_ERROR(response.message);
          }
        })
      }
    })
  }

  const All_meeting = () => {

    teacher_meetings_list({ 'classes_id': All_meeting_id, activity_type: id?.activity_type }).then((response) => {
      if (response.code == 1) {
        setData(response.data)
      } else {
         
      }
    });
  }

  const [editDetails, setEditDetails] = useState('')

  const EditMeetingDetails = (MeetingEdit_id) => {
    teacher_edit_meeting_details({ 'meeting_id': MeetingEdit_id }).then((response) => {
      console.log("teacher_edit_meeting_details", response)
      if (response.code == 1) {
        setEditDetails(response?.data)
        setStartDate(response?.data[0]?.date)
        setIsOpenEdit(true)
        // setStartDate(response?.data[0]?.date)
        console.log("hello", response?.data[0]?.start_time?.split(':')[0])
        var start_time = dayjs().format('YYYY-MM-DD') + " " + response?.data[0]?.start_time
        var end_time = dayjs().format('YYYY-MM-DD') + " " + response?.data[0]?.end_time
        console.log('start_time :', start_time);
        reset({
          date: response?.data[0]?.date,
          start_time_hour: dayjs(start_time).format('h'),
          start_time_min: dayjs(start_time).format('mm'),
          start_time_AM_PM: dayjs(start_time).format('A'),
          end_time_hour: dayjs(end_time).format('h'),
          end_time_min: dayjs(end_time).format('mm'),
          end_time_AM_PM: dayjs(end_time).format('A'),
        });
        setStartHour(dayjs(start_time).format('h'))
        setStartMin(dayjs(start_time).format('mm'))
        setStartMeredian(dayjs(start_time).format('A'))
        setEndHour(dayjs(end_time).format('h'))
        setEndMin(dayjs(end_time).format('mm'))
        setEndMeredian(dayjs(end_time).format('A'))
        setTimePrint((dayjs(response?.data[0]?.date, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${dayjs(start_time).format('h')}:${dayjs(start_time).format('mm')} ${dayjs(start_time).format('A')} – ${dayjs(end_time).format('h')}:${dayjs(end_time).format('mm')} ${dayjs(end_time).format('A')} ${teacherTimezone}`))
      } else {
         
      }
    });
  }

  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}
              <div className>
                <h3 className="mb-4 poppins fw-600">Visibility</h3>
                <div className="row">
                  <div className="col-xl-7">
                    <form className="mb-4">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                          <div className="radio-item mb-4">
                            <input name="radio" id="radio1" type="radio" checked={selectedRadio === "Public"} onChange={handleRadioChange} />
                            <label htmlFor="radio1" className="d-block text-center">Public</label>
                          </div>
                          <p className="fs-6">Anyone can enroll in this class, it will show up in search results and your class detail page.</p>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                          <div className="radio-item mb-4">
                            <input name="radio" id="radio2" type="radio" checked={selectedRadio === "Private"} onChange={handleRadioChange} />
                            <label htmlFor="radio2" className="d-block text-center">Private</label>
                          </div>
                          <p className="fs-6">Only people with the link provided will be able to see and enroll in this section.</p>
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <h3 className="mb-3">Assign Teacher</h3>
                        <label className="mb-2">Select Teacher</label>
                        {/* <input type="text" className="form-control" /> */}
                        {/* {selTeacherData?.length > 0 ? */}
                        <select className="form-select" onChange={(e) => selectTeachername(e.target.value)} >
                          {selTeacherData?.map((subject, index) => {
                            // const classes_id = subject?.classes_id;
                            // const classes_type = subject?.classes_type;
                            // const valueObj = JSON.stringify({ classes_id, classes_type });
                            return (
                              <option key={index} value={subject?.id}>{subject?.full_name}</option>
                            )
                          })}
                        </select>
                        {/* : <></>} */}
                      </div>
                      <div className="meeting_div">
                        <div className="d-flex mb-3 align-items-center justify-content-between">
                          <h3 className="poppins fw-600">Meetings</h3>
                          <ul className="d-flex align-items-center flex-wrap">
                            <li className="me-3"><Link state={id} onClick={() => All_meeting()} className="text-decoration-underline text-purple"> All Meeting List</Link></li>

                            <li className="me-3"><Link state={id} onClick={() => setIsOpen(true)} className="text-decoration-underline text-purple"> Add a Meeting Day &amp; Time</Link></li>
                            {(classData.length === "One-Time" || classData.length === "Multi-Day") ? "" : (
                              <li className="me-3"><Link state={id} onClick={() => setIsOpenRepeat(true)} className="text-decoration-underline text-purple">Duplicate Meeting</Link></li>
                            )}
                            <li><Link className="text-decoration-underline text-purple" state={id} onClick={() => Past_Meeting_list(past_id)}>Show Past Meetings</Link></li>
                          </ul>
                        </div>
                        <ul className="next_meets mb-4">
                          {data?.length > 0 ?
                            data?.map((meetingData, index) => {
                              return (
                                <li key={index} className="card mb-3">
                                  <div className="d-md-flex justify-content-between align-items-center">
                                    <div className>
                                      {/* <h4 className="fs-6 poppins fw-600 mb-2">
                                        Next meets on {dayjs(meetingData.date, 'YYYY-MM-DD').format('dddd, MMM D')},&nbsp;
                                        {dayjs(meetingData.date + ' ' + meetingData.start_time, 'hh:mm:ss').format('h:mm A')}-{meetingData?.end_time}
                                      </h4> */}
                                      <h4 className="fs-6 poppins fw-600 mb-2">
                                        Next meets on {dayjs(meetingData.date, 'YYYY-MM-DD').format('dddd, MMM D')},&nbsp;
                                        {meetingData?.start_time} - {meetingData?.end_time}
                                      </h4>

                                      <span className="mb-md-0 mb-3 d-inline-block font-14">StudentScholars , {meetingData.date_status} , {meetingData?.space_availability}</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <ul className="edit_delete d-flex me-3 align-items-center justify-content-end">
                                        <li className="me-0">
                                          <Link state={id} onClick={() => { EditMeetingDetails(meetingData.id); setMeetingID(meetingData.id) }} className>
                                            <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M3.10763 18.8439C3.32301 18.8439 3.36609 18.8224 3.55993 18.7793L7.43685 18.0039C7.84608 17.8962 8.25532 17.7024 8.57839 17.3793L17.9692 7.98851C19.4122 6.54543 19.4122 4.06851 17.9692 2.62543L17.1722 1.78543C15.7292 0.342356 13.2307 0.342356 11.7876 1.78543L2.39686 11.1977C2.09532 11.4993 1.87993 11.93 1.77224 12.3393L0.953781 16.2593C0.846089 16.9916 1.06147 17.7024 1.5784 18.2193C1.98763 18.6285 2.5907 18.8439 3.10763 18.8439ZM3.83993 12.7485L13.2307 3.3362C13.8553 2.71159 14.9968 2.71159 15.5999 3.3362L16.4184 4.15466C17.1507 4.88697 17.1507 5.92082 16.4184 6.63159L7.04916 16.0439L3.06455 16.7116L3.83993 12.7485Z" fill="#570861" />
                                              <path d="M17.8829 20.1797H1.98746C1.36284 20.1797 0.953613 20.5889 0.953613 21.2135C0.953613 21.8382 1.47054 22.2474 1.98746 22.2474H17.7967C18.4213 22.2474 18.9382 21.8382 18.9382 21.2135C18.9167 20.5889 18.3998 20.1797 17.8829 20.1797Z" fill="#570861" />
                                            </svg>
                                          </Link>
                                        </li>
                                      </ul>
                                      <Link state={id} onClick={() => (delete_class(index, meetingData.id))} className="btn-theme px-2 py-1">DELETE</Link>
                                    </div>
                                  </div>
                                </li>
                              )
                            }) :
                            <h4 className="d-flex justify-content-center" style={{ "color": "#570861" }}>Data Not Found</h4>
                          }

                        </ul>
                        <p className="mb-4 font-18">We recommend scheduling sections at least two weeks before they start. For ongoing classes, add meetings for the first four weeks of each section.</p>
                      </div>
                      <div className="mb-4">
                        <h3 className="mb-3 poppins fw-600">Class link</h3>
                        {/* <div className="d-flex align-items-center">
                          <div className="copy_text_bx border-radius-10 w-100 position-relative">
                            <span id="to-copy" spellCheck="false">Studentscholar.com/3436FRSJ</span>
                          </div>
                          <a href="#" className="btn-theme ms-3 px-3 py-1">Copy</a>
                        </div> */}
                        <div className="d-flex align-items-center">
                          {actvityType === "class" ? (
                            <div className="copy_text_bx border-radius-10 w-100 position-relative">
                              <span id="to-copy" spellCheck="false">{`http://13.234.122.246/studentscholar/class_detail/${id?.id}?teacher_id=${id?.teacher_id}`}</span>
                            </div>
                          ) : actvityType === "club" ? (
                            <div className="copy_text_bx border-radius-10 w-100 position-relative">
                              <span id="to-copy" spellCheck="false">{`http://13.234.122.246/studentscholar/club-detail/${id?.id}?teacher_id=${id?.teacher_id}`}</span>
                            </div>
                          ) : actvityType === "competition" ? (
                            <div className="copy_text_bx border-radius-10 w-100 position-relative">
                              <span id="to-copy" spellCheck="false">{`http://13.234.122.246/studentscholar/competition-detail/${id?.id}?teacher_id=${id?.teacher_id}`}</span>
                            </div>
                          ) : null
                          }

                          {/* Prevent redirection by not setting href="#" */}
                          <button className="btn-theme ms-3 px-3 py-1" onClick={handleCopyClick}>
                            {buttonText}
                          </button>
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <div className="form-check">
                          <input className="form-check-input rounded-1" type="checkbox" defaultValue id="invalidCheck" />
                          <label className="form-check-label ps-2" htmlFor="invalidCheck">
                            <p className="font-18">Automatically cancel meetings with no Enrollments 24 hours before the class start time.</p>
                          </label>
                        </div>
                      </div>
                    </form>
                    <div className="row mt-5">
                      <div className="col-lg-6">
                        <a href="#" className="btn-theme d-block text-center fw-600">Delete Meeting</a>
                      </div>
                      <div className="col-lg-6">
                        {/* <Link to="/upcoming_meeting" className="btn-theme d-block text-center fw-600">Duplicate Meeing</Link>    */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>

      <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              {isMultiday === true ? <><h5 className="modal-title poppins fw-600">Add Meetings</h5></> : <><h5 className="modal-title poppins fw-600">Add Meeting</h5></>}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={() => {
                  clearErrors();
                  setStartHour("1")
                  setStartMin("00");
                  setEndHour("2");
                  setEndMin("00");
                  setStartMeredian("AM")
                  setEndMeredian("AM")
                  setTimePrint("")
                  setTimePrints("")
                  reset();
                  setIsOpen(false);
                }}
                aria-label="Close"
              />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
              {Array.from({ length: sessionCount }).map((_, index) => (
                <div key={index} className="modal-body">
                  {isMultiday === true ? <><h5 className="modal-title poppins fw-600">Add {ordinalSuffix(index + 1)} Meeting</h5></> : ""}

                  <div className="form-group mb-4">
                    <label className="form-label">Enter Date</label>
                    <input
                      type="date"
                      className="form-control"
                      min={StartDateValidation}
                      defaultValue={StartDate}
                      {...register(`meetings[${index}].date`)}
                      onChange={(e) => handleDateChange(e, index)}
                    />
                  </div>
                  <label className="form-label" style={{ color: "#666666" }}>
                    Enter Start Time
                  </label>
                  <div className="row">
                    <div className="col w-5">
                      <select
                        className="w-3"
                        style={{ margin: "8px", width: "60px" }}
                        {...register(`meetings[${index}].start_time_hour`)}
                        onChange={(e) => handleTimeChange(e, index, 'start_time_hour')}
                      >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map((number) => (
                          <option key={number} value={number}>{number}</option>
                        ))}
                      </select>
                      <span className="col">:</span>
                      <select
                        className="col"
                        style={{ margin: "8px", width: "60px" }}
                        {...register(`meetings[${index}].start_time_min`)}
                        onChange={(e) => handleTimeChange(e, index, 'start_time_min')}
                      >
                        {Array.from({ length: 12 }, (_, i) => i * 5).map((number) => (
                          <option key={number} value={number < 10 ? `0${number}` : `${number}`}>
                            {number < 10 ? `0${number}` : `${number}`}
                          </option>
                        ))}
                      </select>
                      <select
                        className="col"
                        style={{ width: "60px" }}
                        {...register(`meetings[${index}].start_time_AM_PM`)}
                        onChange={(e) => handleTimeChange(e, index, 'start_time_AM_PM')}
                      >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                      </select>
                    </div>
                  </div>
                  <label className="form-label" style={{ color: "#666666" }}>
                    Enter End Time
                  </label>
                  <div className="row">
                    <div className="col w-5">
                      <select
                        className="w-3"
                        style={{ margin: "8px", width: "60px" }}
                        {...register(`meetings[${index}].end_time_hour`)}
                        onChange={(e) => handleTimeChange(e, index, 'end_time_hour')}
                      >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map((number) => (
                          <option key={number} value={number}>{number}</option>
                        ))}
                      </select>
                      <span className="col">:</span>
                      <select
                        className="col"
                        style={{ margin: "8px", width: "60px" }}
                        {...register(`meetings[${index}].end_time_min`)}
                        onChange={(e) => handleTimeChange(e, index, 'end_time_min')}
                      >
                        {Array.from({ length: 12 }, (_, i) => i * 5).map((number) => (
                          <option key={number} value={number < 10 ? `0${number}` : `${number}`}>
                            {number < 10 ? `0${number}` : `${number}`}
                          </option>
                        ))}
                      </select>
                      <select
                        className="col"
                        style={{ width: "60px" }}
                        {...register(`meetings[${index}].end_time_AM_PM`)}
                        onChange={(e) => handleTimeChange(e, index, 'end_time_AM_PM')}
                      >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                      </select>
                    </div>
                  </div>
                  <div className="text-center mb-4">
                    <p className="fs-6">{timePrints[index]}</p>
                  </div>
                </div>
              ))}
              <div className="button text-center mb-4 mx-2">
                <button
                  type="submit"
                  style={{ width: "100%" }}
                  className="btn-theme border-0 text-dif-black fw-600 bg-yellow text-center d-block"
                >
                  Add Meetings
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modals>

      <Modals isOpen={modalIsOpenRepeat} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal">
        {/* <div className="modal fade" id="repeat_meeting" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-600 poppins" id="staticBackdropLabel">Repeat Meeting</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => { setIsOpenRepeat(false); clearErrors1(); }}
                aria-label="Close" />
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit1(onSubmit1)} className="custom_form">
                <div className="form-group mb-4">
                  <label className="form-label">Schedule Through</label>
                  <input type="date" className="form-control" min={StartDateValidation} {...register1("repeat_date")} name='repeat_date' />
                  <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors1.repeat_date?.message}</span></p>

                </div>
                <div className="text-center mb-4">
                  <p className>You will continue to be able to manually delete and re-add meetings.You will also be able to bulk add additional meetings.</p>
                </div>
                <div className="button">
                  {/* <a href="#" data-bs-dismiss="modal" className="btn-theme border-0 text-dif-black fw-600 bg-yellow text-center d-block">Repeat Meeting</a> */}
                  <button style={{ "width": "100%" }} className="btn-theme border-0 text-dif-black fw-600 bg-yellow text-center d-block">Repeat Meeting</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Modals>

      <Modals isOpen={modalIsOpenEdit} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal">
        {/* <div className="modal fade" id="add_meeting" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title poppins fw-600" id="staticBackdropLabel">Edit a meeting</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => { setIsOpenEdit(false); clearErrors(); }} aria-label="Close" />
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onEditSubmit)} className="custom_form">
                <div className="form-group mb-4">
                  <label className="form-label">Enter Date</label>
                  <input type="date" className="form-control" id min={StartDateValidation} value={StartDate}  {...register("date")} onChange={(e) => handleEditDateChange(e)} name='date' />
                  {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.date?.message}</span></p> */}
                </div>
                <label className="form-label" style={{ "color": "#666666" }}>Enter Start Time</label>
                <div className="row">

                  <div className="col w-5">
                    <div>
                      <select
                        className="w-3" style={{ "margin": "8px", "width": "60px" }}
                        {...register("start_time_hour")} onChange={handleEditStartHourChange}

                        name="start_time_hour"
                      >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map((number) => (
                          <option key={number} value={number}>
                            {number}
                          </option>
                        ))}
                      </select>
                      <span className='col' >:</span>

                      <select

                        className="col" style={{ "margin": "8px", "width": "60px" }}
                        {...register("start_time_min")}
                        onChange={(e) => handleStartMinChange(e)}
                        name="start_time_min"
                      >
                        {Array.from({ length: 12 }, (_, i) => i * 5).map((number) => (
                          <option key={number} value={number < 10 ? `0${number}` : `${number}`}>
                            {number < 10 ? `0${number}` : `${number}`}
                          </option>
                        ))}

                      </select>

                      <select

                        className="col" style={{ "width": "60px" }}
                        {...register("start_time_AM_PM")} onChange={(e) => handleStartMeredianChange(e)}
                        name="start_time_AM_PM"
                      >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>

                      </select>
                    </div>

                  </div>

                  {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.start_time?.message}</span></p> */}
                </div>

                <label className="form-label" style={{ "color": "#666666" }}>Enter End Time</label>
                <div className="row">

                  <div className="col w-5">
                    <div>
                      <select
                        className="w-3" style={{ "margin": "8px", "width": "60px" }}
                        {...register("end_time_hour")} value={EndHour} onChange={(e) => handleEndHourChange(e)}
                        name="end_time_hour"
                      >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map((number) => (
                          <option key={number} value={number}>
                            {number}
                          </option>
                        ))}
                      </select>
                      <span className='col' >:</span>

                      <select

                        className="col" style={{ "margin": "8px", "width": "60px" }}
                        {...register("end_time_min")} onChange={(e) => handleEndMinChange(e)}
                        name="end_time_min"
                      >
                        {Array.from({ length: 12 }, (_, i) => i * 5).map((number) => (
                          <option key={number} value={number < 10 ? `0${number}` : `${number}`}>
                            {number < 10 ? `0${number}` : `${number}`}
                          </option>
                        ))}

                      </select>
                      <select

                        className="col" style={{ "width": "60px" }}
                        {...register("end_time_AM_PM")} onChange={(e) => handleEndMeredianChange(e)}
                        name="end_time_AM_PM"
                      >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>

                      </select>
                    </div>

                  </div>

                  {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.start_time?.message}</span></p> */}
                </div>

                {/* <div className="form-group mb-4">
                  <label className="form-label">Enter End Time</label>
                  <input
                    type="time"
                    className="form-control"
                    {...register("end_time")}
                    name="end_time"
                  />
                  <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.end_time?.message}</span></p>
                </div> */}
                <div className="text-center mb-4">
                  {/* <p className="fs-6">Fri, Jun 2, 9:00 – 10:${teacherTimezone}0/p> */}
                  <p className="fs-6">{TimePrint}</p>

                </div>
                <div className="button">
                  <button data-bs-dismiss="modal" style={{ "width": "100%" }} className="btn-theme border-0 text-dif-black fw-600 bg-yellow text-center d-block">Edit Meeting</button>
                  {/* <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                            Create Coupon
                          </button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Modals>

      <OFooter_Dashboard />
    </>
  )
}
