import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TOAST_ERROR, TOAST_INFO, TOAST_SUCCESS } from "../../utils/common.service";
import { add_class, delete_class_data } from "../../api/apiHandler";
import * as yup from "yup";
import Modals from "react-modal";
import Model from "../Models/Model";
import Select from "react-select";
import countryList from "react-select-country-list";
import { yupResolver } from "@hookform/resolvers/yup";
import LearnerAvatar from "../Models/LearnerAvatar";
import BackToPrevious from "../../components/backToPrevious";

var model_css = {
  content: {
    backgroundColor: "transparent",
    background: "none",
    border: "0px",
  },
};

var avtar_css = {
  content: {
    backgroundColor: "transparent",
    background: "none",
    border: "0px",
  },
};

export default function AfterSchoolSignup() {
  const navigate = useNavigate();

  const schema = yup.object().shape({
    class_name: yup
      .string()
      .required("Please enter class name")
      .matches(/^[A-Za-z0-9]+$/, "Only alphabet characters and numbers are allowed in the class name field")
      .matches(/^[a-zA-Z0-9\s!]+$/, "Special characters are not allowed")
      .max(10, "Class name must be at most 10 characters long")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .min(2, "Field must contain atleast 2 or 3 characters long"),
    learner_name: yup.string().required("Please enter learner name")
    .matches(/^[A-Za-z]+$/, "Only alphabet and characters allowed")
    .matches(/^[a-zA-Z0-9\s!]+$/, "Special characters are not allowed")
    .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
    .min(2, "Field must contain atleast 2 or 3 characters long"),
    age: yup.string().required("Please select age"),
    email: yup
      .string()
      .email()
      .matches(/^(?!.*@[^,]*,)/)
      .required("Please enter email"),
    password: yup.string()
    .required("Please enter password")
    .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
    .min(8, "Password must contain 8 or more characters, at least one uppercase, one lowercase, one digit, and one special character")
    .matches(/^\S+$/, "Password cannot contain leading or trailing spaces")
    .matches(/(?=.*[a-z])(?=.*[A-Z])/, "Password must contain at least one uppercase and one lowercase letter")
    .matches(/\d/, "Password must contain at least one digit")
    .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Password must contain at least one special character"),
    subject_category: yup.string().required("Please select subject"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [learnerDetail, setLearnerDetail] = useState([]);
  console.log(learnerDetail, 'learnerDetail');
  const [classDetail, setclassDetail] = useState([]);
  console.log("classDetail :", classDetail);
  const [continuebutton, setContinuebutton] = useState(null);
  const [avtarModel, setavtarModel] = useState(false);
  const [selectAvtar, setselectAvtar] = useState([]);
  const [avtarDone, setavtarDone] = useState(false);

  const [addClassDetail, setaddClassDetail] = useState();
  console.log('addClassDetail :', addClassDetail);

  const [imageAvatar, setImageAvatar] = useState();
  console.log(imageAvatar, 'imageAvatar');
  const [checkData, setCheckData] = useState(null);
  console.log("checkData :", checkData);
  // console.log('imageAvatar :', imageAvatar);
  var image = imageAvatar?.split('/');
  const onSubmit = (data) => {
    var learner_detail = {
      learner_email: data.email,
      learner_name: data.learner_name,
      learner_age: data.age,
      learner_password: data.password,
      learner_profile_img: image ? image[5] : 'avatar1.png',
      sub_cat: data.subject_category,

    };

    setCheckData(learner_detail);
    learnerDetail.push(learner_detail);

    // console.log('learner_detail :', learner_detail);

    var class_detail = {
      user_id: localStorage.getItem("PAid"),
      class_name: data.class_name,
      learner_data: learnerDetail,
      subject_category: data.subject_category,


    };

    setclassDetail(class_detail);

    reset({
      email: "",
      learner_name: "",
      age: "",
      password: "",
    });
  };
  // console.log('learnerDetail:', learnerDetail);

  const add_class_data = () => {
    // console.log('classDetail',classDetail)
    setContinuebutton(learnerDetail);
    classDetail.learner_data = learnerDetail;
    console.log('learnerDetail :', learnerDetail);

    add_class(classDetail).then((res) => {
      // console.log("res",res)
      // console.log('addClassDetail :', addClassDetail);
      if (res.code == 1) {
        setaddClassDetail(res.data);

        setLearnerDetail([]);
        TOAST_SUCCESS(res.message);
      } else {
        console.log("else");
        TOAST_ERROR(res.message);
      }
    });
    setSelectSubject([]);
    reset({
      email: "",
      learner_name: "",
      age: "",
      password: "",
      class_name: "",
      subject_category: "",
    });
  };

  const learnerRemove = (index) => {
    // alert(index);
    // learnerDetail.splice(index,1);
    // console.log('learnerDetailfsdfsdfds',learnerDetail)
    // setLearnerDetail(learnerDetail)
    const updatedLearnerDetail = learnerDetail.filter((_, i) => i !== index);
    setLearnerDetail(updatedLearnerDetail);
  };

  const delete_class = (index, id) => {
    // alert(id)
    // return
    let delete_data = {
      user_id: localStorage.getItem("PAid"),
      class_id: id.toString(),
    };
    // console.log(delete_data)

    delete_class_data(delete_data).then((res) => {
      // console.log('delete_data :', res);
      // alert('hello')
      if (res.code == 1) {
        const updatedLearnerDetail = addClassDetail.filter((_, i) => i !== index);
        setaddClassDetail(updatedLearnerDetail);
        TOAST_SUCCESS(res.message);
      } else {
        TOAST_ERROR(res.message);
      }
    });
    setSelectSubject([]);
    // reset();
  };
  // const learnerRemove = index => {
  //   // Create a new array excluding the item at the specified index
  //   const updatedLearnerDetail = learnerDetail.filter((_, i) => i !== index);
  //   setLearnerDetail(updatedLearnerDetail);
  // };

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const [value, setCountry] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setCountry(value);
    // console.log(value);
  };

  const [modalIsOpenForgot, setIsOpenForgot] = useState(false);
  // console.log('model',modalIsOpenForgot)

  const [selectSubject, setSelectSubject] = useState([]);
  const [selectDone, setSelectDone] = useState(false);

  const handleSelectSubject = (subjectName) => {
    clearErrors("subject_category")
    // selectSubject.push(subjectName)
    // setSelectSubject([...selectSubject,subjectName])
    // console.log(selectSubject)
    if (selectSubject.includes(subjectName)) {
      // selectSubject.includes(subjectName)
      let filteredArray = selectSubject.filter((item) => item !== subjectName);
      // console.log('filteredArray',filteredArray)
      setSelectSubject(filteredArray);
    } else {
      setSelectSubject((prevSelectSubject) => [...new Set([...prevSelectSubject, subjectName])]);
    }

    // console.log('subjectName :', subjectName)
  };

  const handleContinue = () =>{
    navigate('/login')
    TOAST_INFO('You must verify your email first before you can login.')
    localStorage.clear()
  }

  // console.log('cgdg',selectSubject)
  return (
    <>
      <div>
        <main>
          <div className="login_sec">
            <div className="container-fluid">
              <div className="row">
                <div
                  className="align-items-center col-lg-6 d-flex justify-content-center left"
                  style={{ backgroundImage: "url(./assets/images/auth/sign_in2.png)" }}
                >
                  <h1 className="text-white poppins fw_600 position-relative">Create Accounts for Learners Under 18 Years Old.</h1>
                </div>
                <div className="col-lg-6 right">
                  <div className="top">
                    <div className="d-md-flex justify-content-between">
                      <BackToPrevious />
                      <div>
                        <p className="fs-6 text-gray">
                          Already Have Account?
                          <Link to="/login" className="text-purple ps-2">
                            Sign In
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="btm_div form-section">
                    <div className="d-xl-flex justify-content-between align-items-center mb-5">
                      <h2 className="fw-600 text-purple poppins text-capitalize mb-xl-0 mb-4">
                        Tell us about your kids! We’ll recommend classes they’ll love.
                      </h2>
                      <span className="text-end d-block text-purple">Step 2/2</span>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                      {addClassDetail &&
                        addClassDetail.map((item, index) => {
                          // Check if learner_count is greater than 0 before rendering the item
                          if (item.learner_count > 0) {
                            return (
                              <div className="bg-gray mb-3 px-3 py-2 rounded-3" key={index}>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span className="text-dif-black fw-500">{item.class_name}</span>
                                    <div>
                                      <span className="font-14 fw-500 text-dif-black">( {item.subject_category})</span>
                                      <span className="font-14 ms-2 fw-500 text-dif-black">{item.learner_count} Learners</span>
                                    </div>
                                  </div>
                                  <a onClick={() => delete_class(index, item.id)}>
                                    <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <circle cx={15} cy={15} r="14.5" fill="white" stroke="#1D1E25" />
                                      <g clipPath="url(#clip0_114_23842)">
                                        <path
                                          d="M9.41305 21.0797C9.41305 22.0714 10.2162 22.8745 11.2079 22.8745H18.3874C19.3791 22.8745 20.1823 22.0714 20.1823 21.0797V10.3104H9.41305V21.0797ZM17.9387 7.61813L17.0412 6.7207H12.5541L11.6566 7.61813H8.51562V9.41301H21.0797V7.61813H17.9387Z"
                                          fill="#FF2929"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_114_23842">
                                          <rect width="16.1538" height="16.1538" fill="white" transform="translate(6.7207 6.7207)" />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </a>
                                </div>
                              </div>
                            );
                          } else {
                            return null; // Skip rendering this item
                          }
                        })}


                      <div className="form-group mb-4">
                        <label className="mb-2">Class Name </label>
                        <input type="text" {...register("class_name")} className="form-control" name="class_name" placeholder />
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.class_name?.message}</span>
                        </p>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Subject category </label>
                        <input
                          name="subject_category"
                          onClick={() => setIsOpenForgot(true)}
                          {...register("subject_category")}
                          type="text"
                          placeholder=""
                          value={selectDone ? selectSubject.join(",") : ""}
                          className="form-select"
                        />
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.subject_category?.message}</span>
                        </p>

                        {/* <select data-bs-toggle="modal" data-bs-target="#select_learner_like" className="form-select" aria-label="Default select example">
                          <option value={1} selected>Arts</option>
                          <option value={2}>Life Skills</option>
                          <option value={3}>Academic</option>
                          <option value={4}>Special ED</option>
                          <option value={5}>Languages</option>
                          <option value={6}>Homeschool</option>
                          <option value={7}>Coding &amp; Tech</option>
                          <option value={8}>Health and Wellness</option>
                          <option value={9}>Professional Training</option>
                          <option value={9}>Music</option>
                        </select> */}
                      </div>
                      {learnerDetail &&
                        learnerDetail.map((item, index) => {
                          return (
                            <div className="bg-gray px-3 py-2 rounded-3 mt-2">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <span className="text-dif-black fw-500">{item.learner_name}</span>
                                  <span className="font-14 ms-2 fw-500 text-dif-black">( {item.learner_age} )</span>
                                </div>
                                <a onClick={() => learnerRemove(index)}>
                                  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx={15} cy={15} r="14.5" fill="white" stroke="#1D1E25" />
                                    <g clipPath="url(#clip0_114_23842)">
                                      <path
                                        d="M9.41305 21.0797C9.41305 22.0714 10.2162 22.8745 11.2079 22.8745H18.3874C19.3791 22.8745 20.1823 22.0714 20.1823 21.0797V10.3104H9.41305V21.0797ZM17.9387 7.61813L17.0412 6.7207H12.5541L11.6566 7.61813H8.51562V9.41301H21.0797V7.61813H17.9387Z"
                                        fill="#FF2929"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_114_23842">
                                        <rect width="16.1538" height="16.1538" fill="white" transform="translate(6.7207 6.7207)" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </a>
                              </div>
                            </div>
                          );
                        })}

                      <div className="avatar-upload">
                        <div className="avatar-edit">
                          <input id="imageUpload" accept=".png, .jpg, .jpeg" />
                          <label htmlFor="imageUpload" onClick={() => setavtarModel(true)} />
                        </div>
                        <div className="avatar-preview">
                          <div
                            id="imagePreview"
                            style={{
                              backgroundImage: imageAvatar
                                ? `url("${imageAvatar}")`
                                : 'url("./assets/images/kids_avtar/avatar1.png")',
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Email ID</label>
                        <input type="text" className="form-control" {...register("email")} id="email" name="email" placeholder />
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.email?.message}</span>
                        </p>
                      </div>
                      <div className="form-group mb-4">
                        <div className="d-flex justify-content-between">
                          <label className="mb-2">Password</label>
                          <a href="#" onClick={handleTogglePassword}>
                            <span>
                              {showPassword ? (
                                <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666666">
                                  <path d="M0 0h24v24H0z" fill="none" />
                                  <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                </svg>
                              ) : (
                                // <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">

                                // </svg>

                                <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M17.0194 0.881336L16.2835 0.145393C16.0755 -0.0625975 15.6915 -0.0305884 15.4515 0.257357L12.8913 2.80132C11.7392 2.30538 10.4754 2.06538 9.14731 2.06538C5.19519 2.08132 1.77141 4.38529 0.12329 7.69749C0.0272626 7.90549 0.0272626 8.16141 0.12329 8.3374C0.891223 9.90543 2.04329 11.2015 3.4833 12.1774L1.3873 14.3054C1.1473 14.5454 1.11529 14.9293 1.27534 15.1374L2.01128 15.8733C2.21928 16.0813 2.60326 16.0493 2.84326 15.7613L16.8912 1.71345C17.1952 1.47358 17.2272 1.08962 17.0192 0.881611L17.0194 0.881336ZM9.9953 5.71322C9.72329 5.6492 9.43534 5.56925 9.16332 5.56925C7.80327 5.56925 6.71538 6.65727 6.71538 8.01718C6.71538 8.28919 6.7794 8.57714 6.85936 8.84916L5.78724 9.90519C5.46728 9.34524 5.2913 8.72114 5.2913 8.01722C5.2913 5.88924 7.00332 4.17722 9.1313 4.17722C9.83536 4.17722 10.4593 4.3532 11.0193 4.67316L9.9953 5.71322Z"
                                    fill="#666666"
                                    fillOpacity="0.8"
                                  />
                                  <path
                                    d="M18.1714 7.69743C17.6115 6.57738 16.8754 5.56945 15.9635 4.75342L12.9875 7.69743V8.01738C12.9875 10.1454 11.2754 11.8574 9.14745 11.8574H8.8275L6.93951 13.7454C7.64357 13.8893 8.37952 13.9854 9.09952 13.9854C13.0516 13.9854 16.4754 11.6814 18.1235 8.35325C18.2675 8.12918 18.2675 7.90527 18.1715 7.69726L18.1714 7.69743Z"
                                    fill="#666666"
                                    fillOpacity="0.8"
                                  />
                                </svg>
                              )}
                            </span>
                            {/* {showPassword ? 'Hide' : 'Show'} */}
                          </a>
                        </div>
                        <input
                          name="password"
                          {...register("password")}
                          type={showPassword ? "text" : "password"}
                          placeholder=""
                          id="password"
                          className="form-control"
                        />
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.password?.message}</span>
                        </p>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">learners name</label>
                        <input type="text" id="learner_name" className="form-control" {...register("learner_name")} name="learner_name" placeholder />
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.learner_name?.message}</span>
                        </p>
                      </div>
                      {/* <div className="form-group mb-4">
                        <label className="mb-2">Age</label>
                        <input type="number" {...register("age")} id="age" className="form-control" name='age' placeholder defaultValue />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.age?.message}</span></p>
                      </div> */}
                      <div className="form-group mb-4">
                        <label className="mb-2">Age</label>
                        <select className="form-select" {...register("age")} id="age" name="age">
                          <option value={''}>select age</option>
                          <option>3 Years</option>
                          <option>4 Years</option>
                          <option>5 Years</option>
                          <option>6 Years</option>
                          <option>7 Years</option>
                          <option>8 Years</option>
                          <option>9 Years</option>
                          <option>10 Years</option>
                          <option>11 Years</option>
                          <option>12 Years</option>
                          <option>13 Years</option>
                          <option>14 Years</option>
                          <option>15 Years</option>
                          <option>16 Years</option>
                          <option>17 Years</option>
                          <option>18 Years</option>
                        </select>
                        <p className="mt-2 font-bold col-red">
                          <span style={{ color: "red" }}>{errors.age?.message}</span>
                        </p>
                      </div>
                      <div className="text-end mb-3">
                        {/* <a href="#!" className="text-decoration-underline text-purple fs-6">+ Add another learner</a> */}
                        <button type="submit" className="btn-theme bg-yellow text-center fw-600 d-block">
                          + Add another learner
                        </button>
                      </div>
                      <div className="mb-4">
                        {console.log("fsdfds", checkData)}
                        {checkData && checkData != null ? (
                          <a onClick={() => add_class_data()} className="btn-theme text-center d-block fw-600">
                            + Add Another Class
                          </a>
                        ) : (
                          <a onClick={() => TOAST_ERROR("Please add atleast one learner")} className="btn-theme text-center d-block fw-600">
                            + Add Another Class
                          </a>
                        )}
                      </div>
                      <div className="mt-4">
                        {continuebutton != null && (addClassDetail && addClassDetail.length > 0)? (
                          <div className="mt-4">
                            <button  onClick={()=>handleContinue()}className="btn-theme bg-yellow text-center fw-600 d-block">
                              Continue
                            </button>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {/* <button type='submit' className="btn-theme bg-yellow text-center fw-600 d-block" >
                          Sign Up
                        </button> */}
                        {/* <Link to="/kidsDashboard" className="btn-theme bg-yellow text-center d-block fw-600">Sign Up</Link> */}
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/*start*/}
        {/* Modal */}
        <Modals isOpen={avtarModel} style={avtar_css} contentLabel="Example Modals">
          <LearnerAvatar
            setavtarModel={setavtarModel}
            handleSelectAvtar={setImageAvatar}
            setavtarDone={setavtarDone}
            setselectAvtar={setselectAvtar}
          />
        </Modals>


        <Modals isOpen={modalIsOpenForgot} style={model_css} contentLabel="Example Modal">
          <Model
            setIsOpenForgot={setIsOpenForgot}
            handleSelectSubject={handleSelectSubject}
            handleSelectAvtar={setImageAvatar}
            setSelectDone={setSelectDone}
            setSelectSubject={setSelectSubject}
            selectSubject={selectSubject}
            selectAvtar={selectAvtar}
          />
        </Modals>
        {/* <div className="modal fade" id="avtar_kids" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Select Your Avatar</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <ul className="list-unstyled your_avatar">
                  <li >
                    <a href="#" className="kid_img active">
                      <img src="./assets/images/kids_avtar/avatar1.png" alt="avatar1" />
                    </a>
                  </li>
                  <li >
                    <a href="#" className="kid_img">
                      <img src="./assets/images/kids_avtar/avatar2.png" alt="avatar2" />
                    </a>
                  </li>
                  <li >
                    <a href="#" className="kid_img">
                      <img src="./assets/images/kids_avtar/avatar3.png" alt="avatar3" />
                    </a>
                  </li>
                  <li >
                    <a href="#" className="kid_img">
                      <img src="./assets/images/kids_avtar/avatar4.png" alt="avatar4" />
                    </a>
                  </li>
                  <li >
                    <a href="#" className="kid_img">
                      <img src="./assets/images/kids_avtar/avatar5.png" alt="avatar5" />
                    </a>
                  </li>
                  <li >
                    <a href="#" className="kid_img">
                      <img src="./assets/images/kids_avtar/avatar6.png" alt="avatar6" />
                    </a>
                  </li>
                  <li >
                    <a href="#" className="kid_img">
                      <img src="./assets/images/kids_avtar/avatar7.png" alt="avatar7" />
                    </a>
                  </li>
                  <li >
                    <a href="#" className="kid_img">
                      <img src="./assets/images/kids_avtar/avatar8.png" alt="avatar8" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="modal-footer justify-content-center">
                <div className="col-lg-4 col-12">
                  <button type="button" className="btn-theme w-100 bg-yellow fw-600" data-bs-dismiss="modal">Done</button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* end*/}
        {/* Modal */}

        {/* <div className="modal fade" id="select_learner_like" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Select Your Category</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="popular_item">
                  <ul>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon active">
                          <img src="./assets/images/topic/topic1.png" alt="topic1" />
                        </div>
                        <span className="ms-4">Arts</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon active">
                          <img src="./assets/images/topic/topic2.png" alt="topic2" />
                        </div>
                        <span className="ms-4">Health and Wellness</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/topic/topic3.png" alt="topic3" />
                        </div>
                        <span className="ms-4">Languages</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/topic/topic4.png" alt="topic4" />
                        </div>
                        <span className="ms-4">Life Skills</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/topic/topic5.png" alt="topic5" />
                        </div>
                        <span className="ms-4">Coding &amp; Tech</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/topic/topic6.png" alt="topic6" />
                        </div>
                        <span className="ms-4">Music</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/topic/topic7.png" alt="topic7" />
                        </div>
                        <span className="ms-4">Professional Training</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/topic/topic8.png" alt="topic8" />
                        </div>
                        <span className="ms-4">In-Person Classes</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/topic/topic9.png" alt="topic9" />
                        </div>
                        <span className="ms-4">Academic</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex align-items-center">
                        <div className="topi_icon">
                          <img src="./assets/images/topic/topic10.png" alt="topic9" />
                        </div>
                        <span className="ms-4">Privateschool</span>
                      </a>
                    </li> 
                  </ul>	
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <div className="col-lg-4 col-12">
                  <button type="button" className="btn-theme w-100 bg-yellow fw-600" data-bs-dismiss="modal">Done</button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
